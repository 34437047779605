import styled from "styled-components";
import { Skeleton } from "antd";
import { buttonActionStyle, Colors, EXP_HORIZONTAL_SPACE } from "assets/styles/constants";
import { Link } from "react-router-dom";

export const EXPERIENCES_PAGER_HEIGHT = 38;

export const CarouselContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
`;

export const PagerWrapper = styled.div`
  height: ${EXPERIENCES_PAGER_HEIGHT}px;
`;

export const LoaderHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  height: ${EXPERIENCES_PAGER_HEIGHT}px;
`;

export const PagerBtnPlaceholder = styled(Skeleton.Button).attrs({
  active: true,
  shape: "circle",
})`
  .ant-skeleton-button {
    width: 23px !important;
    height: 23px;
    margin: 0;
  }
  margin-left: 15px;
`;

export const PagerSeeAllPlaceholder = styled(Skeleton.Button).attrs({
  active: true,
})`
  .ant-skeleton-button {
    width: 51px !important;
    height: 23px;
    margin: 0;
  }
  margin-right: 2px;
`;

export const LoaderItemWrapper = styled.div`
  display: inline-block;
  vertical-align: top;

  :not(:last-child) {
    margin-right: ${EXP_HORIZONTAL_SPACE}px;
  }
`;

export const CarouselWrapper = styled.div`
  width: 100%;
  overflow: hidden;
`;

export const StyledLink = styled(Link)`
  ${buttonActionStyle}

  &:hover {
    color: ${Colors.textColor};
  }
`;
