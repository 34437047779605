import { SelectType } from "./types";


export const industry: SelectType = [
  { key: "4.1", label: "US average" },
  { key: "3.7", label: "Private sector" },
  { key: "3.5", label: "Agriculture and related industries" },
  { key: "3.7", label: "Nonagricultural industries" },
  { key: "4.6", label: "Mining, quarrying, and oil and gas extraction" },
  { key: "4", label: "Construction" },
  { key: "5.1", label: "Manufacturing" },
  { key: "5.3", label: "Durable goods manufacturing" },
  { key: "5.2", label: "Nonmetallic mineral products" },
  { key: "5.4", label: "Primary metals and fabricated metal products" },
  { key: "5.3", label: "Machinery manufacturing" },
  { key: "6.1", label: "Computers and electronic products" },
  { key: "4.5", label: "Electrical equipment and appliances" },
  { key: "5.5", label: "Transportation equipment" },
  { key: "3.9", label: "Wood products" },
  { key: "5.5", label: "Furniture and related product manufacturing" },
  { key: "4.7", label: "Miscellaneous manufacturing" },
  { key: "4.8", label: "Nondurable goods manufacturing" },
  { key: "4.2", label: "Food manufacturing" },
  { key: "3.9", label: "Beverages and tobacco products" },
  { key: "5", label: "Textiles, apparel, and leather" },
  { key: "5.9", label: "Paper and printing" },
  { key: "5.7", label: "Petroleum and coal products" },
  { key: "5.2", label: "Chemicals" },
  { key: "4.5", label: "Plastics and rubber products" },
  { key: "3.3", label: "Wholesale and retail trade" },
  { key: "5", label: "Wholesale trade" },
  { key: "3.1", label: "Retail trade" },
  { key: "4.3", label: "Transportation and utilities" },
  { key: "3.9", label: "Transportation and warehousing" },
  { key: "7.7", label: "Utilities" },
  { key: "4.2", label: "Information" },
  { key: "5.4", label: "Publishing, except Internet" },
  { key: "2.7", label: "Motion pictures and sound recording industries" },
  {
    key: "5.3",
    label: "Radio and television broadcasting and cable subscriptions programming",
  },
  { key: "6.6", label: "Telecommunications" },
  { key: "4.8", label: "Financial activities" },
  { key: "4.9", label: "Finance and insurance" },
  { key: "4.8", label: "Finance" },
  { key: "5", label: "Insurance" },
  { key: "4.6", label: "Real estate and rental and leasing" },
  { key: "4.7", label: "Real estate" },
  { key: "3.8", label: "Rental and leasing services" },
  { key: "3.5", label: "Professional and business services" },
  { key: "3.9", label: "Professional and technical services" },
  { key: "2.9", label: "Management, administrative, and waste services" },
  { key: "2.8", label: "Administrative and support services" },
  { key: "5.3", label: "Waste management and remediation services" },
  { key: "3.8", label: "Education and health services" },
  { key: "3.9", label: "Educational services" },
  { key: "3.8", label: "Health care and social assistance" },
  { key: "5", label: "Hospitals" },
  { key: "3.4", label: "Health services, except hospitals" },
  { key: "2.9", label: "Social assistance" },
  { key: "2.3", label: "Leisure and hospitality" },
  { key: "3.3", label: "Arts, entertainment, and recreation" },
  { key: "2.1", label: "Accommodation and food services" },
  { key: "3.2", label: "Accommodation" },
  { key: "2", label: "Food services and drinking places" },
  { key: "4.1", label: "Other services" },
  { key: "4.1", label: "Other services, except private households" },
  { key: "3.3", label: "Repair and maintenance" },
  { key: "3.8", label: "Personal and laundry services" },
  { key: "4.8", label: "Membership associations and organizations" },
  { key: "4", label: "Other services, private households" },
  { key: "6.5", label: "Public sector" },
  { key: "8.2", label: "Federal government" },
  { key: "5.6", label: "State government" },
  { key: "6.6", label: "Local government" },
];
