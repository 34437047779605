import styled from "styled-components";
import { buttonActionStyle, Colors } from "assets/styles/constants";

export const PagerWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 1;
`;

export const PagerText = styled.span`
  margin-right: 14px;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
`;

export const PagerButton = styled.button`
  transition: opacity 0.2s;

  ${buttonActionStyle}

  &:not(:last-child) {
    margin-right: 15px;
  }
`;

export const PagerIcon = styled.img`
  width: 23x;
  height: 23px;
  box-shadow: 0 0 10px 0 ${Colors.lightBlueGray};
  border-radius: 50%;
`;
