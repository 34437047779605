import { makeStyles, Slider, withStyles } from "@material-ui/core";
import {
  Currency,
  FormWrapper,
  MainPageTextBlock,
  SliderWrapper,
  SoundsGood,
  TotalSum,
  TotalText,
} from "calculator_feature/common/components";
import BackButton from "calculator_feature/common/components/BackMainButton";
import NewSlideButton from "calculator_feature/common/components/NewSlideMainButton";
import ProgressProvider from "calculator_feature/common/components/ProgressBar/ProgressProvider";
import TotalTextMainPage from "calculator_feature/common/components/TotalTextMainPage";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { STORES } from "config/constants";
import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { ICalculatorStore } from "stores/calculator";
import MainSlideTitle from "../../../../../calculator_feature/common/components/MainSlideTitle";
import BarChart2 from "../../../../../calculator_feature/components/BarChart2";
import { ShareThis } from "../../../../../calculator_feature/components/ShareThis";
import reCalculate from "../../../../asserts/reCalculate.svg";
import {
  CircularWrapper,
  CutStyle,
  GreyBox,
  GridSlidersWrapper,
  LeftMainGridSlide,
  LeftMainWrapperGrid,
  MainButtonSendWrapper,
  MainPageBigText,
  MainPageLeft,
  MainPageWrapper,
  MainSlideWrapper,
  PageWrapper,
  RightWrapMain,
  StyledLink,
  ThousandWrapper,
  TotalStrong,
  TotalSumWrapper,
  TotalWrapper,
  YearsWrapper,
} from "../../../../common/slidesStyle";
import AriaChart from "../../../../components/AriaChart";
import { Form } from "../../../../components/Form";
import { IStepProps } from "../../types";
import "./index.css";
import { Theme } from "@material-ui/core";
import { useWindowSize } from "../../../../utils/hooks/useWindowSize";
import size from "../../../../config/devices";
import { Size } from "../../../../utils/hooks/useWindowSizeTypes";

export interface StyleProps {
  screenSize: boolean;
}
interface IThirdStep extends IStepProps {
  [STORES.CALCULATOR]: ICalculatorStore;
}

enum FieldName {
  liability = "liability",
}

interface FormData {
  [FieldName.liability]: number;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: {
    color: "#bfbfbf",
    height: 2,
    padding: ({ screenSize }) => (screenSize ? "0 0 15px 0" : "15px 0 15px 0"),
    marginTop: ({ screenSize }) => (screenSize ? "0" : "17px"),
    width: "70%",
    '& .MuiSlider-markLabel[data-index="0"]': {
      top: ({ screenSize }) => (screenSize ? "25%" : "12%"),
      left: ({ screenSize }) => (screenSize ? "-6% !important" : "-9% !important"),

      color: "#1c1259",
      fontFamily: "Poppins ,sans-serif ",
      fontSize: ({ screenSize }) => (screenSize ? "10px" : "14px"),
    },
    '& .MuiSlider-markLabel[data-index="1"]': {
      top: ({ screenSize }) => (screenSize ? "25%" : "12%"),
      left: ({ screenSize }) => (screenSize ? "-112% !important" : "112% !important"),
      color: "#1c1259",
      fontFamily: "Poppins ,sans-serif ",
      fontSize: ({ screenSize }) => (screenSize ? "10px" : "14px"),
    },
  },
  margin: {
    height: theme.spacing(3),
  },
}));

const useWhenStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: {
    width: "70%",
    '& .MuiSlider-markLabel[data-index="0"]': {
      top: ({ screenSize }) => (screenSize ? "25%" : "12%"),
      left: ({ screenSize }) => (screenSize ? "-6% !important" : "-9% !important"),
      color: "#1c1259",
      fontFamily: "Poppins ,sans-serif ",
      fontSize: ({ screenSize }) => (screenSize ? "10px" : "14px"),
    },
    '& .MuiSlider-markLabel[data-index="1"]': {
      top: ({ screenSize }) => (screenSize ? "25%" : "12%"),
      left: ({ screenSize }) => (screenSize ? "-112% !important" : "112% !important"),
      color: "#1c1259",
      fontFamily: "Poppins ,sans-serif ",
      fontSize: ({ screenSize }) => (screenSize ? "10px" : "14px"),
    },
  },
  margin: {
    height: theme.spacing(3),
  },
}));

const marks = [
  {
    value: 0,
    label: "0%",
  },
  {
    value: 100,
    label: "100%",
  },
];
const whenMarks = [
  {
    value: 0,
    label: (
      <div>
        <>0</>
        <br />
        <>years</>
      </div>
    ),
  },
  {
    value: 3,
    label: (
      <div>
        <>3</>
        <br />
        <>years</>
      </div>
    ),
  },
];

const StyledSlider = withStyles({
  root: {
    "& .MuiSlider-thumb::after": {
      content: "",
      position: "absolute",
      width: "10px",
      height: "10px",
      borderRadius: "50%",
      background: "#1c1259",
      top: "7.5px",
      left: "7.5px",
      bottom: "0",
      right: "0",
    },
    "& .MuiSlider-mark": {
      width: 0,
      height: 0,
    },
    "& .MuiSlider-valueLabel": {
      width: "max-content",
    },
  },

  thumb: {
    width: "25px",
    height: "25px",
    marginTop: "-9px",
    backgroundColor: "white",
    position: "relative",
    boxShadow: "0px 0px 2px 4px rgb(74 90 139 / 7%)",
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 8px)",
    top: -22,
    fontSize: "16px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
    textAlign: "center",
    color: "#bfbfbf",

    "& *": {
      background: "transparent",
      color: "#FF5277",
      fontWeight: 600,
      width: "max-content",
    },
  },

  rail: {
    height: 2,
    opacity: 0.5,
    backgroundColor: "#bfbfbf",
  },
  mark: {
    backgroundColor: "#bfbfbf",
    height: 8,
    width: 1,
    marginTop: -3,
  },
  markLabel: {
    fontFamily: "Poppins",
    fontSize: "12px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 2,
    letterSpacing: "normal",
    textAlign: "center",
    color: "#d5daeb",
  },
  markLabelActive: {
    fontSize: "12px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 2,
    letterSpacing: "normal",
    textAlign: "center",
    color: "#d5daeb",
  },
  markActive: {
    opacity: 1,
    backgroundColor: "#bfbfbf",
  },
})(Slider);

const StyledWhenSlider = withStyles({
  root: {
    // color: "#bfbfbf",
    height: 2,
    padding: "15px 0",
    "& .MuiSlider-thumb::after": {
      content: "",
      position: "absolute",
      width: "10px",
      height: "10px",
      borderRadius: "50%",
      background: "#1c1259",
      top: "7.5px",
      left: "7.5px",
      bottom: "0",
      right: "0",
    },
    "& .MuiSlider-mark": {
      width: 0,
      height: 0,
    },
  },

  thumb: {
    width: "25px",
    height: "25px",
    marginTop: "-9px",
    backgroundColor: "white",
    position: "relative",
    boxShadow: "0px 0px 2px 4px rgb(74 90 139 / 7%)",
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 8px)",
    top: -22,
    fontSize: "16px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
    textAlign: "center",
    color: "#bfbfbf",
    width: "max-content;",
    "& .PrivateValueLabel-circle-46, .PrivateValueLabel-label-47": {
      width: "max-content",
      position: "absolute",
      marginLeft: "10px",
    },

    "&  .PrivateValueLabel-circle-18, .PrivateValueLabel-circle-18": {
      width: "max-content",
      position: "absolute",
      marginLeft: "-13px",
    },

    "& *": {
      background: "transparent",
      color: "#FF5277",
      fontWeight: 600,
      width: "max-content",
    },
  },

  rail: {
    height: 2,
    opacity: 0.5,
    backgroundColor: "#bfbfbf",
  },
  mark: {
    backgroundColor: "#bfbfbf",
    height: 8,
    width: 1,
    marginTop: -3,
  },
  markLabel: {
    fontFamily: "Poppins",
    fontSize: "12px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 2,
    letterSpacing: "normal",
    textAlign: "center",
    color: "#d5daeb",
  },
  markLabelActive: {
    fontSize: "12px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 2,
    letterSpacing: "normal",
    textAlign: "center",
    color: "#d5daeb",
  },
  markActive: {
    opacity: 1,
    backgroundColor: "#bfbfbf",
  },
})(Slider);

const MainPage = ({ onChangeStep, onClose, ...props }: IThirdStep) => {
  const calcStore = props[STORES.CALCULATOR] as ICalculatorStore;
  const ourParams: any = useParams();
  const history = useHistory();
  const [recivedParams, setRecivedParams] = useState(false);

  useEffect(() => {
    if (ourParams.tenure) {
      setRecivedParams(true);
    } else {
      setRecivedParams(false);
    }

    if (ourParams?.rolloverName) {
      setLoadindValue(true);
      calcStore.setRolloverName(ourParams.rolloverName);
      setLoadindValue(false);
    }

    if (ourParams?.tenure) {
      calcStore.setTenure(Number(ourParams.tenure));
      let tenure = calcStore.tenure;
    }
    if (ourParams?.employees) {
      calcStore.setEmployees(Number(ourParams.employees));
      let employees = calcStore.employees;
    }
    if (ourParams?.salaryAvarage) {
      calcStore.setAvarageSalary(Number(ourParams.salaryAvarage));
      let avarageSalary = calcStore.avarageSalary;
    }
    if (ourParams?.avarageSalarySlider) {
      calcStore.setAvarageSalarySlider(Number(ourParams.avarageSalarySlider));
      let avarageSalarySlider = calcStore.avarageSalarySlider;
    }
    if (ourParams?.taxRate) {
      calcStore.setTaxRate(Number(ourParams.taxRate));
      let taxRate = calcStore.taxRate;
    }
    if (ourParams?.rolloverSlider) {
      calcStore.setRolloverSlider(Number(ourParams.rolloverSlider));
      let rolloverSlider = calcStore.rolloverSlider;
    }
    if (ourParams?.paidTimeSlider) {
      calcStore.setPaidTimeSlider(Number(ourParams.paidTimeSlider));
      let paidTimeSlider = calcStore.paidTimeSlider;
    }
    if (ourParams?.usedPaidTime) {
      calcStore.setAvarageSlider(Number(ourParams.usedPaidTime));
      let usedPaidTime = calcStore.usedPaidTime;
    }
    if (ourParams?.liabilitySorber_G14) {
      setLoadindValue(true);
      calcStore.setLiabilitySlider(Number(ourParams.liabilitySorber_G14));
      let liabilitySorber_G14 = calcStore.liabilitySorber_G14;
      setValue(liabilitySorber_G14);
      setLoadindValue(false);
    }
    if (ourParams?.whenRepay) {
      setLoadindValue(true);
      calcStore.setWhenRepay(Number(ourParams.whenRepay));
      let whenRepay = calcStore.whenRepay;
      setWhenValue(whenRepay);
      setLoadindValue(false);
    }
  }, [ourParams]);

  useEffect(() => {
    let firstLoop: any = calcStore.firstLoop;
    let secondLoop: any = calcStore.secondLoop;
    let thirdLoop: any = calcStore.thirdLoop;
    let fourthLoop: any = calcStore.fourthLoop;
    let getSumSorbetManaged = calcStore.getSumSorbetManaged;
    let getResultFundingCost = calcStore.getResultFundingCost;
    let getResultInflation = calcStore.getResultInflation;
    let getNet = calcStore.getNet;
    let getSumOfTaxSaving: any = calcStore.getSumOfTaxSaving;
    let getSumOfSorbetManages: any = calcStore.getSumOfSorbetManages;
    let getSumOfImpactSorbet: any = calcStore.getSumOfImpactSorbet;
    let sorbetYearsRowAria = calcStore.getSorbetYearsRow;
    let totalSavings: any = calcStore.getTotalSavings;
    let liabilitySorber_G14: any = calcStore.liabilitySorber_G14;
    let dataCalc: any = toJS(calcStore);
    let savingPerUsd: any = calcStore.getSavingPerUsd;
    console.clear();
  });
  const [yearsWithSorbet, setYearsWithSorbet] = useState(68);
  const [loadindValue, setLoadindValue] = useState(false);
  const [active2, setActive2] = useState(false);
  const [active3, setActive3] = useState(true);
  const [localValue, setValue] = useState(calcStore.liabilitySorber_G14);
  const [localWhenValue, setWhenValue] = useState(calcStore.whenRepay);

  const { register, handleSubmit, errors, control } = useForm({
    defaultValues: {
      liability: 0,
      cashflow: 6,
    },
    mode: "onBlur",
  });
  const resetClick = () => {
    calcStore.clearStore();
    history.push("./");
    window.location.reload();
  };

  const sizes: Size = useWindowSize();
  const tabletWidth = +size.tablet.slice(0, -2);
  const mobileView = () => {
    if (sizes.width) {
      if (sizes.width < tabletWidth) {
        return { screenSize: true };
      }
      return { screenSize: false };
    }
  };
  const mobile = mobileView();
  const classes = useStyles(mobileView());
  const whenClasses = useWhenStyles(mobileView());

  const onSubmit = (data: FormData) => {
    calcStore.increaseProgress(99);
    handleLetsTalk();
    console.clear();
  };

  let handleClick1yearTimerId: any;
  const debounce1yearFunc = (value: any, delay: number) => {
    handleClick1yearTimerId = setTimeout(() => {
      calcStore.setHowLong(value);
    }, delay);
    handleClick1yearTimerId = undefined;
  };

  let liabilitySorber_G14 = calcStore.liabilitySorber_G14;
  let howLong = calcStore.howLong;
  let liabilityTimerId: any;
  const debounceFunc = (localValue: any, delay: number) => {
    clearTimeout(liabilityTimerId);

    liabilityTimerId = setTimeout(() => {
      calcStore.setLiabilitySlider(localValue);
    }, delay);
    liabilityTimerId = undefined;
  };

  const handleLiabilityChange = (event: React.ChangeEvent<{}>, newValue: any) => {
    setValue(newValue);
    debounceFunc(newValue, 200);
  };

  let timerId = undefined;
  const throttleCashflowFunc = (value: any, delay: number) => {
    timerId = setTimeout(() => {
      calcStore.setWhenRepay(value);
    }, delay);
    timerId = undefined;
  };

  const handleCashflowChange = (event: React.ChangeEvent<{}>, newValue: any) => {
    throttleCashflowFunc(newValue, 500);
    setWhenValue(newValue);
  };

  const totalRes = 
  (
    Number(calcStore.resultInflation.toFixed(2)) - Number(calcStore.resultFundingCost.toFixed(2))
  ).toFixed(0);

  const outTextValue = isNaN(Number(totalRes))
    ? 0
    : `$${String(totalRes).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
  function valuetext(value: number) {
    return `${value}%`;
  }
  function valueWhenRepay(value: number) {
    return `${value} years`;
  }

  function sumValue(value: number) {
    if (value < 0) {
      return -value;
    } else if (isNaN(value)) {
      return 0;
    }
    const outSum = String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const cutMainSum = String(outSum).replace(/\.(.*)/gm, "");

    const cutSum = String(value).match(/\.(.*)/gm);
    return (
      <div>
        {cutMainSum}
        <CutStyle style={{ display: "inline" }}>{cutSum}</CutStyle>
      </div>
    );
  }

  const handleShareButton = () => {
    calcStore.setLiabilitySlider(localValue);
    calcStore.setWhenRepay(localWhenValue);
    if (recivedParams) {
      const paramsList = [
        { tenure: calcStore.tenure },
        { employees: calcStore.employees },
        { avarageSalary: calcStore.avarageSalary },
        { avarageSalarySlider: calcStore.avarageSalarySlider },
        { taxRate: calcStore.taxRate },
        { rolloverSlider: calcStore.rolloverSlider },
        { paidTimeSlider: calcStore.paidTimeSlider },
        { usedPaidTime: calcStore.usedPaidTime },
        { liabilitySorber_G14: calcStore.liabilitySorber_G14 },
        { whenRepay: calcStore.whenRepay },
        { rolloverName: calcStore.rolloverName },
      ];

      const ourUrl = "./emailpage";
      window.history.pushState(paramsList, document.title, "/" + ourUrl);
      window.history.go();
    } else {
      if (
        (calcStore.rolloverName === "rollover" || calcStore.rolloverName === "unlimited") &&
        calcStore.rolloverSlider > 0
      ) {
        const paramsList = [
          { tenure: calcStore.tenure },
          { employees: calcStore.employees },
          { avarageSalary: calcStore.avarageSalary },
          { avarageSalarySlider: calcStore.avarageSalarySlider },
          { taxRate: calcStore.taxRate },
          { rolloverSlider: 50 },
          { paidTimeSlider: calcStore.paidTimeSlider },
          { usedPaidTime: calcStore.usedPaidTime },
          { liabilitySorber_G14: calcStore.liabilitySorber_G14 },
          { whenRepay: calcStore.whenRepay },
          { rolloverName: calcStore.rolloverName },
        ];
        history.push("./emailpage", { userData: paramsList });
        return paramsList;
      } else {
        const paramsList = [
          { tenure: calcStore.tenure },
          { employees: calcStore.employees },
          { avarageSalary: calcStore.avarageSalary },
          { avarageSalarySlider: calcStore.avarageSalarySlider },
          { taxRate: calcStore.taxRate },
          { rolloverSlider: calcStore.rolloverSlider },
          { paidTimeSlider: calcStore.paidTimeSlider },
          { usedPaidTime: calcStore.usedPaidTime },
          { liabilitySorber_G14: calcStore.liabilitySorber_G14 },
          { whenRepay: calcStore.whenRepay },
          { rolloverName: calcStore.rolloverName },
        ];
        history.push("./emailpage", { userData: paramsList });
        return paramsList;
      }
    }
  };

  const handleSoundsGood = () => {
    window.open(
      "https://meetings.hubspot.com/veetahl?utm_source=calculator&utm_medium=cta&utm_campaign=chat_with_us",
      "_blank",
    );
  };
  const handleLetsTalk = () => {
    window.open(
      "https://meetings.hubspot.com/veetahl?utm_source=calculator&utm_medium=cta&utm_campaign=lets_talk",
      "_blank",
    );
  };
  const handleBackButClick = () => {
    history.push("./paidpolicystep");
    calcStore.setBackTrigger(1);
  };
  const gradientId = "contest-prize";
  const foundCostPrepear = sumValue(Number(calcStore.resultFundingCost.toFixed(0)));
  return (
    <MainPageWrapper className="pageWrapper">
      <div style={{ visibility: "hidden", height: 0 }}>{calcStore.whenRepay}</div>
      <SoundsGood onClick={handleSoundsGood}>TELL ME MORE</SoundsGood>
      <MainSlideWrapper className="SlideWrapper">
        <LeftMainWrapperGrid>
          <MainPageLeft>
            <LeftMainGridSlide>
              <MainPageTextBlock />
              <MainSlideTitle>% of liability financed through Sorbet</MainSlideTitle>
              <SliderWrapper>
                <div className={classes.root}>
                  <StyledSlider
                    {...register("liability", {
                      required: "required",
                    })}
                    step={1}
                    marks={marks}
                    min={0}
                    max={100}
                    valueLabelDisplay="on"
                    getAriaValueText={valuetext}
                    valueLabelFormat={valuetext}
                    onChange={handleLiabilityChange}
                    defaultValue={ourParams.liabilitySorber_G14 || localValue}
                    {...props}
                  />
                </div>
              </SliderWrapper>
              <MainSlideTitle style={{ paddingTop: "30px" }}>
                When would you like to repay Sorbet?
              </MainSlideTitle>
              <SliderWrapper>
                <div className={whenClasses.root}>
                  <StyledWhenSlider
                    {...register("cashflow", {
                      required: "required",
                    })}
                    getAriaValueText={valueWhenRepay}
                    valueLabelFormat={valueWhenRepay}
                    onChange={handleCashflowChange}
                    defaultValue={ourParams.whenRepay || localWhenValue}
                    step={1}
                    marks={whenMarks}
                    min={0}
                    max={3}
                    valueLabelDisplay="on"
                    {...props}
                  />
                </div>
              </SliderWrapper>
              {!!mobile?.screenSize && !recivedParams ? (
                <div style={{ padding: "7px 0 0 16px" }}>
                  <MainButtonSendWrapper>
                    <img
                      src={reCalculate}
                      alt=""
                      onClick={resetClick}
                      style={{ cursor: "pointer" }}
                    />
                    <StyledLink to="/" onClick={resetClick}>
                      Re-Calculate
                    </StyledLink>
                  </MainButtonSendWrapper>
                </div>
              ) : recivedParams ? (
                ""
              ) : (
                <MainButtonSendWrapper mainPage>
                  <img
                    src={reCalculate}
                    alt=""
                    onClick={resetClick}
                    style={{ cursor: "pointer" }}
                  />
                  <StyledLink to="/" onClick={resetClick}>
                    Re-Calculate
                  </StyledLink>
                </MainButtonSendWrapper>
              )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "baseline",
                  margin: 0,
                  minWidth: "80%",
                  justifyContent: "start",
                  marginBottom: "40px",
                }}>
                {!!mobile?.screenSize ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "baseline",
                      paddingLeft: "35px",
                    }}>
                    <NewSlideButton>Lets Talk</NewSlideButton>
                    {!recivedParams && (
                      <BackButton type="text" onClick={handleBackButClick}>
                        Back
                      </BackButton>
                    )}
                  </div>
                ) : (
                  <>
                    <NewSlideButton>Lets Talk</NewSlideButton>
                    {!recivedParams && (
                      <BackButton type="text" onClick={handleBackButClick}>
                        Back
                      </BackButton>
                    )}
                  </>
                )}
              </div>
            </LeftMainGridSlide>
          </MainPageLeft>
        </LeftMainWrapperGrid>

        <RightWrapMain>
          <FormWrapper style={{ marginTop: 0 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                paddingBottom: "28px",
              }}>
              {!!mobile?.screenSize && !recivedParams ? (
                <div style={{ position: "absolute", paddingRight: "17px" }}>
                  <ShareThis shareClick={handleShareButton} />
                </div>
              ) : recivedParams ? (
                ""
              ) : (
                <ShareThis shareClick={handleShareButton} />
              )}
            </div>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <GridSlidersWrapper style={{ pointerEvents: "none" }}>
                <GreyBox>
                  <TotalSumWrapper>
                    <TotalTextMainPage>Saving </TotalTextMainPage>
                    <TotalSum>{outTextValue}</TotalSum>
                  </TotalSumWrapper>
                </GreyBox>
                <GreyBox>
                  <TotalSumWrapper>
                    <TotalTextMainPage>Added Tax deduction </TotalTextMainPage>
                    {/* <TotalSum>{sumValue(Number(calcStore.sumOfannualTaxSaving.toFixed(0)))}</TotalSum> */}
                    <TotalSum>${sumValue(Number(calcStore.sumOfImpactSorbet.toFixed(0)))}</TotalSum>
                  </TotalSumWrapper>
                </GreyBox>
              </GridSlidersWrapper>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  justifyContent: "center",
                }}>
                <MainSlideTitle>
                  {mobile?.screenSize ? (
                    <div style={{ marginTop: "20px" }}>
                      You will save ${toJS(calcStore.savingPerUsd).toFixed(2)} and increase your tax
                      deduction by $
                      {toJS(+calcStore.sumOfImpactSorbet / calcStore.sumOfSorbetManages).toFixed(2)}{" "}
                      per $1.
                    </div>
                  ) : (
                    <div style={{ marginLeft: "25px" }}>
                      You will save ${toJS(calcStore.savingPerUsd).toFixed(2)} and increase your tax
                      deduction by $
                      {toJS(+calcStore.sumOfImpactSorbet / calcStore.sumOfSorbetManages).toFixed(2)}{" "}
                      per $1.
                    </div>
                  )}
                </MainSlideTitle>
                <div style={{ marginTop: "20px" }}>
                  <div style={{ position: "relative" }}>
                    <ThousandWrapper>$Thousands</ThousandWrapper>
                    <YearsWrapper>Years</YearsWrapper>
                    <BarChart2 />
                  </div>
                </div>

                <div style={{ marginTop: "-30px" }}>
                  <MainSlideTitle>
                    {mobile?.screenSize ? (
                      <div>Cash flow certainty and control through Sorbet</div>
                    ) : (
                      <div style={{ marginLeft: "35px" }}>
                        Cash flow certainty and control through Sorbet
                      </div>
                    )}
                  </MainSlideTitle>
                  <div style={{ position: "relative" }}>
                    <ThousandWrapper>$Thousands</ThousandWrapper>
                    <YearsWrapper>Years</YearsWrapper>
                    <AriaChart />
                  </div>
                </div>
              </div>
            </Form>
            {mobile?.screenSize ? (
              <div>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                    <MainSlideTitle>Read this</MainSlideTitle>
                  </AccordionSummary>
                  <AccordionDetails>
                    <MainPageBigText>
                      Shift Time, Inc. does not provide tax, legal or accounting advice. The
                      information provided on our website is intended for general informational and
                      illustrative purposes only and is not intended to provide legal, financial,
                      tax and/or accounting advice on any matter, and is not to be used as such. You
                      must not rely on the information provided on our website as an alternative to
                      legal, financial, tax and/or accounting advice from an appropriately qualified
                      professional. If you have any specific questions about any legal, financial,
                      tax and/or accounting matter you should consult an appropriately qualified
                      professional.We do not represent, warrant, undertake or guarantee that the use
                      of information on our website will lead to any particular outcome or result.
                      We make no representation or warranty with regard to the accuracy or
                      completeness of the information provided on our website. We shall have no
                      liability for any errors or omissions or any business losses with respect to
                      or arising from such information, including without limitation, loss of or
                      damage to profits, income, revenue, use, production, anticipated savings,
                      business, contracts, commercial opportunities or goodwill.The trademarks,
                      service marks, and logos used on the website are registered and unregistered
                      trademarks or service marks of Shift Time, Inc. or its licensors (“Marks”).
                      Nothing on the website should be construed as granting, by implication,
                      estoppel, or otherwise, any license or right to use the Marks, without our
                      prior written permission specific for each such use.
                    </MainPageBigText>
                  </AccordionDetails>
                </Accordion>
              </div>
            ) : (
              <MainPageBigText>
                Shift Time, Inc. does not provide tax, legal or accounting advice. The information
                provided on our website is intended for general informational and illustrative
                purposes only and is not intended to provide legal, financial, tax and/or accounting
                advice on any matter, and is not to be used as such. You must not rely on the
                information provided on our website as an alternative to legal, financial, tax
                and/or accounting advice from an appropriately qualified professional. If you have
                any specific questions about any legal, financial, tax and/or accounting matter you
                should consult an appropriately qualified professional.We do not represent, warrant,
                undertake or guarantee that the use of information on our website will lead to any
                particular outcome or result. We make no representation or warranty with regard to
                the accuracy or completeness of the information provided on our website. We shall
                have no liability for any errors or omissions or any business losses with respect to
                or arising from such information, including without limitation, loss of or damage to
                profits, income, revenue, use, production, anticipated savings, business, contracts,
                commercial opportunities or goodwill.The trademarks, service marks, and logos used
                on the website are registered and unregistered trademarks or service marks of Shift
                Time, Inc. or its licensors (“Marks”). Nothing on the website should be construed as
                granting, by implication, estoppel, or otherwise, any license or right to use the
                Marks, without our prior written permission specific for each such use.
              </MainPageBigText>
            )}
          </FormWrapper>
        </RightWrapMain>
      </MainSlideWrapper>
    </MainPageWrapper>
  );
};

export default inject(STORES.CALCULATOR)(observer(MainPage));
