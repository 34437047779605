// Core
import React, { PureComponent } from "react";
import { inject, observer } from "mobx-react";
import { Menu } from "antd";
import { UnorderedListOutlined } from "@ant-design/icons";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";

// Components
import { Dropdown } from "components/Dropdown";
import { UserAvatar, IUserAvatarProps } from "components/UserAvatar";

// Styles
import {
  StyledHeader,
  StyledLogo,
  HeaderNav,
  StyledNavLink,
  InfoWrapper,
  LoginButton,
  NameLink,
  NavMenuButton,
  NavMenuButtonWrapper,
  NavLinksWrapper,
} from "./styles";

// Assets
import loggedOutAvatarImage from "assets/images/avatar-logged-out.svg";
import logo from "assets/images/logo.svg";

// Types
import { IUserStore } from "stores/user/types";
import { IAppStore } from "stores/app/types";
import { RegionCodes } from "services/api/types";

// Other
import { STORES, ROUTES, ACCOUNT_ROUTES } from "config/constants";
import { notificationStore } from "stores/notification";
import { IRouteConfig, NAVIGATION_ROUTES } from "config/navigation";

interface INavLinkData extends IRouteConfig {
  title: string;
  activeGroup?: string[];
  onClick?: () => void;
}

interface IProfileMenuButton {
  title: string;
  onClick: () => void;
}

interface IProps extends RouteComponentProps {
  [STORES.USER]?: IUserStore;
  [STORES.APP]?: IAppStore;
}

@inject(STORES.USER, STORES.APP)
@observer
class HeaderComponent extends PureComponent<IProps> {
  private get userStore() {
    return this.props[STORES.USER] as IUserStore;
  }

  private get appStore() {
    return this.props[STORES.APP] as IAppStore;
  }

  private get navLinks(): INavLinkData[] {
    return [
      {
        ...NAVIGATION_ROUTES[ROUTES.HOME],
        activeGroup: [
          ROUTES.EXPERIENCES,
          ROUTES.EXPERIENCE,
          ROUTES.TRENDING,
          ROUTES.LOGIN,
          ROUTES.RATE,
          ROUTES.WAYS_TO_USE_GIFT,
          ROUTES.COMPLETE_ONBOARDING,
        ],
        title: "Explore",
      },
      { ...NAVIGATION_ROUTES[ROUTES.CALENDAR], title: "Calendar" },
      {
        ...NAVIGATION_ROUTES[ROUTES.CARD],
        title: "My Sorbet Card",
        onClick:
          this.userStore.userInfo?.region === RegionCodes.IL
            ? this.appStore.showCardActivationModal
            : undefined,
      },
      { ...NAVIGATION_ROUTES[ROUTES.ABOUT], title: "About" },
    ];
  }

  private get menuButtons(): IProfileMenuButton[] {
    const { history } = this.props;
    return [
      {
        title: "My account",
        onClick: () => history.push(ACCOUNT_ROUTES.ACCOUNT),
      },
      // {
      //   title: "Complete my profile",
      //   onClick: () => history.push(ACCOUNT_ROUTES.COMPLETE_PROFILE),
      // },
      // {
      //   title: "My wellness goal",
      //   onClick: () => history.push(ACCOUNT_ROUTES.CHANGE_WELLNESS),
      // },
      {
        title: "Sorbet history",
        onClick: () => history.push(ACCOUNT_ROUTES.SORBET_HISTORY),
      },
      {
        title: "Saved sorbets",
        onClick: () => history.push(ACCOUNT_ROUTES.SAVED_SORBET),
      },
      {
        title: "Sign out",
        onClick: () =>
          notificationStore.showDialog({
            title: "Are you sure you want to sign out?",
            onConfirm: () => this.userStore.logOut(),
            confirmButtonTitle: "Sign Out",
          }),
      },
    ];
  }

  private get avatarProps(): IUserAvatarProps {
    const { isAuthenticated, userInfo } = this.userStore;
    if (isAuthenticated) {
      return { user: userInfo };
    }
    return { src: loggedOutAvatarImage };
  }

  showAuthModal = this.appStore.showAuthModal;

  renderUserInfo = () => {
    const { isAuthenticated, userInfo } = this.userStore;

    if (!isAuthenticated) {
      return (
        <LoginButton data-testid="headerLoginButton" onClick={() => this.showAuthModal()}>
          Log In
        </LoginButton>
      );
    }

    return <NameLink to={ROUTES.ACCOUNT}>Hi, {userInfo?.firstName}</NameLink>;
  };

  renderNavLinks = () => {
    const { location } = this.props;
    const { isAuthenticated } = this.userStore;
    return (
      <NavLinksWrapper>
        {this.navLinks.map(({ title, path, activeGroup, isPrivate, onClick }) => {
          let isActive = location.pathname === path;
          if (!isActive) {
            isActive = activeGroup
              ? activeGroup.some((p) => location.pathname.includes(p))
              : location.pathname.includes(path);
          }

          const requireLogin = isPrivate && !isAuthenticated;

          return (
            <StyledNavLink
              key={path}
              to={requireLogin || onClick ? {} : path}
              onClick={requireLogin ? () => this.showAuthModal({ loginReferrer: path }) : onClick}
              $active={isActive}>
              {title}
            </StyledNavLink>
          );
        })}
      </NavLinksWrapper>
    );
  };

  renderProfileMenu = () => (
    <Menu selectable={false}>
      {this.menuButtons.map(({ title, onClick }) => (
        <Menu.Item key={title} onClick={onClick}>
          {title}
        </Menu.Item>
      ))}
    </Menu>
  );

  getPopupContainer = (triggerNode: HTMLElement) => triggerNode.parentElement as HTMLElement;

  render() {
    const { isAuthenticated } = this.userStore;

    return (
      <StyledHeader>
        <StyledLogo>
          <Link to={ROUTES.HOME}>
            <img src={logo} alt="Sorbet" />
          </Link>
        </StyledLogo>
        <NavMenuButtonWrapper>
          <Dropdown overlay={this.renderNavLinks()} getPopupContainer={this.getPopupContainer}>
            <NavMenuButton>
              <UnorderedListOutlined />
            </NavMenuButton>
          </Dropdown>
        </NavMenuButtonWrapper>
        <HeaderNav>{this.renderNavLinks()}</HeaderNav>
        <InfoWrapper>{this.renderUserInfo()}</InfoWrapper>
        <div>
          <Dropdown
            overlay={this.renderProfileMenu()}
            getPopupContainer={this.getPopupContainer}
            disabled={!isAuthenticated}>
            <UserAvatar size={50} {...this.avatarProps} />
          </Dropdown>
        </div>
      </StyledHeader>
    );
  }
}

export const Header = withRouter(HeaderComponent);
