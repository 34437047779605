import CancelIcon from "@material-ui/icons/Cancel";
import BackButton from "calculator_feature/common/components/BackPaidButton";
import NewPageTextBlock from "calculator_feature/common/components/NewPageTextBlock";
import NewSlideButton from "calculator_feature/common/components/NewSlideButton";
import { STORES } from "config/constants";
import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { ICalculatorStore } from "stores/calculator";
import light from "../../../../asserts/light.svg";
import {
  FormWrapper,
  SliderWrapper,
  SlideTitle,
  SmallText,
  SoundsGood,
  SubSlideTitle,
} from "../../../../common/components";
import LightWithText from "../../../../common/components/LightWithText";
import {
  ButtonsBlock,
  GridWrapper,
  LeftGrid,
  LeftGridSlide,
  LeftWrapperGrid,
  NewSlideWrapper,
  PageWrapper,
  RightPostGrid,
  RightWrapperPaidGrid,
} from "../../../../common/slidesStyle";
import { CustomStyles } from "../../../../common/types/formInputsStyles/selectStyles";
import AvarageSlider from "../../../../components/AvarageSlider";
import { Form } from "../../../../components/Form";
import PaidTimeSlider from "../../../../components/PaidTimeSlider";
import RolloverSlider from "../../../../components/RolloverSlider";
import { rolloverPolicy } from "../../../../config/rollover";
import { IStepProps } from "../../types";

interface IFirstStep extends IStepProps {
  [STORES.CALCULATOR]: ICalculatorStore;
}

enum FieldName {
  rolloverData = "rolloverData",
  paidTimeData = "paidTimeData",
  avarageData = "avarageData",
}

interface FormData {
  [FieldName.paidTimeData]: number;
  [FieldName.rolloverData]: number;
  [FieldName.avarageData]: number;
}

interface markType {
  value: number;
  label: string;
}

const PaidPolicy = ({ onChangeStep, onClose, ...props }: IFirstStep): JSX.Element => {
  const calcStore = props[STORES.CALCULATOR] as ICalculatorStore;
  const history = useHistory();
  const [rolloverName, setRolloverName] = useState("days");
  const [paidTime, setPaidTime] = useState(+localStorage.getItem("paidTimer"));
  const [rolloverValue, setRolloverValue] = useState(10);
  const [avarageValue, setAvarageValue] = useState(72);
  useEffect(() => {
    setRolloverName(calcStore.rolloverName);
    if (calcStore.backTrigger === 1 && calcStore.rolloverName === "days") {
      setRolloverValue(calcStore.rolloverSlider);
    }
    if (calcStore.backTrigger === 1 && calcStore.rolloverName === "rollover") {
      setRolloverValue(calcStore.rolloverSlider);
    }
    if (calcStore.backTrigger === 0 && calcStore.rolloverName === "days") {
      setRolloverValue(10);
    }
    if (calcStore.backTrigger === 0 && calcStore.rolloverName === "rollover") {
      setRolloverValue(1);
    }
  }, [calcStore.rolloverName]);

  useEffect(() => {
    calcStore.increaseProgress(99);
    localStorage.setItem("paidTimer", String(calcStore.paidTimeSlider));
    setPaidTime(calcStore.paidTimeSlider);
    setAvarageValue(calcStore.usedPaidTime * 100);
    console.clear();
  });

  /**
   * markers for sliders
   */
  const paidTimeMarks: markType[] = [
    {
      value: 0,
      label: "0",
    },
    {
      value: 50,
      label: "50",
    },
  ];

  const [rollover, setRollover] = useState(
    calcStore.rolloverName === "rollover"
      ? {
          // defaultValue: "1",
          min: 0,
          max: 5,
          step: 1,
          marks: [
            {
              value: 0,
              label: "0",
            },
            {
              value: 5,
              label: "5",
            },
          ],
        }
      : {
          // defaultValue: "18",
          min: 0,
          max: 50,
          step: 1,
          marks: [
            {
              value: 0,
              label: "0",
            },
            {
              value: 50,
              label: "50",
            },
          ],
        },
  );
  const [rolloverVisible, setRolloverVisible] = useState(
    calcStore.rolloverName === "unlimited" ? false : true,
  );
  const [avarageVisible, setavarageVisible] = useState(false);
  const [lightIsVisible, setlightIsVisible] = useState(true);
  useEffect(() => {}, [rolloverVisible]);
  const { register, handleSubmit, control } = useForm({
    defaultValues: {
      rolloverPolicy: 10,
      paidTime: localStorage.getItem("paidTimer"),
      avarage: 0.72,  
    },
    mode: "onBlur",
  });
  // MainPage
  const onSubmit = (data: FormData) => {
    if (calcStore.rolloverName === "rollover" && calcStore.rolloverSlider) {
      calcStore.setRolloverSlider(calcStore.rolloverSlider);
      calcStore.increaseProgress(99);
      calcStore.setBackTrigger(0);
      history.push("./mainpage");
    } else if (calcStore.rolloverName === "unlimited") {
      // calcStore.setRolloverSlider(0);
      calcStore.increaseProgress(99);
      calcStore.setBackTrigger(0);
      history.push("./mainpage");
    }
    calcStore.increaseProgress(99);
    calcStore.setBackTrigger(0);
    history.push("./mainpage");
  };

  const handleRolloverChange = (e: React.BaseSyntheticEvent, newValue: number) => {
    calcStore.setRolloverSlider(newValue);
  };

  const handlePaidTimeChange = (event: React.ChangeEvent<HTMLInputElement>, newValue: number) => {
    localStorage.setItem("paidTimer", String(newValue));
    calcStore.setPaidTimeSlider(newValue);
  };

  const valuetext = (avarageValue: number) => {
    return `${avarageValue}%`;
  };

  const handleAvarageChange = (event: React.ChangeEvent<HTMLInputElement>, newValue: number) => {
    localStorage.setItem("avarage", String(newValue));
    calcStore.setAvarageSlider(newValue / 100);
  };

  const options = rolloverPolicy;

  const linkHandler = () => {
    setRolloverValue(calcStore.rolloverSlider);
    setlightIsVisible(false);
    setRolloverVisible(false);
    setavarageVisible(true);
  };

  const handleChange = (e: any) => {
    if (e.label === "Annual multiple") {
      calcStore.setBackTrigger(0);
      calcStore.setRolloverName(e.key);
      calcStore.setRolloverSlider(1);
      setRolloverValue(1);
      setRollover({
        min: 0,
        max: 5,
        step: 1,
        marks: [
          {
            value: 0,
            label: "0",
          },
          {
            value: 5,
            label: "5",
          },
        ],
      });
      setavarageVisible(false);
      setRolloverVisible(true);
      setlightIsVisible(true);
    } else if (e.label === "Number of days") {
      calcStore.setBackTrigger(0);
      calcStore.setRolloverName(e.key);
      setRolloverValue(10);
      calcStore.setRolloverSlider(10);
      setRollover({
        min: 0,
        max: 50,
        step: 1,
        marks: [
          {
            value: 0,
            label: "0",
          },
          {
            value: 50,
            label: "50",
          },
        ],
      });
      setlightIsVisible(true);
      setRolloverVisible(true);
      // setavarageVisible(true);
    } else if (e.label === "Uncapped") {
      calcStore.setBackTrigger(0);
      calcStore.setRolloverName(e.key);
      setavarageVisible(false);
      setlightIsVisible(true);
      setRolloverVisible(false);
    }
  };

  const handleSoundsGood = () => {
    window.open(
      "https://meetings.hubspot.com/veetahl?utm_source=calculator&utm_medium=cta&utm_campaign=chat_with_us",
      "_blank",
    );
  };

  return (
    <PageWrapper className="pageWrapper" isPaidPolicyPage={true}>
      <SoundsGood onClick={handleSoundsGood}>TELL ME MORE</SoundsGood>
      <NewSlideWrapper className="SlideWrapper">
        <LeftWrapperGrid>
          <LeftGridSlide>
            <NewPageTextBlock />
          </LeftGridSlide>
        </LeftWrapperGrid>
        <RightWrapperPaidGrid>
          <SlideTitle>What is your current Paid time off policy?</SlideTitle>
          <RightPostGrid>
            <SmallText style={{ marginBottom: "1%" }}>
              Don’t know or don’t feel comfortable sharing this info? <br />
              Don’t worry about it! <br /> We’ll use industry averages as default.
            </SmallText>

            <FormWrapper style={{ marginTop: "1%" }}>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <GridWrapper>
                  <SubSlideTitle style={{ display: "contents", zIndex: "0" }}>
                    Annual accruable paid time off allocation
                  </SubSlideTitle>
                  <SliderWrapper>
                    <PaidTimeSlider
                      marks={paidTimeMarks}
                      defaultValue={+localStorage.getItem("paidTimer")}
                      onChange={handlePaidTimeChange}
                    />
                  </SliderWrapper>
                  <LeftGrid>
                    <SubSlideTitle style={{ display: "contents", zIndex: "0" }}>
                      Annual max rollover policy
                    </SubSlideTitle>
                    <div style={{ width: "162px"}}>
                      <Controller
                        control={control}
                        name="rolloverPolicy"
                        render={({ onChange, value, name, ref }: any) => (
                          <Select
                            inputRef={ref}
                            options={options}
                            styles={CustomStyles()}
                            placeholder="Number of days"
                            value={options.filter((c: any) => c.key === calcStore.rolloverName)}
                            onChange={handleChange}
                            components={{
                              IndicatorSeparator: () => null,
                            }}
                          />
                        )}
                      />
                    </div>
                  </LeftGrid>

                  <SliderWrapper>
                    {rolloverVisible && (
                      <RolloverSlider
                        {...register("rolloverPolicy", {
                          required: "required",
                        })}
                        onChange={handleRolloverChange}
                        marks={rollover.marks}
                        defaultValue={rolloverValue}
                        min={rollover.min}
                        max={rollover.max}
                        step={rollover.step}
                      />
                    )}
                  </SliderWrapper>
                  {avarageVisible && (
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "baseline",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setAvarageValue(Number((calcStore.usedPaidTime * 100).toFixed(0)));
                          setRolloverValue(calcStore.rolloverSlider);
                          setavarageVisible(false);
                          setlightIsVisible(true);
                        }}>
                        <SubSlideTitle style={{ display: "contents", zIndex: "0" }}>
                          <CancelIcon style={{ marginRight: "10px", alignSelf: "center" }} />
                          Average % of allocation used annually
                        </SubSlideTitle>
                      </div>
                      <SliderWrapper>
                        <AvarageSlider
                          {...register("avarage", {
                            required: "required",
                          })}
                          defaultValue={avarageValue}
                          getAriaValueText={valuetext}
                          valueLabelFormat={valuetext}
                          onChange={handleAvarageChange}
                        />
                      </SliderWrapper>
                    </>
                  )}
                </GridWrapper>
                {lightIsVisible && (
                  <LightWithText>
                    <LightWithText.LightIcon>
                      <img src={light} alt="" />
                    </LightWithText.LightIcon>
                    <LightWithText.LightText>
                      Did you know that on average US employees
                      <br /> use up only{" "}
                      <span style={{ fontSize: "1.5em", fontFamily: "Poppins ,sans-serif " }}>
                        {avarageValue.toFixed(0)}%
                      </span>
                      % on their annual paid time off? <br /> If your stats are different, just{" "}
                      <a onClick={linkHandler} style={{ color: "#FF5277" }}>
                        click here
                      </a>
                    </LightWithText.LightText>
                  </LightWithText>
                )}
                <ButtonsBlock style={{ position: "relative" }}>
                  <NewSlideButton>Calculate</NewSlideButton>
                  <BackButton
                    onClick={() => {
                      calcStore.setBackTrigger(1);
                      return history.push("./tenurestep");
                    }}>
                    Back
                  </BackButton>
                </ButtonsBlock>
                <div style={{ visibility: "hidden" }}>{"0"}</div>
              </Form>
            </FormWrapper>
          </RightPostGrid>
        </RightWrapperPaidGrid>
      </NewSlideWrapper>
    </PageWrapper>
  );
};

export default inject(STORES.CALCULATOR)(observer(PaidPolicy));
