import dayjs from "dayjs";
import { observable, computed, action } from "mobx";
import {
  IExperience,
  IBannerData,
  IExperienceFilters,
  ExperienceQuery,
  IPromotionData,
  IEvent,
  CalendarPeriod,
  EventStatus,
  IDetailedExperience,
  API_ERROR_CODES,
  RegionCodes,
  IUserInfo,
  IApiError,
} from "services/api/types";
import { appStore } from "stores/app";
import { userStore } from "stores/user";
import { calendarStore } from "stores/calendar";
import { IExperiencesStore } from "./types";
import { ENV } from "config/env";
import { getWaysToUseList } from "./constans";
import { chunkArray } from "utils/helpers";
import { apiService } from "services/api";
import { errorService } from "services/error";

class ExperiencesStore implements IExperiencesStore {
  @computed public get experienceAvailableEvents() {
    return this._experienceAvailableEvents;
  }

  @computed public get experienceBookedEvents() {
    return this._experienceBookedEvents;
  }

  @computed public get currentExperience() {
    return this._currentExperience;
  }

  @computed public get experiences() {
    return this._experiences;
  }

  @computed public get similar() {
    return this._similar;
  }

  @computed public get trending() {
    return this._trending;
  }

  @computed public get waysToUseGift() {
    return this._waysToUseGift;
  }

  @computed public get banners() {
    return this._banners;
  }

  @computed public get promoted() {
    return this._promoted;
  }

  @computed public get favorites() {
    return this._favorites;
  }

  @computed public get experienceFilters() {
    return this._experienceFilters;
  }

  public loadCurrentExperience = async (id: number) => {
    try {
      const currentExperience = await apiService.getExperience(id);
      if (currentExperience) {
        await this.loadExperienceEvents(currentExperience);
      }
      this.setCurrentExperience(currentExperience);
    } catch (error) {
      console.error(error);
    }
  };

  public expCTAClicked = async (id: IExperience["id"]) => {
    try {
      await apiService.experienceCTAClicked(id);
    } catch (error) {
      console.error(error);
    }
  };

  public bookCurrentExperience = async (eventId: number) => {
    if (!this._currentExperience) {
      throw new Error("No current experience");
    }

    try {
      const bookingResponse = await apiService.bookSorbet(
        eventId as number,
        this._currentExperience.id,
      );
      await this.loadExperienceEvents(this._currentExperience, true);
      calendarStore.loadPeriodEvents(true);
      return bookingResponse;
    } catch (error) {
      console.error(error);
    }
  };

  public rateExperience = async (experienceId: IExperience["id"], rating: number) => {
    try {
      await apiService.rateExperience(experienceId, rating);
    } catch (error) {
      console.error(error);
    }
  };

  public inviteForCurrentExperience = async (
    userId: IUserInfo["id"],
    eventId: IEvent["eventId"],
  ) => {
    try {
      if (!this.currentExperience) {
        throw new Error("No current experience");
      }
      await apiService.inviteForExperience(userId, eventId);
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  private loadExperienceEvents = async (experience: IExperience, force?: boolean) => {
    if (!userStore.isAuthenticated) {
      this.setAvailableEvents([]);
      return;
    }
    const currentDate = dayjs();
    if (calendarStore.currentPeriodEvents && !force) {
      const experienceEvents = calendarStore.currentPeriodEvents.filter(
        (e) =>
          dayjs(e.start).isAfter(currentDate) &&
          [EventStatus.ACCEPTED, EventStatus.BOOKED].includes(e.status) &&
          (e.scoopUnits || 0) >= experience.wpPost.scoops,
      );
      this.setAvailableEvents(experienceEvents, experience.id);
      return;
    }
    try {
      const experienceEvents = await apiService.getCalendarEvents({
        status: [EventStatus.ACCEPTED, EventStatus.BOOKED],
        period: CalendarPeriod.CUSTOM,
        from: currentDate.toISOString(),
        to: userStore.challengePeriodDateRange[1],
        scoopSize: experience.wpPost.scoops,
      });
      this.setAvailableEvents(experienceEvents, experience.id);
    } catch (error) {
      console.error(error);
    }
  };

  public loadExperiences = async (query?: ExperienceQuery) => {
    try {
      const experiences = await apiService.getExperiences(query);
      this.setExperiences(experiences);
    } catch (error) {
      console.error(error);
    }
  };

  public loadSimilar = async (experienceId: number) => {
    try {
      const similar = await apiService.getSimilar(experienceId);
      this.setSimilar(similar);
    } catch (error) {
      console.error(error);
    }
  };

  public loadFavorites = async () => {
    try {
      // TODO: implement pagination logic here
      const { payload: favorites } = await apiService.getFavorites();
      this.setFavorites(favorites);
    } catch (error) {
      console.error(error);
    }
  };

  @action public clearFavorites = () => {
    this._favorites = undefined;
  };

  public addToFavorites = async (id: number) => {
    if (!userStore.isAuthenticated) {
      appStore.showAuthModal();
      return;
    }
    try {
      await apiService.addToFavorites(id);
      this.updateExperienceInStore(id, { favorite: true });
    } catch (error) {
      console.error(error);
    }
  };

  public removeFromFavorites = async (id: number) => {
    if (!userStore.isAuthenticated) {
      appStore.showAuthModal();
      return;
    }
    try {
      await apiService.removeFromFavorites(id);
      this.updateExperienceInStore(id, { favorite: false });
    } catch (error) {
      console.error(error);
    }
  };

  private updateExperienceInStore = (id: number, data: Partial<IExperience["wpPost"]>) => {
    const updateInList = (list: IExperience[]) =>
      list.map((exp) => (exp.id === id ? { ...exp, wpPost: { ...exp.wpPost, ...data } } : exp));

    if (this._currentExperience) {
      this.setCurrentExperience({
        ...this._currentExperience,
        wpPost: { ...this._currentExperience.wpPost, ...data },
      });
    }
    if (!data.favorite && this._favorites?.length) {
      this.setFavorites(this._favorites.filter((fv) => fv.id !== id));
    }
    if (this._experiences?.length) {
      this.setExperiences(updateInList(this._experiences));
    }
    if (this._trending?.length) {
      this.setTrending(updateInList(this._trending));
    }
    if (this._similar?.length) {
      this.setSimilar(updateInList(this._similar));
    }
    if (this._waysToUseGift?.length) {
      this.setSimilar(updateInList(this._waysToUseGift));
    }
  };

  public loadTrending = async () => {
    try {
      const trending = await apiService.getTrending();
      this.setTrending(trending);
    } catch (error) {
      console.error(error);
    }
  };

  private waysToUseGiftRegion?: RegionCodes;

  public loadWaysToUseGift = async (region: RegionCodes) => {
    const idsList = getWaysToUseList(region);

    if (this._waysToUseGift?.length === idsList.length && region === this.waysToUseGiftRegion) {
      return;
    }

    try {
      let list: IExperience[];

      const cachedList = JSON.parse(localStorage.getItem(`waysToUseGift${region}`) || "[]");
      if (Array.isArray(cachedList) && cachedList.length === idsList.length) {
        list = cachedList;
      } else if (ENV.REACT_APP_ENV === "staging") {
        list = await apiService.getExperiences({ count: 50 });
      } else {
        const errorIds: IExperience["id"][] = [];

        const partsArr = chunkArray(idsList, 6);

        for (let index = 0; index < partsArr.length; index++) {
          const part = partsArr[index];
          const partResult = await Promise.all(
            part.map(async (id) => {
              if (id) {
                try {
                  return await apiService.getExperience(id);
                } catch (error) {
                  errorIds.push(id);
                }
              }
              return null;
            }),
          );
          const filteredPartResult = partResult.filter((exp) => exp) as IExperience[];
          if (partResult.length) {
            this.setWaysToUseGift(
              this.waysToUseGift
                ? this.waysToUseGift.concat(filteredPartResult)
                : filteredPartResult,
            );
          }
        }

        if (!this._waysToUseGift?.length) {
          throw new Error("Failed to load 'ways to use gift' list");
        }
        list = this._waysToUseGift;
        localStorage.setItem(`waysToUseGift${region}`, JSON.stringify(list));

        if (errorIds.length) {
          console.error(errorIds.length);
        }
      }

      this.waysToUseGiftRegion = region;
      this.setWaysToUseGift(list);
    } catch (error) {
      console.error(error);
    }
  };

  public loadBanners = async () => {
    try {
      const banners = await apiService.getBanners();
      this.setBanners(banners);
    } catch (error) {
      console.error(error);
    }
  };

  public loadPromoted = async () => {
    try {
      const promoted = await apiService.getPromotions();
      this.setPromoted(promoted);
    } catch (error) {
      console.error(error);
    }
  };

  public loadExperienceFilters = async () => {
    try {
      const experienceFilters = await apiService.getExperienceFilters();
      this.setExperienceFilters(experienceFilters);
    } catch (error) {
      console.error(error);
    }
  };

  @action public clearCurrentExperience = () => {
    this._currentExperience = undefined;
    this._similar = undefined;
    this._experienceAvailableEvents = undefined;
    this._experienceBookedEvents = undefined;
  };

  @action public clearExperienceFilters() {
    this._experienceFilters = undefined;
  }

  @action public clearStore() {
    this._experienceAvailableEvents = undefined;
    this._experienceBookedEvents = undefined;
    this._currentExperience = undefined;
    this._experiences = undefined;
    this._similar = undefined;
    this._trending = undefined;
    this._waysToUseGift = undefined;
    this._banners = [];
    this._promoted = undefined;
    this._favorites = undefined;
    this._experienceFilters = undefined;
  }

  @action private setAvailableEvents(events: IEvent[], experienceId?: IExperience["id"]) {
    let bookedEvents: IEvent[] = [];

    this._experienceAvailableEvents = events.filter((ev) => {
      if (ev.status === EventStatus.BOOKED) {
        if (ev.experienceId === experienceId) {
          bookedEvents.push(ev);
        }
        return false;
      }
      return true;
    });
    this._experienceBookedEvents = bookedEvents;
  }

  @action private setCurrentExperience(experience: IDetailedExperience) {
    this._currentExperience = experience;
  }

  @action private setExperiences(experiences: IExperience[]) {
    this._experiences = experiences;
  }

  @action private setSimilar(similar: IExperience[]) {
    this._similar = similar;
  }

  @action private setTrending(trending: IExperience[]) {
    this._trending = trending;
  }

  @action private setWaysToUseGift(list: IExperience[]) {
    this._waysToUseGift = list;
  }

  @action private setBanners(banners: IBannerData[]) {
    this._banners = banners;
  }

  @action private setPromoted(promoted: IPromotionData[]) {
    this._promoted = promoted;
  }

  @action private setFavorites(favorites: IExperience[]) {
    this._favorites = favorites;
  }

  @action private setExperienceFilters(filters: IExperienceFilters) {
    this._experienceFilters = filters;
  }

  @observable private _experienceAvailableEvents?: IEvent[];

  @observable private _experienceBookedEvents?: IEvent[];

  @observable private _currentExperience?: IDetailedExperience;

  @observable private _experiences?: IExperience[];

  @observable private _similar?: IExperience[];

  @observable private _trending?: IExperience[];

  @observable private _waysToUseGift?: IExperience[];

  @observable private _banners: IBannerData[] = [];

  @observable private _promoted?: IPromotionData[];

  @observable private _favorites?: IExperience[];

  @observable private _experienceFilters?: IExperienceFilters;
}

export const experiencesStore: IExperiencesStore = new ExperiencesStore();
