import { CommonTextTypes } from "calculator_feature/common/types";
import { STORES } from "config/constants";
import { inject, observer } from "mobx-react";
import React, { FC } from "react";
import { GridLastPageWrapper } from "../../slidesStyle";
import { BigText, BigEmailText } from "../BigText";
import { BigEmailTitle } from "../BigTitle";

const LastPageTextBlock: FC<CommonTextTypes> = ({ ...props }) => {
  return (
    <GridLastPageWrapper>
      <div>
        <BigEmailTitle style={{ textAlign: "center" }}>Share your company report</BigEmailTitle>
        <BigEmailText style={{ textAlign: "center" }}>
          Want to show your team how much money you can save with Sorbet? Enter the
          <br /> information below and we'll send the recipient an email with our estimated <br />
          savings in tax deductions, wage inflation protection, and refinancing costs.{" "}
        </BigEmailText>
      </div>
    </GridLastPageWrapper>
  );
};

export default inject(STORES.CALCULATOR)(observer(LastPageTextBlock));
