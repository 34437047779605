import { STORES } from "config/constants";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import Confetti from "react-confetti";
import { useHistory } from "react-router-dom";
import { ICalculatorStore } from "stores/calculator";
import email from "../../../../asserts/email.svg";
import smallEmail from "../../../../asserts/smallEmail.svg";
import reCalculate from "../../../../asserts/reCalculate.svg";
import { FormWrapper, SoundsGood } from "../../../../common/components";
import LastPageTextBlock from "../../../../common/components/LastPageTextBlock";
import {
  ButtonBackToReportStyle,
  ButtonLastWrapper,
  EmailIconWrapper,
  EmailWrapperStyle,
  PageWrapper,
  SlideWrapperLastPage,
  StyledLink,
} from "../../../../common/slidesStyle";
import { IStepProps } from "../../types";
import { useWindowSize } from "../../../../utils/hooks/useWindowSize";
import size from "../../../../config/devices";
import { Size } from "../../../../utils/hooks/useWindowSizeTypes";
export interface StyleProps {
  screenSize: boolean;
}

interface ILastStep extends IStepProps {
  [STORES.CALCULATOR]: ICalculatorStore;
}

const LastPage = ({ onChangeStep, onClose, ...props }: ILastStep): JSX.Element => {
  const sizes: Size = useWindowSize();
  const tabletWidth = +size.tablet.slice(0, -2);
  const mobileView = () => {
    if (sizes.width) {
      if (sizes.width < tabletWidth) {
        return { screenSize: true };
      }
      return { screenSize: false };
    }
  };
  const mobile = mobileView();

  const calcStore = props[STORES.CALCULATOR] as ICalculatorStore;

  const handleShareButton = () => {
    const paramsList = [
      { tenure: calcStore.tenure },
      { employees: calcStore.employees },
      { avarageSalary: calcStore.avarageSalary },
      { avarageSalarySlider: calcStore.avarageSalarySlider },
      { taxRate: calcStore.taxRate },
      { rolloverSlider: calcStore.rolloverSlider },
      { paidTimeSlider: calcStore.paidTimeSlider },
      { usedPaidTime: calcStore.usedPaidTime },
      { liabilitySorber_G14: calcStore.liabilitySorber_G14 },
      { whenRepay: calcStore.whenRepay },
      { rolloverName: calcStore.rolloverName },
    ];
    const ourUrl = "./emailpage";
    window.history.pushState(paramsList, document.title, "/" + ourUrl);
    window.history.go();
  };
  const [runValue, setRunValue] = useState(200);

  const history = useHistory();
  useEffect(() => {
    calcStore.increaseProgress(99);
    const timer = setTimeout(() => {
      setRunValue(0);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  const resetClick = () => {
    calcStore.clearStore();
    history.push("./");
    window.location.reload();
  };

  const handleSoundsGood = () => {
    window.open(
      "https://meetings.hubspot.com/veetahl?utm_source=calculator&utm_medium=cta&utm_campaign=chat_with_us",
      "_blank",
    );
  };
  return (
    <PageWrapper
      className="pageWrapper"
      style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
      <Confetti numberOfPieces={runValue} />
      <SoundsGood onClick={handleSoundsGood}>TELL ME MORE</SoundsGood>
      <SlideWrapperLastPage>
        <LastPageTextBlock onClick={handleShareButton} />
        <FormWrapper style={{ width: "45%" }}>
          <EmailIconWrapper className="emailForm">
            <EmailWrapperStyle>
              <img src={mobile?.screenSize ? smallEmail : email} alt="" />
            </EmailWrapperStyle>
          </EmailIconWrapper>
        </FormWrapper>
        <div style={{ display: "flex", alignItems: "baseline" }}>
          <ButtonBackToReportStyle onClick={() => history.push("./mainpage")}>
            Back to Your Report
          </ButtonBackToReportStyle>
          <ButtonLastWrapper>
            <img
              src={reCalculate}
              alt=""
              onClick={resetClick}
              style={{ cursor: "pointer", marginLeft: "25px" }}
            />
            <StyledLink to="/" onClick={resetClick}>
              Re-Calculate
            </StyledLink>
          </ButtonLastWrapper>
        </div>
      </SlideWrapperLastPage>
    </PageWrapper>
  );
};

export default inject(STORES.CALCULATOR)(observer(LastPage));
