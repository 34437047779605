// Core
import React, { PureComponent } from "react";
import { Carousel } from "antd";
import { CarouselProps } from "antd/lib/carousel";

// Components
import { ExperienceItem } from "../ExperienceItem";
import { Pager } from "components/Pager";
import { ExperienceLoader } from "../ExperienceLoader";
import { ExperienceItemsContainer } from "../ExperienceItemsContainer";

// Types
import { IExperience } from "services/api/types";
import { ExperienceDataLayerType } from "services/gtm/types";

// Styles
import {
  LoaderHeader,
  PagerWrapper,
  CarouselContainer,
  CarouselWrapper,
  PagerBtnPlaceholder,
  PagerSeeAllPlaceholder,
  LoaderItemWrapper,
  StyledLink,
} from "./styles";
import { EXP_HORIZONTAL_SPACE } from "assets/styles/constants";

// Other
import { gtmService } from "services/gtm";

interface IProps {
  experiences?: IExperience[];
  gtmDataLayer: ExperienceDataLayerType;
  allLink?: string;
}

interface IState {
  wrapperWidth?: number;
  currentSlide: number;
}

export class ExperiencesCarousel extends PureComponent<IProps> {
  private readonly carouselSettings: CarouselProps = {
    dots: false,
    infinite: false,
    speed: 500,
    arrows: false,
  };

  private carouselRef = React.createRef<Carousel>();

  state: IState = {
    currentSlide: 0,
  };

  updateGTMDataLayer = (currentSlide: number, itemsToShow: number) => {
    const { gtmDataLayer, experiences } = this.props;
    if (!experiences?.length) {
      return;
    }

    gtmService.onVisibleImpressionUpdated(
      gtmDataLayer,
      experiences.slice(currentSlide, currentSlide + itemsToShow).map((exp) => exp.wpPost.title),
    );
  };

  private prevItemsToShow?: number;

  componentDidUpdate(prevProps: IProps) {
    // when the experiences are loaded
    if (this.props.experiences?.length && !prevProps.experiences?.length) {
      this.updateGTMDataLayer(this.state.currentSlide, this.prevItemsToShow as number);
    }
  }

  onItemsToShowChange = (itemsToShow: number) => {
    if (itemsToShow !== this.prevItemsToShow) {
      this.updateGTMDataLayer(this.state.currentSlide, itemsToShow);
      this.prevItemsToShow = itemsToShow;
    }
  };

  onSlidesChange = (currentSlide: number) => {
    this.setState({ currentSlide });
    this.updateGTMDataLayer(currentSlide, this.prevItemsToShow as number);
  };

  public slideTo = (direction: "next" | "prev") => () =>
    (this.carouselRef.current as Carousel)[direction]();

  public renderLoader = (itemsToShow: number) => {
    const items = new Array(itemsToShow).fill(null);
    return (
      <>
        <LoaderHeader>
          <PagerSeeAllPlaceholder />
          <PagerBtnPlaceholder />
          <PagerBtnPlaceholder />
        </LoaderHeader>
        {items.map((_, i) => (
          <LoaderItemWrapper key={`loader_item_${i}`}>
            <ExperienceLoader />
          </LoaderItemWrapper>
        ))}
      </>
    );
  };

  public renderCarouselItems = (itemsToShow: number) => {
    let { experiences, gtmDataLayer } = this.props;
    if (!experiences?.length) {
      return null;
    }

    const diff =
      experiences.length < itemsToShow
        ? itemsToShow - experiences.length
        : experiences.length % itemsToShow;
    if (diff) {
      experiences = experiences.concat(new Array(diff).fill(null));
    }

    return experiences.map((exp, idx) => (
      <div key={exp?.id || `null${idx}`}>
        {exp && <ExperienceItem gtmDataLayer={gtmDataLayer} data={exp} />}
      </div>
    ));
  };

  render() {
    const { experiences, allLink } = this.props;

    return (
      <CarouselContainer>
        <ExperienceItemsContainer>
          {({ itemsToShow, containerWidth }) => {
            this.onItemsToShowChange(itemsToShow);

            if (!experiences?.length) {
              return this.renderLoader(itemsToShow);
            }

            const carouselWidth = containerWidth + EXP_HORIZONTAL_SPACE;

            return (
              <>
                <PagerWrapper>
                  <Pager onNextClick={this.slideTo("next")} onPrevClick={this.slideTo("prev")}>
                    {allLink && <StyledLink to={allLink}>see all</StyledLink>}
                  </Pager>
                </PagerWrapper>
                <CarouselWrapper>
                  <div style={{ width: carouselWidth }}>
                    <Carousel
                      ref={this.carouselRef}
                      {...this.carouselSettings}
                      slidesToShow={itemsToShow}
                      slidesToScroll={itemsToShow}
                      afterChange={this.onSlidesChange}>
                      {this.renderCarouselItems(itemsToShow)}
                    </Carousel>
                  </div>
                </CarouselWrapper>
              </>
            );
          }}
        </ExperienceItemsContainer>
      </CarouselContainer>
    );
  }
}
