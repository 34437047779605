import styled, { css } from "styled-components";
import { Rate } from "antd";
import { EXP_ITEM_WIDTH, Colors, EXP_ITEM_IMG_HEIGHT } from "assets/styles/constants";

export const ExperienceWrapper = styled.div`
  width: ${EXP_ITEM_WIDTH}px;
`;

export const ExperienceImageContainer = styled.div`
  width: 100%;
  min-width: 200px;
  height: ${EXP_ITEM_IMG_HEIGHT}px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
`;

export const ExperienceImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: ${Colors.paleGrey};
`;

export const ImgHoverContainer = styled.div`
  position: absolute;
  z-index: 2;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: pointer;

  transition: opacity 0.5s;

  ${ExperienceImageContainer}:hover & {
    opacity: 1;
  }
`;

export const ExperienceContentWrapper = styled.div`
  width: 100%;
  padding: 8px 18px 0;
`;

export const ActionButton = styled.button`
  margin-left: 10px;
  width: 27px;
  height: 27px;
  justify-content: center;
  align-items: center;
  border: 1.2px solid white;
  transition: opacity 0.3s;
  border-radius: 50%;
  display: flex;
  visibility: hidden;

  &:hover {
    border: none;
    background-color: rgba(255, 255, 255, 0.5);
  }
  &:active {
    opacity: 0.5;
  }

  ${ExperienceImageContainer}:hover & {
    visibility: visible;
  }

  img {
    width: 17px;
    height: 17px;
    object-fit: contain;
  }
`;

export const SaveButton = styled(ActionButton)<{ $favorite?: boolean }>`
  ${({ $favorite }) =>
    $favorite &&
    css`
      visibility: visible;
      background-color: ${Colors.pink} !important;
      border: none;

      &:hover {
        opacity: 0.7;
      }
    `}
`;

export const DeleteButton = styled(ActionButton)`
  width: 32px;
  height: 32px;

  img {
    width: 20px;
    height: 20px;
  }
`;

export const ExperienceText = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
  max-height: 65px; /* safety */
`;

export const ExperienceTitle = styled.h4`
  font-weight: 600 !important;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  display: inline; /* for the line-clamp to work */
`;

export const ExperienceDescription = styled.p`
  display: inline; /* for the line-clamp to work */
  color: ${Colors.textColorSecondary};
  margin: 0;
  font-size: 14px;
  line-height: 20px;
`;

export const LineBreak = styled.br`
  line-height: 27px;
`;

export const RatingContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  margin-top: 10px;
`;

export const RatingTitle = styled.span`
  margin-right: 12px;
  margin-top: 2px;
  font-size: 10px;
  color: ${Colors.textColorSecondary};
`;

export const StyledRate = styled(Rate)`
  &.ant-rate {
    font-size: 13px;
    pointer-events: none;

    .ant-rate-star:not(:last-child) {
      margin-right: 3px !important;
    }
  }
`;

export const ScoopImgContainer = styled.div`
  position: absolute;
  right: 9px;
  bottom: 10px;
  display: flex;
  align-items: center;
`;

export const ScoopsCountContainer = styled.div`
  width: 46px;
  height: 23px;
  border-radius: 11.6px;
  box-shadow: 1px 1px 7px 0 #f8f8fc;
  background-color: rgba(255, 255, 255, 0.8);
  font-size: 16.6px;
  font-weight: bold;
  color: ${Colors.textColor};
  padding-left: 13px;
  margin-right: -20px;
`;

export const ButtonsContainer = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 3;
  display: flex;
`;
