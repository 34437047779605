import * as msal from "@azure/msal-browser";

import { ENV } from "config/env";

import { AuthServices, IAuthInstance } from "../types";
import { CommonMicrosoftAuthInstance } from "./common";

/**
 * Microsoft auth instance with read/write calendar access
 */
class MicrosoftRWAuthInstance extends CommonMicrosoftAuthInstance {
  readonly name = AuthServices.MICROSOFT_RW;

  protected readonly scopes = ["profile", "email", "calendars.readWrite"];

  init = () => {
    const config: msal.Configuration = {
      auth: {
        clientId: ENV.REACT_APP_MICROSOFT_RW_CLIENT_ID,
      },
    };

    this.msalInstance = new msal.PublicClientApplication(config);
  };
}

export const microsoftRWAuthInstance: IAuthInstance = new MicrosoftRWAuthInstance();
