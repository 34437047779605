import styled, { css } from "styled-components";

export const IconsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
  align-items: center;
  justify-content: center;
`;

export const StyledButton = styled.a<{ color: string; icon: string }>`
  height: 30px;
  width: 30px;

  &:hover {
    opacity: 0.9;
  }

  ${(props) => css`
    background-color: ${props.color};
    mask: url(${props.icon}) no-repeat 100%/100%;
  `}

  &:not(:last-child) {
    margin-right: 10px;
  }
`;
