// Core
import React, { PropsWithChildren } from "react";
import { Dropdown as AntdDropdown } from "antd";
import { DropDownProps } from "antd/lib/dropdown";

// Styles
import { OverlayWrapper } from "./styles";

interface IProps extends PropsWithChildren<DropDownProps> {
  onOverlayClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
}

export function Dropdown({ children, overlay, onOverlayClick, ...props }: IProps) {
  return (
    <AntdDropdown
      arrow
      overlay={<OverlayWrapper onClick={onOverlayClick}>{overlay}</OverlayWrapper>}
      {...props}>
      {children}
    </AntdDropdown>
  );
}
