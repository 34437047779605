// Core
import styled, { css } from "styled-components";
import { Modal } from "antd";

// Components
import { buttonActionStyle, Colors } from "assets/styles/constants";

// Assets
import googleIcon from "assets/images/google-g-logo.svg";
import msIcon from "assets/images/ms-logo.svg";
import closeIcon from "assets/images/close.svg";

export const AUTH_MODAL_WRAPPER_CLASS_NAME = "auth-modal-wrapper";

export const StyledModal = styled(Modal).attrs({
  wrapClassName: AUTH_MODAL_WRAPPER_CLASS_NAME,
  width: 560,
  closable: false,
  centered: true,
  bodyStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "55px 0 35px",
  },
})`
  .ant-modal-content {
    border-radius: 10px;
  }

  h2 {
    font-size: 42px;
    line-height: normal;
    font-weight: 500;
    margin-bottom: 1px;
  }

  h3 {
    font-size: 24px;
    line-height: 31px;
    font-weight: normal;
    margin-bottom: 60px;
    text-align: center;
    white-space: pre-line;
  }

  p {
    color: ${Colors.textColorSecondary};
    font-size: 16px;
    margin-bottom: 18px;
    padding: 0;
  }

  small {
    color: ${Colors.textColorSecondary};
    font-size: 14px;
    width: 386px;
    text-align: center;

    a {
      color: inherit !important;
    }
  }
`;

export const Logo = styled.img`
  width: 182px;
  height: 43px;
  margin-bottom: 39px;
`;

const loginBtnStyle = css`
  width: 296px;
  height: 64px;
  outline: 0 solid transparent;
  transition: outline-color 0.3s, background-color 0.3s;
  margin-bottom: 20px;

  &:hover {
    outline: 5px solid rgba(66, 133, 244, 0.3);
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.7;
  }
`;

export const GoogleButton = styled.button`
  ${loginBtnStyle}

  border-radius: 3.2px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
  background-color: #4285f4;
  font-size: 22.4px;
  letter-spacing: 0.35px;
  color: #ffffff;
  position: relative;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  padding-left: 62.5px;
  text-align: center;
  position: relative;

  &:active {
    background-color: #3367d6;
  }

  &:before {
    content: "";
    width: 61px;
    height: 61px;
    border-radius: 1.6px;
    background-color: #ffffff;
    background-image: url(${googleIcon});
    background-repeat: no-repeat;
    background-position: center;
    top: 1.5px;
    left: 1.5px;
    position: absolute;
  }
`;

export const MicrosoftButton = styled.button`
  ${loginBtnStyle}
  font-size: 20px;
  color: #5e5e5e;
  position: relative;
  text-align: left;
  border: 1px solid #8c8c8c;
  padding-left: 60px;
  margin-bottom: 60px;

  &:active {
    opacity: 0.7;
  }

  &:before {
    content: "";
    background: url(${msIcon}) no-repeat center / 30px;
    position: absolute;
    top: 0;
    left: 0;
    width: 60px;
    height: 100%;
  }
`;

export const CloseButton = styled.button`
  ${buttonActionStyle}

  position: absolute;
  width: 30px;
  height: 30px;
  background-image: url(${closeIcon});
  top: 30px;
  right: 30px;
`;
