import styled, { css } from "styled-components";
import { buttonActionStyle, Colors } from "assets/styles/constants";
import closeIcon from "assets/images/close.svg";
import { ButtonBase } from "components/Buttons";
import { ButtonColors } from "components/Buttons/ButtonBase/styles";

export const CloseButton = styled.button`
  ${buttonActionStyle}

  position: absolute;
  width: 30px;
  height: 30px;
  background-image: url(${closeIcon});
  top: 20px;
  right: 20px;
`;

export const DialogContainer = styled.div`
  padding-top: 54px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const DialogTitle = styled.h3`
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  margin: 0;
  text-align: center;
  white-space: pre;
`;

export const DialogDescription = styled.p`
  line-height: 1.61;
  font-size: 18px;
  text-align: center;
  color: ${Colors.textColorSecondary};
  margin: 30px 55px 0;
  white-space: pre-line;
`;

export const DialogActions = styled.div`
  display: flex;
  margin-top: 41px;
  margin-bottom: 46px;
`;

const modalButtonStyle = css<{ $singleButton?: boolean }>`
  height: 42px;
  min-width: ${({ $singleButton }) => ($singleButton ? 254 : 190)}px;
  font-size: 20px;
  font-weight: 600;
  border-radius: 28.2px;
`;

export const ConfirmButton = styled(ButtonBase).attrs({
  colors: [Colors.orange] as ButtonColors,
})`
  ${modalButtonStyle}
`;

export const CancelButton = styled(ButtonBase).attrs({
  colors: [Colors.orange],
  border: true,
  inverse: true,
})`
  ${modalButtonStyle}
  margin-right: 15px;
`;
