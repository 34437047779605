import React, { FC } from "react";
import { PrimaryButton } from "../../../components/PrimaryButton";
import { SlideButtonStyle, StyledLinkHidden } from "../../slidesStyle";
import { CommonFormElement } from "../../types";
import BackButton from "../BackButton";

const SlideButton: FC<CommonFormElement> = ({ children, disabled, ...props }) => {
  return (
    <SlideButtonStyle>
      <StyledLinkHidden to="/">Hidden</StyledLinkHidden>
      <PrimaryButton disabled={disabled} onClick={props.onClick}>
        {children}
      </PrimaryButton>
      <BackButton to="/" onClick={props.resetClick}>
        Reset
      </BackButton>
    </SlideButtonStyle>
  );
};

export default SlideButton;
