import NewPageTextBlock from "calculator_feature/common/components/NewPageTextBlock";
import NewSlideButton from "calculator_feature/common/components/NewSlideButton";
import Tooltip from "@atlaskit/tooltip";
import { ENV } from "config/env";
import mixpanel from "mixpanel-browser";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import Select, { components } from "react-select";
import { STORES } from "../../../../../config/constants";
import { ICalculatorStore } from "../../../../../stores/calculator";
import { FormWrapper, SlideTitle, SmallText, SoundsGood } from "../../../../common/components";
import {
  ButtonsBlock,
  LeftGridSlide,
  LeftWrapperGrid,
  NewSlideWrapper,
  PageWrapper,
  RightWrapperSmallGrid,
  SelectWrapper,
} from "../../../../common/slidesStyle";
import { CustomStyles } from "../../../../common/types/industrySelectStyles/selectStyles";
import { Form } from "../../../../components/Form";
import { industry } from "../../../../config/industry";
import { IStepProps } from "../../types";
import styled from "styled-components";
import BackButton from "calculator_feature/common/components/BackButton";

export interface IFirstStep extends IStepProps {
  [STORES.CALCULATOR]: ICalculatorStore;
}
enum FieldName {
  industry = "industry",
}

interface FormData {
  [FieldName.industry]: any;
}

const IndustryStep = ({ onChangeStep, onClose, ...props }: IFirstStep): JSX.Element => {
  const calcStore = props[STORES.CALCULATOR] as ICalculatorStore;
  const history = useHistory();
  mixpanel.init(ENV.REACT_APP_MIXPANEL_TOKEN);
  const defaultValue = industry.find((i) => i.label === calcStore.industry);

  useEffect(() => {
    calcStore.setIndustry(calcStore.industry);
    calcStore.setTenure(Number(calcStore.tenure));
    calcStore.increaseProgress(22);
    localStorage.clear();
    console.clear();
  }, []);

  const { handleSubmit, control, register } = useForm<FormData>({
    mode: "onBlur",
  });

  const onSubmit = ({ industry = { key: "4.1", label: "US average" } }: FormData) => {
    localStorage.setItem("tenure", JSON.stringify(industry.key));
    calcStore.setIndustry(industry.label);
    calcStore.setTenure(industry.key);
    history.push("./employeesstep");
    calcStore.increaseProgress(33.4);
    console.clear();
    mixpanel.track("Click first step");
  };

  const handleSoundsGood = () => {
    window.open(
      "https://meetings.hubspot.com/veetahl?utm_source=calculator&utm_medium=cta&utm_campaign=chat_with_us",
      "_blank",
    );
  };

  const validationOptions = {
    required: {
      required: "This field is required",
    },
  };

  const Option = (props: any) => {
    return (
      <Tooltip content={props.label}>
        <div>
          <components.Option {...props} />
        </div>
      </Tooltip>
    );
  };

  return (
    <PageWrapper className="pageWrapper">
      <SoundsGood onClick={handleSoundsGood}>TELL ME MORE</SoundsGood>
      <NewSlideWrapper className="SlideWrapper">
        <LeftWrapperGrid>
          <LeftGridSlide>
            <NewPageTextBlock />
          </LeftGridSlide>
        </LeftWrapperGrid>
        <RightWrapperSmallGrid >
          <SlideTitle>Your industry</SlideTitle>
          <SmallText>
            Don’t know or don’t feel comfortable sharing this info?
            <br /> Don’t worry about it! <br /> We’ll use industry averages as default.
          </SmallText>
          <FormWrapper>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Controller
                control={control}
                defaultValue={defaultValue}
                name={FieldName.industry}
                render={({ onChange, value, name }: any) => (
                  <SelectWrapper>
                    <Select
                      style={{ color: "#1C1259" }}
                      inputRef={register(validationOptions.required)}
                      options={industry}
                      styles={CustomStyles()}
                      placeholder="US average"
                      value={value}
                      onChange={onChange}
                      components={{
                        Option,
                        IndicatorSeparator: () => null,
                      }}
                    />
                  </SelectWrapper>
                )}
              />
              <ButtonsBlock>
                <NewSlideButton>Next</NewSlideButton>
                <BackButton style={{ visibility: "hidden" }}>Back</BackButton>
              </ButtonsBlock>
              <div style={{ visibility: "hidden" }}>{"0"}</div>
            </Form>
          </FormWrapper>
        </RightWrapperSmallGrid>
      </NewSlideWrapper>
    </PageWrapper>
  );
};

export default inject(STORES.CALCULATOR)(observer(IndustryStep));
