// Core
import React from "react";

// Styles
import { EmptyImage, EmptyTitle, EmptyWrapper } from "./styles";

interface IProps {
  title?: string;
}

export function EmptyList({ title }: IProps) {
  return (
    <EmptyWrapper>
      <EmptyTitle>{title || "No experiences found"}</EmptyTitle>
      <EmptyImage />
    </EmptyWrapper>
  );
}
