import { ERRORS } from "config/constants";
import firebase from "firebase/app";
import "firebase/auth";

import { AuthServices, AuthTypes, IAuthInstance, ILoginPayload } from "../types";

const { MICROSOFT, GOOGLE } = AuthTypes;

export interface IFirebaseProviderData {
  providerId: string;
  scopes: string[];
}

export abstract class CommonFirebaseAuthInstance implements IAuthInstance {
  abstract readonly name: AuthServices;
  /**
   * Providers config
   */
  protected abstract readonly providers: { [key in AuthTypes]?: IFirebaseProviderData };
  /**
   * Firebase instance
   */
  protected abstract fbInstance?: firebase.app.App;
  /**
   * Firebase auth service
   */
  protected get fbAuth() {
    return (this.fbInstance as firebase.app.App).auth();
  }

  get isInitialized() {
    return !!this.fbInstance;
  }

  abstract init: () => void;

  protected get providerAccessToken(): string | undefined {
    return window.localStorage.getItem(`${this.name}accessToken`) || undefined;
  }

  protected set providerAccessToken(value: string | undefined) {
    if (value) {
      window.localStorage.setItem(`${this.name}accessToken`, value);
    } else {
      window.localStorage.removeItem(`${this.name}accessToken`);
    }
  }

  public getAuthTokens = async () => {
    const user = this.fbAuth.currentUser;
    if (user) {
      const authToken = await user.getIdToken();
      return [authToken, this.providerAccessToken];
    }
    return [];
  };

  public loadAuthState = async () =>
    new Promise<string | null>((resolve) => {
      const unsubscribe = this.fbAuth.onAuthStateChanged((user) => {
        resolve(this.getFid(user));
        unsubscribe();
      });
    });

  public logInWith = async (type: AuthTypes, email?: string, password?: string) => {
    try {
      let id: string | null = null;
      if ([GOOGLE, MICROSOFT].includes(type)) {
        id = await this.oAuthLogin(type);
      } else {
        id = await this.emailLogin(email as string, password as string);
      }
      return { id, success: true };
    } catch (e) {
      const result: ILoginPayload = { success: false };
      if (
        e.code === "auth/account-exists-with-different-credential" ||
        e.code === "custom/already-exists-with-another-provider"
      ) {
        const provider = e.provider || "different";
        result.error = `You have previously logged in with this email using a ${provider} provider. Please sign in using this provider instead.`;
      } else if (e.code === "auth/user-not-found") {
        result.error = ERRORS.USER_NOT_FOUND;
      } else if (
        // if user closed or canceled auth popup we do nothing
        e.code !== "auth/popup-closed-by-user" &&
        e.code !== "auth/user-cancelled" &&
        !/User cancelled request/.test(e.message)
      ) {
        throw e;
      }
      return result;
    }
  };

  public logOut = async () => {
    await this.fbAuth.signOut();
    this.providerAccessToken = undefined;
  };

  protected emailLogin = async (email: string, password: string) => {
    const loginMethods = await this.fbAuth.fetchSignInMethodsForEmail(email);
    if (!loginMethods.length || loginMethods.includes("password")) {
      const result = await this.fbAuth.signInWithEmailAndPassword(email, password);
      return this.getFid(result.user);
    }
    // eslint-disable-next-line no-throw-literal
    throw { code: "custom/already-exists-with-another-provider", provider: loginMethods[0] };
  };

  protected oAuthLogin = async (type: AuthTypes) => {
    const providerData = this.providers[type];
    if (!providerData) {
      throw new Error(`${this.name} auth instance doesn't support "${type}" auth type`);
    }
    const { providerId, scopes } = providerData;
    const provider = new firebase.auth.OAuthProvider(providerId);
    provider.setCustomParameters({
      prompt: "select_account",
    });
    scopes.forEach((scope) => provider.addScope(scope));

    const result = await this.fbAuth.signInWithPopup(provider);
    const credential = result.credential as firebase.auth.OAuthCredential;
    this.providerAccessToken = credential.accessToken;
    return this.getFid(result.user);
  };

  protected getFid = (user: firebase.User | null) => user?.uid || null;
}
