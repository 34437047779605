import React, { PropsWithChildren } from "react";
import Menu, { MenuProps } from "antd/lib/menu";

// components
import { Dropdown } from "components/Dropdown";

import { Wrapper, Title, Button, ArrowIcon } from "./styles";

interface IProps<T extends string> {
  values: Record<T, string>;
  keys: T[];
  selectedKey: T;
  onChange: (selectedKey: T) => void;
}

export function SortTitle<T extends string>({
  values,
  keys,
  selectedKey,
  onChange,
  children,
}: PropsWithChildren<IProps<T>>) {
  const onMenuChange: MenuProps["onClick"] = ({ key }) => {
    onChange((key as unknown) as T);
  };

  return (
    <Wrapper>
      <Title>{children}</Title>
      <Dropdown
        placement="bottomCenter"
        trigger={["click"]}
        overlay={
          <Menu onClick={onMenuChange} selectedKeys={[selectedKey]}>
            {keys.map((key) => (
              <Menu.Item key={key}>{values[key]}</Menu.Item>
            ))}
          </Menu>
        }>
        <Button>
          {values[selectedKey]}
          <ArrowIcon />
        </Button>
      </Dropdown>
    </Wrapper>
  );
}
