// Core
import React, { HTMLAttributes } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";

// Types / Consts
import { IBannerData } from "services/api/types";
import { Props } from "react-responsive-carousel/lib/ts/components/Carousel";
import { BANNER_CONFIG } from "config/constants";

// Styles
import { Carousel, CarouselItem, Indicator } from "./styles";
import "react-responsive-carousel/lib/styles/carousel.min.css";

// Assets
import placeholderImg from "assets/images/placeholder-image.jpg";

interface ICarouselProps extends Partial<Props>, RouteComponentProps {
  banners: IBannerData[];
  itemStyle?: HTMLAttributes<HTMLImageElement>["style"];
}

function BannersCarouselComponent({ banners, itemStyle, history, ...props }: ICarouselProps) {
  let carouselProps: Partial<Props> = {};
  carouselProps = {
    ...BANNER_CONFIG,
    showArrows: true,
    infiniteLoop: true,
    autoPlay: true,
    emulateTouch: true,
    showIndicators: true,
    showStatus: false,
    showThumbs: false,
    renderIndicator: (handler, isSelected) => <Indicator $active={isSelected} onClick={handler} />,
    onClickItem: (i: number) => history.push(banners[i].bannerLink),
  };

  return banners.length ? (
    <Carousel {...carouselProps} {...props}>
      {banners.map(({ bannerImageUrl, bannerId }, i) => (
        <CarouselItem
          key={bannerId}
          src={bannerImageUrl}
          style={itemStyle}
          alt={`carousel pic ${i}`}
        />
      ))}
    </Carousel>
  ) : (
    <CarouselItem style={itemStyle} src={placeholderImg} alt="loading" />
  );
}

export const BannersCarousel = withRouter(BannersCarouselComponent);
