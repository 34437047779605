import styled from "styled-components";
import { EXP_ITEM_WIDTH } from "assets/styles/constants";

export const CenterWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
const EXP_ITEM_MAX_HEIGHT = 300;
const EXP_BOTTOM_MARGIN = 75;

export const ExperienceItemWrapper = styled.div`
  margin-bottom: ${EXP_BOTTOM_MARGIN}px;
`;

export const ExperiencesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
`;

export const ExperiencePlaceholder = styled.div`
  height: 0;
  width: ${EXP_ITEM_WIDTH}px;
`;

export const LoaderWrapper = styled(ExperiencesWrapper)`
  max-height: ${(EXP_ITEM_MAX_HEIGHT + EXP_BOTTOM_MARGIN) * 2}px;
  overflow: hidden;
`;
