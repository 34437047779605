// Core
import React, { PureComponent, ReactNode } from "react";
import { Lambda, observe } from "mobx";
import { inject, observer } from "mobx-react";

// Stores and types
import { STORES } from "config/constants";
import { IAppStore } from "stores/app/types";

// Styles
import { ExperiencesWrapper } from "./styles";
import { EXP_HORIZONTAL_SPACE, EXP_ITEM_WIDTH } from "assets/styles/constants";

interface IChildrenRenderProps {
  itemsToShow: number;
  containerWidth: number;
}

interface IProps {
  [STORES.APP]?: IAppStore;
  children:
    | ((props: IChildrenRenderProps) => ReactNode)
    | JSX.Element
    | (JSX.Element | undefined)[];
}

interface IState {
  parentWidth?: number;
}

const RESIZE_DEBOUNCE = 100;

@inject(STORES.APP)
@observer
export class ExperienceItemsContainer extends PureComponent<IProps> {
  private get appStore() {
    return this.props[STORES.APP] as IAppStore;
  }

  private wrapperRef = React.createRef<HTMLDivElement>();
  private resizeTimer: number = -1;
  private removeStoreObserver?: Lambda;

  state: IState = {
    parentWidth: this.parentWidth,
  };

  private get parentWidth() {
    return this.wrapperRef.current?.parentElement?.offsetWidth;
  }

  private get itemsToShow() {
    const { parentWidth } = this.state;

    // fallback
    if (!parentWidth) {
      return this.appStore.sidebarCollapsed ? 4 : 3;
    }

    return Math.floor(
      (parentWidth + EXP_HORIZONTAL_SPACE) / (EXP_ITEM_WIDTH + EXP_HORIZONTAL_SPACE),
    );
  }

  componentDidMount() {
    window.addEventListener("resize", this.onResize);
    this.removeStoreObserver = observe(this.appStore, "sidebarTransitionEnd", () => {
      this.setState({ parentWidth: this.parentWidth });
    });
    this.setState({ parentWidth: this.parentWidth });
  }

  private sidebarReady = false;

  componentDidUpdate() {
    const { sidebarCollapsed } = this.appStore;
    if (!this.sidebarReady && !sidebarCollapsed) {
      this.setState({ parentWidth: this.parentWidth });
      this.sidebarReady = true;
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize);
    (this.removeStoreObserver as Lambda)();
    clearTimeout(this.resizeTimer);
  }

  public onResize = () => {
    clearTimeout(this.resizeTimer);
    this.resizeTimer = setTimeout(() => {
      this.setState({ parentWidth: this.parentWidth });
    }, RESIZE_DEBOUNCE);
  };

  render() {
    const { children } = this.props;
    const itemsToShow = this.itemsToShow;

    const containerWidth =
      (EXP_ITEM_WIDTH + EXP_HORIZONTAL_SPACE) * itemsToShow - EXP_HORIZONTAL_SPACE;

    return (
      <ExperiencesWrapper ref={this.wrapperRef} $width={containerWidth}>
        {typeof children === "function" ? children({ itemsToShow, containerWidth }) : children}
      </ExperiencesWrapper>
    );
  }
}
