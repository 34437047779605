import { CommonTextTypes } from "calculator_feature/common/types";
import React, { FC } from "react";
import { BigTitleStyleEmail, BigTitleStyle } from "../PageTextBlock/styles";

const BigTitle: FC<CommonTextTypes> = ({ children, ...restProps }) => {
  return <BigTitleStyle {...restProps}>{children}</BigTitleStyle>;
};

const BigEmailTitle: FC<CommonTextTypes> = ({ children, ...restProps }) => {
  return <BigTitleStyleEmail {...restProps}>{children}</BigTitleStyleEmail>;
};

export { BigTitle, BigEmailTitle };
