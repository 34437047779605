import styled from "styled-components";
import { Skeleton } from "antd";
import { EXP_ITEM_IMG_HEIGHT, EXP_ITEM_WIDTH } from "assets/styles/constants";

export const LoaderItemTitle = styled(Skeleton.Button).attrs({
  active: true,
})`
  .ant-skeleton-button {
    width: 205px !important;
    height: 14px;
    margin: 6px 0 5px;
  }
`;

export const LoaderItemText = styled(Skeleton.Button).attrs({
  active: true,
})`
  .ant-skeleton-button {
    width: ${EXP_ITEM_WIDTH - 36}px !important;
    height: 14px;
    margin: 6px 0 0;
  }
`;

export const LoaderItemFooter = styled(Skeleton.Button).attrs({
  active: true,
})`
  .ant-skeleton-button {
    width: 140px !important;
    height: 13px;
    margin: 13px 0;
  }
`;

export const LoaderImage = styled(Skeleton.Image)`
  &.ant-skeleton-image {
    width: ${EXP_ITEM_WIDTH}px;
    height: ${EXP_ITEM_IMG_HEIGHT}px;
  }
`;

export const LoaderContentWrapper = styled.div`
  width: ${EXP_ITEM_WIDTH}px;
  padding: 0 18px;
  display: flex;
  flex-direction: column;
`;

export const LoaderImageWrapper = styled.div`
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 8px;
`;

export const CarouselWrapper = styled.div`
  width: 100%;
  overflow: hidden;
`;
