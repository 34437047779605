import { notification } from "antd";
import { computed, action, observable } from "mobx";
import { INotificationStore, IMessageParams, IDialogData } from "./types";
import { uppercaseFirst } from "utils/formatters";

class NotificationStore implements INotificationStore {
  @computed public get dialogData() {
    return this._dialogData;
  }

  @computed public get dialogShown() {
    return !!this._dialogData;
  }

  notify = ({ type, message, title }: IMessageParams) => {
    notification[type || "open"]({
      key: Date.now().toString(16),
      message: title || uppercaseFirst(type || "notification"),
      description: message,
      placement: "bottomRight",
      // time to dismiss in sec
      duration: 6,
    });
  };

  @action showDialog = (props: IDialogData) => {
    this._dialogData = props;
  };

  @action closeDialog = () => {
    this._dialogData = undefined;
  };

  @observable private _dialogData?: IDialogData;
}

export const notificationStore = new NotificationStore();
