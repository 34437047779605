import React, { FC } from "react";
import { ContainerRow } from "../../slidesStyle";
import { CommonFormElement } from "../../types";


const FormWrapperRow: FC<CommonFormElement> = ({ children, ...props }) => {
  return <ContainerRow {...props}>{children}</ContainerRow>;
};

export default FormWrapperRow;
