import { css } from "styled-components";

export enum Breakpoints {
  XS = 480,
  SM = 576,
  MD = 768,
  LG = 992,
  XL = 1200,
  XXL = 1600,
}

export enum Colors {
  textColor = "#1c1259",
  textColorFaded = "rgba(28, 18, 89, 0.5)",
  darkIndigo = "#201472",
  silver = "#f8f8fa",
  orange = "#ffae00",
  orange_rgb = "255, 174, 0",
  sand = "#f6a65a",
  rose = "#f04394",
  tangerine = "#ff9600",
  yellow = "#fcd307",
  red = "#ff6666",
  dangerRed = "#e02020",
  pink = "#ff5277",
  pink_rgb = "255, 82, 119",
  purple = "#ff63af",
  blue = "#66cccc",
  green = "#99e128",
  lightGreen = "#bee040",
  silverLine = "rgba(28, 18, 89, 0.1)",
  paleGrey = "#eef0f7",
  paleGreyTwo = "#eaecf5",
  blueGrey = "#9893ba",
  lightGrey = "#f8f8fc",
  lightBlueGray = "#d5daeb",
  textColorSecondary = "#77719b",
  textColorSecondary_rgb = "119, 113, 155",
  titleStripe = "rgba(255, 174, 0, 0.6)",
}

export const GRADIENT_COLORS = [Colors.yellow, Colors.tangerine] as [Colors, Colors];

export const GRADIENT_COLORS_REVERSED = [...GRADIENT_COLORS].reverse() as [Colors, Colors];

export const BUTTON_BASE_GRADIENT = ["#ff63af", "#ff6666"];

export const HEADER_HEIGHT = 100;

export const CONTENT_PADDING = 20;

/**
 * Maximum allowed width of the content
 */
export const MAX_CONTENT_WIDTH = 1440;

/**
 * Width of the experience card
 */
export const EXP_ITEM_WIDTH = 327;
/**
 * Space between two experience items in a row
 */
export const EXP_HORIZONTAL_SPACE = 36;
/**
 * Height of experience item image
 */
export const EXP_ITEM_IMG_HEIGHT = 200;

export const SCROLL_BAR_WIDTH = 4;

export const buttonActionStyle = css`
  cursor: pointer;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.7;
  }
  &:active {
    opacity: 0.5;
  }
`;

export const scrollBarStyle = css`
  &::-webkit-scrollbar {
    border: none;
    width: ${SCROLL_BAR_WIDTH}px;
    height: ${SCROLL_BAR_WIDTH}px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: ${Colors.textColor};
  }
`;
