import React, { FC } from "react";
import { PrimaryButtonSend } from "../../../components/PrimaryButtonSend";
import {
  SendButtonEmail
} from "../../slidesStyle";
import { CommonFormElement } from "../../types";


const SendButton: FC<CommonFormElement> = ({ children, disabled, type, ...props }) => {
  return (
    <SendButtonEmail>
      <PrimaryButtonSend disabled={disabled} onClick={props.onClick}>
        {children}
      </PrimaryButtonSend>
    </SendButtonEmail>
  );
};

export default SendButton;
