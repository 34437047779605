import React, { FC, useMemo } from "react";

// types
import { ICardInfo, ICardPanInfo } from "services/api/types";

// styles
import {
  CardPreview,
  CardPreviewLogo,
  CardPreviewNumber,
  CardPreviewExpiration,
  CardPreviewCvv,
  CardPreviewName,
} from "./styles";

// other
import { chunkArray } from "utils/helpers";

const normalize = (cardInfo: ICardPanInfo | ICardInfo): ICardPanInfo => {
  const { expiration, cardNumber, state } = cardInfo;
  return {
    expiration,
    cardNumber: cardNumber.replace(/XXXX-/g, "****"),
    cvc: "cvc" in cardInfo ? cardInfo.cvc : "***",
    state,
  };
};

interface IProps {
  cardInfo: ICardPanInfo | ICardInfo;
  onClick?: () => void;
  name: string;
}

export const SorbetCardPreview: FC<IProps> = ({ cardInfo, name, onClick }) => {
  const { cardNumber, expiration, cvc } = useMemo(() => normalize(cardInfo), [cardInfo]);

  // chunk card number into batches of 4
  const number = useMemo(
    () =>
      chunkArray(cardNumber.split(""), 4)
        .map((chunk) => chunk.join(""))
        .join(" "),
    [cardNumber],
  );

  // parse expiration
  let [expYear, expMonth] = expiration.split("-");
  expYear = expYear && expYear.substr(2, 4);

  return (
    <CardPreview onClick={onClick}>
      <CardPreviewLogo />
      <CardPreviewNumber>{number}</CardPreviewNumber>
      <CardPreviewExpiration>
        {expMonth}/{expYear}
      </CardPreviewExpiration>
      <CardPreviewCvv>
        <strong>CVV</strong> {cvc}
      </CardPreviewCvv>
      <CardPreviewName>{name}</CardPreviewName>
    </CardPreview>
  );
};
