import React, { FC } from "react";
import { BackButtonStyle } from "../../../components/BackButtonPaidWrapper";
import { ButtonBackStyle } from "../../slidesStyle";
import { CommonFormElement } from "../../types";

const BackButton: FC<CommonFormElement> = ({ children, disabled, ...props }) => {
  return (
    <ButtonBackStyle>
      <BackButtonStyle disabled={disabled} onClick={props.onClick}>
        {children}
      </BackButtonStyle>
    </ButtonBackStyle>
  );
};

export default BackButton;
