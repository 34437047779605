import React, { FC } from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { CommonTextTypes } from "calculator_feature/common/types";
import { Theme } from "@material-ui/core";
import { useWindowSize } from "../utils/hooks/useWindowSize";
import size from "../config/devices";
import { Size } from "../utils/hooks/useWindowSizeTypes";

interface StyleProps {
  screenSize: boolean;
}
const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: {
    width: "50.41%",
    height: "6.86%",
    padding: "1% 25% 1% 25%",
    borderRadius: "30px",
    marginBottom: "11.4%",
    fontSize: ({ screenSize }) => (screenSize ? "14px" : "18px"),
    fontWeight: 400,
    fontStretch: "normal",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Poppins",
    textTransform: "none",
    position: "relative",
    "&:hover": {
      backgroundColor: "#fff",
    },
    "& .MuiTouchRipple-child": {
      height: "0 !important",
    },
  },
}));

export const BackButtonStyle: FC<CommonTextTypes> = ({ children, disabled, ...props }) => {
  const sizes: Size = useWindowSize();
  const tabletWidth = +size.tablet.slice(0, -2);
  const mobileView = () => {
    if (sizes.width) {
      if (sizes.width < tabletWidth) {
        return { screenSize: true };
      }
      return { screenSize: false };
    }
  };
  const classes = useStyles(mobileView());

  return (
    <Button type="submit" disabled={Boolean(disabled)} className={classes.root} {...props}>
      {children}
    </Button>
  );
};
