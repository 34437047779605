import * as Sentry from "@sentry/react";
import { ENV } from "config/env";
import { Integrations } from "@sentry/tracing";

export interface IErrorLogger {
  captureError: (error: Error) => void;
  captureMessage: (message: string) => void;
  flush: () => void;
}

class ErrorLogger implements IErrorLogger {
  constructor() {
    this.init();
  }

  init() {
    Sentry.init({
      dsn: ENV.REACT_APP_SENTRY_DSN,
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 0.5,
    });
  }

  captureError(error: Error): void {
    Sentry.captureException(error);
  }

  captureMessage(message: string): void {
    Sentry.captureMessage(message);
  }

  async flush() {
    await Sentry.flush();
  }
}

export { ErrorLogger };
