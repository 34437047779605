import { qsService } from "services/qs";
import { IMailOptions, IMailService } from "./types";

class MailService implements IMailService {
  sendMail(options: IMailOptions) {
    const url = this.generateMailUrl(options);
    window.open(url, "_blank");
  }

  generateMailUrl = ({ to, ...options }: IMailOptions) => {
    const queryString = qsService.stringify(options, { arrayFormat: "comma" });
    return `mailto:${Array.isArray(to) ? to.join(",") : to}${queryString ? `?${queryString}` : ""}`;
  };

  prepareTemplate = (template: string, params: Record<string, string>) => {
    return Object.entries(params).reduce((formattedTemplate, [key, value]) => {
      const regExp = new RegExp(`{{${key}}}`, "g");
      return formattedTemplate.replace(regExp, value);
    }, template);
  };
}

export const mailService: IMailService = new MailService();
