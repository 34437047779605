import React from "react";

export class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error) {
    // You can also log the error to an error reporting service
      console.error(error);
  }

  render() {
    if (this.state.hasError) {
      console.log(this.state.hasError);
      // You can render any custom fallback UI
      // TODO: add nice UI here
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}
