import { CommonFormElement } from "calculator_feature/common/types";
import { device } from "calculator_feature/config/devices";
import React, { BaseSyntheticEvent, FC } from "react";
import styled from "styled-components";
import combined from "../asserts/Combined Shape.svg";

const Button = styled.button`
  border-radius: 17.5px;
  border: 1px solid #ff5277;

  background-color: #ffffff;
  @media ${device.mobileS} {
    margin-bottom: 10px;
    padding: 2px 5px 2px 5px;
  }
  @media ${device.tablet} {
    margin-bottom: 0px;
    padding: 5px 5px 5px 5px;
  }
`;

const ShareButtonLastPage = styled.button`
  border-radius: 17.5px;

  background-color: #ffffff;

  @media ${device.mobileS} {
    margin-bottom: 10px;
    padding: 2px 5px 2px 5px;
  }
  @media ${device.tablet} {
    margin-bottom: 0px;
    padding: 5px 5px 5px 5px;
  }
`;

const TextButton = styled.button`
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #f04394;
  @media ${device.mobileS} {
    font-size: 14px;
  }
  @media ${device.tablet} {
    font-size: 16px;
  }
`;


const TextLastShareButton = styled.button`
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #f04394;
  text-decoration: underline;
  @media ${device.mobileS} {
    font-size: 14px;
  }
  @media ${device.tablet} {
    font-size: 16px;
  }
`;

const ShareThis: FC<CommonFormElement> = (props) => {
  const handleClick = (e: BaseSyntheticEvent) => {
    e.preventDefault();
    props.shareClick();
  };
  return (
    <Button onClick={handleClick}>
      <div style={{ display: "flex", padding: "0 10px 0 10px", alignItems: "center" }}>
        <div style={{ paddingRight: "10px" }}>
          <img src={combined} alt="comb" />
        </div>
        <TextButton>Share this</TextButton>
      </div>
    </Button>
  );
};

const ShareLastPage: FC<CommonFormElement> = (props) => {
  const handleClick = (e: BaseSyntheticEvent) => {
    e.preventDefault();
    props.onClick();
  };

  return (
    <ShareButtonLastPage onClick={handleClick}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ paddingRight: "10px" }}>
          <img src={combined} alt="comb" />
        </div>
        <TextLastShareButton>Share</TextLastShareButton>
      </div>
    </ShareButtonLastPage>
  );
};

export { ShareThis, ShareLastPage };
