import { RegionCodes } from "services/api/types";

/**
 * List of ids (from production) for 'ways to use gift' list
 * US region
 */
export const WAYS_TO_USE_GIFT_IDS_US_REGION = [
  134, 135, 136, 31, 69, 3, 123, 125, 103, 96, 98, 21, 12, 7, 6, 5,
  4, 2, 62, 58, 49, 47, 46, 101, 37, 35, 29, 28, 11, 50, 48, 44,
  212, 210, 205, 206, 208, 146, 149, 221, 153, 177, 167, 53, 218, 154
];

/**
 * List of ids (from production) for 'ways to use gift' list
 * IL region
 */
export const WAYS_TO_USE_GIFT_IDS_IL_REGION = [
  192, 72, 93, 161, 160, 181, 188, 187, 185, 184, 183, 182, 193,
  191, 189, 197, 200, 199, 198, 204, 203, 202, 211, 210, 213, 212,
  218, 223, 222, 229, 228, 91, 129, 107, 143, 142, 108, 95, 94, 71,
  118, 110, 84, 82, 89, 92, 88, 78, 116, 231
];

export const getWaysToUseList = (region: RegionCodes) => {
  if (region === RegionCodes.IL) {
    return WAYS_TO_USE_GIFT_IDS_IL_REGION;
  }
  return WAYS_TO_USE_GIFT_IDS_US_REGION;
};
