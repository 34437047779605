import React from "react";
import { LogoStyle } from "../../slidesStyle";

const LogoComponent = ({ ...props }): JSX.Element => {
  return (
    <LogoStyle {...props}>
      <img src={props.src} alt='logo' />
    </LogoStyle>
  );
};

export default LogoComponent;
