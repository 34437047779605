import styled, { css } from "styled-components";
import { buttonActionStyle, Colors } from "assets/styles/constants";

import arrowIcon from "assets/images/arrow-bottom-dark.svg";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 44px;
`;

const TextStyle = css`
  line-height: 33px;
  font-size: 24px;
  font-weight: 600;
  white-space: nowrap;
`;

export const Title = styled.h3`
  color: ${Colors.textColor};
  margin: 0;
  ${TextStyle}
`;

export const Button = styled.button`
  ${buttonActionStyle}
  ${TextStyle}

  color: ${Colors.pink};
  margin-left: 10px;
`;

export const ArrowIcon = styled.img.attrs({
  src: arrowIcon,
})`
  width: 20px;
  height: 20px;
  margin-left: 7px;
`;
