import styled from "styled-components";
import scoopsImg from "assets/images/scoops-illus.svg";
import { ButtonBase } from "components/Buttons";
import { GRADIENT_COLORS_REVERSED } from "assets/styles/constants";

export const ScoopsImg = styled.img.attrs({
  src: scoopsImg,
})`
  width: 116px;
  height: 125px;
  margin-top: -24px;
  margin-bottom: 15px;
`;

export const CompleteProfileButton = styled(ButtonBase).attrs({
  height: 52,
  orientation: "vertical",
  colors: GRADIENT_COLORS_REVERSED,
})`
  margin: 30px 0 50px;
  border-radius: 26px;
  width: 310px;
  font-size: 24px;
`;
