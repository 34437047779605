import styled from "styled-components";
import { Layout, Modal, Table as AntTable } from "antd";
import { MAX_CONTENT_WIDTH, CONTENT_PADDING, Colors, buttonActionStyle } from "./constants";

import leftArrow from "assets/images/scroll-left.svg";
import rightArrow from "assets/images/scroll-right.svg";

export const ContentWrapper = styled.div`
  max-width: ${MAX_CONTENT_WIDTH + CONTENT_PADDING * 2}px;
  margin: 0 auto;
  padding: 0 ${CONTENT_PADDING}px;
  width: 100%;
`;

export const PageWrapper = styled(Layout.Content)`
  background-color: #fff;
  padding-bottom: 60px;
`;

export const ModalBase = styled(Modal).attrs({
  closable: false,
  footer: null,
  centered: true,
  maskClosable: true,
  destroyOnClose: true,
})`
  min-width: 500px;

  .ant-modal-content {
    border-radius: 10px;

    .ant-modal-body {
      padding: 0;
    }

    position: relative;
  }
`;

export const Table = styled(AntTable)`
  .ant-table-thead > tr > th {
    background-color: transparent;
    font-size: 16px;
    font-weight: normal;
    color: ${Colors.textColorSecondary};
    border-bottom: none;
    padding: 0;
  }

  .ant-table-tbody > tr > td {
    border-color: ${Colors.lightBlueGray};
    color: ${Colors.textColor};
    font-size: 14px;
    padding-left: 0;
    height: 112px;
  }

  .ant-table-tbody > tr.ant-table-row:hover > td {
    background-color: transparent;
  }

  .ant-table-tbody > tr:last-of-type > td {
    border-bottom: none;
  }

  .ant-pagination {
    .ant-pagination-simple-pager {
      color: ${Colors.textColor};
      position: absolute;
      right: 68px;
      font-size: 16px;

      input {
        margin: 0;
        border: 0;
        padding: 0;
        pointer-events: none;
        text-align: right;
        font-size: 16px;
      }

      .ant-pagination-slash {
        margin: 0 3px;
      }
    }

    .ant-pagination-disabled {
      opacity: 0.3;
    }

    .ant-pagination-item-link {
      width: 23px;
      height: 23px;
      border-radius: 50%;
      background: white no-repeat center;
      background-size: 16px;
      box-shadow: 0 0 10px 0 ${Colors.lightBlueGray};
      transition: opacity 0.2s;
      ${buttonActionStyle}

      span {
        display: none; // hide ant icon
      }
    }
    .ant-pagination-next .ant-pagination-item-link {
      background-image: url(${rightArrow});
    }
    .ant-pagination-prev {
      margin-right: 15px;
      .ant-pagination-item-link {
        background-image: url(${leftArrow});
      }
    }

    .ant-pagination-prev,
    .ant-pagination-next {
      min-width: 0;
      height: 23px;
      line-height: 23px;
      border-radius: unset;
    }
  }
`;
