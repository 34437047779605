import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { IForm } from "calculator_feature/common/types";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
}));

export const Form: FC<IForm> = ({ children, onSubmit, ...props }) => {
  const styles = useStyles();

  return (
    <form id="myform" {...props} onSubmit={onSubmit} className={styles.root} noValidate>
      {children}
    </form>
  );
};
