import styled from "styled-components";
import removeIcon from "assets/images/tag-close.svg";
import { Colors } from "assets/styles/constants";

export const StyledFilterTag = styled.button`
  font-size: 12px;
  border-radius: 21px;
  border: solid 1px ${Colors.textColorSecondary};
  color: ${Colors.textColorSecondary};
  padding: 2px 3px 2px 10px;
  margin-top: 15px;
  margin-right: 14px;
  display: inline-flex;
  align-items: center;
  transition: all 0.1s linear;

  &:hover {
    background-color: rgba(${Colors.textColorSecondary_rgb}, 0.1);
  }

  &:active {
    background-color: rgba(${Colors.textColorSecondary_rgb}, 0.3);
  }
`;

export const StyledClearFilterTag = styled(StyledFilterTag)`
  padding: 5px 20px;
  display: none;
  margin-right: 0;

  /* show only if next to other tags */
  * ~ & {
    display: inline-flex;
  }
`;

export const RemoveButton = styled.span`
  background: url(${removeIcon}) no-repeat 5px center;
  height: 24px;
  width: 24px;
  display: inline-block;
`;
