import styled from "styled-components";

import calendarIcon from "assets/images/calendar-icon-blue.svg";

export const CalendarIcon = styled.img.attrs({
  src: calendarIcon,
})`
  width: 24px;
  height: 24px;
  margin-bottom: 2px;
`;
