import { firebaseROAuthInstance } from "./firebase/readOnly";
import { firebaseRWAuthInstance } from "./firebase/readWrite";
import { microsoftROAuthInstance } from "./microsoft/readOnly";
import { microsoftRWAuthInstance } from "./microsoft/readWrite";
import { AuthServices, IAuthInstance, IAuthInstanceFactory } from "./types";

class AuthInstanceFactory implements IAuthInstanceFactory {
  getInstance = (serviceName: AuthServices) => {
    let instance: IAuthInstance;

    switch (serviceName) {
      case AuthServices.MICROSOFT_RO:
        instance = microsoftROAuthInstance;
        break;

      case AuthServices.MICROSOFT_RW:
        instance = microsoftRWAuthInstance;
        break;

      case AuthServices.FIREBASE_RO:
        instance = firebaseROAuthInstance;
        break;

      case AuthServices.FIREBASE_RW:
      default:
        instance = firebaseRWAuthInstance;
    }
    if (!instance.isInitialized) {
      instance.init();
    }
    return instance;
  };
}

export const authInstanceFactory: IAuthInstanceFactory = new AuthInstanceFactory();
