// Core
import React, { HTMLAttributes, useState } from "react";

// Components
import { Dropdown } from "components/Dropdown";

// Styles
import {
  FilterButtonWrapper,
  StyledFilterButton,
  FilterIcon,
  FilterButtonTitle,
  StyledMenu,
  Checkbox,
  MenuText,
  StyledMenuItem,
} from "./styles";

// Types
import { IFilterOption, FilterValue } from "services/api/types";

interface IFilterButtonProps<T extends FilterValue = string> {
  activeOptions?: T[];
  toggle?: boolean;
  title: string;
  icon?: string;
  iconSize?: number;
  options?: IFilterOption<T>[];
  onSelectChange?: (value: T[]) => void;
}

export function FilterButton<T extends FilterValue = string>({
  activeOptions = [],
  title,
  toggle,
  icon,
  iconSize,
  options,
  onSelectChange = () => {},
  ...props
}: IFilterButtonProps<T> & HTMLAttributes<HTMLButtonElement>) {
  const [visible, setVisible] = useState(false);

  const isOptions = options?.length;

  let onClick: HTMLAttributes<HTMLButtonElement>["onClick"];
  if (isOptions && toggle) {
    onClick = () => {
      onSelectChange(activeOptions?.length ? [] : [(options as IFilterOption<T>[])[0].value]);
    };
  }

  let button = (
    <StyledFilterButton onClick={onClick} {...props}>
      <FilterIcon src={icon} alt={title} $size={iconSize} data-testid={`filterIcon-${title}`} />
    </StyledFilterButton>
  );

  if (isOptions && !toggle) {
    button = (
      <Dropdown
        trigger={["click"]}
        placement="bottomCenter"
        visible={visible}
        onVisibleChange={(visible) => setVisible(visible)}
        overlay={
          <StyledMenu selectable={false} data-testid="filterMenu">
            {options?.map(({ value, title: optionTitle = value }) => {
              const isSelected = activeOptions.includes(value);
              return (
                <StyledMenuItem
                  className="exp-filter-menu-option"
                  key={value as string}
                  onClick={() => {
                    const newValues = isSelected
                      ? activeOptions.filter((v) => v !== value)
                      : activeOptions.concat(value);
                    onSelectChange(newValues);
                  }}>
                  <Checkbox checked={isSelected} />
                  <MenuText>{optionTitle}</MenuText>
                </StyledMenuItem>
              );
            })}
          </StyledMenu>
        }>
        {button}
      </Dropdown>
    );
  }

  return (
    <FilterButtonWrapper>
      {button}
      <FilterButtonTitle>{title}</FilterButtonTitle>
    </FilterButtonWrapper>
  );
}
