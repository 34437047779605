import { action, computed, observable } from "mobx";
import { apiService } from "services/api";
import {
  IAllocationSlotsResponse,
  IExperience,
  IGoalItem,
  IOnboardingData,
  IPeakHoursItem,
} from "services/api/types";
import { errorService } from "services/error";
import { IOnboardingStore } from "stores/onboarding/types";
import dayjs, { Dayjs } from "dayjs";
import { userStore } from "stores/user";
import { ENV } from "config/env";
import { convertPTOUnits } from "utils/formatters";

class OnboardingStore implements IOnboardingStore {
  //<------ Global data ------>//

  ////Observables
  @observable private _onboardingProcessData?: IOnboardingData;

  @observable private _onboardingReady = false;

  ////Computed
  @computed get onboardingProcessData() {
    return this._onboardingProcessData;
  }

  @computed get onboardingReady() {
    return this._onboardingReady;
  }

  @computed get onboardingCompleted() {
    return !!this._onboardingProcessData?.isCompleted;
  }

  @action setOnboardingReady = (ready: boolean) => {
    this._onboardingReady = ready;
  };

  async updateOnboardingProcessData(data: Partial<IOnboardingData>) {
    try {
      await apiService.updateOnboardingData(data);
      this.setOnboardingProcessData(data);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  @action private setOnboardingProcessData(data: Partial<IOnboardingData>) {
    this._onboardingProcessData = {
      ...this._onboardingProcessData,
      ...(data as IOnboardingData),
    };
  }

  public loadOnboardingProcessData = async () => {
    try {
      const data = await apiService.getOnboardingProcessData();
      this.setOnboardingProcessData(data);
    } catch (e) {
      console.error(e);
    }
  };

  //<------ PTOBalance ------>//

  public setWellnessGoal = async (days: number, goalPercent: number) => {
    try {
      let units = days;
      if (ENV.REACT_APP_USE_PTO_MIN_UNITS) {
        // if the new units feature is enabled we need to convert days to minutes first
        units = convertPTOUnits(units, {
          hoursInDay: userStore.organizationWorkingHours,
          type: "daysToMinutes",
        });
      }
      await apiService.convertDaysToScoop(units, goalPercent);
      userStore.loadBalanceInfo(true);
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  //<------ PeakHours ------>//

  public updateUserPeakHours = async (peakHoursItem: IPeakHoursItem) => {
    try {
      await apiService.updateUserPeakHours(peakHoursItem);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  //<------ Goals ------>//

  @observable private _goalsList?: IGoalItem[];

  @computed get goalsList() {
    return this._goalsList;
  }

  public loadGoalsList = async () => {
    try {
      const goals = await apiService.getOnboardingGoalsList();
      this.setGoalsList(goals);
    } catch (error) {
      console.error(error);
    }
  };

  @action private setGoalsList = (goals: IGoalItem[]) => {
    this._goalsList = goals;
  };

  //<------ Suggested Plan ------>//

  @observable private _suggestedPlan?: IAllocationSlotsResponse;
  @observable private _suggestedPlanDateRange?: Dayjs[];

  @computed get suggestedPlan() {
    return this._suggestedPlan;
  }

  @computed get suggestedPlanDateRange() {
    return this._suggestedPlanDateRange;
  }

  @action setSuggestedPlan(suggestedPlan: IAllocationSlotsResponse, dateRange: Dayjs[]) {
    this._suggestedPlan = suggestedPlan;
    this._suggestedPlanDateRange = dateRange;
  }

  public loadSuggestedPlan = async () => {
    const [startString, endString] = userStore.challengePeriodDateRange;
    // minimum date for the user to start using his sorbets
    const startSorbetsDate = dayjs().tz().add(1, "day").startOf("day");
    // we use company.startDate or currentDate (if it's after company.startDate)
    const startDate =
      startString && startSorbetsDate.isAfter(startString) ? startSorbetsDate : dayjs(startString);
    const dateRange = [startDate, dayjs(endString)];
    const [start, end] = dateRange;

    try {
      const suggestedPlan = await apiService.getSuggestedPlan(start, end);
      this.setSuggestedPlan(suggestedPlan, dateRange);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  public acceptSuggestedPlan = async (plan: IAllocationSlotsResponse) => {
    try {
      await apiService.acceptPlan(plan);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  //<------ Preferences ------>//

  public getPreferences = async () => {
    const minCount = 15;
    try {
      const userGoals = [...this.userGoals];
      let goals = this._goalsList;
      if (!goals) {
        goals = await apiService.getOnboardingGoalsList();
        this.setGoalsList(goals);
      }

      // if user chosen less than 4 goals we append random ones to reach 4
      while (userGoals.length < 4) {
        const filteredGoals = goals.filter((g) => !userGoals.some((ug) => ug.key === g.key));
        const randomIndex = Math.floor(Math.random() * (filteredGoals.length - 1));
        userGoals.push(filteredGoals[randomIndex]);
      }
      const userGoalsTitles = userGoals.map((g) => g.title);

      let preferences = await apiService.getExperiences({
        activities: userGoalsTitles,
        count: minCount, // amount of preferences to get
      });
      const diff = minCount - preferences.length;
      // if amount of preferences less than minCount we need to fetch preferences with another goals to reach minCount
      if (diff > 0) {
        const anotherGoalsTitles = goals
          .filter((g) => !userGoalsTitles.includes(g.title))
          .map((g) => g.title);
        const morePreferences = await apiService.getExperiences({
          activities: anotherGoalsTitles,
          count: diff,
        });
        preferences = preferences.concat(morePreferences);
      }
      return preferences;
    } catch (error) {
      console.error(error);
    }
  };

  public getSimilarPreferences = async (experienceId: IExperience["id"]) => {
    try {
      return await apiService.getSimilar(experienceId);
    } catch (error) {
      console.error(error);
    }
  };

  public savePreferences = async (preferences: IExperience[]) => {
    try {
      await apiService.savePreferences(preferences.map((p) => p.id));
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  //<------ User Goals ------>//

  public saveUserGoals = async (goals: IGoalItem[]) => {
    try {
      await apiService.updateUserGoals(goals);
      this.setUserGoals(goals);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  @observable private _userGoals: IGoalItem[] = [];

  @computed get userGoals() {
    return this._userGoals;
  }

  @action private setUserGoals = (goals: IGoalItem[]) => {
    this._userGoals = goals;
  };
}

export const onboardingStore: IOnboardingStore = new OnboardingStore();
