import { AuthServices, AuthTypes, IAuthInstance, IAuthService } from "./types";
import { authInstanceFactory } from "./instancesFactory";

class AuthService implements IAuthService {
  private get currentServiceName() {
    return localStorage.getItem("authServiceName") as AuthServices | null;
  }

  private setCurrentServiceName = (name: AuthServices) => {
    localStorage.setItem("authServiceName", name);
  };

  private get authInstance() {
    const serviceName = this.currentServiceName;
    if (!serviceName) {
      return null;
    }
    return authInstanceFactory.getInstance(serviceName);
  }

  getAuthTokens = async () => {
    if (!this.authInstance || !this.currentServiceName) {
      return null;
    }
    return {
      tokens: await this.authInstance.getAuthTokens(),
      serviceName: this.currentServiceName,
    };
  };

  loadAuthState = async () => {
    if (!this.authInstance) {
      return null;
    }
    return this.authInstance.loadAuthState();
  };

  logInWith = (
    type: AuthTypes,
    email?: string,
    password?: string,
    serviceName: AuthServices = AuthServices.FIREBASE_RW,
  ) => {
    this.setCurrentServiceName(serviceName);
    return (this.authInstance as IAuthInstance).logInWith(type, email, password);
  };

  logOut = async () => {
    if (!this.authInstance) {
      return;
    }
    this.authInstance.logOut();
  };
}

export const authService: IAuthService = new AuthService();
