import styled from "styled-components";
import { Colors } from "assets/styles/constants";

import emptyImg from "assets/images/empty-state-illustration.svg";

export const EmptyImage = styled.img.attrs({
  src: emptyImg,
})`
  width: 300px;
  height: 287px;
  margin-top: 51px;
  margin-bottom: 30px;
`;

export const EmptyTitle = styled.h3`
  font-size: 20px;
  line-height: 36px;
  color: ${Colors.blueGrey};
`;

export const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
