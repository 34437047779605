import { GlobalStyle } from "assets/styles/globalStyle";
import { ErrorBoundary } from "components";
import AppContainer from "containers/App/index";
import { initDayJs } from "external/dayjs";
import "index.css";
import { Provider } from "mobx-react";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { gtmService } from "services/gtm";
import { stores } from "stores";




initDayJs();
gtmService.init();
// errorService.init();

ReactDOM.render(
  // <React.StrictMode>
  <ErrorBoundary>
    <BrowserRouter>
      <Provider {...stores}>
        <GlobalStyle />
        {/* <ScrollToTop /> */}
        <AppContainer />
      </Provider>
    </BrowserRouter>
  </ErrorBoundary>,
  // </React.StrictMode>,
  document.getElementById("root"),
);
