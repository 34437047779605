import { Colors } from "assets/styles/constants";
import { device } from "calculator_feature/config/devices";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const PageWrapper = styled.div<{ isPaidPolicyPage?: boolean }>`
  background-color: #ffffff;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;

  @media ${device.mobileS} {
    max-height: 85vh;
    padding-left: 11%;
    padding-right: 11%;
    min-width: 90%;
  }
`;
export const EmailPageWrapper = styled.div`
  background-color: #ffffff;
  display: flex;
  align-items: center;
  flex-direction: column;
  max-height: 70vh;
  width: 100%;

  @media ${device.mobileS} {
    max-height: 85vh;
    padding-left: 11%;
    padding-right: 11%;
    min-width: 90%;
  }

  @media ${device.laptop} {
    max-height: 70vh;
    padding-left: 23%;
    padding-right: 23%;
  }
`;

export const MainPageWrapper = styled.div`
  background-color: #ffffff;
  display: flex;
  align-items: center;
  flex-direction: column;
  max-height: 70vh;
  width: 100vw;

  @media ${device.mobileS} {
    max-height: 85vh;
    padding-left: 2%;
    padding-right: 2%;
    align-self: center;
  }
  @media ${device.tablet} {
    padding-left: 11%;
    padding-right: 11%;
  }

  @media ${device.laptop} {
    max-height: 70vh;
  }
`;
export const GreyBox = styled.div`
  background-color: #e6e9f3;

  border-radius: 8px;
  align-items: center;
  @media ${device.mobileS} {
    width: 45%;
    min-height: 50px;
  }

  @media ${device.laptop} {
    width: 314px;
    height: 112px;
  }
`;
export const TotalSumWrapper = styled.div`
  padding: 28px 0 0 23px;
  @media ${device.mobileS} {
    padding: 8px 5px 5px 10px;
  }

  @media ${device.laptop} {
    padding: 28px 0 0 23px;
  }
`;
export const DetailsStyle = styled.div`
  color: #ff5277;
  font-size: 1.2em;
  font-weight: 600;
  font-family: "Poppins", sans-serif !important;
`;
export const ButtonsEmailSendWrapper = styled.div`
  margin-top: 36px;
  @media ${device.mobileS} {
    margin-top: 20px;
  }
  @media ${device.tablet} {
    margin-top: 25px;
  }
  @media ${device.laptop} {
    margin-top: 36px;
  }
`;
export const TotalTextStyle = styled.div`
  font-size: 0.875em;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: #1c1259;
`;
export const TotalTextMainPadeStyle = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #1c184f;
  z-index: 5;
  @media ${device.mobileS} {
    font-size: 10px;
    line-height: 12px;
  }
  @media ${device.tablet} {
    font-size: 14px;
    line-height: 21px;
  }
`;
export const TotalSumStyle = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 31px;
  color: #1c184f;
  display: flex;
  @media ${device.mobileS} {
    font-size: 20px;
    line-height: 22px;
  }
  @media ${device.tablet} {
    font-size: 32px;
    line-height: 31px;
  }
`;
export const CurrencyStyle = styled.div`
  font-size: 0.813em;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #1c1259;
  padding-bottom: 12px;
  align-self: end;
`;
export const CutStyle = styled.div`
  display: inline;
  font-size: 13px;
  align-self: end;
  /* font-weight: 600; */
  font-stretch: normal;
  font-style: normal;
  /* line-height: 2; */
  letter-spacing: normal;
  color: #1c1259;
  padding-bottom: 2px;
`;
export const MainContainer = styled.div`
  width: 1100px;
  display: flex;
  padding: 0 40px;
  justify-content: center;
  flex-direction: column;
  align-items: left;
  @media (min-width: 1200px) {
    width: 1100px;
  }

  /* Landscape tablets and medium desktops */
  @media (min-width: 992px) and (max-width: 1199px) {
    width: 1000px;
  }

  /* Portrait tablets and small desktops */
  @media (min-width: 768px) and (max-width: 991px) {
    width: 800px;
  }

  /* Landscape phones and portrait tablets */
  @media (max-width: 767px) {
    width: 600px;
  }

  /* Portrait phones and smaller */
  @media (max-width: 480px) {
    width: 450px;
  }
`;
export const MainPageLeft = styled.div`
  padding: 40px 20px;
  @media ${device.mobileS} {
    padding: 0;
  }
  @media ${device.tablet} {
    padding: 40px 20px;
  }
  @media ${device.laptop} {
  }
`;
export const MainEmailContainer = styled.div`
  width: 31%;
  display: flex;
  padding: 0 40px;
  justify-content: center;
  flex-direction: column;
  align-items: left;
  @media (min-width: 1200px) {
    /* width: 31%; */
  }

  /* Landscape tablets and medium desktops */
  @media (min-width: 992px) and (max-width: 1199px) {
    /* width: 1000px; */
  }

  /* Portrait tablets and small desktops */
  @media (min-width: 768px) and (max-width: 991px) {
    /* width: 800px; */
  }

  /* Landscape phones and portrait tablets */
  @media (max-width: 767px) {
    /* width: 600px; */
  }

  /* Portrait phones and smaller */
  @media (max-width: 480px) {
    /* width: 450px; */
  }
`;
export const ResponsiveWrapperStyle = styled.div`
  /* Large desktops and laptops */
  @media (min-width: 1200px) {
    width: 1100px;
  }

  /* Landscape tablets and medium desktops */
  @media (min-width: 992px) and (max-width: 1199px) {
    width: 1000px;
  }

  /* Portrait tablets and small desktops */
  @media (min-width: 768px) and (max-width: 991px) {
    width: 800px;
  }

  /* Landscape phones and portrait tablets */
  @media (max-width: 767px) {
    width: 600px;
  }

  /* Portrait phones and smaller */
  @media (max-width: 480px) {
    width: 450px;
  }
`;
export const SlideWrapper = styled.div`
  margin-top: 39px;
  padding: 20px 50px 30px 50px;
  border-radius: 16px;
  box-shadow: 0 10px 40px 0 rgba(28, 18, 89, 0.15);
  background-color: #ffffff;
`;
export const SlideWrapperEmailPage = styled.div`
  overflow: hidden;
  display: flex;
  min-height: 700px;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  margin-top: 39px;
  padding: 40px 100px 30px 100px;
  border-radius: 16px;
  box-shadow: 0 10px 40px 0 rgba(28, 18, 89, 0.15);
  background-color: #ffffff;
  flex-direction: column;
  align-items: center;
  @media ${device.mobileS} {
    padding: 0 20px;
    margin-top: 0;
  }
  @media ${device.tablet} {
    padding: 40px 30px 30px 30px;
  }
  @media ${device.laptopXL} {
    padding: 40px 100px 30px 100px;
  }
  @media ${device.laptopXL} {
    padding: 40px 100px 30px 100px;
  }
`;

export const SlideWrapperLastPage = styled.div`
  /* overflow: hidden; */
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  border-radius: 16px;
  background-color: transparent;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
`;
export const IconSlideWrapper = styled.div`
  text-align: center;
`;
export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
export const ButtonsBlock = styled.div`
  position: absolute;
  bottom: 4%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  @media ${device.mobileS} {
    position: relative;
  }
  @media ${device.laptop} {
    position: absolute;
  }
`;

export const FormContainerEmailPage = styled.div`
  display: flex;
  background-color: "red";
  align-items: center;
  flex-direction: column;
  @media ${device.mobileS} {
  }
  @media ${device.mobileL} {
  }
`;

export const ContainerRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const CatWrapperStyle = styled.div`
  position: absolute;
  left: 98px;
  bottom: -15px;
  @media ${device.mobileS} {
    visibility: hidden;
  }

  @media ${device.tablet} {
    visibility: visible;
  }
`;
export const EmailWrapperStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: "center";
`;
export const EmailIconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 220px;
  margin-left: -74px;
  padding: 60px 150px 50px 150px;
  @media ${device.mobileS} {
    padding: 0 150px 50px 150px;
  }
  @media ${device.tablet} {
    padding: 60px 150px 50px 150px;
  }
`;
export const StyledLinkLastPage = styled.a`
  .httpsyoutubebsY {
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: right;
    color: #ffae00;
  }
`;
export const SmallTextStyle = styled.div`
  font-size: 0.875em;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 20px;
  letter-spacing: normal;
  text-align: center;
  color: #77719b;
  margin-bottom: 9%;
  @media ${device.mobileS} {
    margin-top: 10px;
    margin-bottom: 10%;
    font-size: 0.5em;
    margin-bottom: 7.86%;
    line-height: 12px;
  }
  @media ${device.mobileM} {
    margin-top: 10px;
    font-size: 0.7em;
  }
  @media ${device.mobileL} {
    font-size: 0.8em;
    line-height: 20px;
  }
  @media ${device.tablet} {
    font-size: 14px;
  }
`;

export const LastPageTextStyle = styled.div`
  font-size: 1.5em;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #1c1259;
  @media ${device.mobileS} {
    font-size: 0.5em;
    /* max-width: auto; */
  }
  @media ${device.mobileM} {
    font-size: 0.7em;
  }
  @media ${device.mobileL} {
    font-size: 0.8em;
  }
  @media ${device.tablet} {
    font-size: 0.9em;
  }
  @media ${device.laptop} {
    font-size: 1em;
  }
  @media ${device.laptopL} {
    font-size: 1.5em;
  }
  @media ${device.desktop} {
    font-size: 1.5em;
  }
`;

export const SlideTitleStyle = styled.div`
  font-size: 1.875em;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: center;
  color: "#1c1259";
  @media ${device.mobileS} {
    font-size: 1.3em;
    margin-bottom: 40px;
  }
  @media ${device.mobileM} {
    font-size: 1.3em;
    margin-bottom: 0px;
  }
  @media ${device.mobileL} {
    font-size: 1.5em;
    margin-bottom: 0px;
  }
  @media ${device.tablet} {
    font-size: 30px;
  }
`;
export const SlideSalaryTitleStyle = styled.div`
  font-size: 1.875em;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: center;
  color: "#1c1259";
  margin-top: 40px;

  @media ${device.mobileS} {
    font-size: 1.3em;
    margin-bottom: 40px;
  }
  @media ${device.mobileM} {
    font-size: 1.3em;
    margin-bottom: 0px;
  }
  @media ${device.mobileL} {
    font-size: 1.5em;
    margin-bottom: 0px;
  }
  @media ${device.tablet} {
    font-size: 26px;
    margin-top: 0;
  }
  @media ${device.laptopXL} {
    font-size: 30px;
    margin-top: 40px;
  }
`;
export const ThousandWrapper = styled.div`
  position: absolute;
  transform: rotate(270deg);
  top: 79px;
  left: -40px;
  font-size: 10px;
  font-weight: 600;
  color: #d5daeb;

  @media ${device.mobileS} {
    top: 28px;
    left: 0;
    font-size: 8px;
  }
  @media ${device.tablet} {
    top: 79px;
    left: -40px;
    font-size: 10px;
  }
`;
export const YearsWrapper = styled.div`
  position: absolute;
  font-weight: 600;
  color: #d5daeb;

  @media ${device.mobileS} {
    right: 20px;
    font-size: 8px;
    bottom: 15px;
  }
  @media ${device.tablet} {
    right: 10px;
    bottom: 25px;
    font-size: 10px;
  }
`;
export const MainSlideTitleStyle = styled.div`
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1c1259;
  margin-top: 10px;
  margin-right: 10px;
  padding-left: 60px;
  @media ${device.mobileS} {
    font-size: 12px;
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 10px 0;
    text-align: center;
  }
  @media ${device.tablet} {
    font-size: 16px;
    display: block;
    margin-top: 40px;
    padding: 0;
    text-align: left;
    width: 100%;
  }
  @media ${device.laptop} {
    margin-top: 10px;
    margin-right: 10px;
  }
`;
export const SliderWrapperStyle = styled.div`
  margin-top: 20px;

  @media ${device.mobileS} {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
  @media ${device.laptop} {
    margin-left: 13px;
    display: block;
  }
`;
export const SalarySliderWrapperStyle = styled.div`
  margin-top: 20px;

  @media ${device.mobileS} {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
  @media ${device.laptop} {
    margin-left: 13px;
  }
`;

export const SliderMainWrapperStyle = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;

  @media ${device.mobileS} {
    display: flex;
    justify-content: center;
  }
  @media ${device.laptop} {
    justify-content: start;
    padding-left: 20px;
  }
`;

export const ButtonBackStyle = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 5px;
  z-index: 100;
  margin: 0 auto;
  width: fit-content;

  @media ${device.mobileS} {
    font-size: 0.9em;
  }
  @media ${device.mobileM} {
    font-size: 1em;
  }
  @media ${device.mobileL} {
    font-size: 1.1em;
  }
  @media ${device.tablet} {
    font-size: 1.1em;
  }
  @media ${device.laptop} {
    font-size: 1.2em;
  }
  @media ${device.laptopL} {
    font-size: 1.2em;
  }
  @media ${device.desktop} {
    font-size: 2.67em;
  }
`;
export const ButtonBackToReportStyle = styled.div<{ emailPage?: boolean }>`
  display: flex;
  font-family: "Poppins", sans-serif;
  justify-content: center;
  margin-top: 5px;
  border-radius: 30px;
  color: ${Colors.textColor};
  font-weight: ${({ emailPage }) => (emailPage ? "500" : "400")};
  font-stretch: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: none;
  text-decoration-line: underline;
  padding: 0;
  cursor: pointer;

  @media ${device.mobileL} {
    font-size: 12px;
  }

  @media ${device.tablet} {
    font-size: ${({ emailPage }) => (emailPage ? "18px" : "16px")};
  }

  & :hover {
    background-color: #f8f8fc;
  }
`;

export const GridWrapper = styled.div`
  justify-content: center;
  @media ${device.mobileS} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @media ${device.mobileM} {
    display: flex;
  }
  @media ${device.mobileL} {
    display: flex;
  }
  @media ${device.tablet} {
    display: flex;
  }
  @media ${device.laptop} {
    display: flex;
    justify-content: center;
  }
`;
export const GridLastPageWrapper = styled.div`
  margin: "0% 10% 0% 10%";
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media ${device.mobileS} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: "0% 2% 0% 2%";
  }
  @media ${device.tablet} {
    margin: "0% 5% 0% 5%";
  }
`;
export const LastPageTextWrapper = styled.div`
  @media ${device.mobileS} {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 40px;
  }
  @media ${device.tablet} {
    padding-top: 0;
  }
`;
export const ButtonSendWrapper = styled.div`
  @media ${device.mobileS} {
    margin-top: 50px;
  }
  @media ${device.tablet} {
    margin-top: 50px;
    margin-left: 20px;
    margin-bottom: 20px;
  }
`;
export const ButtonReWrapper = styled.div`
  @media ${device.mobileS} {
    margin-top: 10px;
    padding-bottom: 20px;
  }
  @media ${device.tablet} {
    margin-top: 50px;
    margin-left: 20px;
    margin-bottom: 20px;
  }
`;
export const MainButtonSendWrapper = styled.div<{ mainPage?: boolean }>`
  z-index: 500;
  @media ${device.mobileS} {
    margin-top: 10px;
  }
  @media ${device.tablet} {
    margin-top: ${({ mainPage }) => (mainPage ? "65px" : "10px")};
    margin-left: 20px;
    margin-bottom: 20px;
  }
`;
export const ButtonLastWrapper = styled.div`
  @media ${device.mobileS} {
  }
  @media ${device.tablet} {
  }
`;
export const TotalWrapper = styled.div`
  @media ${device.mobileS} {
    font-size: 0.6em;
  }
  @media ${device.tablet} {
    font-size: 0.75em;
  }
  @media ${device.laptop} {
    font-size: 1em;
  }
  @media ${device.laptopL} {
    font-size: 1em;
  }
`;
export const TotalStrong = styled.div`
  @media ${device.mobileS} {
    font-size: 0.6em;
  }
  @media ${device.tablet} {
    font-size: 0.75em;
  }
  @media ${device.laptop} {
    font-size: 1.2em;
  }
  @media ${device.laptopL} {
    font-size: 1.5em;
  }
`;
export const CircularWrapper = styled.div`
  @media ${device.mobileS} {
    width: 30%;
  }
  @media ${device.tablet} {
    width: 65%;
  }
  @media ${device.laptop} {
    width: 60%;
  }
  @media ${device.laptopL} {
    width: 60%;
  }
  @media ${device.desktop} {
    width: 65%;
  }
`;
export const GridSlidersWrapper = styled.div`
  @media ${device.mobileS} {
    display: flex;
    grid-template-columns: 50% 50%;
    grid-gap: 10px;
    justify-content: center;
  }
  @media ${device.tablet} {
    display: flex;
    grid-template-columns: 314px 314px;
    grid-gap: 24px;
    justify-content: center;
  }
`;
export const ChartWrapper = styled.div`
  width: 714px;
  height: 200px;
  @media ${device.mobileS} {
    width: 380px;
    height: 100px;
  }
  @media ${device.mobileM} {
    width: 380px;
    height: 100px;
  }
  @media ${device.mobileL} {
    width: 380px;
    height: 100px;
  }
  @media ${device.tablet} {
    width: 400px;
    height: 200px;
  }
  @media ${device.laptop} {
    width: 714px;
    min-height: 200px;
  }
`;

export const LeftGrid = styled.div`
  display: flex;
  justify-content: left;
  align-items: baseline;
  @media ${device.mobileS} {
    flex-direction: column;
    align-items: center;
  }
  @media ${device.laptopL} {
    flex-direction: row;
  }
`;

export const StyledLinkHidden = styled(Link)`
  align-self: center;
  width: 44px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  visibility: hidden;
  letter-spacing: normal;
  text-align: right;
  color: #1c1259;
`;

export const StyledLink = styled(Link)`
  align-self: center;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  margin-left: 5px;
  z-index: 500;
  color: ${Colors.textColor};
  & :hover :visited :active {
    color: ${Colors.textColor};
  }
  @media ${device.mobileS} {
    margin-top: 20px;
  }
  @media ${device.mobileM} {
    margin-top: 20px;
  }
  @media ${device.mobileL} {
    margin-top: 30px;
  }
  @media ${device.tablet} {
    margin-top: 40px;
  }
  @media ${device.laptop} {
    margin-top: 40px;
  }
  @media ${device.laptopL} {
    margin-top: 40px;
  }
  @media ${device.desktop} {
    margin-top: 0px;
  }
`;
export const SlideButtonStyle = styled.div`
  display: flex;
  margin: "0 auto";
  justify-content: center;
  align-items: baseline;
  padding-bottom: 2px;

  @media ${device.mobileS} {
    flex-direction: column;
    align-items: center;
    font-size: 0.5em;
    margin-left: 0px;
    width: 50%;
  }
  @media ${device.mobileM} {
    font-size: 0.7em;
  }
  @media ${device.mobileL} {
    font-size: 0.7em;
    margin-left: 0px;
  }
  @media ${device.tablet} {
    flex-direction: row;
    align-items: center;
    font-size: 0.7em;
    margin-left: 0px;
  }
  @media ${device.laptop} {
    font-size: 1em;
    margin-left: 0px;
  }
  @media ${device.laptopL} {
    font-size: 1em;
    margin-left: 0px;
  }
  @media ${device.desktop} {
    font-size: 1.125em;
    margin-left: 0px;
  }
`;
export const SendButtonStyle = styled.div`
  display: flex;
  margin: "0 auto";
  justify-content: center;
  @media ${device.mobileS} {
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin-top: 100px;
  }
  @media ${device.tablet} {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 80px;
  }
`;
export const SendButtonEmail = styled.div`
  display: flex;
  margin: "0 auto";
  justify-content: center;
  text-transform: none;
  @media ${device.mobileS} {
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin-top: 20px;
  }
`;

export const LogoStyle = styled.div`
  padding: 8px 309.3px 50px 40px;
  object-fit: contain;

  background-color: #ffffff;
  > img {
    width: 100.7px;
    height: 25px;
    @media ${device.mobileS} {
      margin-top: 5px;
    }
    @media ${device.tablet} {
      margin-top: 20px;
    }
  }
`;

export const SoundsGoodStyle = styled.div`
  position: absolute;
  cursor: pointer;
  right: 2.35%;
  top: 0;
  background: #1c1259;
  border-radius: 16px;
  padding: 6px 26px 6px 26px;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0.3px;
  text-align: center;
  margin-top: 50px;
  color: white;
  @media ${device.mobileS} {
    font-size: 0.5em;
  }
  @media ${device.mobileM} {
    font-size: 0.7em;
  }
  @media ${device.mobileL} {
    font-size: 0.9em;
  }
  @media ${device.tablet} {
    font-size: 13px;
    margin-top: 2%;
  }
`;

export const NewSlideWrapper = styled.div`
  width: 100%;
  gap: 0;
  border-radius: 16px;
  background-color: #ffffff;
  box-shadow: 0 10px 40px 0 rgba(28, 18, 89, 0.15);
  height: 70vh;
  @media ${device.mobileS} {
    display: flex;
    flex-direction: column;
    overflow: auto;
  }
  @media ${device.mobileM} {
    height: fit-content;
  }
  @media ${device.mobileL} {
  }
  @media ${device.tablet} {
  }
  @media ${device.laptop} {
    display: grid;
    grid-template-columns: 50% 50%;
  }
  @media ${device.laptopL} {
    display: grid;
    grid-template-columns: 42% 58%;
  }
`;

export const MainSlideWrapper = styled.div`
  width: 80%;
  gap: 0;
  border-radius: 16px;
  background-color: #ffffff;
  height: 80vh;
  @media ${device.mobileS} {
    display: flex;
    flex-direction: column;
  }
  @media ${device.mobileM} {
    width: 100%;
    display: flex;
    align-items: center;
  }
  @media ${device.mobileL} {
  }
  @media ${device.tablet} {
  }
  @media ${device.laptop} {
    display: grid;
    grid-template-columns: 43% 58%;
  }
  @media ${device.laptopL} {
  }
  @media ${device.desktop} {
  }
`;

export const LeftWrapperGrid = styled.div`
  background-color: #f8f8fc;
  height: 100%;
  @media ${device.mobileS} {
    height: fit-content;
  }
  @media ${device.tablet} {
    height: 100%;
  }
`;
export const LeftMainWrapperGrid = styled.div`
  background-color: #ffffff;
  height: 100%;
  @media ${device.mobileS} {
    height: max-content;
  }
  @media ${device.tablet} {
    height: max-content;
  }
`;

export const RightWrapperGrid = styled.div`
  position: relative;
  @media ${device.mobileS} {
    height: fit-content;
    padding: 5% 5% 10% 5% !important;
  }
  @media ${device.mobileM} {
  }
  @media ${device.mobileL} {
  }
  @media ${device.tablet} {
    height: 100%;
    padding: 14% 15.77% 10% 15.77%;
  }
  @media ${device.laptop} {
  }
  @media ${device.laptopL} {
  }
  @media ${device.desktop} {
  }
`;
export const RightWrapperSmallGrid = styled.div`
  position: relative;
  @media ${device.mobileS} {
    height: fit-content;
    padding: 16.07% 5% 10% 5% !important;
  }
  @media ${device.mobileM} {
  }
  @media ${device.mobileL} {
  }
  @media ${device.tablet} {
    height: 100%;
    padding: 14% 15.77% 10% 15.77%;
  }
  @media ${device.laptop} {
  }
  @media ${device.laptopL} {
  }
  @media ${device.desktop} {
  }
`;

export const RightWrapperGridWrap = styled.div`
  /* height: 100%; */
  @media ${device.mobileS} {
    padding: 2% 5% 10% 5%;
  }
  @media ${device.mobileM} {
  }
  @media ${device.mobileL} {
  }
  @media ${device.tablet} {
    padding: 14% 15.77% 10% 15.77%;
  }
  @media ${device.laptop} {
  }
  @media ${device.laptopL} {
  }
  @media ${device.desktop} {
  }
`;

export const RightWrapperPaidGrid = styled.div`
  padding: 3% 10% 10% 10%;
  position: relative;
  @media ${device.mobileS} {
    padding: 3% 5% 10% 5%;
  }
  @media ${device.laptopL} {
    padding: 2% 15% 0% 15%;
  }
`;
export const SelectWrapper = styled.div`
  @media ${device.mobileS} {
    padding-left: 7%;
    padding-right: 7%;
  }
  @media ${device.tablet} {
    padding-left: 30%;
    padding-right: 30%;
  }
`;
export const RightPostGrid = styled.div`
  padding: 3% 15% 0% 15%;
  @media ${device.mobileS} {
    padding: 3% 0% 0% 0%;
  }
`;
export const RightWrapMain = styled.div`
  padding: 20px 40px 0;
  box-shadow: 0 10px 40px 0 rgba(28, 18, 89, 0.15);
  width: 783px;
  margin: 20px 0;
  border-radius: 16px;
  @media ${device.mobileS} {
    position: absolute;
    top: 444px;
    width: 95vw;
    padding: 20px 0 0 0;
    z-index: 0;
  }
  @media ${device.tablet} {
    position: static;
    padding: 20px 40px 0;
  }
  @media ${device.laptop} {
    width: 783px;
  }
`;
export const MainPageBigText = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 9px;
  line-height: 13px;
  color: #8884a3;
  text-align: justify;
  @media ${device.mobileS} {
    padding: 0px 15px 20px 15px;
  }
  @media ${device.tablet} {
    padding: 49px 30px 58px 35px;
  }
`;
export const LeftGridSlide = styled.div`
  background-color: #f8f8fc;
`;
export const LeftMainGridSlide = styled.div`
  background-color: #ffffff;
  @media ${device.mobileS} {
    justify-content: center;
  }
  @media ${device.tablet} {
    justify-content: start;
  }
`;

export const SubSlideTitleStyle = styled.div`
  font-size: 1em;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: center;
  color: #1c1259;
  @media ${device.mobileS} {
    font-size: 1em;
  }
  @media ${device.mobileM} {
    font-size: 1em;
  }
  @media ${device.mobileL} {
    font-size: 1em;
  }
  @media ${device.tablet} {
    font-size: 1em;
  }
  @media ${device.laptop} {
    font-size: 16px;
  }
`;
export const LightContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1%;
`;
export const LightIcon = styled.div`
  width: 31px;
  height: 35.5px;
  object-fit: contain;
  margin-right: 10px;
`;
export const LightText = styled.div`
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #1c1259;
  @media ${device.mobileS} {
    font-size: 10px;
  }
  @media ${device.tablet} {
    font-size: 14px;
  }
`;
export const ReturnContainer = styled.button`
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
`;
export const ReturnButtonStyle = styled.div`
  padding: 5px 12px 4px 17px;
  border-radius: 17.5px;
  box-shadow: 0 5px 15px 0 rgba(61, 10, 21, 0.2);
  background-color: #ffffff;
  flex-wrap: nowrap;
  display: flex;
`;
export const ReturnIcon = styled.div`
  object-fit: contain;
  margin-right: 10px;
`;
export const ReturnText = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #f04394;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  align-items: center;
`;
export const EmailPageInputsWrapper = styled.div`
  width: 40%;
`;
export const MoneyIconWrapper = styled.div`
  position: absolute;
  left: 50px;
  bottom: 15px;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1c1259;
`;
