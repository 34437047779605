import { useWindowSize } from "../../../utils/hooks/useWindowSize";
import size from "../../../config/devices";
import { Size } from "../../../utils/hooks/useWindowSizeTypes";

export const CustomStyles: any = () => {
  const sizes: Size = useWindowSize();
  const tabletWidth = +size.tablet.slice(0, -2);
  const mobileView = () => {
    if (sizes.width) {
      if (sizes.width < tabletWidth) {
        return true;
      }
      return false;
    }
  };
  const view = mobileView();

  if (view) {
    const selectMobileInputStyles = {
      fontSize: "12px",
      fontWeight: "400",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      margin: "auto",
      color: "#1c1259",
      borderTop: 0,
      borderLeft: 0,
      borderRight: 0,
      borderBottom: "solid 2px #d5daeb",
      boxShadow: "none",
      minHeight: '0',
      "&:hover": {
        borderBottom: "solid 2px #d5daeb",
        cursor: "pointer",
      },
      "& .css-tj5bde-Svg": {
        fill: "#1c1259",
      },
      "& .css-1wa3eu0-placeholder": {
        color: "#1c1259",
        paddingLeft: "10px",
      },

      "& .css-1uccc91-singleValue": {
        color: "#1c1259",
        paddingLeft: "10px",
      },
      "& .css-2b097c-container, .css-z1gi6q-control ": {
        marginLeft: "15%",
        marginRight: "15%",
      },
      "& .css-tlfecz-indicatorContainer": {
        padding: "0",
      },
      "& .css-1gtu0rj-indicatorContainer": {
        padding: "0",
      },
      "& .css-2b097c-container div:nth-of-type(1)": {
        backgroundColor: "red",
      },
      "&  .css-g1d714-ValueContainer": {
        padding: "0",
      },

    };
    return {
      placeholder: () => ({
        fontSize: "12px",
        color: "#1c1259",
        paddingLeft: "10px",
        // margin: "auto",
      }),
      menuList: () => ({
        maxHeight: "150px",
        overflowY: "auto",
        paddingBottom: "4px",
        paddingTop: "4px",
        position: "relative",
        overflowScrolling: "touch",
        boxSizing: "border-box",
      }),
      option: () => ({
        fontSize: "12px",
        fontWeight: "400",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        paddingLeft: "10px",
        color: "#1c1259",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        cursor: "pointer",
        fill: "#1c1259",
        opacity: 1,
        zIndex: 100,
        "&: hover": {
          backgroundColor: "#EDEEF6",
        },
      }),
      control: (styles: any) => ({ ...styles, ...selectMobileInputStyles }),
    };
  } else {
    const selectInputStyles = {
      fontWeight: "400",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      margin: "auto",
      color: "#1c1259",
      borderTop: 0,
      borderLeft: 0,
      borderRight: 0,
      borderBottom: "solid 2px #d5daeb",
      boxShadow: "none",
      "&:hover": {
        borderBottom: "solid 2px #d5daeb",
        cursor: "pointer",
      },
      "& .css-tj5bde-Svg": {
        fill: "#1c1259",
      },
      "& .css-1wa3eu0-placeholder": {
        color: "#1c1259",
        paddingLeft: "10px",
      },

      "& .css-2b097c-container, .css-z1gi6q-control ": {
        marginLeft: "15%",
        marginRight: "15%",
      },
      "& .css-tlfecz-indicatorContainer": {
        padding: "0",
      },
      "& .css-1gtu0rj-indicatorContainer": {
        padding: "0",
      },
      "& .css-2b097c-container div:nth-of-type(1)": {
        backgroundColor: "red",
      },
      "&  .css-g1d714-ValueContainer": {
        padding: "0",
        flexWrap: "nowrap",
      },
    };
    return {
      placeholder: () => ({
        fontSize: "20px",
        color: "#1c1259",
        paddingLeft: "10px",
        whiteSpace: "nowrap",

        "& .css-1uccc91-singleValue": {
          color: "#1c1259",
          paddingLeft: "10px",
        },
      }), 
      singleValue: () => ({
        fontSize: "20px",
        color: "#1c1259",
        paddingLeft: "10px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        // margin: "auto",
      }),
      menuList: () => ({
        maxHeight: "255px",
        overflowY: "auto",
        paddingBottom: "4px",
        paddingTop: "4px",
        position: "relative",
        overflowScrolling: "touch",
        boxSizing: "border-box",
      }),
      option: () => ({
        fontSize: "20px",
        fontWeight: "400",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        paddingLeft: "10px",
        color: "#1c1259",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        cursor: "pointer",
        fill: "#1c1259",
        opacity: 1,
        zIndex: 100,
        "&: hover": {
          backgroundColor: "#EDEEF6",
        },
      }),
      control: (styles: any) => ({ ...styles, ...selectInputStyles }),
    };
  }
};
