// Core
import React, { PropsWithChildren } from "react";
import { DropDownProps } from "antd/lib/dropdown";

// Components
import { Dropdown } from "components/Dropdown";
import { gtmService } from "services/gtm";

// Styles
import { IconsWrapper, StyledButton } from "./styles";

// Assets
import linkedinIcon from "assets/images/linkedin.svg";
import facebookIcon from "assets/images/facebook.svg";
import twitterIcon from "assets/images/twitter.svg";

// Other
import { getPopupContainer } from "utils/helpers";
import { ExperienceDataLayerType, GTMSocialType } from "services/gtm/types";

interface IProps extends PropsWithChildren<Omit<DropDownProps, "overlay">> {
  shareLink: string;
  experienceTitle?: string;
  gtmDataLayer?: ExperienceDataLayerType;
}

interface IShareButton {
  title: string;
  icon: string;
  link: string;
  color: string;
  gtmSocialType: GTMSocialType;
}

const shareButtons: IShareButton[] = [
  {
    title: "Share with Facebook",
    icon: facebookIcon,
    link: "https://www.facebook.com/sharer/sharer.php?u=",
    color: "#3b5998",
    gtmSocialType: "Facebook",
  },
  {
    title: "Share with Twitter",
    icon: twitterIcon,
    link: "https://twitter.com/intent/tweet?url=",
    color: "#00aced",
    gtmSocialType: "Twitter",
  },
  {
    title: "Share with Linkedin",
    icon: linkedinIcon,
    link: "https://www.linkedin.com/sharing/share-offsite/?url=",
    color: "#007bb5",
    gtmSocialType: "LinkedIn",
  },
];

export function ShareDialog({
  children,
  shareLink,
  experienceTitle,
  gtmDataLayer,
  ...props
}: IProps) {
  const handleItemClick = (gtmSocialType: GTMSocialType) => (e: React.MouseEvent<any>) => {
    e.stopPropagation();

    if (gtmDataLayer && experienceTitle) {
      gtmService.onSocialsShareBtnClick(gtmDataLayer, gtmSocialType, experienceTitle);
    }
  };

  return (
    <Dropdown
      placement="bottomRight"
      trigger={["click"]}
      getPopupContainer={getPopupContainer} // maintains parent hover state
      {...props}
      overlay={
        <IconsWrapper>
          {shareButtons.map(({ icon, title, link, color, gtmSocialType }) => (
            <StyledButton
              className="share-exp-dialog-btn"
              key={link}
              color={color}
              href={`${link}${encodeURIComponent(shareLink)}`}
              target="_blank"
              icon={icon}
              title={title}
              onClick={handleItemClick(gtmSocialType)}
            />
          ))}
        </IconsWrapper>
      }>
      {children}
    </Dropdown>
  );
}
