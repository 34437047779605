import { ENV } from "config/env";
import {
  IUserInfo,
  IExperience,
  IApiError,
  IBannerData,
  IEvent,
  IExperienceFilters,
  ExperienceQuery,
  IPaginationResponse,
  IPaginationParams,
  IOnboardingData,
  IPromotionData,
  IUserBalance,
  IAppInitConfig,
  IDetailedExperience,
  IGetCalendarEventsParams,
  IAllocationSlotsResponse,
  ICardInfo,
  ICardPanInfo,
  ICommunityMembersResponse,
  IUserInfoResponse,
  AddressType,
  ScoopsValue,
  IEventSchedule,
  UNIT_TYPES,
  IConversationRatioResponse,
  IPeakHoursItem,
  IGoalItem,
  ITransaction,
  INewEvent,
  API_ERROR_CODES,
  IBookSorbetResponse,
} from "./types";
import { authService } from "services/auth";
import { qsService, QsOptions } from "services/qs";
import { Dayjs } from "dayjs";
import { omit } from "utils/helpers";
import { appStore } from "stores/app";
import { formatDate } from "utils/formatters";
import { AuthModalType } from "stores/app/types";
import { userStore } from "stores/user";

type FetchMethod = "GET" | "PUT" | "POST" | "DELETE" | "GET-FILE";
/**
 * Set of fields that must be converted from string to number format during response parsing
 */
const STRING_TO_NUMBER_FIELDS = [
  "availableUnits",
  "initialUnitsBalance",
  "scoopUnits",
  "scoops",
  "hoursPerDay",
];

interface IFetchParams {
  method?: FetchMethod;
  requestBody?: object | FormData;
  fileInResponse?: boolean;
  shouldAppendAccessToken?: boolean;
}

class ApiService {
  private async makeFetch<R extends object | Blob = object>(
    url: string,
    params: IFetchParams = {},
  ): Promise<R> {
    const { method, requestBody, fileInResponse, shouldAppendAccessToken } = params;
    const tokensData = await authService.getAuthTokens();
    const headers = new Headers({
      accept: "application/json, text/plain",
    });

    if (tokensData) {
      const {
        tokens: [authToken, providerAccessToken],
        serviceName,
      } = tokensData;

      if (authToken) {
        headers.append("auth-token", authToken);
        headers.append("auth-strategy", serviceName);

        if (providerAccessToken && shouldAppendAccessToken) {
          headers.append("access-token", providerAccessToken);
        }
      }
    }

    let body: string | FormData | undefined;
    if (requestBody) {
      if (requestBody instanceof FormData) {
        body = requestBody;
      } else {
        headers.append("Content-Type", "application/json");
        body = JSON.stringify(requestBody);
      }
    }

    const response = await fetch(`${ENV.REACT_APP_API_URL}${url}`, {
      method: method === "GET-FILE" ? "GET" : method,
      headers,
      body,
    });

    if (response.ok) {
      if (fileInResponse || method === "GET-FILE") {
        return response.blob() as Promise<R>;
      }
      const textBody = await response.text();
      if (!textBody) {
        return {} as R;
      }
      return JSON.parse(textBody, this.responseJsonParser);
    }
    let textBody = await response.text();
    let error: Partial<IApiError>;
    try {
      error = JSON.parse(textBody);
    } catch (e) {
      const matchResult = textBody.match(/<title>(.+)<\/title>/);

      if (matchResult) {
        textBody = matchResult[1] as string;
      }
      error = { data: { error: textBody } };
    }
    if (!error.status) {
      error.status = response.status;
    }

    if (response.status === 401) {
      if (error.data?.statusCode === API_ERROR_CODES.UserNotFound) {
        userStore.logOut();
      } else if (shouldAppendAccessToken) {
        // if the accessToken is expired
        appStore.showAuthModal({ type: AuthModalType.REFRESH });
      }
    }

    // eslint-disable-next-line no-throw-literal
    throw error as IApiError;
  }

  /* API CALLS */

  /* App API calls */
  public async getInitConfig(): Promise<IAppInitConfig> {
    return this.makeFetch("/initConfig");
  }

  /* User API calls */
  public async getUserInfo(): Promise<IUserInfo> {
    const { addresses, ...userInfo } = await this.makeFetch<IUserInfoResponse>("/user?myAccount=1");
    const personalAddress = addresses?.find((address) => address.type === AddressType.PERSONAL);
    return {
      ...userInfo,
      personalAddress: personalAddress ? omit(personalAddress, ["id", "type", "userId"]) : {},
    };
  }

  public getUserCardInfo(): Promise<ICardInfo | {}> {
    return this.makeFetch("/cards/sorbet/getCard");
  }

  public requestCardOtp() {
    return this.makeFetch("/cards/sorbet/sendCode");
  }

  public getCardPan(otp: string): Promise<ICardPanInfo> {
    return this.makeFetch(`/cards/sorbet/getCardPan/${otp}`);
  }

  public getCardTransactions(): Promise<ITransaction[]> {
    return this.makeFetch(`/transactions`);
  }

  public activateCard() {
    return this.makeFetch("/cards/sorbet/activate");
  }

  public updateUserInfo(newUserInfo: IUserInfo): Promise<IUserInfo> {
    return this.makeFetch("/user", { method: "PUT", requestBody: newUserInfo });
  }

  public async changeWellnessGoal(scoops: number) {
    // TODO: use real logic once the issue will be resolved https://letshift.atlassian.net/browse/GG-647
    return new Promise((res) =>
      setTimeout(() => {
        res(scoops);
      }, 300),
    );
  }

  public async uploadUserAvatar(newAvatar: File): Promise<{ avatarUrl: string }> {
    const formData = new FormData();
    formData.append("file", newAvatar);
    return this.makeFetch("/user/image", { method: "POST", requestBody: formData });
  }

  public async experienceCTAClicked(experienceId: IExperience["id"]) {
    return this.makeFetch("/user/experienceShowMore", {
      method: "POST",
      requestBody: { experienceId },
    });
  }

  public async getBalanceInfo(): Promise<IUserBalance> {
    return this.makeFetch("/unit/accountBalance");
  }

  public async getCommunityMembers(): Promise<ICommunityMembersResponse> {
    return this.makeFetch("/user/orgUsers");
  }

  /* Experiences API calls */
  public async getExperiences(
    query?: ExperienceQuery & { count?: number },
  ): Promise<IExperience[]> {
    // TODO: implement paging logic
    return this.makeFetch(`/experience/all${this.getQueryString({ count: 100, ...query })}`);
  }

  public async getTrending(): Promise<IExperience[]> {
    return this.makeFetch("/experience/trending");
  }

  public async getPromoted(): Promise<IExperience[]> {
    return this.makeFetch("/experience/promoted");
  }

  public async getSimilar(experienceId: number): Promise<IExperience[]> {
    return this.makeFetch(`/experience/similar?experienceId=${experienceId}`);
  }

  public async getExperience(id: number): Promise<IDetailedExperience> {
    return this.makeFetch(`/experience/${id}`);
  }

  public async inviteForExperience(inviteeUserId: IUserInfo["id"], eventId: IEvent["eventId"]) {
    return this.makeFetch("/user/invite", {
      method: "POST",
      requestBody: { inviteeUserId, eventId },
    });
  }

  public async rateExperience(experienceId: IExperience["id"], rating: number) {
    return this.makeFetch("/rating/experience", {
      method: "POST",
      requestBody: { experienceId, rating },
    });
  }

  public async getExperienceFilters(): Promise<IExperienceFilters> {
    return this.makeFetch("/experience/filters");
  }

  public async getBanners(): Promise<IBannerData[]> {
    return this.makeFetch("/comms/banners");
  }

  public async getPromotions(): Promise<IPromotionData[]> {
    return this.makeFetch("/comms/promotions");
  }

  /* Favorites API calls */
  public async getFavorites(query?: IPaginationParams): Promise<IPaginationResponse<IExperience>> {
    return this.makeFetch(`/favorite/all${this.getQueryString(query)}`);
  }

  public async addToFavorites(id: number) {
    return this.makeFetch("/favorite", {
      method: "POST",
      requestBody: {
        experienceId: id,
        status: "active",
      },
    });
  }

  public async removeFromFavorites(id: number) {
    return this.makeFetch(`/favorite/${id}`, { method: "DELETE" });
  }

  /* Calendar API calls */
  public async bookSorbet(eventId: number, experienceId: number) {
    return this.makeFetch<IBookSorbetResponse>("/calendar/bookSorbet", {
      method: "POST",
      requestBody: { eventId, experienceId },
      shouldAppendAccessToken: true,
    });
  }

  public async cancelSorbet(eventId: number, experienceId: number) {
    return this.makeFetch("/calendar/cancelSorbet", {
      method: "POST",
      requestBody: { eventId, experienceId },
      shouldAppendAccessToken: true,
    });
  }

  public async reportUnexpectedSorbet(eventId: number) {
    // TODO: use API logic once it'll be available
    return new Promise((res) =>
      setTimeout(() => {
        res({ eventId });
      }, 300),
    );
  }

  public async getCalendarEvents(params?: IGetCalendarEventsParams): Promise<IEvent[]> {
    if (params?.from) {
      params.from = formatDate(params.from);
    }
    if (params?.to) {
      params.to = formatDate(params.to);
    }
    const queryString = this.getQueryString(params, { arrayFormat: "index" });
    return this.makeFetch(`/calendar/events${queryString}`);
  }

  /* Onboarding API calls */
  public async updateOnboardingData(requestBody: Partial<IOnboardingData>) {
    await this.makeFetch("/user/onboarding", { method: "PUT", requestBody });
  }

  public async updateUserPeakHours(peakHour: IPeakHoursItem) {
    await this.makeFetch("/user/peakhour", { method: "PUT", requestBody: peakHour });
  }

  public async updateUserGoals(goals: IGoalItem[]) {
    return this.makeFetch("/user/goal", { method: "PUT", requestBody: { data: goals } });
  }

  public async savePreferences(experienceIds: IExperience["id"][]) {
    this.makeFetch("/user/userExperience", {
      method: "PUT",
      requestBody: { experiences: experienceIds },
    });
  }

  public async convertDaysToScoop(days: number, goalPercent: number) {
    return this.makeFetch("/user/daysToScoop", {
      method: "POST",
      requestBody: { days, goalPercent },
    });
  }

  public async addCalendarEvents(events: IEvent[]) {
    // TODO: workaround until issue fixed https://letshift.atlassian.net/browse/GG-649
    const WORKAROUND_events = {
      events: events.map(({ eventId, status, ...event }) => ({
        status: status.toUpperCase(),
        ...event,
      })),
    };
    return this.makeFetch("/calendar/addEvents", {
      method: "POST",
      requestBody: WORKAROUND_events,
      shouldAppendAccessToken: true,
    });
  }

  public async addCalendarEvent(data: INewEvent) {
    // workaround until issue fixed https://letshift.atlassian.net/browse/GG-649
    const event = { ...data, status: data.status.toUpperCase() } as IEvent;

    return this.makeFetch("/calendar/add", {
      method: "POST",
      requestBody: event,
      shouldAppendAccessToken: true,
    });
  }

  public async cancelEvent(eventId: number): Promise<any> {
    return this.makeFetch(`/calendar/cancel?eventId=${eventId}`, {
      method: "POST",
      shouldAppendAccessToken: true,
    });
  }

  public async moveEvent(eventId: number, newStart: string, newEnd: string): Promise<any> {
    const queryString = qsService.stringify({
      eventId,
      newStart: formatDate(newStart),
      newEnd: formatDate(newEnd),
    });
    return this.makeFetch(`/calendar/move?${queryString}`, {
      method: "POST",
      shouldAppendAccessToken: true,
    });
  }

  public async getSuggestedSchedule(scoops: ScoopsValue): Promise<IEventSchedule> {
    return this.makeFetch(`/calendar/suggestReschedule?scoopSize=${scoops}`, {
      shouldAppendAccessToken: true,
    });
  }

  public async acceptPlan(suggestedPlan: IAllocationSlotsResponse): Promise<any> {
    return this.makeFetch(`/calendar/acceptPlan`, {
      method: "POST",
      requestBody: suggestedPlan,
      shouldAppendAccessToken: true,
    });
  }

  public async getSuggestedPlan(
    startDate: Dayjs,
    endDate: Dayjs,
  ): Promise<IAllocationSlotsResponse> {
    const queryString = qsService.stringify({
      startDate: startDate.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"), // formatting in this way is not standard but was asked by Asher/Ruslan for now due to backend limitation
      endDate: formatDate(endDate),
    });
    return this.makeFetch(`/calendar/suggestPlan?${queryString}`, {
      shouldAppendAccessToken: true,
    });
  }

  public async getRandomExperiences(params?: { limit?: number }): Promise<IExperience[]> {
    return this.makeFetch(`/experience/allRandom${this.getQueryString(params)}`);
  }

  public async getConversationRatio(
    from: UNIT_TYPES,
    to: UNIT_TYPES,
  ): Promise<IConversationRatioResponse> {
    return this.makeFetch(
      `/user/conversationRatio${this.getQueryString({ fromUnitType: from, toUnitType: to })}`,
    );
  }

  public async getOnboardingProcessData(): Promise<IOnboardingData> {
    return this.makeFetch("/user/onboarding");
  }

  public async getOnboardingGoalsList(): Promise<IGoalItem[]> {
    return this.makeFetch("/user/onboarding/goal");
  }

  /* Api Service utils */

  private getQueryString(query?: object, options?: QsOptions) {
    return query ? `?${qsService.stringify(query, options)}` : "";
  }

  /**
   * Parses each object field from api response and looks if any transformations needed
   * scoopUnits -  until this issue is fixed https://letshift.atlassian.net/browse/GG-651
   */
  private responseJsonParser = (key: string, value: any) => {
    if (typeof value === "string" && STRING_TO_NUMBER_FIELDS.includes(key)) {
      return +value;
    }
    return value;
  };
}

export const apiService = new ApiService();
