import Slider from "@material-ui/core/Slider";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import React from "react";
import { Theme } from "@material-ui/core";
import { useWindowSize } from "../utils/hooks/useWindowSize";
import size from "../config/devices";
import { Size } from "../utils/hooks/useWindowSizeTypes";

export interface StyleProps {
  screenSize: boolean;
}
const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: {
    width: 300,
    [theme.breakpoints.down("sm")]: {
      width: 200,
    },
    [theme.breakpoints.up("md")]: {
      width: 250,
    },
    [theme.breakpoints.up("lg")]: {
      width: 300,
    },

    '& .MuiSlider-markLabel[data-index="0"]': {
      top: ({ screenSize }) => (screenSize ? "25%" : "10%"),
      left: "-5% !important",
      color: "#1c1259",
      fontFamily: "Poppins ,sans-serif ",
      fontSize: ({ screenSize }) => (screenSize ? "10px" : "14px"),
    },
    '& .MuiSlider-markLabel[data-index="1"]': {
      top: ({ screenSize }) => (screenSize ? "25%" : "10%"),
      left: "108% !important",
      color: "#1c1259",
      fontFamily: "Poppins ,sans-serif ",
      fontSize: ({ screenSize }) => (screenSize ? "10px" : "14px"),
    },
    "& .MuiSlider-thumb::after": {
      content: "",
      position: "absolute",
      width: "10px",
      height: "10px",
      borderRadius: "50%",
      background: "#1c1259",
      top: "7.5px",
      left: "7.5px",
      bottom: "0",
      right: "0",
    },
    "& .MuiSlider-mark": {
      width: 0,
      height: 0,
    },
  },
  margin: {
    height: theme.spacing(3),
  },
}));

const marks = [
  {
    value: 0,
    label: "0%",
  },
  {
    value: 100,
    label: "100%",
  },
];

export default function DiscreteSlider(props: any) {
  const sizes: Size = useWindowSize();
  const tabletWidth = +size.tablet.slice(0, -2);
  const mobileView = () => {
    if (sizes.width) {
      if (sizes.width < tabletWidth) {
        return { screenSize: true };
      }
      return { screenSize: false };
    }
  };
  const classes = useStyles(mobileView());
 const mobile = mobileView();
  let StyledSlider: any;
  if (mobile?.screenSize) {
   StyledSlider = withStyles({
    root: {
      color: "#bfbfbf",
      height: 2,
      padding: "15px 0",
      marginTop: "17px",
      zIndex: 0,
    },

    thumb: {
      width: "25px",
      height: "25px",
      marginTop: "-9px",
      backgroundColor: "white",
      position: "relative",
      boxShadow: "0px 0px 2px 4px rgb(74 90 139 / 7%)",
    },
    active: {},
    valueLabel: {
      left: "calc(-50% + 8px)",
      top: -22,
      fontSize: "12px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.5,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#bfbfbf",

      "& *": {
        background: "transparent",
        color: "#FF5277",
        fontWeight: 600,
      },
    },

    rail: {
      height: 2,
      opacity: 0.5,
      backgroundColor: "#bfbfbf",
    },
    mark: {
      backgroundColor: "#bfbfbf",
      height: 8,
      width: 1,
      marginTop: -3,
    },
    markLabel: {
      fontFamily: "Poppins",
      fontSize: "12px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 2,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#d5daeb",
      zIndex: 0,
    },
    markLabelActive: {
      fontSize: "12px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 2,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#d5daeb",
    },
    markActive: {
      opacity: 1,
      backgroundColor: "#bfbfbf",
    },
  })(Slider)
} else {
     StyledSlider = withStyles({
       root: {
         color: "#bfbfbf",
         height: 2,
         padding: "15px 0",
         marginTop: "17px",
         zIndex: 0,
       },

       thumb: {
         width: "25px",
         height: "25px",
         marginTop: "-9px",
         backgroundColor: "white",
         position: "relative",
         boxShadow: "0px 0px 2px 4px rgb(74 90 139 / 7%)",
       },
       active: {},
       valueLabel: {
         left: "calc(-50% + 8px)",
         top: -22,
         fontSize: "16px",
         fontWeight: 500,
         fontStretch: "normal",
         fontStyle: "normal",
         lineHeight: 1.5,
         letterSpacing: "normal",
         textAlign: "center",
         color: "#bfbfbf",

         "& *": {
           background: "transparent",
           color: "#FF5277",
           fontWeight: 600,
         },
       },

       rail: {
         height: 2,
         opacity: 0.5,
         backgroundColor: "#bfbfbf",
       },
       mark: {
         backgroundColor: "#bfbfbf",
         height: 8,
         width: 1,
         marginTop: -3,
       },
       markLabel: {
         fontFamily: "Poppins",
         fontSize: "12px",
         fontWeight: 600,
         fontStretch: "normal",
         fontStyle: "normal",
         lineHeight: 2,
         letterSpacing: "normal",
         textAlign: "center",
         color: "#d5daeb",
         zIndex: 0,
       },
       markLabelActive: {
         fontSize: "12px",
         fontWeight: 600,
         fontStretch: "normal",
         fontStyle: "normal",
         lineHeight: 2,
         letterSpacing: "normal",
         textAlign: "center",
         color: "#d5daeb",
       },
       markActive: {
         opacity: 1,
         backgroundColor: "#bfbfbf",
       },
     })(Slider);
}
  return (
    <div className={classes.root}>
      <StyledSlider
        ref={props.register}
        defaultValue={72}
        step={1}
        marks={marks}
        min={0}
        max={100}
        valueLabelDisplay="on"
        {...props}
      />
    </div>
  );
}
