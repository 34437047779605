import { useWindowSize } from "../../../utils/hooks/useWindowSize";
import size from "../../../config/devices";
import { Size } from "../../../utils/hooks/useWindowSizeTypes";
import { Colors } from "assets/styles/constants";

export const CustomStyles: any = () => {
  const sizes: Size = useWindowSize();
  const tabletWidth = +size.tablet.slice(0, -2);
  const mobileView = () => {
    if (sizes.width) {
      if (sizes.width < tabletWidth) {
        return true;
      }
      return false;
    }
  };
  const view = mobileView();

  if (view) {
    const selectInputStyles = {
      fontSize: "12px",
      fontWeight: "500",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      margin: "auto",
      color: "#1c1259",
      borderTop: 0,
      borderLeft: 0,
      borderRight: 0,
      borderBottom: "solid 2px #d5daeb",
      boxShadow: "none",
      "&:hover": {
        borderBottom: "solid 2px #d5daeb",
        cursor: "pointer",
      },
      "& .css-26l3qy-menu": {
        overflow: "auto",
      },
      "& .css-tj5bde-Svg": {
        fill: "#1c1259",
      },
      "& .css-1wa3eu0-placeholder": {
        color: "#1c1259",
        paddingLeft: "10px",
      },

      "& .css-1uccc91-singleValue": {
        paddingLeft: "10px",
      },
      "& .css-2b097c-container, .css-z1gi6q-control ": {
        marginLeft: "15%",
        marginRight: "15%",
      },
      "& .css-tlfecz-indicatorContainer": {
        padding: "0",
      },
      "& .css-1gtu0rj-indicatorContainer": {
        padding: "0",
      },
      "& .css-2b097c-container div:nth-of-type(1)": {
        backgroundColor: "red",
      },
      "&  .css-g1d714-ValueContainer": {
        padding: "0",
      },
    };
    return {
      option: (base: any, state: any) => ({
        fontSize: "12px",
        display: "flex",
        alignItems: "center",
        fontWeight: "400",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        paddingLeft: "10px",
        width: "154px",
        color: "#1c1259",
        whiteSpace: "nowrap",
        height: "30px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        cursor: "pointer",
        fill: "#1c1259",
        opacity: 1,
        zIndex: 100,
        "&: hover": {
          backgroundColor: "#EDEEF6",
          fontWeight: state.isSelected ? "600" : "normal",
        },
      }),
      control: (styles: any) => ({ ...styles, ...selectInputStyles }),
      menu: (styles: any) => ({
        ...styles,
        ...selectInputStyles,
        boxShadow: "0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%) !important",
        marginTop: "-4px !important",
        paddingTop: "0px",
        paddingBottom: "0px",
      }),
      menuList: (styles: any) => ({
        ...styles,
        paddingTop: "0px",
        paddingBottom: "0px",
      }),
    };
  } else {
    const selectInputStyles = {
      fontSize: "16px",
      fontWeight: "500",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      margin: "auto",
      color: "#1c1259",
      borderTop: 0,
      borderLeft: 0,
      borderRight: 0,
      borderBotton: 0,
      borderWidth: 0,
      boxShadow: "none",
      "&:hover": {
        cursor: "pointer",
      },
      "& .css-26l3qy-menu": {
        overflow: "auto",
      },
      "& .css-tj5bde-Svg": {
        fill: "#1c1259",
      },
      "& .css-1wa3eu0-placeholder": {
        color: "#1c1259",
        paddingLeft: "10px",
      },

      "& .css-1uccc91-singleValue": {
        marginLeft: "5px",
        fontWeight: "500",
        lineHeight: "17px",
        borderBottom: `1px solid ${Colors.textColor}`,
      },
      "& .css-2b097c-container, .css-z1gi6q-control ": {
        marginLeft: "15%",
        marginRight: "15%",
        lineHeight: "25px",
      },
      "& .css-tlfecz-indicatorContainer": {
        padding: "0",
      },
      "& .css-1gtu0rj-indicatorContainer": {
        padding: "0",
      },
      "& .css-2b097c-container div:nth-of-type(1)": {
        backgroundColor: "red",
      },
      "&  .css-g1d714-ValueContainer": {
        padding: "0",
      },
    };
    return {
      option: (base: any, state: any) => ({
        fontSize: "16px",
        display: "flex",
        alignItems: "center",
        fontWeight: "400",
        height: "48px",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        paddingLeft: "10px",
        color: "#1c1259",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        cursor: "pointer",
        fill: "#1c1259",
        opacity: 1,
        zIndex: 100,
        "&: hover": {
          backgroundColor: "#EDEEF6",
          fontWeight: state.isSelected ? "600" : "normal",
        },
      }),
      control: (styles: any) => ({ ...styles, ...selectInputStyles }),
      menu: (styles: any) => ({
        ...styles,
        ...selectInputStyles,
        boxShadow: "0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%) !important",
        marginTop: "-4px !important",
        paddingTop: "0px",
        paddingBottom: "0px",
        width: "145%",
      }),
      menuList: (styles: any) => ({
        ...styles,
        paddingTop: "0px",
        paddingBottom: "0px",
      }),
    };
  }
};
