import styled from "styled-components";
import { buttonActionStyle, Colors } from "assets/styles/constants";

export const Wrapper = styled.div`
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const FlavorImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

interface ImageStyleProps {
  $disabled?: boolean;
}

export const ImageWrapper = styled.div<ImageStyleProps>`
  background-color: ${Colors.paleGrey};
  min-width: 512px;
  width: 512px;
  height: 313px;
  overflow: hidden;
  border-radius: 10px;
  ${({ onClick }) => !onClick && "pointer-events: none;"}

  ${buttonActionStyle}

  &:first-child {
    margin-right: 32px;
  }
`;
