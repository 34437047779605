import styled from "styled-components";
import { buttonActionStyle, Colors, CONTENT_PADDING } from "assets/styles/constants";

import footerImage from "assets/images/smiling-scoop.svg";
import footerLogo from "assets/images/footer-logo.svg";
import rightArrowIcon from "assets/images/arrow-right-yellow.svg";

export const FooterTop = styled.div`
  background-color: ${Colors.pink};
  color: white;
  display: flex;
  justify-content: space-between;
  padding-left: 88px;
  padding-right: 202px;
`;

export const FooterBottom = styled.div`
  background-color: ${Colors.textColor};
  display: flex;
  justify-content: center;
  padding: 14px ${CONTENT_PADDING}px;
`;

export const FooterTopBlock = styled.div`
  display: flex;
  align-items: center;
`;

export const FooterImage = styled.img.attrs({
  src: footerImage,
})`
  width: 240px;
  height: 89px;
  margin-right: 30px;
  margin-bottom: -1.5px;
`;

export const FooterLogo = styled.img.attrs({
  src: footerLogo,
})`
  width: 150px;
  height: 36px;
`;

export const SocialIcon = styled.a`
  margin-left: 30px;

  ${buttonActionStyle}
`;

export const ContactUsBlock = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
  color: #ffffff;
  margin-left: 70px;
`;

export const ContactUsButton = styled.a`
  color: #ffffff;
  text-decoration: underline;
  padding-right: 20px;
  background: url(${rightArrowIcon}) no-repeat right 5px/12px;
  position: relative;
  white-space: nowrap;

  &:hover {
    text-decoration: none;
    color: #ffffff;
  }
`;

export const FooterBottomText = styled.p`
  font-size: 12px;
  color: #ffffff;
  margin: 0 10px;

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      color: inherit;
      text-decoration: underline;
    }
  }
`;
