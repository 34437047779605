import dayjs from "dayjs";
import { observable, computed, action } from "mobx";

// types
import {
  IEvent,
  EventStatus,
  CalendarPeriod,
  HistorySortBy,
  ScoopsValue,
  EventType,
} from "services/api/types";
import { AddEventData, ICalendarStore, PeriodDaysWithTimeoffEvents } from "./types";

// services / stores
import { userStore } from "stores/user";
import { apiService } from "services/api";
import { errorService } from "services/error";

// other
import { formatDay } from "utils/formatters";

class CalendarStore implements ICalendarStore {
  @computed public get calendarWidgetEvents() {
    return this._calendarWidgetEvents;
  }

  @computed public get onboardingCalendarEvents() {
    return this._onboardingCalendarEvents;
  }

  @computed public get currentPeriodEvents() {
    return this._currentPeriodEvents;
  }

  @computed public get loading() {
    return this._loading;
  }

  private get periodDateRange() {
    return userStore.challengePeriodDateRange;
  }

  public async loadOnboardingCalendarEvents(force?: boolean) {
    if (this.onboardingCalendarEvents && this.onboardingCalendarEvents.length && !force) {
      return;
    }

    try {
      const [, endPeriod] = this.periodDateRange;
      const events = await apiService.getCalendarEvents({
        from: dayjs().toString(),
        status: [EventStatus.BLOCKED],
        period: CalendarPeriod.CUSTOM,
        to: endPeriod,
      });
      this.setOnboardingCalendarEvents(events);
    } catch (e) {
      console.error(e);
    }
  }

  private widgetEventsLoading = false;

  public async loadCalendarWidgetEvents(forDate: string) {
    if (this.widgetEventsLoading) {
      return;
    }

    this.widgetEventsLoading = true;
    try {
      const events = await apiService.getCalendarEvents({
        status: [EventStatus.ACCEPTED, EventStatus.BOOKED],
        period: CalendarPeriod.CUSTOM,
        from: forDate,
        to: dayjs(forDate).add(1, "week").format(),
      });
      this.setCalendarWidgetEvents(events);
    } catch (error) {
      console.error(error);
    }
    this.widgetEventsLoading = false;
  }

  private periodEventsLoading = false;

  public loadPeriodEvents = async (force?: boolean) => {
    if (this.periodEventsLoading || (this.currentPeriodEvents && !force)) {
      return;
    }
    this.periodEventsLoading = true;
    this.setLoading(true);
    try {
      const events = await apiService.getCalendarEvents({
        from: this.periodDateRange[0],
        to: this.periodDateRange[1],
        period: CalendarPeriod.CUSTOM,
      });
      this.setCurrentPeriodEvents(events);
    } catch (error) {
      console.error(error);
    }
    this.periodEventsLoading = false;
    this.setLoading(false);
  };

  public async addEvent(data: AddEventData) {
    this.setLoading(true);
    try {
      await apiService.addCalendarEvent({
        ...data,
        type: data.recurring?.repeatType ? EventType.RECURRING : EventType.STANDARD,
      });
      this.loadPeriodEvents(true);
    } catch (error) {
      this.setLoading(false);
      console.error(error);
      // throw error;
    }
  }

  public async moveEvent(eventId: number, newStart: string, newEnd: string) {
    this.setLoading(true);
    try {
      // this doesn't work, and actually deletes the event
      // https://letshift.atlassian.net/browse/GG-697
      await apiService.moveEvent(eventId, newStart, newEnd);
      this.loadPeriodEvents(true);
    } catch (error) {
      console.error(error);
      this.loadPeriodEvents(true); // if dragged, this puts the event back in the place
      // throw error;
    }
  }

  public async cancelBookedEvent(eventId: number) {
    this.setLoading(true);
    try {
      await apiService.cancelEvent(eventId);
      this.loadPeriodEvents(true);
    } catch (error) {
      this.setLoading(false);
      console.error(error);
      // throw error;
    }
  }

  public async cancelSorbet(eventId: number, experienceId: number) {
    this.setLoading(true);
    try {
      await apiService.cancelSorbet(eventId, experienceId);
      await this.loadPeriodEvents(true);
    } catch (error) {
      this.setLoading(false);
      console.error(error);
      // throw error;
    }
  }

  public async reportUnexpectedEvent(eventId: number) {
    try {
      await apiService.reportUnexpectedSorbet(eventId);
      return true;
    } catch (error) {
      this.setLoading(false);
      console.error(error);
      // return false;
    }
  }

  public getSorbetHistory = async (sortBy: HistorySortBy) => {
    try {
      const history = await apiService.getCalendarEvents({
        status: [EventStatus.ACCEPTED, EventStatus.BOOKED],
        period: CalendarPeriod.CUSTOM,
        from: this.periodDateRange[0],
        to: dayjs().format(),
      });
      // TODO: this is a temporary solution
      return history
        .map((e) => ({ ...e, title: e.title || e.scoopUnits?.toString() }))
        .sort((a, b) => {
          switch (sortBy) {
            case "name": {
              if ((a.title as any) > (b.title as any)) {
                return 1;
              }
              if ((a.title as any) < (b.title as any)) {
                return -1;
              }
              return 0;
            }
            case "oldest":
              return dayjs(a.start).valueOf() - dayjs(b.start).valueOf();
            case "newest":
            default:
              return dayjs(b.start).valueOf() - dayjs(a.start).valueOf();
          }
        });
    } catch (error) {
      console.error(error);
    }
  };

  public cancelOnboardingCalendarEvent = async (eventId: number) => {
    await this.cancelBookedEvent(eventId);

    this.setOnboardingCalendarEvents(
      this._onboardingCalendarEvents.filter((e) => e.eventId !== eventId),
    );
  };

  public updateOnboardingCalendarEvent = async (newData: IEvent) => {
    try {
      await this.cancelBookedEvent(newData.eventId as number);
      await apiService.addCalendarEvents([newData]);

      this.setOnboardingCalendarEvents(
        this._onboardingCalendarEvents.map((e) => (e.eventId === newData.eventId ? newData : e)),
      );
    } catch (error) {
      console.error(error);
      // throw error;
    }
  };

  public addOnboardingCalendarEvents = async (events: IEvent[]) => {
    try {
      await apiService.addCalendarEvents(events);
      this.setOnboardingCalendarEvents([]);
    } catch (error) {
      console.error(error);
      // throw error;
    }
  };

  public checkEventConflictsWithOthersInADay = (
    dayDate: dayjs.ConfigType,
    eventScoops: ScoopsValue | 0,
  ) => {
    const dayTimestamp = formatDay(dayDate);
    const dayScoops = this._currentPeriodDaysWithTimeoffEvents[dayTimestamp];
    return (
      // if current day has 2 or more scoops
      dayScoops >= 2 ||
      // or if the day has 1 scoop but the event has more than 1 scoop size, it means the conflict
      (dayScoops === 1 && eventScoops > 1)
    );
  };

  /**
   * Builds a a map of days to scoops value (how much scoops in one day)
   */
  private getDaysWithTimeoffEvents = (events: IEvent[]): PeriodDaysWithTimeoffEvents => {
    return events
      .filter((event) => event.status !== EventStatus.BLOCKED)
      .reduce((days, event) => {
        const dayTimestamp = formatDay(event.start);
        const eventScoops = event.scoopUnits || 0;
        const dayScoops = days[dayTimestamp] || 0;

        return { ...days, [dayTimestamp]: dayScoops + eventScoops };
      }, {} as PeriodDaysWithTimeoffEvents);
  };

  @action public clearStore() {
    this._currentPeriodEvents = undefined;
    this._calendarWidgetEvents = undefined;
    this._currentPeriodDaysWithTimeoffEvents = {};
    this._onboardingCalendarEvents = [];
    this._loading = false;
  }

  @action private setCalendarWidgetEvents = (events: IEvent[]) => {
    this._calendarWidgetEvents = events;
  };

  @action private setOnboardingCalendarEvents(events: IEvent[]) {
    this._onboardingCalendarEvents = events;
  }

  @action private setCurrentPeriodEvents(events: IEvent[]) {
    this._currentPeriodEvents = events;
    this._currentPeriodDaysWithTimeoffEvents = this.getDaysWithTimeoffEvents(events);
  }

  @action private setLoading = (loading: boolean) => {
    this._loading = loading;
  };

  @observable private _calendarWidgetEvents?: IEvent[] = undefined;

  @observable private _onboardingCalendarEvents: IEvent[] = [];

  @observable private _currentPeriodEvents?: IEvent[] = undefined;

  @observable private _currentPeriodDaysWithTimeoffEvents: PeriodDaysWithTimeoffEvents = {};

  @observable private _loading: boolean = false;
}

export const calendarStore: ICalendarStore = new CalendarStore();
