import styled, { css } from "styled-components";
import { ButtonBase } from "components/Buttons";
import { Menu, Checkbox as AntCheckbox } from "antd";
import { Breakpoints, Colors, scrollBarStyle } from "assets/styles/constants";

export const FILTER_BTN_SIZE = 66;

export const StyledFilterButton = styled(ButtonBase)`
  width: ${FILTER_BTN_SIZE / 2}px;
  height: ${FILTER_BTN_SIZE / 2}px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: ${Breakpoints.MD}px) {
    width: ${FILTER_BTN_SIZE}px;
    height: ${FILTER_BTN_SIZE}px;
  }
`;

export const FilterIcon = styled.img<{ $size?: number }>`
  ${({ $size = 40 }) => css`
    width: ${$size / 2}px;
    height: ${$size / 2}px;
    object-fit: contain;

    @media (min-width: ${Breakpoints.MD}px) {
      width: ${$size}px;
      height: ${$size}px;
    }
  `}
`;

export const FilterButtonTitle = styled.p`
  font-size: 12px;
  line-height: 1;
  margin-top: 8px;

  @media (min-width: ${Breakpoints.MD}px) {
    font-size: 16px;
    margin-top: 12px;
  }
`;

export const FilterButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  max-width: 134px;
  padding: 0 5px;
`;

export const StyledMenu = styled(Menu)`
  &.ant-menu {
    max-height: 166px;
    overflow-y: auto;

    ${scrollBarStyle}
  }
`;

export const StyledMenuItem = styled(Menu.Item)`
  &.ant-menu-item {
    height: auto;
    padding-top: 8px;
    padding-bottom: 8px;
    display: flex;
    line-height: 16px;
  }
`;

export const Checkbox = styled(AntCheckbox)`
  .ant-checkbox-inner {
    border: 2px solid ${Colors.textColorSecondary} !important;
    border-radius: 4px;
  }

  .ant-checkbox-checked::after {
    border: none;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${Colors.textColorSecondary};
  }
`;

export const MenuText = styled.span`
  padding-left: 16px;
  white-space: pre-wrap;
`;
