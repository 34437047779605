// Core
import React, { PureComponent } from "react";

// Components
import { ExperienceItem } from "components/Experience";
import { ExperienceItemType } from "../ExperienceItem";
import { ExperienceLoader } from "../ExperienceLoader";
import { EmptyList } from "../EmptyList";
import { ExperienceItemsContainer } from "../ExperienceItemsContainer";

// Styles
import {
  ExperienceItemWrapper,
  ExperiencesWrapper,
  ExperiencePlaceholder,
  LoaderWrapper,
  CenterWrapper,
} from "./styles";
import { MAX_CONTENT_WIDTH, EXP_ITEM_WIDTH } from "assets/styles/constants";

// Types and services
import { IExperience } from "services/api/types";
import { ExperienceDataLayerType } from "services/gtm/types";
import { gtmService } from "services/gtm";

interface IProps {
  gtmDataLayer?: ExperienceDataLayerType;
  experiences?: IExperience[];
  emptyTitle?: string;
  experienceType?: ExperienceItemType;
  /**
   * Align to center horizontally. Default: `true`
   */
  center?: boolean;
  title?: JSX.Element;
}

const MAX_EXPERIENCES_IN_ROW = Math.floor(MAX_CONTENT_WIDTH / EXP_ITEM_WIDTH);
const PLACEHOLDERS_ARR = new Array(MAX_EXPERIENCES_IN_ROW).fill(null);

const EXP_LOADER_COUNT = 8;

const LOADERS_ARR = new Array(EXP_LOADER_COUNT).fill(null);

export class ExperiencesList extends PureComponent<IProps> {
  componentDidUpdate(prevProps: IProps) {
    const { experiences, gtmDataLayer } = this.props;
    if (
      gtmDataLayer &&
      experiences?.length &&
      experiences.length !== prevProps.experiences?.length
    ) {
      gtmService.onVisibleImpressionUpdated(
        gtmDataLayer,
        experiences.map((exp) => exp.wpPost.title),
      );
    }
  }

  renderContent = () => {
    const { experiences, emptyTitle, experienceType } = this.props;

    if (!experiences) {
      return (
        <LoaderWrapper>
          {LOADERS_ARR.map((_, i) => (
            <ExperienceItemWrapper key={`loader_item_${i}`}>
              <ExperienceLoader />
            </ExperienceItemWrapper>
          ))}
        </LoaderWrapper>
      );
    }

    if (!experiences.length) {
      return <EmptyList title={emptyTitle} />;
    }

    return (
      <>
        {experiences.map((experience) => (
          <ExperienceItemWrapper key={experience.id}>
            <ExperienceItem
              key={experience.id}
              gtmDataLayer={this.props.gtmDataLayer}
              data={experience}
              type={experienceType}
            />
          </ExperienceItemWrapper>
        ))}
        {PLACEHOLDERS_ARR.map((_, i) => (
          <ExperiencePlaceholder key={i} />
        ))}
      </>
    );
  };

  render() {
    const { center = true, title } = this.props;

    const list = (
      <ExperienceItemsContainer>
        {title}
        <ExperiencesWrapper>{this.renderContent()}</ExperiencesWrapper>
      </ExperienceItemsContainer>
    );

    if (center) {
      return <CenterWrapper>{list}</CenterWrapper>;
    }
    return list;
  }
}
