import { device } from "calculator_feature/config/devices";
import styled, { css } from "styled-components";

const btn = (light: string, dark: string) => css`
  white-space: nowrap;
  display: inline-block;
  border-radius: 5px;
  padding: 5px 15px;
  font-size: 16px;
  color: white;
  &:visited {
    color: white;
  }
  background-image: linear-gradient(${light}, ${dark});
  border: 1px solid ${dark};
  &:hover {
    background-image: linear-gradient(${light}, ${dark});
    &[disabled] {
      background-image: linear-gradient(${light}, ${dark});
    }
  }
  &:visited {
    color: black;
  }
  &[disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

const btnDefault = css`
  ${btn("#ffffff", "#d5d5d5")} color: #555;
`;

const btnPrimary = btn("#4f93ce", "#285f8f");

export default styled.div`
  font-family: sans-serif;
  width: 100%;

  h1 {
    text-align: center;
    color: #222;
  }


  form {
    width: 100%;

    & > div {
      flex-flow: row nowrap;
      line-height: 2em;
      margin: 40px 5px 5px 5px;
      @media ${device.mobileS} {
        margin: 0px 5px 5px 5px;
      } 
      @media ${device.laptop} {
        margin: 40px 5px 5px 5px;
      }
      & > label {
        color: #333;
        width: 110px;
        font-size: 1em;
        line-height: 32px;
      }
      & > input,
      & > select,
      & > textarea {
        flex: 1;
        padding: 3px 5px;
        font-size: 1em;
        margin-left: 15px;
        border: 1px solid #ccc;
        border-radius: 3px;
      }
      & > input[type="checkbox"] {
        margin-top: 7px;
      }
      & > label {
        display: block;
        & > input {
          margin-right: 3px;
        }
      }
    }
  }
`;
