// Core
import styled from "styled-components";
import Modal from "antd/lib/modal";

// Components
import { ButtonBase } from "components/Buttons/ButtonBase";
import { buttonActionStyle, Colors, GRADIENT_COLORS } from "assets/styles/constants";

// Assets
import closeIcon from "assets/images/close.svg";

export const ERROR_MODAL_WRAPPER_CLASS_NAME = "error-modal-wrapper";

export const StyledModal = styled(Modal).attrs({
  wrapClassName: ERROR_MODAL_WRAPPER_CLASS_NAME,
  width: 570,
  closable: false,
  centered: true,
  bodyStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "50px 60px",
  },
})`
  .ant-modal-content {
    border-radius: 10px;
  }

  h2 {
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 13px;
  }

  p {
    color: ${Colors.textColorSecondary};
    font-size: 18px;
    line-height: 29px;
    margin-bottom: 30px;
    padding: 0;
    text-align: center;
    white-space: pre-line;
  }

  a,
  a:hover {
    color: ${Colors.pink};
  }
`;

export const MeltedIceCream = styled.img`
  height: 128px;
  width: 104px;
  margin-bottom: 13px;
`;

export const CloseButton = styled.button`
  ${buttonActionStyle}

  position: absolute;
  width: 20px;
  height: 20px;
  background-image: url(${closeIcon});
  background-size: 20px;
  background-position: center;
  background-repeat: no-repeat;
  top: 24px;
  right: 24px;
`;

export const ConfirmButton = styled(ButtonBase).attrs({
  colors: [...GRADIENT_COLORS].reverse() as [Colors, Colors],
  orientation: "vertical",
  height: 52,
})`
  width: 160px;
  font-size: 24px;
`;
