import { lazy } from "react";

// Core
import { RouteComponentProps } from "react-router-dom";

// Constants
import { ROUTES, ACCOUNT_ROUTES, STORES } from "./constants";

// Types
import { IUserStore } from "stores/user/types";
import { RatePage } from "pages/Rate";

// Containers
const OnboardingContainer = lazy(() => import("containers/Onboarding"));
const AccountContainer = lazy(() => import("containers/Account"));

// Pages
const HomePage = lazy(() => import("pages/Home"));
const CalendarPage = lazy(() => import("pages/Calendar"));
const ExperiencePage = lazy(() => import("pages/Experience"));
const ExperiencesPage = lazy(() => import("pages/Experiences"));
const TrendingPage = lazy(() => import("pages/Trending"));
const CardActivation = lazy(() => import("pages/CardActivation"));
const CardPage = lazy(() => import("pages/Card"));
const TermsPage = lazy(() => import("pages/Terms"));
const AboutPage = lazy(() => import("pages/About"));
const AccountOverviewPage = lazy(() => import("pages/AccountOverview"));
const SorbetHistoryPage = lazy(() => import("pages/SorbetHistory"));
const SavedSorbetPage = lazy(() => import("pages/SavedSorbet"));
const ChangeWellnessPage = lazy(() => import("pages/ChangeWellness"));
const CompleteProfilePage = lazy(() => import("pages/CompleteProfile"));
const LoginPage = lazy(() => import("pages/Login"));
const CompleteOnboardingPage = lazy(() => import("pages/CompleteOnboarding"));
const WaysToUseGiftPage = lazy(() => import("pages/WaysToUseGift"));

export interface IRouteConfig {
  path: string;
  Component: React.ComponentType<any>;
  exact?: boolean;
  metaTitle: string; // the document.title
  gtmTitle: string | undefined; // if the value is `undefined` the gtmService.updateGeneralDataLayer won't be called on page mount
  isPrivate?: boolean;
  withSidebar?: boolean;
  layout?: "normal" | "solo";
}

export type RoutesRecord = Record<ROUTES, IRouteConfig>;

export const NAVIGATION_ROUTES: RoutesRecord = {
  [ROUTES.HOME]: {
    path: ROUTES.HOME,
    Component: HomePage,
    exact: true,
    withSidebar: true,
    gtmTitle: "home",
    metaTitle: "",
  },
  [ROUTES.LOGIN]: {
    path: ROUTES.LOGIN,
    Component: LoginPage,
    withSidebar: true,
    metaTitle: "",
    gtmTitle: "home",
  },
  [ROUTES.RATE]: {
    path: ROUTES.RATE,
    Component: RatePage,
    isPrivate: false,
    withSidebar: true,
    metaTitle: "",
    gtmTitle: "home",
  },
  [ROUTES.TERMS]: {
    path: ROUTES.TERMS,
    Component: TermsPage,
    isPrivate: false,
    withSidebar: false,
    gtmTitle: "terms",
    metaTitle: "Terms and Conditions", // overriden by nested page title
  },
  [ROUTES.EXPERIENCES]: {
    path: ROUTES.EXPERIENCES,
    Component: ExperiencesPage,
    withSidebar: true,
    gtmTitle: "experiences",
    metaTitle: "Experiences",
  },
  [ROUTES.EXPERIENCE]: {
    path: `${ROUTES.EXPERIENCE}/:id/:slug`,
    exact: true,
    Component: ExperiencePage,
    gtmTitle: "experience",
    metaTitle: "Experience", // overriden by nested page title
  },
  [ROUTES.TRENDING]: {
    path: ROUTES.TRENDING,
    Component: TrendingPage,
    withSidebar: true,
    gtmTitle: "trending",
    metaTitle: "Trending",
  },
  [ROUTES.WAYS_TO_USE_GIFT]: {
    path: ROUTES.WAYS_TO_USE_GIFT,
    Component: WaysToUseGiftPage,
    withSidebar: true,
    gtmTitle: "home",
    metaTitle: "",
  },
  [ROUTES.CALENDAR]: {
    path: ROUTES.CALENDAR,
    Component: CalendarPage,
    isPrivate: false,
    withSidebar: true,
    gtmTitle: "calendar",
    metaTitle: "Calendar",
  },
  [ROUTES.ABOUT]: {
    path: ROUTES.ABOUT,
    Component: AboutPage,
    isPrivate: false,
    withSidebar: false,
    gtmTitle: "about",
    metaTitle: "About Us",
  },
  [ROUTES.CARD]: {
    path: ROUTES.CARD,
    Component: CardPage,
    isPrivate: false,
    withSidebar: true,
    gtmTitle: "card",
    metaTitle: "My Sorbet Card",
  },
  [ROUTES.CARD_ACTIVATION]: {
    path: ROUTES.CARD_ACTIVATION,
    Component: CardActivation,
    isPrivate: false,
    layout: "solo",
    gtmTitle: "card_activate",
    metaTitle: "Activate My Sorbet Card",
  },
  [ROUTES.COMPLETE_ONBOARDING]: {
    path: ROUTES.COMPLETE_ONBOARDING,
    Component: CompleteOnboardingPage,
    metaTitle: "",
    withSidebar: true,
    gtmTitle: "home",
  },
  [ROUTES.ONBOARDING]: {
    path: ROUTES.ONBOARDING,
    Component: OnboardingContainer,
    isPrivate: false,
    layout: "solo",
    metaTitle: "Welcome to Sorbet",
    gtmTitle: undefined,
  },
  [ROUTES.ACCOUNT]: {
    path: ROUTES.ACCOUNT,
    Component: AccountContainer,
    isPrivate: false,
    withSidebar: true,
    metaTitle: "My Account", // overriden by nested page title
    gtmTitle: undefined,
  },
};

export interface IAccountScreenProps extends RouteComponentProps {
  [STORES.USER]?: IUserStore;
}

export const ACCOUNT_NAVIGATION_ROUTES: IRouteConfig[] = [
  {
    path: ACCOUNT_ROUTES.ACCOUNT,
    Component: AccountOverviewPage,
    exact: true,
    metaTitle: "Account Overview",
    gtmTitle: "myaccount",
  },
  {
    path: ACCOUNT_ROUTES.SORBET_HISTORY,
    Component: SorbetHistoryPage,
    gtmTitle: "myaccount_history",
    metaTitle: "Sorbet History",
  },
  {
    path: ACCOUNT_ROUTES.SAVED_SORBET,
    Component: SavedSorbetPage,
    gtmTitle: "myaccount_saved",
    metaTitle: "Saved Sorbets",
  },
  {
    path: ACCOUNT_ROUTES.CHANGE_WELLNESS,
    Component: ChangeWellnessPage,
    gtmTitle: "myaccount_wellness",
    metaTitle: "Change My Wellness Goal",
  },
  {
    path: ACCOUNT_ROUTES.COMPLETE_PROFILE,
    Component: CompleteProfilePage,
    gtmTitle: "myaccount_complete",
    metaTitle: "Complete My Profile",
  },
];
