import InputAdornment from "@material-ui/core/InputAdornment";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import CancelSharpIcon from "@material-ui/icons/CancelSharp";
import React, { forwardRef } from "react";
import "./inputStyle.css";
import { useWindowSize } from "../utils/hooks/useWindowSize";
import size from "../config/devices";
import { Size } from "../utils/hooks/useWindowSizeTypes";

export interface StyleProps {
  screenSize: boolean;
}
const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) =>
  createStyles({
    root: {},

    container: {
      display: "flex",
      justifyContent: "center",
    },
    textField: {
      "& .MuiInputBase-input": {
        borderBottom: "solid 1px #d5daeb",
        fontWeight: "300",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        textAlign: "center",
        color: "#1c1259",
        maxWidth: ({ screenSize }) => (screenSize ? "auto" : "281px"),
        fontFamily: "Poppins ,sans-serif ",
        [theme.breakpoints.down("sm")]: {
          fontSize: "1em",
        },
        [theme.breakpoints.up("md")]: {
          fontSize: "48px",
        },
        [theme.breakpoints.up("lg")]: {
          fontSize: "48px",
        },
      },
    },
  }),
);

export const Input = forwardRef((props: any, ref: any) => {
  const sizes: Size = useWindowSize();
  const tabletWidth = +size.tablet.slice(0, -2);
  const mobileView = () => {
    if (sizes.width) {
      if (sizes.width < tabletWidth) {
        return { screenSize: true };
      }
      return { screenSize: false };
    }
  };
  const classes = useStyles(mobileView());
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <TextField
        className={classes.textField}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <CancelSharpIcon
                style={{
                  color: "#D5DAEB",
                  width: "14px",
                  height: "14px",
                  cursor: "pointer",
                }}
                onClick={props.onClick}
              />
            </InputAdornment>
          ),
        }}
        id="standard-basic"
        inputRef={ref}
        {...props}
      />
    </div>
  );
});
