import React, { FC } from "react";

import { StyledFilterTag, StyledClearFilterTag, RemoveButton } from "./styles";

interface IProps {
  onRemove: () => void;
}

export const FilterTag: FC<IProps> = ({ onRemove, children }) => (
  <StyledFilterTag onClick={onRemove}>
    {children}
    <RemoveButton />
  </StyledFilterTag>
);

export const ClearFilterTag: FC<{ onClick: () => void }> = ({ onClick }) => (
  <StyledClearFilterTag onClick={onClick}>Clear All</StyledClearFilterTag>
);
