import { IFormWrapper } from "calculator_feature/common/types";
import React from "react";
import { FC } from "react";
import { SalarySliderWrapperStyle, SliderWrapperStyle } from "../../slidesStyle";

const SliderWrapper: FC<IFormWrapper> = ({ children, ...props }) => {
  return <SliderWrapperStyle {...props}>{children}</SliderWrapperStyle>;
};
const SalarySliderWrapper: FC<IFormWrapper> = ({ children, ...props }) => {
  return <SalarySliderWrapperStyle {...props}>{children}</SalarySliderWrapperStyle>;
};

export { SliderWrapper, SalarySliderWrapper };
