import React, { FC } from "react";
import { SlideButtonStyle } from "../../slidesStyle";
import { CommonFormElement } from "../../types";
import { NewPrimaryButton } from "../NewPrimaryButton/index";


const NewSlideButton: FC<CommonFormElement> = ({ children, disabled, ...props }) => {
  return (
    <SlideButtonStyle style={{ margin: "0 auto" }}>
      <NewPrimaryButton disabled={disabled} onClick={props.onClick}>
        {children}
      </NewPrimaryButton>
    </SlideButtonStyle>
  );
};

export default NewSlideButton;
