import { STORES } from "config/constants";
import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import React from "react";
import { Area, AreaChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { ICalculatorStore } from "stores/calculator";
import { ChartWrapper } from "../common/slidesStyle";
import "../styles/test.css";
import { useWindowSize } from "../utils/hooks/useWindowSize";
import size from "../config/devices";
import { Size } from "../utils/hooks/useWindowSizeTypes";

function SuperChart({ ...props }: any) {
  const sizes: Size = useWindowSize();
  const tabletWidth = +size.tablet.slice(0, -2);
  const mobileView = () => {
    if (sizes.width) {
      if (sizes.width < tabletWidth) {
        return { screenSize: true };
      }
      return { screenSize: false };
    }
  };
  const mobile = mobileView();
  const calcStore = props[STORES.CALCULATOR] as ICalculatorStore;
  const getSorbetYearsRowAria: any = toJS(calcStore.getSorbetYearsRow);
  const getWithOutRowAria: any = toJS(calcStore.getWithOutRow);

  const data: { name?: string; sorbet: number; without: number }[] = [
    {
      name: "0",
      sorbet: Math.ceil(getSorbetYearsRowAria[0]),
      without: Math.ceil(getWithOutRowAria[0]),
    },
    {
      sorbet: Math.ceil(getSorbetYearsRowAria[1]),
      without: Math.ceil(getWithOutRowAria[1]),
    },
    {
      sorbet: Math.ceil(getSorbetYearsRowAria[2]),
      without: Math.ceil(getWithOutRowAria[2]),
    },
    {
      name: "1",
      sorbet: Math.ceil(getSorbetYearsRowAria[3]),
      without: Math.ceil(getWithOutRowAria[3]),
    },
    {
      sorbet: Math.ceil(getSorbetYearsRowAria[4]),
      without: Math.ceil(getWithOutRowAria[4]),
    },
    {
      sorbet: Math.ceil(getSorbetYearsRowAria[5]),
      without: Math.ceil(getWithOutRowAria[5]),
    },
    {
      sorbet: Math.ceil(getSorbetYearsRowAria[6]),
      without: Math.ceil(getWithOutRowAria[6]),
    },
    {
      name: "2",
      sorbet: Math.ceil(getSorbetYearsRowAria[7]),
      without: Math.ceil(getWithOutRowAria[7]),
    },
    {
      sorbet: Math.ceil(getSorbetYearsRowAria[8]),
      without: Math.ceil(getWithOutRowAria[8]),
    },
    {
      sorbet: Math.ceil(getSorbetYearsRowAria[9]),
      without: Math.ceil(getWithOutRowAria[9]),
    },
    {
      sorbet: Math.ceil(getSorbetYearsRowAria[10]),
      without: Math.ceil(getWithOutRowAria[10]),
    },
    {
      name: "3",
      sorbet: Math.ceil(getSorbetYearsRowAria[11]),
      without: Math.ceil(getWithOutRowAria[11]),
    },
    {
      sorbet: Math.ceil(getSorbetYearsRowAria[12]),
      without: Math.ceil(getWithOutRowAria[12]),
    },
    {
      sorbet: Math.ceil(getSorbetYearsRowAria[13]),
      without: Math.ceil(getWithOutRowAria[13]),
    },
    {
      sorbet: Math.ceil(getSorbetYearsRowAria[14]),
      without: Math.ceil(getWithOutRowAria[14]),
    },
    {
      name: "4",
      sorbet: Math.ceil(getSorbetYearsRowAria[15]),
      without: Math.ceil(getWithOutRowAria[15]),
    },
    {
      sorbet: Math.ceil(getSorbetYearsRowAria[16]),
      without: Math.ceil(getWithOutRowAria[16]),
    },
    {
      sorbet: Math.ceil(getSorbetYearsRowAria[17]),
      without: Math.ceil(getWithOutRowAria[17]),
    },
    {
      sorbet: Math.ceil(getSorbetYearsRowAria[18]),
      without: Math.ceil(getWithOutRowAria[18]),
    },
    {
      name: "5",
      sorbet: Math.ceil(getSorbetYearsRowAria[19]),
      without: Math.ceil(getWithOutRowAria[19]),
    },
    {
      sorbet: Math.ceil(getSorbetYearsRowAria[20]),
      without: Math.ceil(getWithOutRowAria[20]),
    },
    {
      sorbet: Math.ceil(getSorbetYearsRowAria[21]),
      without: Math.ceil(getWithOutRowAria[21]),
    },
    {
      sorbet: Math.ceil(getSorbetYearsRowAria[22]),
      without: Math.ceil(getWithOutRowAria[22]),
    },
    {
      name: "6",
      sorbet: Math.ceil(getSorbetYearsRowAria[23]),
      without: Math.ceil(getWithOutRowAria[23]),
    },
    {
      sorbet: Math.ceil(getSorbetYearsRowAria[24]),
      without: Math.ceil(getWithOutRowAria[24]),
    },
    {
      sorbet: Math.ceil(getSorbetYearsRowAria[25]),
      without: Math.ceil(getWithOutRowAria[25]),
    },
    {
      sorbet: Math.ceil(getSorbetYearsRowAria[26]),
      without: Math.ceil(getWithOutRowAria[26]),
    },
    {
      name: "7",
      sorbet: Math.ceil(getSorbetYearsRowAria[27]),
      without: Math.ceil(getWithOutRowAria[27]),
    },
    {
      sorbet: Math.ceil(getSorbetYearsRowAria[28]),
      without: Math.ceil(getWithOutRowAria[28]),
    },
    {
      sorbet: Math.ceil(getSorbetYearsRowAria[29]),
      without: Math.ceil(getWithOutRowAria[29]),
    },
    {
      sorbet: Math.ceil(getSorbetYearsRowAria[30]),
      without: Math.ceil(getWithOutRowAria[30]),
    },
    {
      name: "8",
      sorbet: Math.ceil(getSorbetYearsRowAria[31]),
      without: Math.ceil(getWithOutRowAria[31]),
    },
    {
      sorbet: Math.ceil(getSorbetYearsRowAria[32]),
      without: Math.ceil(getWithOutRowAria[32]),
    },
    {
      sorbet: Math.ceil(getSorbetYearsRowAria[33]),
      without: Math.ceil(getWithOutRowAria[33]),
    },
    {
      sorbet: Math.ceil(getSorbetYearsRowAria[34]),
      without: Math.ceil(getWithOutRowAria[34]),
    },
    {
      name: "9",
      sorbet: Math.ceil(getSorbetYearsRowAria[35]),
      without: Math.ceil(getWithOutRowAria[35]),
    },
    {
      sorbet: Math.ceil(getSorbetYearsRowAria[36]),
      without: Math.ceil(getWithOutRowAria[36]),
    },
    {
      sorbet: Math.ceil(getSorbetYearsRowAria[37]),
      without: Math.ceil(getWithOutRowAria[37]),
    },
    {
      sorbet: Math.ceil(getSorbetYearsRowAria[38]),
      without: Math.ceil(getWithOutRowAria[38]),
    },
    {
      name: "10",
      sorbet: Math.ceil(getSorbetYearsRowAria[39]),
      without: Math.ceil(getWithOutRowAria[39]),
    },
  ];

  const editTicks = (y: number) => {
    y = y / 1000;
    return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const renderColorfulLegendText = (value: string, entry: any) => {
    let color = "#1C1259";
    return <span style={{ color }}>{value}</span>;
  };

  return (
    <ChartWrapper>
      <ResponsiveContainer width="100%" height="87%">
        <AreaChart
          data={data}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}>
          <XAxis dataKey="name" style={mobile ? { fontSize: "8px" } : { fontSize: "12px" }} />
          <YAxis
            tickFormatter={editTicks}
            style={mobile ? { fontSize: "8px" } : { fontSize: "12px" }}
          />
          <Tooltip formatter={(value: number) => new Intl.NumberFormat("en").format(value)} />
          <Legend
            payload={[
              { id: "pv", value: "With Sorbet", type: "square", color: "#FFAE00" },
              { id: "uv", value: "Without Sorbet", type: "square", color: "rgb(128, 123, 163)" },
            ]}
            align="left"
            formatter={renderColorfulLegendText}
            wrapperStyle={mobile?.screenSize ? { top: "55px" } : { top: "150px" }}
          />

          <Area
            name="Without Sorbet"
            dataKey="without"
            stroke=" #706B98"
            fill="#706B98"
            activeDot={{ r: 8 }}
          />
          <Area name="With Sorbet" dataKey="sorbet" stroke="#ffae00" fill="#ffae00" />
        </AreaChart>
      </ResponsiveContainer>
    </ChartWrapper>
  );
}

export default inject(STORES.CALCULATOR)(observer(SuperChart));
