import styled from "styled-components";

// assets
import logoImage from "assets/images/logo.svg";
import cardPreview from "assets/images/card-bg.png";

export const CardPreview = styled.div`
  width: 299px;
  height: 187px;
  color: white;
  position: relative;
  text-align: left;
  margin: 20px 32px 60px;
  display: inline-block;

  &:before {
    content: "";
    position: absolute;
    top: -20px;
    bottom: -60px;
    left: -40px;
    right: -40px;
    background: url(${cardPreview}) center;
    background-size: 379px 267px;
    background-position: top left;
    pointer-events: none;
  }
`;

export const CardPreviewLogo = styled.div`
  background-color: white;
  mask: url(${logoImage}) no-repeat 100%/100%;
  width: 107px;
  height: 24px;
  position: absolute;
  left: 25px;
  top: 31px;
`;

export const CardPreviewNumber = styled.div`
  font-size: 27px;
  font-weight: 500;
  font-family: "Inconsolata";
  position: absolute;
  top: 73px;
  left: 0;
  letter-spacing: -0.3px;
  width: 100%;
  text-align: center;
`;

export const CardPreviewExpiration = styled.div`
  position: absolute;
  left: 26px;
  top: 112px;
  letter-spacing: 1.1px;
  font-size: 10px;
  font-weight: bold;
`;

export const CardPreviewCvv = styled.div`
  position: absolute;
  right: 24px;
  top: 112px;
  letter-spacing: 1.3px;
  font-size: 10px;
`;

export const CardPreviewName = styled.div`
  text-transform: uppercase;
  font-size: 19.2px;
  letter-spacing: 0.5px;
  position: absolute;
  left: 26px;
  bottom: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 180px;
`;
