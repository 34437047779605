import BackButton from "calculator_feature/common/components/BackButton";
import NewPageTextBlock from "calculator_feature/common/components/NewPageTextBlock";
import NewSlideButton from "calculator_feature/common/components/NewSlideButton";
import { STORES } from "config/constants";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import React, { BaseSyntheticEvent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { ICalculatorStore } from "stores/calculator";
import { FormWrapper, SlideTitle, SmallText, SoundsGood } from "../../../../common/components";
import {
  ButtonsBlock,
  LeftGridSlide,
  LeftWrapperGrid,
  NewSlideWrapper,
  PageWrapper, RightWrapperGridWrap
} from "../../../../common/slidesStyle";
import { Form } from "../../../../components/Form";
import { Input } from "../../../../components/Input";
import { IStepProps } from "../../types";
interface IFirstStep extends IStepProps {
  [STORES.CALCULATOR]: ICalculatorStore;
}

enum FieldName {
  tax = "tax",
}

interface FormDateType {
  [FieldName.tax]: number;
}

const TaxRatePage = ({ onChangeStep, onClose, ...props }: IFirstStep): JSX.Element => {
  const calcStore = props[STORES.CALCULATOR] as ICalculatorStore;
  const history = useHistory();
  const [taxRateValue, setTaxRateValue] = useState("26%");

  const limitedTaxRate = () => {
    const newVal = taxRateValue.replace(/\%/, "");
    document.addEventListener("keydown", function (event) {
      if (event.key === "Backspace") {
        setTaxRateValue("");
      }
    });
    if (Number(newVal) > 100) {
      return "100%";
    } else {
      return taxRateValue;
    }
  };
  useEffect(() => {
    calcStore.increaseProgress(75);
    setTaxRateValue(percentageValue(Number((calcStore.taxRate * 100).toFixed(0))));
    console.clear();
  }, []);

  function percentageValue(value: number) {
    const res = `${value}%`;
    return res;
  }
  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
  });

  const onSubmit = ({ tax }: FormDateType) => {
    const newVal = taxRateValue.replace(/\%/, "");
    const newValue = Number(newVal) / 100;
    calcStore.setTaxRate(newValue);
    console.clear();
    history.push("./paidpolicystep");
    if (!errors.tax) {
      calcStore.increaseProgress(99);
    } else {
      calcStore.increaseProgress(75);
    }
  };

  const handleClick = (e: BaseSyntheticEvent) => {
    e.preventDefault();
    setTaxRateValue("");
    const item = document.getElementById("taxRate");
    return item.focus();
  };
  const handleInputChange = (e: React.BaseSyntheticEvent) => {
    const dataIn = String(e.target.value);
    const dataOut = dataIn.replace(/[^\d]/g, "");

    const storedValue = Number(dataOut) > 100 ? "100%" : percentageValue(Number(dataOut));
    setTaxRateValue(storedValue);
    calcStore.setTaxRate(Number(storedValue.replace(/[^\d]/g, "")) / 100);
  };

  const handleSoundsGood = () => {
    window.open(
      "https://meetings.hubspot.com/veetahl?utm_source=calculator&utm_medium=cta&utm_campaign=chat_with_us",
      "_blank",
    );
  };
  return (
    <PageWrapper className="pageWrapper">
      <SoundsGood onClick={handleSoundsGood}>TELL ME MORE</SoundsGood>
      <NewSlideWrapper className="SlideWrapper">
        <LeftWrapperGrid>
          <LeftGridSlide>
            <NewPageTextBlock />
          </LeftGridSlide>
        </LeftWrapperGrid>
        <div style={{ padding: "8% 5% 10% 5%", position: "relative" }}>
          <SlideTitle>
            What was your company’s effective tax rate <br /> (federal + state) last year?
          </SlideTitle>
          <RightWrapperGridWrap style={{ padding: "0 23% 10% 23%" }}>
            <SmallText>
              Don’t know or don’t feel comfortable sharing this info?
              <br /> Don’t worry about it! <br /> We’ll use industry averages as default.
            </SmallText>
            <FormWrapper style={{ marginTop: "10px" }}>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Input
                  ref={register}
                  name={FieldName.tax}
                  value={limitedTaxRate()}
                  onChange={handleInputChange}
                  id="taxRate"
                  onClick={handleClick}
                />
                <ButtonsBlock>
                  <NewSlideButton>Next</NewSlideButton>
                  <BackButton onClick={() => history.push("./tenurestep")}>Back</BackButton>
                </ButtonsBlock>
              </Form>
            </FormWrapper>
          </RightWrapperGridWrap>
        </div>
      </NewSlideWrapper>
    </PageWrapper>
  );
};

export default inject(STORES.CALCULATOR)(observer(TaxRatePage));
