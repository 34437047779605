import { yupResolver } from "@hookform/resolvers";
import BackButton from "calculator_feature/common/components/BackButton";
import NewPageTextBlock from "calculator_feature/common/components/NewPageTextBlock";
import NewSlideButton from "calculator_feature/common/components/NewSlideButton";
import { NumberFormatEmployee } from "calculator_feature/common/utils/customDigit";
import { STORES } from "config/constants";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import React, { BaseSyntheticEvent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { ICalculatorStore } from "stores/calculator";
import {
  FormWrapper,
  SlideTitle,
  SmallText,
  SoundsGood,
  SubSlideTitle,
} from "../../../../common/components";
import {
  ButtonsBlock,
  LeftGridSlide,
  LeftWrapperGrid,
  NewSlideWrapper,
  PageWrapper,
  RightWrapperSmallGrid,
} from "../../../../common/slidesStyle";
import { employeesSchema } from "../../../../common/utils/yupUtils";
import { Form } from "../../../../components/Form";
import { Input } from "../../../../components/Input";
import { IStepProps } from "../../types";

interface ISecondStep extends IStepProps {
  [STORES.CALCULATOR]: ICalculatorStore;
}

enum FieldName {
  employees = "employees",
}

interface FormData {
  [FieldName.employees]: number;
}

const EmployeesStep = ({ onChangeStep, onClose, ...props }: ISecondStep): JSX.Element => {
  const calcStore = props[STORES.CALCULATOR] as ICalculatorStore;
  const [defVal, setDefVal] = useState(String(calcStore.employees));
  const [disabled, setDisabled] = useState(true);
  const preValue = defVal === "0" ? "500" : defVal;

  useEffect(() => {
    calcStore.increaseProgress(34);
    setDefVal(String(calcStore.employees));
    console.clear();
  }, []);

  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      employees: preValue,
    },
    reValidateMode: "onBlur",
    resolver: yupResolver(employeesSchema),
  });
  const history = useHistory();
  function sumToValue(value: string) {
    // debugger
    const outNumSum = value.replace(/[^a-z0-9-]/g, "");
    return `${outNumSum}`;
  }

  const onSubmit = ({ employees }: FormData) => {
    if (!errors.employees) {
      calcStore.increaseProgress(40);
    } else {
      calcStore.increaseProgress(34);
    }
    calcStore.setEmployees(Number(sumToValue(defVal)));
    history.push("./salarystep");
    // onChangeStep(CALCULATOR_STEPS.THIRD_STEP);
    console.clear();
  };

  const handleChange = (e: BaseSyntheticEvent) => {
    setDefVal(e.target.value);
    calcStore.setEmployees(Number(sumToValue(e.target.value)));
  };

  const handleClick = (e: BaseSyntheticEvent) => {
    e.preventDefault();
    setDefVal("");
    const item = document.getElementById("employees");
    return item.focus();
  };

  useEffect(() => {
    if (errors.employees) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [errors.employees]);
  const handleSoundsGood = () => {
    window.open(
      "https://meetings.hubspot.com/veetahl?utm_source=calculator&utm_medium=cta&utm_campaign=chat_with_us",
      "_blank",
    );
  };

  return (
    <PageWrapper className="pageWrapper">
      <SoundsGood onClick={handleSoundsGood}>TELL ME MORE</SoundsGood>
      <NewSlideWrapper className="SlideWrapper">
        <LeftWrapperGrid>
          <LeftGridSlide>
            <NewPageTextBlock />
          </LeftGridSlide>
        </LeftWrapperGrid>
        <RightWrapperSmallGrid >
          <SlideTitle>Number of employees</SlideTitle>
          <SubSlideTitle>(FTE’s or Headcount)</SubSlideTitle>
          <SmallText>
            Don’t know or don’t feel comfortable sharing this info?
            <br /> Don’t worry about it! <br /> We’ll use industry averages as default.
          </SmallText>

          <FormWrapper>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Input
                ref={register}
                name={FieldName.employees}
                value={preValue}
                onChange={handleChange}
                onClick={handleClick}
                id="employees"
                error={!!errors.employees}
                helperText={errors?.employees?.message}
                InputProps={{
                  inputComponent: NumberFormatEmployee,
                }}
              />
              <ButtonsBlock>
                <NewSlideButton>Next</NewSlideButton>
                <BackButton disabled={disabled} onClick={() => history.push("./")}>
                  Back
                </BackButton>
              </ButtonsBlock>
            </Form>
          </FormWrapper>
        </RightWrapperSmallGrid>
      </NewSlideWrapper>
    </PageWrapper>
  );
};

export default inject(STORES.CALCULATOR)(observer(EmployeesStep));
