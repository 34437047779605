// Core
import React, { PureComponent } from "react";
import { RouteComponentProps } from "react-router-dom";

// Components
import HomePage from "pages/Home";

// Services and constants
import { notificationStore } from "stores/notification";
import { qsService } from "services/qs";
import { ROUTES } from "config/constants";
import { ExploreMoreBtn, RatingTitle, StyledRate } from "./styles";
import { experiencesStore } from "stores/experiences";

interface IRateQuery {
  star?: number;
  experienceId?: number;
}

export class RatePage extends PureComponent<RouteComponentProps> {
  componentDidMount() {
    const { location, history } = this.props;

    const { star, experienceId } = qsService.parse(location.search) as IRateQuery;
    if (star === undefined || experienceId === undefined) {
      history.replace(ROUTES.HOME);
      return;
    }

    experiencesStore.rateExperience(experienceId, star);

    notificationStore.showDialog({
      title: "Thank you for rating",
      description: "Sharing your feedback helps\nyour colleagues.",
      hideActions: true,
      content: (
        <>
          <RatingTitle>Your rating</RatingTitle>
          <StyledRate value={star} />
          <ExploreMoreBtn
            onClick={() => {
              notificationStore.closeDialog();
              history.replace(ROUTES.HOME);
            }}>
            EXPLORE MORE
          </ExploreMoreBtn>
        </>
      ),
      onClose: () => history.replace(ROUTES.HOME),
    });
  }

  render() {
    return <HomePage {...this.props} />;
  }
}
