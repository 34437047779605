import styled from "styled-components";

export const FiltersWrapper = styled.div`
  margin: 40px auto 0;
  width: 100%;
  max-width: 743px;
  min-height: 177px;
`;

export const FiltersButtonsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;

export const FilterTagsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;
