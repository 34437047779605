import {
  IndustryStep,
  EmployeesStep,
  SalaryStep,
  TenureStep,
  TaxPoliticStep,
  PaidPolicyStep,
  MainPage,
  EmailPage,
  LastPage,
} from "calculator_feature/pages/Calculator";
import "normalize.css";
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import logo from "../../calculator_feature/asserts/logo.svg";
import logoSmall from "../../calculator_feature/asserts/logo-small.svg";
import { LogoComponent, Progress } from "../../calculator_feature/common/components";
import { errorLogger } from "../../calculator_feature/services/sentry";
import { useWindowSize } from "../../calculator_feature/utils/hooks/useWindowSize";
import size from "../../calculator_feature/config/devices";
import { Size } from "../../calculator_feature/utils/hooks/useWindowSizeTypes";

function App() {
  const sizes: Size = useWindowSize();
  const tabletWidth = +size.tablet.slice(0, -2);
  const ourLogo = () => {
    if (sizes.width) {
      if (sizes.width < tabletWidth) {
        return logoSmall;
      }
      return logo;
    }
  };

  errorLogger.captureMessage("Sentry calc");
  return (
    <>
      <Progress />
      <LogoComponent src={ourLogo()} />
      <Router>
        <Switch>
          <Route exact path="/" component={IndustryStep} />
          <Route path="/employeesstep" component={EmployeesStep} />
          <Route path="/salarystep" component={SalaryStep} />
          <Route path="/tenurestep" component={TenureStep} />
          <Route path="/taxpoliticstep" component={TaxPoliticStep} />
          <Route path="/paidpolicystep" component={PaidPolicyStep} />
          <Route
            path="/mainpage/:tenure?/:employees?/:salaryAvarage?/:avarageSalarySlider?/:taxRate?/:rolloverSlider?/:paidTimeSlider?/:usedPaidTime?/:liabilitySorber_G14?/:whenRepay?/:rolloverName?"
            component={MainPage}
          />
          <Route path="/emailpage" component={EmailPage} />
          <Route path="/lastpage" component={LastPage} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
