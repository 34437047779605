import { CommonTextTypes } from "calculator_feature/common/types";
import { ShareLastPage } from "calculator_feature/components/ShareThis";
import { STORES } from "config/constants";
import { inject, observer } from "mobx-react";
import React, { FC } from "react";
import { GridLastPageWrapper, LastPageTextWrapper } from "../../slidesStyle";
import { BigText } from "../BigText";
import { BigTitle } from "../BigTitle";

const LastPageTextBlock: FC<CommonTextTypes> = ({ onChangeStep, onClick,  ...props }) => {
  
  return (
    <GridLastPageWrapper>
      <LastPageTextWrapper>
        <BigTitle style={{ alignSelf: "center" }}>Thanks!</BigTitle>
        <BigText style={{ height: "50px", alignSelf: "center", textAlign: "center" }}>
          We will be in touch within the next couple of days.
          <br /> In the meantime, feel free to share this with your colleagues.
        </BigText>
      </LastPageTextWrapper>
      <ShareLastPage onClick={onClick} />
    </GridLastPageWrapper>
  );
};
export default inject(STORES.CALCULATOR)(observer(LastPageTextBlock));
