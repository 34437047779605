import React from "react";
import { Link } from "react-router-dom";

// styles
import {
  FooterTop,
  SocialIcon,
  FooterBottom,
  FooterTopBlock,
  FooterImage,
  FooterLogo,
  ContactUsButton,
  FooterBottomText,
  ContactUsBlock,
} from "./styles";

// assets
import linkedinIcon from "assets/images/linkedin.svg";
import facebookIcon from "assets/images/facebook.svg";
import twitterIcon from "assets/images/twitter.svg";
import instagramIcon from "assets/images/instagram.svg";

// Other
import { COMPANY_EMAILS, ROUTES, SOCIAL_LINKS, TERMS_ROUTES, FAQ_LINK } from "config/constants";
import { mailService } from "services/mail";
import dayjs from "dayjs";

interface ISocialIcon {
  link: string;
  icon: string;
  name: string;
}

const socialIcons: ISocialIcon[] = [
  { link: SOCIAL_LINKS.LINKEDIN, icon: linkedinIcon, name: "LinkedIn" },
  { link: SOCIAL_LINKS.INSTAGRAM, icon: instagramIcon, name: "Instagram" },
  { link: SOCIAL_LINKS.TWITTER, icon: twitterIcon, name: "Twitter" },
  { link: SOCIAL_LINKS.FACEBOOK, icon: facebookIcon, name: "Facebook" },
];

const CONTACT_US_LINK = mailService.generateMailUrl({ to: COMPANY_EMAILS.SUPPORT });

export const FOOTER_ID = "main-footer";

export function Footer() {
  return (
    <footer id={FOOTER_ID}>
      <FooterTop>
        <FooterTopBlock>
          <FooterImage />
          <Link to={ROUTES.HOME}>
            <FooterLogo />
          </Link>
        </FooterTopBlock>
        <FooterTopBlock>
          {socialIcons.map(({ link, icon, name }) => (
            <SocialIcon key={name} href={link} target="_blank">
              <img src={icon} alt={`Sorbet on ${name}`} />
            </SocialIcon>
          ))}
          <ContactUsBlock>
            Have a question? Send us an email at
            <br />
            <ContactUsButton href={CONTACT_US_LINK}>{COMPANY_EMAILS.SUPPORT}</ContactUsButton>
          </ContactUsBlock>
        </FooterTopBlock>
      </FooterTop>
      <FooterBottom>
        <FooterBottomText>
          © All Rights Reserved to Shift Time Inc. {dayjs().format("YYYY")}
        </FooterBottomText>
        <FooterBottomText>
          <a href={FAQ_LINK} target="_blank" rel="noopener noreferrer">
            FAQ
          </a>
        </FooterBottomText>
        <FooterBottomText>
          <Link to={`${ROUTES.TERMS}/${TERMS_ROUTES.PRIVACY}`}>Privacy</Link>
        </FooterBottomText>
        <FooterBottomText>
          <Link to={ROUTES.TERMS}>Terms & Conditions</Link>
        </FooterBottomText>
      </FooterBottom>
    </footer>
  );
}
