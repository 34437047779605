import { CommonTextTypes } from "calculator_feature/common/types";
import React, { FC } from "react";
import { BigTextStyle, BigTextStyleEmail } from "../PageTextBlock/styles";

const BigText: FC<CommonTextTypes> = ({ children, ...restProps }) => {
  return <BigTextStyle {...restProps}>{children}</BigTextStyle>;
};
const BigEmailText: FC<CommonTextTypes> = ({ children, ...restProps }) => {
  return <BigTextStyleEmail {...restProps}>{children}</BigTextStyleEmail>;
};

export { BigText, BigEmailText };
