import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { Breakpoints, HEADER_HEIGHT, Colors } from "assets/styles/constants";

const NAV_LINK_MARGIN_TOP = 2;

const NAV_MENU_COLLAPSE_WIDTH = 1120;

export const StyledHeader = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  height: 60px;
  position: sticky;
  top: 0;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  z-index: 10;
  padding: 0 15px;

  @media (min-width: ${Breakpoints.MD}px) {
    padding: 0 45px;
    height: ${HEADER_HEIGHT}px;
  }
`;

export const StyledLogo = styled.div`
  margin-right: 134px;
  width: 151px;
`;

export const HeaderNav = styled.nav`
  flex: 1;
  margin-right: 20px;

  @media (max-width: ${NAV_MENU_COLLAPSE_WIDTH}px) {
    display: none;
  }
`;

const linkStyle = css<{ $active?: boolean }>`
  font-size: 16px;
  line-height: 23px;
  font-weight: 500;
  text-decoration: none !important;
  position: relative;
  padding: ${NAV_LINK_MARGIN_TOP}px 16px 0;
  text-align: center;
  z-index: 3;
  color: ${Colors.textColor};
  white-space: nowrap;

  &:hover {
    color: ${Colors.textColor};
  }

  &:hover {
    transform: translateY(-1px);
  }

  ${({ $active }) =>
    $active &&
    css`
      font-weight: bold;

      &:before {
        content: "";
        width: 100%;
        height: 9px;
        border-radius: 2px;
        background-color: ${Colors.titleStripe};
        position: absolute;
        bottom: 2px;
        left: 0;
        transition: opacity 200ms ease-out;
        z-index: -1;
      }
    `}
`;

export const StyledNavLink = styled(Link)`
  ${linkStyle}
`;

const HeaderButton = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
  background: transparent;

  &:hover {
    opacity: 0.7;
  }
  &:active {
    opacity: 0.5;
  }
`;

export const NavMenuButtonWrapper = styled.div`
  flex: 1;
  @media (min-width: ${NAV_MENU_COLLAPSE_WIDTH}px) {
    display: none;
  }
`;

export const NavMenuButton = styled(HeaderButton)`
  font-size: 18px;
  margin-top: ${NAV_LINK_MARGIN_TOP}px;
`;

export const InfoWrapper = styled.div`
  min-width: 116px;
  padding: 0 10px;
  text-align: right;
  display: none;

  @media (min-width: ${Breakpoints.MD}px) {
    display: block;
  }
`;

const headerText = css`
  font-size: 16px;
  font-weight: 500;
  color: ${Colors.pink} !important;
`;

export const NameLink = styled(Link)`
  ${headerText}
  display: block;
  line-height: 23px;
  margin-top: ${NAV_LINK_MARGIN_TOP}px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const LoginButton = styled.a`
  ${headerText}
  margin-left: 70px;

  &:hover {
    text-decoration: none;
  }
`;

export const NavLinksWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 740px;

  @media (max-width: ${NAV_MENU_COLLAPSE_WIDTH}px) {
    padding: 10px;
    flex-direction: column;
  }
`;
