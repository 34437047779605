import React from "react";
import {BigText} from "../BigText";
import {BigTitle} from "../BigTitle";
import BoldText from "../BoldText";
import { PageTextBlockContainer } from "./styles";

const NewPageTextBlock = () => {
  return (
    <PageTextBlockContainer>
      <BigTitle>
        We found a hidden
        <br /> gem in your
        <br /> balance sheet.
      </BigTitle>
      <BigText>
        With Sorbet, employees are encouraged to take the time
        <br /> they can and instantly cash out the time they can’t and
        <br /> spend it on anything they please. Best part? For the first
        <br /> time, you are able to refinance an enormous liability and
        <br /> turn it into a cost-saving, easy to implement finance hack.
      </BigText>
      <BoldText>
        Find out how Sorbet can impact your business by
        <br /> answering a few short questions:
      </BoldText>
    </PageTextBlockContainer>
  );
};

export default NewPageTextBlock;
