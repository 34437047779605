import { CommonTextTypes } from "calculator_feature/common/types";
import React, { FC } from "react";
import { SlideSalaryTitleStyle, SlideTitleStyle } from "../../slidesStyle";

const SlideTitle: FC<CommonTextTypes> = ({ children, ...props }) => {
  return <SlideTitleStyle {...props}>{children}</SlideTitleStyle>;
};
const SlideSalaryTitle: FC<CommonTextTypes> = ({ children, ...props }) => {
  return <SlideSalaryTitleStyle {...props}>{children}</SlideSalaryTitleStyle>;
};

export { SlideTitle, SlideSalaryTitle };
