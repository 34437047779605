// Core
import styled, { css } from "styled-components";

// Assets
import { Colors } from "assets/styles/constants";

const INPUT_HEIGHT = 40;

interface IErrorProps {
  $isError?: boolean;
}

export const commonInputStyles = css<IErrorProps>`
  height: ${INPUT_HEIGHT}px;
  width: 100%;
  border-radius: 6px;
  border: 1px solid ${Colors.lightBlueGray};
  background-color: ${Colors.lightGrey};
  padding: 0 20px;
  font-size: 16px;
  line-height: 23px;
  color: ${Colors.textColor};
  transition: border .3s;
  ${(p) => p.$isError && `border-color: ${Colors.dangerRed};`}}

  &:focus {
    border-color: ${Colors.textColor};
    ${(p) => p.$isError && `border-color: ${Colors.dangerRed};`}
  }

  &:disabled {
    cursor: not-allowed;
    color: rgba(${Colors.textColorSecondary_rgb}, 0.6);
  }

  &::placeholder {
    color: rgba(${Colors.textColorSecondary_rgb}, 0.6);
  }
`;

export const StyledInput = styled.input`
  ${commonInputStyles}
`;

export const InputLabel = styled.label<IErrorProps>`
  font-size: 12px;
  line-height: 17px;
  color: ${({ $isError: isError }) => (isError ? Colors.dangerRed : Colors.textColorSecondary)};
  margin-bottom: 6px;
  margin-left: 5px;
`;

export const ErrorText = styled.p`
  font-size: 12px;
  line-height: 17px;
  color: ${Colors.dangerRed};
  margin: 3px 0 0;
  text-align: right;
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
