import * as msal from "@azure/msal-browser";

import { AuthServices, AuthTypes, IAuthInstance } from "../types";

const { MICROSOFT } = AuthTypes;

export abstract class CommonMicrosoftAuthInstance implements IAuthInstance {
  abstract readonly name: AuthServices;
  /**
   * Auth scopes
   */
  protected abstract readonly scopes: string[];
  /**
   * MSAL instance
   */
  private _msalInstance?: msal.PublicClientApplication;

  protected get msalInstance() {
    return this._msalInstance;
  }

  protected set msalInstance(instance: msal.PublicClientApplication | undefined) {
    this._msalInstance = instance;
  }

  protected get msAuth() {
    return this.msalInstance as msal.PublicClientApplication;
  }

  get isInitialized() {
    return !!this.msalInstance;
  }

  abstract init: () => void;

  private getAuthSessionData = async () => {
    const account = this.getCurrentAccount();
    try {
      if (!account) {
        return null;
      }
      return await this.msAuth.acquireTokenSilent({
        scopes: this.scopes,
        account,
      });
    } catch (error) {
      if (error instanceof msal.InteractionRequiredAuthError) {
        this.logOut();
        return null;
      }
      throw error;
    }
  };

  public getAuthTokens = async () => {
    const data = await this.getAuthSessionData();
    return [data?.idToken, data?.accessToken];
  };

  public loadAuthState = async () => {
    const data = await this.getAuthSessionData();
    return data?.account?.localAccountId || null;
  };

  public logInWith = async (type: AuthTypes) => {
    if (type === MICROSOFT) {
      try {
        const { account } = await this.msAuth.loginPopup({
          scopes: this.scopes,
          prompt: "select_account",
        });
        return { success: true, id: account?.localAccountId };
      } catch (error) {
        if (/access_denied|user_cancelled/.test(error.message)) {
          return { success: false };
        }
        throw error;
      }
    }
    throw new Error(`${this.name} auth instance doesn't support "${type}" auth type`);
  };

  public logOut = async () => {
    // TODO: should be this.msAuth.logOut, but since there is no silent log out feature in MSAL
    // and no other services using sessionStorage, for now we are just clearing the storage completely
    // look at https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/1782
    sessionStorage.clear();
    return;
  };

  private getCurrentAccount = () => {
    const [account] = this.msAuth.getAllAccounts();
    return account;
  };
}
