import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme:any) => ({
  root: {
    padding: "5px 44px 5px 45px",
    borderRadius: "30px",
    backgroundColor: "#ffae00",
    fontSize: "1.5em",
    fontWeight: 500,
    fontStretch: "normal",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Poppins",
    textTransform: "none",
    
  },
  "& .MuiButton-contained.Mui-disabled": {
  }
}));

export const PrimaryButton = ({ children, disabled, ...props }:any) => {
  const classes = useStyles();

  return (
    <Button
      type="submit"
      variant="contained"
      color="primary"
      disabled={Boolean(disabled)}
      className={classes.root}
      {...props}>
      {children}
    </Button>
  );
};
