// Core
import React, { PropsWithChildren, Ref, ButtonHTMLAttributes } from "react";
import { Loading3QuartersOutlined } from "@ant-design/icons";

// Styles
import {
  StyledButtonBase,
  ButtonColors,
  Orientations,
  LoaderWrapper,
  ButtonBaseContent,
} from "./styles";
import { BUTTON_BASE_GRADIENT } from "assets/styles/constants";

interface IButtonBaseProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  colors?: ButtonColors;
  inverse?: boolean;
  border?: boolean;
  orientation?: Orientations;
  height?: number;
  disabled?: boolean;
  loading?: boolean;
}

function ButtonBaseComponent({
  children,
  colors = BUTTON_BASE_GRADIENT as ButtonColors,
  inverse = false,
  border = false,
  orientation = "horizontal",
  height = 35,
  loading,
  disabled = false,
  forwardRef,
  ...props
}: PropsWithChildren<IButtonBaseProps & { forwardRef?: Ref<HTMLButtonElement> }>) {
  return (
    <StyledButtonBase
      $colors={colors}
      $inverse={inverse}
      $border={border}
      $orientation={orientation}
      $height={height}
      $disabled={loading || disabled}
      ref={forwardRef}
      {...props}>
      <ButtonBaseContent $visible={!loading}>{children}</ButtonBaseContent>
      {loading && (
        <LoaderWrapper>
          <Loading3QuartersOutlined spin style={{ fontSize: height * 0.6 }} />
        </LoaderWrapper>
      )}
    </StyledButtonBase>
  );
}

export const ButtonBase = React.forwardRef<HTMLButtonElement, IButtonBaseProps>((props, ref) => (
  <ButtonBaseComponent forwardRef={ref} {...props} />
));
