// Core
import React from "react";

// Styles
import {
  LoaderImage,
  LoaderImageWrapper,
  LoaderItemTitle,
  LoaderItemText,
  LoaderContentWrapper,
  LoaderItemFooter,
} from "./styles";

export function ExperienceLoader() {
  return (
    <div>
      <LoaderImageWrapper>
        <LoaderImage />
      </LoaderImageWrapper>
      <LoaderContentWrapper>
        <LoaderItemTitle />
        <LoaderItemText />
        <LoaderItemText />
        <LoaderItemFooter />
      </LoaderContentWrapper>
    </div>
  );
}
