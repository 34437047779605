import { StyledLinkLastPage } from "calculator_feature/common/slidesStyle";
import { CommonFormElement } from "calculator_feature/common/types";
import React from "react";
import { FC } from "react";

const LinkCompoment: FC<CommonFormElement> = ({ children, ...restProps }) => {
  return <StyledLinkLastPage {...restProps}>{children}</StyledLinkLastPage>;
};

export default LinkCompoment;
