import * as yup from "yup";
export const employeesSchema = yup.object().shape({
  employees: yup
    .number()
    .transform((o, v) => parseFloat(v.replace(/,/g, "")))
    .typeError("Number of employees is a required field")
    .positive()
    .nullable(false)
    .required("Number of employees is a required field"),
});
export const avarageSalarySchema = yup.object().shape({
  avarageSalary: yup
    .string()
    .required("Average salary is a required field"),
});
export const tenureSchema = yup.object().shape({
  tenure: yup
    .number()
    .transform((o, v) => parseFloat(v.replace(/,/g, "")))
    .typeError("Average employee tenure is a required field")
    .positive()
    .nullable(false)
    .required("Average employee tenure is a required field"),
});
export const taxSchema = yup.object().shape({
  tax: yup.string().required("Tax is a required field"),
});

export const nameSchema = yup.object().shape({
  userName: yup
    .string()
    .matches(/^([^0-9]*)$/, "User name should not contain numbers")
    .required("User name is a required field"),
  company: yup
    .string()
    .matches(/^([^0-9]*)$/, "Last name should not contain numbers")
    .required("Last name is a required field"),
  email: yup
    .string()
    .email("Email should have correct format")
    .required("Email is a required field"),
});

export const companySchema = yup.object().shape({
  company: yup
    .string()
    .matches(/^([^0-9]*)$/, "Last name should not contain numbers")
    .required("Last name is a required field"),
});
export const emailSchema = yup.object().shape({
  email: yup
    .string()
    .email("Email should have correct format")
    .required("Email is a required field"),
});
