import React, { FC } from "react";
import loaderImage from "assets/images/loader.svg";
import { Wrapper } from "./styles";

export const Loader: FC = () => {
  return (
    <Wrapper>
      <img src={loaderImage} alt="loading" />
    </Wrapper>
  );
};
