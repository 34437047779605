
interface IAppConfig {
  REACT_APP_API_URL: string;
  REACT_APP_MEETLINK: string;
  REACT_APP_BASE_URL: string;
  REACT_APP_MIXPANEL_TOKEN: string;
  REACT_APP_MARKET_EMAIL: string;
  REACT_APP_SENTRY_DSN: string;
  REACT_APP_ENV: "staging" | "production";
  REACT_APP_FIREBASE_API_KEY: string;
  REACT_APP_FIREBASE_PROJECT_ID: string;
  REACT_APP_FIREBASE_SENDER_ID: string;
  REACT_APP_FIREBASE_APP_ID: string;
  REACT_APP_FIREBASE_RO_API_KEY: string;
  REACT_APP_FIREBASE_RO_PROJECT_ID: string;
  REACT_APP_FIREBASE_RO_SENDER_ID: string;
  REACT_APP_FIREBASE_RO_APP_ID: string;
  REACT_APP_MICROSOFT_RW_CLIENT_ID: string;
  REACT_APP_MICROSOFT_RO_CLIENT_ID: string;
  REACT_APP_GTM_ID: string;
  REACT_APP_IS_DEVELOPMENT: boolean;
  REACT_APP_AUTH_METHOD?: "google" | "password";
  REACT_APP_PDP_INVITE_BTN?: "visible" | "hidden";
  REACT_APP_VERSION_NUMBER?: string;
  REACT_APP_USE_PTO_MIN_UNITS: boolean;
}

export const ENV: IAppConfig = {
  ...(((window["_env_" as any] ? window["_env_" as any] : process.env) as unknown) as IAppConfig),
};
