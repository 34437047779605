import React from "react";
import Avatar, { AvatarProps } from "antd/lib/avatar";
import { ICommunityMember, IUserInfo } from "services/api/types";
import { UserOutlined } from "@ant-design/icons";

export interface IUserAvatarProps extends AvatarProps {
  user?: IUserInfo | ICommunityMember;
}

const getFirstLetter = (str?: string) => (str?.length ? str[0].toUpperCase() : "");

export function UserAvatar({ user, ...props }: IUserAvatarProps) {
  if (!user) {
    return <Avatar icon={<UserOutlined />} {...props} />;
  }

  const { avatarUrl, firstName, lastName } = user;

  if (props.src || avatarUrl) {
    return <Avatar {...props} src={props.src || avatarUrl} />;
  }

  const initials = `${getFirstLetter(firstName)}${getFirstLetter(lastName)}`;

  return (
    <Avatar
      {...props}
      style={{ fontSize: typeof props.size === "number" ? props.size * 0.4 : undefined }}>
      {initials}
    </Avatar>
  );
}
