// Core
import React, { InputHTMLAttributes } from "react";

// Styles
import { InputLabel, StyledInput, ErrorText, Wrapper } from "./styles";

export interface IInputCommonProps {
  /**
   * Input placeholder
   */
  placeholder?: string;
  /**
   * input label (optional)
   */
  label?: string;
  /**
   * Enable input error style
   */
  isError?: boolean;
  /**
   * Error text to be shown under the input field
   */
  errorText?: string | false;
  /**
   * Input label style
   */
  labelStyle?: React.CSSProperties;
  /**
   * Input style
   */
  wrapperStyle?: React.CSSProperties;
}

interface ITextInputProps extends InputHTMLAttributes<HTMLInputElement>, IInputCommonProps {}

declare type ITextInputWrapperProps<
  P extends IInputCommonProps = IInputCommonProps,
  El = HTMLInputElement
> = P & {
  forwardedRef?: React.Ref<El>;
  Component: any;
};

export function InputWrapper<
  P extends IInputCommonProps = IInputCommonProps,
  El = HTMLInputElement
>({
  label,
  labelStyle,
  wrapperStyle,
  errorText,
  isError = !!errorText,
  forwardedRef,
  Component,
  ...props
}: ITextInputWrapperProps<P, El>) {
  return (
    <Wrapper style={wrapperStyle}>
      {label !== undefined && (
        <InputLabel $isError={isError} style={labelStyle}>
          {label || "\u00A0"}
        </InputLabel>
      )}
      <Component {...props} $isError={isError} />
      {!!errorText && <ErrorText>{errorText}</ErrorText>}
    </Wrapper>
  );
}

export type Input = HTMLInputElement;

export const Input = React.forwardRef<Input, ITextInputProps>((props, ref) => (
  <InputWrapper type="text" {...props} forwardedRef={ref} Component={StyledInput} />
));
