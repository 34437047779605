import { device } from "calculator_feature/config/devices";
import styled from "styled-components";

export const BigTitleStyle = styled.div`
  font-size: 2.67em;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: #1c1259;
  text-transform: uppercase;
  margin-bottom: 50px;
  /* text-align: center; */
  text-align: left;
  @media ${device.mobileS} {
    font-size: 15px;
    margin: 0 15px;
    justify-content: center;
    padding-bottom: 10px;
  }
  @media ${device.mobileM} {
  }
  @media ${device.mobileL} {
    font-size: 2.67em;
    font-weight: bold;  
  }
  @media ${device.tablet} {
    font-size: 2.67em;
    font-weight: bold;
    /* font-size: 1.3em; */
    margin-left: 0px;
    margin-bottom: 10px;
  }
  @media ${device.laptop} {
    font-size: 30px;
    margin-left: 0px;
    margin-bottom: 10px;
  }
  @media ${device.laptopL} {
    font-size: 40px;
    margin-left: 0px;
    margin-bottom: 10px;
  }
  @media ${device.desktop} {
    /* font-size: 2.67em; */
    margin-left: 0px;
    margin-bottom: 10px;
  }
`;

export const BigTitleWrapper = styled.div`
  @media ${device.mobileS} {
    display: flex;
    justify-content: center;
  }
  @media ${device.mobileM} {
  }
  @media ${device.mobileL} {
  }
  @media ${device.tablet} {
    display: block;
  }
  @media ${device.laptop} {
  }
  @media ${device.laptopL} {
  }
  @media ${device.desktop} {
  }
`;

export const BigTitleStyleEmail = styled.div`
  font-size: 2.67em;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: #1c1259;
  text-transform: uppercase;
  margin-bottom: 50px;
  text-align: center;
  @media ${device.mobileS} {
    font-size: 1em;
    margin-bottom: 10px;
  }
  @media ${device.mobileM} {
    font-size: 1.1em;
    margin-bottom: 10px;
  }
  @media ${device.mobileL} {
    font-size: 1.5em;
    margin-left: 0px;
    margin-bottom: 10px;
  }
  @media ${device.tablet} {
    font-size: 2em;
    margin-left: 0px;
    margin-bottom: 10px;
  }
  @media ${device.laptop} {
    font-size: 2.5em;
    margin-left: 0px;
    margin-bottom: 10px;
  }
  @media ${device.laptopL} {
    font-size: 2.5em;
    margin-left: 0px;
    margin-bottom: 10px;
  }
  @media ${device.desktop} {
    font-size: 2.67em;
    margin-left: 0px;
    margin-bottom: 10px;
  }
`;

export const BigTextStyleEmail = styled.div`
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: #1c1259;
  text-align: center;
  @media ${device.mobileS} {
    font-size: 10px;
    max-width: "context";
    margin-bottom: 10px;
    text-align: justify !important;
    /* align-items: "justify"; */
  }
  @media ${device.mobileL} {
    margin-left: 0px;
  }
  @media ${device.tablet} {
    font-size: 10px;
    margin-left: 0px;
    margin-bottom: 10px;
    text-align: center !important;
  }
  @media ${device.laptopL} {
    font-size: 16px;
    margin-left: 0px;
  }
`;
export const BigTextStyle = styled.div`
  text-align: left;
  font-size: 1em;
  font-weight: regular;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: #1c1259;
  text-align: left;
  @media ${device.mobileS} {
    font-size: 0.7em;
    margin-left: 15px;
    max-width: "context";
    margin-bottom: 10px;
    align-items: "justify";
  }
  @media ${device.mobileM} {
    font-size: 0.7em;
    max-width: "context";
    margin-left: 15px;
    margin-bottom: 10px;
  }
  @media ${device.mobileL} {
    font-size: 0.8em;
    max-width: "context";
    /* margin-left: 15px; */
    margin-bottom: 10px;
  }
  @media ${device.tablet} {
    font-size: 1em;
    margin-left: 0px;
    margin-bottom: 10px;
  }
  @media ${device.desktop} {
    margin-left: 0px;
  }
  @media ${device.laptop} {
    margin-left: 0px;
  }
  @media ${device.laptopXL} {
    font-size: 16px;
    margin-left: 0px;
  }
`;
export const BigMainTextStyle = styled.div`
  text-align: left;
  font-size: 1em;
  font-weight: regular;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: #77719b;
  text-align: left;
  @media ${device.mobileS} {
    font-size: 0.7em;
    margin-left: 15px;
    max-width: "context";
    margin-bottom: 10px;
    align-items: "justify";
  }
  @media ${device.mobileM} {
    margin: 0px 5px;
  }
  @media ${device.mobileL} {
  }
  @media ${device.tablet} {
    font-size: 1em;
    margin-left: 0px;

    margin-bottom: 10px;
  }
  @media ${device.desktop} {
    margin-left: 0px;
  }
  @media ${device.laptop} {
    margin-left: 0px;
  }
  @media ${device.laptopXL} {
    font-size: 16px;
    margin-left: 0px;
  }
`;

export const BoldTextStyle = styled.p`
  font-family: Poppins;
  font-style: normal;
  letter-spacing: 0px;
  margin-top: 20px;

  @media ${device.mobileS} {
    font-size: 0.7em;
    margin-left: 15px;
    font-weight: 600;
    margin-bottom: 10px;
    text-align: justify;
  }
  @media ${device.mobileM} {
    font-size: 0.7em;
    margin-left: 15px;
    margin-bottom: 10px;
    text-align: justify;
  }
  @media ${device.mobileL} {
    font-size: 1em;
    margin-left: 15px;
    margin-bottom: 10px;
    text-align: justify;
  }

  @media ${device.tablet} {
    font-size: 0.7em;
    text-align: left;
    font-size: 14px;
    margin-left: 0;
  }
  @media ${device.laptop} {
    text-align: left;
  }
  @media ${device.laptopL} {
  }
  @media ${device.desktop} {
    font-size: 16px;
  }
`;
export const HowLongStyle = styled.button<{ active: boolean }>`
  cursor: pointer;
  max-width: 86px;
  min-height: 19px;
  font-size: 12px;
  border-radius: 16px;
  box-shadow: 0 10px 40px 0 rgba(28, 18, 89, 0.15);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  border: 1px solid #1c1259;
  padding: 2px 6px;
  color: ${(props) => (props.active ? "#ffffff" : "#1c1259")};
  background-color: ${(props) => (props.active ? "#1c1259" : "#ffffff")};
  margin-left: 20px;
  &:hover {
    background-color: #1c1259;
    transform: scale(1.05);
    color: #ffffff;
  }
`;
