import { yupResolver } from "@hookform/resolvers";
import BackButton from "calculator_feature/common/components/BackButton";
import NewPageTextBlock from "calculator_feature/common/components/NewPageTextBlock";
import NewSlideButton from "calculator_feature/common/components/NewSlideButton";
import { NumberFormatCustom } from "calculator_feature/common/utils/customDigit";
import { STORES } from "config/constants";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import React, { BaseSyntheticEvent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { ICalculatorStore } from "stores/calculator";
import {
  FormWrapper,
  SalarySliderWrapper,
  SlideSalaryTitle,
  SlideTitle,
  SmallText,
  SoundsGood,
} from "../../../../common/components";
import {
  ButtonsBlock,
  LeftGridSlide,
  LeftWrapperGrid,
  NewSlideWrapper,
  PageWrapper,
  RightWrapperGrid,
} from "../../../../common/slidesStyle";
import { avarageSalarySchema } from "../../../../common/utils/yupUtils";
import { Form } from "../../../../components/Form";
import { Input } from "../../../../components/Input";
import DiscreteSlider from "../../../../components/Slider";
import { IStepProps } from "../../types";

interface IThirdStep extends IStepProps {
  [STORES.CALCULATOR]: ICalculatorStore;
}

enum FieldName {
  avarageSalary = "avarageSalary",
  avarageSalarySlider = "avarageSalarySlider",
}

interface FormData {
  [FieldName.avarageSalary]: number;
  [FieldName.avarageSalarySlider]: number;
}

const SalaryStep = ({ onChangeStep, onClose, ...props }: IThirdStep) => {
  const calcStore = props[STORES.CALCULATOR] as ICalculatorStore;
  const [defVal, setDefVal] = useState(sumValue(calcStore.avarageSalary));
  const [salarySliderValue, setSalarySliderValue] = useState(+localStorage.getItem("avarageSlider"));
  const preValue = defVal === "0" ? "56000" : defVal;
  const history = useHistory();
  useEffect(() => {
    calcStore.increaseProgress(40);
    setSalarySliderValue(Number((calcStore.avarageSalarySlider * 100).toFixed(1)));
    localStorage.setItem("avarageSlider", String(calcStore.avarageSalarySlider));
    console.clear();
  });

  const valuetext = (salarySliderValue: number) => {
    return `${salarySliderValue}%`;
  };

  function sumValue(value: number) {
    const outSum = String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return `${outSum}`;
  }

  function sumToValue(value: string) {
    // debugger
    const outNumSum = value.replace(/[^a-z0-9-]/g, "");
    return `${outNumSum}`;
  }

  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      avarageSalary: sumValue(calcStore.avarageSalary),
      avarageSalarySlider: 5.3,
    },
    reValidateMode: "onBlur",
    resolver: yupResolver(avarageSalarySchema),
  });
  const handleInputChange = (e: BaseSyntheticEvent) => {
    setDefVal(sumValue(e.target.value));
    calcStore.setAvarageSalary(Number(sumToValue(e.target.value)));
  };

  const handleClick = (e: BaseSyntheticEvent) => {
    e.preventDefault();
    setDefVal("");
    const item = document.getElementById("avarageSalary");
    return item.focus();
  };

  const handleSliderChange = (event: React.ChangeEvent<HTMLInputElement>, newValue: any) => {
    localStorage.setItem("avarageSlider", String(newValue));
    calcStore.setAvarageSalarySlider(Number(newValue / 100));
  };
  const onSubmit = ({ avarageSalary }: FormData) => {
    calcStore.setAvarageSalary(Number(sumToValue(defVal)));
    history.push("./tenurestep");
    calcStore.increaseProgress(60);
    console.clear();
  };
  const handleSoundsGood = () => {
    window.open(
      "https://meetings.hubspot.com/veetahl?utm_source=calculator&utm_medium=cta&utm_campaign=chat_with_us",
      "_blank",
    );
  };
  return (
    <PageWrapper className="pageWrapper">
      <SoundsGood onClick={handleSoundsGood}>TELL ME MORE</SoundsGood>
      <NewSlideWrapper className="SlideWrapper">
        <LeftWrapperGrid>
          <LeftGridSlide>
            <NewPageTextBlock />
          </LeftGridSlide>
        </LeftWrapperGrid>
        <RightWrapperGrid style={{ paddingTop: "9%", paddingLeft: "21%", paddingRight: "21%" }}>
          <SlideTitle>Average Salary</SlideTitle>
          <SmallText>
            Don’t know or don’t feel comfortable sharing this info?
            <br /> Don’t worry about it! <br /> We’ll use industry averages as default.
          </SmallText>
          <FormWrapper style={{ marginTop: 0 }}>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Input
                ref={register}
                name={FieldName.avarageSalary}
                onChange={handleInputChange}
                id="avarageSalary"
                error={!!errors.avarageSalary}
                onClick={handleClick}
                valueLabelDisplay="on"
                value={preValue}
                helperText={errors?.avarageSalary?.message}
                required
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
              <SlideSalaryTitle>Annual wage increase</SlideSalaryTitle>
              <SalarySliderWrapper>
                <DiscreteSlider
                  {...register("avarageSalarySlider", {
                    required: "required",
                  })}
                  onChange={handleSliderChange}
                  getAriaValueText={valuetext}
                  valueLabelFormat={valuetext}
                  defaultValue={salarySliderValue}
                />
              </SalarySliderWrapper>
              <ButtonsBlock>
                <NewSlideButton>Next</NewSlideButton>
                <BackButton onClick={() => history.push("./employeesstep")}>Back</BackButton>
              </ButtonsBlock>
            </Form>
          </FormWrapper>
        </RightWrapperGrid>
      </NewSlideWrapper>
    </PageWrapper>
  );
};

export default inject(STORES.CALCULATOR)(observer(SalaryStep));
