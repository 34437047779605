// Core
import React, { PureComponent } from "react";
import { inject, observer } from "mobx-react";
import { RouteComponentProps } from "react-router-dom";

// Other
import { STORES, ROUTES } from "config/constants";
import { IExperiencesStore } from "stores/experiences/types";

// Components
import { BannersCarousel } from "components/BannersCarousel";
import { ExperienceFilters, ExperiencesCarousel, ExperiencesList } from "components/Experience";
import { Featured } from "./Featured";

// Styles
import {
  CarouselContainer,
  Title,
  TextBlock,
  FeaturedWrapper,
  ExperiencesListWrapper,
} from "./styles";
import { ContentWrapper, PageWrapper } from "assets/styles/components";

// Other
import { gtmService } from "services/gtm";
import { parseExperiencesQuery } from "utils/helpers";
import { ExperienceDataLayerType } from "services/gtm/types";

interface IProps extends RouteComponentProps {
  [STORES.EXPERIENCES]?: IExperiencesStore;
}

@inject(STORES.EXPERIENCES)
@observer
export default class HomePage extends PureComponent<IProps> {
  private get experiencesStore() {
    return this.props[STORES.EXPERIENCES] as IExperiencesStore;
  }

  private checkFiltersApplied() {
    const { location } = this.props;
    const query = parseExperiencesQuery(location.search);
    return Object.values(query).some((values) => values?.length);
  }

  public async componentDidMount() {
    this.experiencesStore.loadBanners();
    this.experiencesStore.loadPromoted();
    this.experiencesStore.loadTrending();
  }

  componentWillUnmount() {
    gtmService.clearExperienceDataLayer();
  }

  render() {
    const { experiences, banners, trending, promoted } = this.experiencesStore;

    const filtersApplied = this.checkFiltersApplied();

    return (
      <PageWrapper>
        <ContentWrapper>
          <CarouselContainer>
            <BannersCarousel banners={banners} />
          </CarouselContainer>
          <TextBlock>
            <strong>Browse and schedule</strong> a variety of flavorful
            <br />
            Sorbets to feel <strong>refreshed</strong> and inspired
            <small>Yes you can take seconds!</small>
          </TextBlock>
          <ExperienceFilters />
          <Title>MADE FOR YOUR PALATE</Title>
          {filtersApplied ? (
            <ExperiencesListWrapper>
              <ExperiencesList
                gtmDataLayer={ExperienceDataLayerType.MFP}
                experiences={experiences}
              />
            </ExperiencesListWrapper>
          ) : (
            <ExperiencesCarousel
              gtmDataLayer={ExperienceDataLayerType.MFP}
              allLink={ROUTES.EXPERIENCES}
              experiences={experiences}
            />
          )}
        </ContentWrapper>
        {!filtersApplied && (
          <>
            <FeaturedWrapper>
              <Title>FLAVORS OF THE WEEK</Title>
              <ContentWrapper>
                <Featured items={promoted} />
              </ContentWrapper>
            </FeaturedWrapper>
            <ContentWrapper>
              <Title>TRY WHAT YOUR FRIENDS ARE TASTING</Title>
              <ExperiencesCarousel
                gtmDataLayer={ExperienceDataLayerType.TRENDING}
                allLink={ROUTES.TRENDING}
                experiences={trending}
              />
            </ContentWrapper>
          </>
        )}
      </PageWrapper>
    );
  }
}
