import React, { FC } from "react";

// styles
import { PagerWrapper, PagerText, PagerButton, PagerIcon } from "./styles";

// Assets
import scrollLeftIcon from "assets/images/scroll-left.svg";
import scrollRightIcon from "assets/images/scroll-right.svg";

interface IProps {
  onPrevClick: () => void;
  onNextClick: () => void;
}

export const Pager: FC<IProps> = ({ onPrevClick, onNextClick, children }) => {
  return (
    <PagerWrapper className="pager-wrapper">
      <PagerText>{children}</PagerText>
      <PagerButton onClick={onPrevClick}>
        <PagerIcon src={scrollLeftIcon} alt="previous experiences page" />
      </PagerButton>
      <PagerButton onClick={onNextClick}>
        <PagerIcon src={scrollRightIcon} alt="next experiences page" />
      </PagerButton>
    </PagerWrapper>
  );
};
