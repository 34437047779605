import { Grid, Paper, withStyles } from "@material-ui/core";
import axios from "axios";
import MyField from "calculator_feature/common/components/MyField";
import { STORES } from "config/constants";
import { ENV } from "config/env";
import { TextField } from "final-form-material-ui";
import mixpanel from "mixpanel-browser";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Form } from "react-final-form";
import { useHistory, useLocation } from "react-router-dom";
import { ICalculatorStore } from "stores/calculator";
import reCalculate from "../../../../asserts/reCalculate.svg";
import { FormWrapper, SendButton } from "../../../../common/components";
import LastPageTextBlock from "../../../../common/components/EmailPageTextBlock";

import {
  ButtonBackToReportStyle,
  ButtonReWrapper,
  ButtonsEmailSendWrapper,
  DetailsStyle,
  EmailPageWrapper,
  FormContainerEmailPage,
  SlideWrapperEmailPage,
  StyledLink,
} from "../../../../common/slidesStyle";
import { IStepProps } from "../../types";
import Styles from "./styles";
import { useWindowSize } from "../../../../utils/hooks/useWindowSize";
import size from "../../../../config/devices";
import { Size } from "../../../../utils/hooks/useWindowSizeTypes";
export interface StyleProps {
  screenSize: boolean;
}

interface IEmailStep extends IStepProps {
  [STORES.CALCULATOR]: ICalculatorStore;
}

enum FieldName {
  firstName = "userName",
  lastName = "lastName",
  companyEmail = "companyEmail",
  recipientName = "recipientName",
  email = "email",
}

interface FormData {
  [FieldName.firstName]: string;
  [FieldName.lastName]: string;
  [FieldName.companyEmail]: string;
  [FieldName.recipientName]: string;
  [FieldName.email]: string;
}

const StyledGrid = withStyles({
  root: {
    "& .MuiFormLabel-root": {
      fontSize: "13px",
      width: "80%",
    },
    height: "65px",
  },
})(Grid);

const EmailPage = ({ onChangeStep, onClose, ...props }: IEmailStep): JSX.Element => {
  mixpanel.init(ENV.REACT_APP_MIXPANEL_TOKEN);
  const calcStore = props[STORES.CALCULATOR] as ICalculatorStore;
  const history = useHistory();
  const location: any = useLocation();
  const [ourDatas, setOurDatas] = useState();
  const sizes: Size = useWindowSize();
  const tabletWidth = +size.tablet.slice(0, -2);
  const mobileView = () => {
    if (sizes.width) {
      if (sizes.width < tabletWidth) {
        return { screenSize: true };
      }
      return { screenSize: false };
    }
  };
  const mobile = mobileView();
  useEffect(() => {
    calcStore.increaseProgress(99);

    if (location.state?.userData) {
      return setOurDatas(location.state?.userData);
    }
    if (window.history.state) {
      return setOurDatas(window.history.state);
    }
    console.clear();
  }, []);

  const ourData: any = ourDatas?.map((item: any) => {
    return Object.values(item);
  });

  const urlCreate = () => {
    const paramsList = ourData.flat(1);
    let ourUrl = `/mainpage${paramsList.map((item: any) => {
      return `/${item}`;
    })}`;

    ourUrl = ourUrl.replace(/,/g, "");
    return ourUrl;
  };

  const resetClick = () => {
    calcStore.clearStore();
    history.push("./");
    window.location.reload();
  };

  const handleNameChange = (e: string) => {
    calcStore.setFirstName(e);
  };

  const handleLastNameChange = (e: string) => {
    calcStore.setLastName(e);
  };

  const handleEmailChange = (e: string) => {
    calcStore.setEmail(e);
  };

  const handleCompanyEmailChange = (e: string) => {
    calcStore.setCompanyEmail(e);
  };

  const handleRecipientNameChange = (e: string) => {
    calcStore.setRecipientName(e);
  };
  const handleCompanyNameChange = (e: string) => {
    calcStore.setCompanyName(e);
  };

  const onSubmit = (values: FormData) => {
    const calcParams = urlCreate();
    // base64 encode
    const meetlink = Buffer.from(ENV.REACT_APP_MEETLINK).toString("base64");
    const baseUrl = Buffer.from(`${ENV.REACT_APP_BASE_URL}${calcParams}`).toString("base64");
    calcStore.setBaseUrl(baseUrl);
    axios({
      method: "post",
      url: `${ENV.REACT_APP_API_URL}/v1/templateSender/sendTemplate`,
      headers: {},
      data: {
        emailFields: {
          firstName: calcStore.firstName,
          lastName: calcStore.lastName,
          email: calcStore.email,
        },
        recipient: {
          firstName: calcStore.recipientName,
          email: calcStore.companyEmail,
          companyName: calcStore.companyName,
        },
        reportUrl: baseUrl,
        meetingUrl: meetlink,
      },
    }).then(
      (response: any) => {
        history.push("./lastpage");
        mixpanel.track("Email send", { sentTo: calcStore.companyEmail });
      },
      (error) => {
        console.error(error.message);
      },
    );
    console.clear();
  };
  const validate = (values: any) => {
    let pattern = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;

    const errors: any = {};
    if (!values.firstName) {
      errors.firstName = "Required";
    }
    if (!values.lastName) {
      errors.lastName = "Required";
    }
    if (!values.recipientName) {
      errors.recipientName = "Required";
    }
    if (!values.companyEmail) {
      errors.companyEmail = "Required";
    }

    if (!pattern.test(String(values.companyEmail).toLowerCase())) {
      errors.companyEmail = "must be a valid email address";
    }

    if (!pattern.test(String(values.emailAddress).toLowerCase())) {
      errors.emailAddress = "must be a valid email address";
    }

    if (!values.recCompanyName) {
      errors.recCompanyName = "Required";
    }
    if (!values.emailAddress) {
      errors.emailAddress = "Required";
    }
    return errors;
  };

  return (
    <EmailPageWrapper className="pageWrapper">
      <SlideWrapperEmailPage className="SlideWrapper">
        <FormWrapper>
          <FormContainerEmailPage>
            <LastPageTextBlock onChangeStep={onChangeStep} />
            <Styles>
              <Form
                onSubmit={onSubmit}
                initialValues={{ employed: true, stooge: "larry" }}
                validate={validate}
                render={({ handleSubmit, submitting, pristine, values }) => (
                  <form onSubmit={handleSubmit} noValidate>
                    <Paper style={{ boxShadow: "none" }}>
                      {!mobile?.screenSize && (
                        <Grid container alignItems="flex-start" spacing={2}>
                          <Grid item xs={6}>
                            <DetailsStyle>Your Details</DetailsStyle>
                          </Grid>
                          <Grid item xs={6}>
                            <DetailsStyle>Recipient Details</DetailsStyle>
                          </Grid>
                        </Grid>
                      )}
                      .
                      {!mobile?.screenSize ? (
                        <Grid container alignItems="flex-start" spacing={2}>
                          <StyledGrid item xs={6}>
                            <MyField
                              fullWidth
                              required
                              onChange={handleNameChange}
                              name="firstName"
                              component={TextField}
                              type="text"
                              label="First name"
                            />
                          </StyledGrid>
                          <StyledGrid item xs={6}>
                            <MyField
                              fullWidth
                              required
                              name="recipientName"
                              onChange={handleRecipientNameChange}
                              component={TextField}
                              type="text"
                              label="Recipient name"
                            />
                          </StyledGrid>
                          <StyledGrid item xs={6}>
                            <MyField
                              fullWidth
                              required
                              name="lastName"
                              onChange={handleLastNameChange}
                              component={TextField}
                              type="text"
                              label="Last name"
                            />
                          </StyledGrid>
                          <StyledGrid item xs={6}>
                            <MyField
                              fullWidth
                              required
                              name="companyEmail"
                              onChange={handleCompanyEmailChange}
                              component={TextField}
                              type="email"
                              label="Recipient email address"
                            />
                          </StyledGrid>
                          <StyledGrid item xs={6}>
                            <MyField
                              fullWidth
                              required
                              onChange={handleEmailChange}
                              name="emailAddress"
                              component={TextField}
                              type="email"
                              label="Email address"
                            />
                          </StyledGrid>
                          <StyledGrid item xs={6}>
                            <MyField
                              fullWidth
                              required
                              onChange={handleCompanyNameChange}
                              name="recCompanyName"
                              component={TextField}
                              type="text"
                              label="Recipient company name"
                            />
                          </StyledGrid>

                          <Grid item xs={12}>
                            <ButtonsEmailSendWrapper className="buttons">
                              <SendButton
                                type="submit"
                                disabled={submitting}
                                resetClick={resetClick}>
                                Send
                              </SendButton>
                              <div style={{ marginTop: "10px" }}>
                                {window.history.state ? (
                                  <ButtonBackToReportStyle
                                    emailPage
                                    onClick={() =>
                                      history.push("./mainpage", { userData: window.history.state })
                                    }>
                                    Back to Your Report
                                  </ButtonBackToReportStyle>
                                ) : (
                                  <ButtonBackToReportStyle
                                    emailPage
                                    onClick={() => history.push("./mainpage")}>
                                    Back to Your Report
                                  </ButtonBackToReportStyle>
                                )}
                              </div>
                            </ButtonsEmailSendWrapper>
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid container alignItems="flex-start" spacing={2}>
                          <StyledGrid item xs={12}>
                            <MyField
                              fullWidth
                              required
                              onChange={handleNameChange}
                              name="firstName"
                              component={TextField}
                              type="text"
                              label="First name"
                            />
                          </StyledGrid>
                          <StyledGrid item xs={12}>
                            <MyField
                              fullWidth
                              required
                              name="lastName"
                              onChange={handleLastNameChange}
                              component={TextField}
                              type="text"
                              label="Last name"
                            />
                          </StyledGrid>
                          <StyledGrid item xs={12}>
                            <MyField
                              fullWidth
                              required
                              onChange={handleEmailChange}
                              name="emailAddress"
                              component={TextField}
                              type="email"
                              label="Email address"
                            />
                          </StyledGrid>
                          <StyledGrid item xs={12}>
                            <MyField
                              fullWidth
                              required
                              name="recipientName"
                              onChange={handleRecipientNameChange}
                              component={TextField}
                              type="text"
                              label="Recipient name"
                            />
                          </StyledGrid>

                          <StyledGrid item xs={12}>
                            <MyField
                              fullWidth
                              required
                              name="companyEmail"
                              onChange={handleCompanyEmailChange}
                              component={TextField}
                              type="email"
                              label="Recipient email address"
                            />
                          </StyledGrid>

                          <StyledGrid item xs={12}>
                            <MyField
                              fullWidth
                              required
                              onChange={handleCompanyNameChange}
                              name="recCompanyName"
                              component={TextField}
                              type="text"
                              label="Recipient company name"
                            />
                          </StyledGrid>

                          <Grid item xs={12}>
                            <ButtonsEmailSendWrapper className="buttons">
                              <SendButton
                                type="submit"
                                disabled={submitting}
                                resetClick={resetClick}>
                                Send
                              </SendButton>
                              <div style={{ marginTop: "10px" }}>
                                {window.history.state ? (
                                  <ButtonBackToReportStyle
                                    emailPage
                                    onClick={() =>
                                      history.push("./mainpage", { userData: window.history.state })
                                    }>
                                    Back to Your Report
                                  </ButtonBackToReportStyle>
                                ) : (
                                  <ButtonBackToReportStyle
                                    emailPage
                                    onClick={() => history.push("./mainpage")}>
                                    Back to Your Report
                                  </ButtonBackToReportStyle>
                                )}
                              </div>
                            </ButtonsEmailSendWrapper>
                          </Grid>
                        </Grid>
                      )}
                    </Paper>
                  </form>
                )}
              />
            </Styles>
          </FormContainerEmailPage>
        </FormWrapper>
      </SlideWrapperEmailPage>
      <ButtonReWrapper>
        <img src={reCalculate} alt="" onClick={resetClick} style={{ cursor: "pointer" }} />
        <StyledLink to="/" onClick={resetClick}>
          Re-Calculate
        </StyledLink>
      </ButtonReWrapper>
    </EmailPageWrapper>
  );
};

export default inject(STORES.CALCULATOR)(observer(EmailPage));
