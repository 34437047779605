import { IUserInfo, ICommunityMember, IExtendedUserBalance, ICardInfo } from "services/api/types";
import { AuthServices, AuthTypes } from "services/auth/types";

export enum GET_CARD_STATUS {
  NOT_ACTIVATED = "not_activated",
  ERROR = "error",
  INELIGIBLE = "ineligible",
  SUCCESS = "success",
}

export interface IGetCardInfoResponse {
  status: GET_CARD_STATUS;
  cardInfo?: ICardInfo | {};
}

export type ChallengePeriodDateRange = [string?, string?];

export interface IUserStore {
  /**
   * Organization challenge period start and end dates
   */
  challengePeriodDateRange: ChallengePeriodDateRange;
  /**
   * Is user logged in or not
   */
  isAuthenticated: boolean;
  /**
   * User info
   */
  userInfo?: IUserInfo;
  /**
   * A list of members in the user's community
   */
  communityMembers?: ICommunityMember[];
  /**
   * User's balance info
   */
  balanceInfo?: IExtendedUserBalance;
  /**
   * Indicated the user explicitly logged out
   */
  justLoggedOut: boolean;

  timezone: string;

  weekStart: 0 | 1;
  /**
   * Amount of working hours in the organization
   */
  organizationWorkingHours: number;

  loadUserInfo: (force?: boolean) => Promise<void>;

  getUserCardInfo: () => Promise<IGetCardInfoResponse>;

  loadCommunityMembers: (force?: boolean) => Promise<void>;

  updateUserInfo: (newUserInfo: IUserInfo) => Promise<boolean>;
  /**
   * Upload new avatar file
   * @returns is upload successful
   */
  uploadUserAvatar: (newAvatar: File) => Promise<boolean>;

  loadBalanceInfo: (force?: boolean) => Promise<void>;
  /**
   * Set new wellness goal (in scoops units)
   * @returns success or not
   */
  changeWellnessGoal: (scoops: number) => Promise<boolean>;
  /**
   * Checks user's auth state and loads his data if he is authenticated
   */
  checkUserAuthStateAndLoadData: () => Promise<void>;
  /**
   * Sign in with provided auth type
   * @returns success or not
   */
  signInWith: (
    type: AuthTypes,
    email?: string,
    password?: string,
    serviceName?: AuthServices,
  ) => Promise<boolean>;
  /**
   * Log out from the system
   */
  logOut: () => Promise<void>;
  /**
   * Sets the justLoggedOut value
   */
  setJustLoggedOut: (value: boolean) => void;
  /**
   * Clear all store data
   */
  clearStore: () => void;
}
