import Slider from "@material-ui/core/Slider";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import React from "react";
import { Theme } from "@material-ui/core";
import { useWindowSize } from "../utils/hooks/useWindowSize";
import size from "../config/devices";
import { Size } from "../utils/hooks/useWindowSizeTypes";

export interface StyleProps {
  screenSize: boolean;
}
const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: {
    width: ({ screenSize }) => (screenSize ? "50%" : "40%"),
    "& .MuiSlider-thumb::after": {
      content: "",
      position: "absolute",
      width: "10px",
      height: "10px",
      borderRadius: "50%",
      background: "#1c1259",
      top: "7.5px",
      left: "7.5px",
      bottom: "0",
      right: "0",
    },
  },
  margin: {
    height: theme.spacing(3),
  },
}));

const marks = [
  {
    value: 0,
    label: "0%",
  },
  {
    value: 20,
    label: "20%",
  },
];

const DiscreteSlider = (props: any) => {
  const sizes: Size = useWindowSize();
  const tabletWidth = +size.tablet.slice(0, -2);
  const mobileView = () => {
    if (sizes.width) {
      if (sizes.width < tabletWidth) {
        return { screenSize: true };
      }
      return { screenSize: false };
    }
  };
  const mobile = mobileView();
  const classes = useStyles(mobileView());

  let StyledSlider: any;
  if (mobile?.screenSize) {
    StyledSlider = withStyles({
      root: {
        color: "#bfbfbf",
        height: 2,
        padding: "15px 0",
        '& .MuiSlider-markLabel[data-index="0"]': {
          top: "19%",
          right: "8px",
          left: "-15% !important",
          color: "#1c1259",
          fontFamily: "Poppins ,sans-serif ",
          fontSize: "10px",
        },
        '& .MuiSlider-markLabel[data-index="1"]': {
          top: "19%",
          right: "8px",
          left: "115% !important",
          color: "#1c1259",
          fontFamily: "Poppins ,sans-serif ",
          fontSize: "10px",
        },
      },
      thumb: {
        width: "25px",
        height: "25px",
        marginTop: "-9px",
        backgroundColor: "white",
        position: "relative",
        boxShadow: "0px 0px 2px 4px rgb(74 90 139 / 7%)",
      },
      active: {},

      valueLabel: {
        left: "calc(-50% + 8px)",
        top: -22,
        fontSize: "12px",
        fontWeight: 500,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.5,
        letterSpacing: "normal",
        textAlign: "center",
        color: "#bfbfbf",

        "& *": {
          background: "transparent",
          color: "#FF5277",
          fontWeight: 600,
        },
        "& .makeStyles-textField-4 .MuiInputBase-input": {
          fontSize: "1.5em",
        },
      },

      rail: {
        height: 2,
        opacity: 0.5,
        backgroundColor: "#bfbfbf",
      },
      markLabel: {
        fontFamily: '"Poppins"',
        fontSize: "12px",
        fontWeight: 600,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 2,
        letterSpacing: "normal",
        textAlign: "center",
        color: "#d5daeb",
      },
      markLabelActive: {
        fontSize: "12px",
        fontWeight: 600,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 2,
        letterSpacing: "normal",
        textAlign: "center",
        color: "#d5daeb",
      },
      markActive: {
        opacity: 1,
        backgroundColor: "#bfbfbf",
      },
      "& .makeStyles-textField-4 .MuiInputBase-input": {
        fontSize: "1.5em",
      },
    })(Slider);
  } else {
    StyledSlider = withStyles({
      root: {
        color: "#bfbfbf",
        height: 2,
        padding: "15px 0",
        '& .MuiSlider-markLabel[data-index="0"]': {
          top: "8%",
          right: "8px",
          left: "-11% !important",
          color: "#1c1259",
          fontFamily: "Poppins ,sans-serif ",
          fontSize: "14px",
        },
        '& .MuiSlider-markLabel[data-index="1"]': {
          top: "8%",
          right: "8px",
          left: "111% !important",
          color: "#1c1259",
          fontFamily: "Poppins ,sans-serif ",
          fontSize: "14px",
        },
      },
      thumb: {
        width: "25px",
        height: "25px",
        marginTop: "-9px",
        backgroundColor: "white",
        position: "relative",
        boxShadow: "0px 0px 2px 4px rgb(74 90 139 / 7%)",
      },
      active: {},

      valueLabel: {
        left: "calc(-50% + 8px)",
        top: -22,
        fontSize: "16px",
        fontWeight: 500,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.5,
        letterSpacing: "normal",
        textAlign: "center",
        color: "#bfbfbf",

        "& *": {
          background: "transparent",
          color: "#FF5277",
          fontWeight: 600,
        },
        "& .makeStyles-textField-4 .MuiInputBase-input": {
          fontSize: "1.5em",
        },
      },

      rail: {
        height: 2,
        opacity: 0.5,
        backgroundColor: "#bfbfbf",
      },
      markLabel: {
        fontFamily: '"Poppins"',
        fontSize: "12px",
        fontWeight: 600,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 2,
        letterSpacing: "normal",
        textAlign: "center",
        color: "#d5daeb",
      },
      markLabelActive: {
        fontSize: "12px",
        fontWeight: 600,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 2,
        letterSpacing: "normal",
        textAlign: "center",
        color: "#d5daeb",
      },
      markActive: {
        opacity: 1,
        backgroundColor: "#bfbfbf",
      },
      "& .makeStyles-textField-4 .MuiInputBase-input": {
        fontSize: "1.5em",
      },
    })(Slider);
  }

  return (
    <div className={classes.root} id="discrete-slider">
      <StyledSlider
        ref={props.register}
        step={0.1}
        aria-labelledby="discrete-slider"
        marks={marks}
        min={0}
        max={20}
        valueLabelDisplay="on"
        {...props}
      />
    </div>
  );
};

export default DiscreteSlider;
