import { PropsWithChildren, PureComponent } from "react";
import { STORES } from "config/constants";
import { inject, observer } from "mobx-react";
import { IUserStore } from "stores/user/types";
import { gtmService } from "services/gtm";
import { GTMUserState } from "services/gtm/types";

interface IProps {
  [STORES.USER]?: IUserStore;
  pageTitle?: string;
}

@inject(STORES.USER)
@observer
export class GTMDataLayer extends PureComponent<PropsWithChildren<IProps>> {
  private get userStore() {
    return this.props[STORES.USER] as IUserStore;
  }

  componentDidMount() {
    const { pageTitle } = this.props;
    const { userInfo, isAuthenticated } = this.userStore;

    if (pageTitle) {
      gtmService.updateGeneralDataLayer({
        userId: userInfo?.id ? +userInfo.id : null,
        userState: isAuthenticated ? GTMUserState.LOGIN : GTMUserState.NO_LOGIN,
        pageTitle,
      });
    }
  }

  render() {
    return this.props.children;
  }
}
