import styled from "styled-components";
import { Colors } from "assets/styles/constants";
import { EXPERIENCES_PAGER_HEIGHT } from "components/Experience/ExperiencesCarousel/styles";

export const CarouselContainer = styled.div`
  width: 100%;
  max-width: 1055px;
  margin: 42px auto 0;
  border-radius: 10px;
  overflow: hidden;
`;

export const FeaturedWrapper = styled.div`
  width: 100%;
  padding: 31px 0 50px;
  min-height: 462px;
  background-image: linear-gradient(108deg, #ffdb8c, #ffee98 34%, #ff8e8e 97%);
  margin-bottom: 53px;
`;
export const Title = styled.h2`
  margin-bottom: 0;
  font-size: 30px;
  text-align: center;
`;

export const TextBlock = styled.h3`
  font-size: 30px;
  line-height: 42px;
  font-weight: 400;
  text-align: center;
  margin-top: 75px;

  small {
    color: ${Colors.orange};
    font-size: 20px;
    line-height: 40px;
    display: block;
    margin-top: 6px;
  }
`;

export const ExperiencesListWrapper = styled.div`
  width: 100%;
  margin-top: ${EXPERIENCES_PAGER_HEIGHT}px;
`;
