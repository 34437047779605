import { IErrorLogger, ErrorLogger } from "./sentry";

export enum LOGGER_TYPE {
  SENTRY = "sentry",
}

export default class SentryLogger {
  static createLogger(type: LOGGER_TYPE): IErrorLogger {
    switch (type) {
      case LOGGER_TYPE.SENTRY:
        return new ErrorLogger();
    }
  }
}
