import { IAppInitConfig } from "services/api/types";
import { RouteComponentProps } from "react-router-dom";

import { ISidebarSizeInfo } from "config/constants";

export type InitStateType = "ready" | "error";

export type LoginReferrer = RouteComponentProps["location"] | string;

export enum AuthModalType {
  LOGIN = "login",
  REFRESH = "refresh",
}

export interface IShowAuthModalParams {
  type?: AuthModalType;
  loginReferrer?: LoginReferrer;
}

export interface IErrorModalParams {
  /**
   * Error message. There is a default text if nothing provided
   */
  message?: string;
  /**
   * Error title. Default: `Oops!`
   */
  title?: string;
}

export interface IAppStore {
  sidebarSizeInfo: ISidebarSizeInfo;
  // auth modal
  authModalVisible: boolean;
  authModalType?: AuthModalType;
  showAuthModal: (params?: IShowAuthModalParams) => void;
  closeAuthModal: () => void;

  // error modal
  errorModalVisible: boolean;
  errorModalParams?: IErrorModalParams;
  showErrorModal: (params?: IErrorModalParams) => void;
  closeErrorModal: () => void;

  // card activation modal
  cardActivationModalVisible: boolean;
  showCardActivationModal: () => void;
  closeCardActivationModal: () => void;
  /**
   * App init state
   */
  initState?: InitStateType;
  /**
   * Mask visible state
   */
  maskVisible: boolean;
  /**
   * Sidebar collapsed state
   */
  sidebarCollapsed: boolean;
  /**
   * Sidebar transition end event
   */
  sidebarTransitionEnd: number;
  /**
   * The original pathname that caused login route redirection
   */
  loginReferrer?: LoginReferrer;
  /**
   * Should show banner in calendar page
   */
  showCalendarPageBanner: boolean;
  /**
   * Set mask visible state
   */
  setMaskVisible: (collapsed: boolean) => void;
  /**
   * Set sidebar collapsed state
   */
  setSidebarCollapsed: (collapsed: boolean) => void;
  /**
   * Trigger sidebar transition end event
   */
  setSidebarTransitionEnd: () => void;
  /**
   * Sets the loginReferrer value
   */
  setLoginReferrer: (loginReferrer?: LoginReferrer) => void;
  /**
   * App's init config
   */
  initConfig?: IAppInitConfig;
  /**
   * Loads app init config
   */
  loadInitConfig: () => Promise<void>;
  /**
   * User clicked dismiss on banner in calendar page
   */
  dismissCalendarPageBanner: () => void;
  /**
   * Set initialized state
   */
  setInitState: (value?: InitStateType) => void;
}
