/**
 * Supported auth types
 */
export enum AuthTypes {
  GOOGLE = "google",
  MICROSOFT = "microsoft",
  MAIL = "mail",
}

export enum AuthServices {
  FIREBASE_RW = "firebase_rw",
  FIREBASE_RO = "firebase_ro",
  MICROSOFT_RW = "microsoft_rw",
  MICROSOFT_RO = "microsoft_ro",
}

export interface ILoginPayload {
  /**
   * User auth id
   */
  id?: string | null;
  success: boolean;
  error?: string;
}

export interface IAuthTokensData {
  tokens: (string | undefined)[];
  serviceName: AuthServices;
}

export interface IAuthInstance {
  /**
   * Instance name
   */
  readonly name: AuthServices;
  /**
   * Is service initialized or not
   */
  readonly isInitialized: boolean;
  /**
   * Initializes the service
   */
  init: () => void;
  /**
   * Returns idToken of the current user (if he has logged in)
   */
  getAuthTokens: () => Promise<(string | undefined)[]>;
  /**
   * Load auth state
   * @returns user.fid
   */
  loadAuthState: () => Promise<string | null>;
  /**
   * Log in with provided auth type. If auth with email, need to provide email and password
   */
  logInWith: (type: AuthTypes, email?: string, password?: string) => Promise<ILoginPayload>;
  /**
   * Log out and clears auth state
   */
  logOut: () => Promise<void>;
}

export interface IAuthService
  extends Omit<IAuthInstance, "isInitialized" | "init" | "signInWith" | "getAuthTokens" | "name"> {
  /**
   * Returns tokens data
   */
  getAuthTokens: () => Promise<IAuthTokensData | null>;
  /**
   * Log in with provided auth type. If auth with email, need to provide email and password
   * @param serviceName the name of AuthService to login with. Default: `AuthServices.DEFAULT`
   */
  logInWith: (
    type: AuthTypes,
    email?: string,
    password?: string,
    serviceName?: AuthServices,
  ) => Promise<ILoginPayload>;
}

export interface IAuthInstanceFactory {
  /**
   * Returns certain `authService` depending on provided `serviceName` param
   */
  getInstance: (serviceName: AuthServices) => IAuthInstance;
}
