import {
  IUserInfo,
  ICommunityMember,
  UNIT_TYPES,
  IExtendedUserBalance,
  IBalanceUnitItem,
  RegionCodes,
  IApiError,
} from "services/api/types";
import { observable, computed, action } from "mobx";
import dayjs from "dayjs";

// services / stores
import { authService } from "services/auth";
import { apiService } from "services/api";
import { errorService } from "services/error";
import { onboardingStore } from "stores/onboarding";
import { notificationStore } from "stores/notification";
import { experiencesStore } from "stores/experiences";
import { appStore } from "stores/app";

// types
import { AuthServices, AuthTypes } from "services/auth/types";
import { ChallengePeriodDateRange, GET_CARD_STATUS, IUserStore } from "./types";

// other
import { getWeekStartByTimezone } from "utils/helpers";
import { calendarStore } from "stores/calendar";
import { ERRORS } from "config/constants";
import { ENV } from "config/env";
import { convertPTOUnits, IConvertPTOUnitsParams } from "utils/formatters";

const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const REGION_CODES_TO_TIMEZONE_MAP = {
  [RegionCodes.IL]: "Asia/Jerusalem",
  [RegionCodes.US_NY]: "America/New_York", // US Central time
  [RegionCodes.US_CA]: "America/Los_Angeles", // US Pacific time
  [RegionCodes.US_CO]: "America/Denver", // US Mountain time
};

class UserStore implements IUserStore {
  @observable private _userInfo?: IUserInfo;

  @observable private _communityMembers?: ICommunityMember[];

  @observable private _balanceInfo?: IExtendedUserBalance;

  @observable private _justLoggedOut: boolean = false;

  @observable private _timezone: string = browserTimezone;

  @observable private _weekStart: 0 | 1 = getWeekStartByTimezone(browserTimezone);

  @computed public get isAuthenticated() {
    return !!this.userInfo;
  }

  @computed public get organizationWorkingHours() {
    return this._userInfo?.organization.hoursPerDay || 9;
  }

  @computed public get userInfo() {
    return this._userInfo;
  }

  @computed public get balanceInfo() {
    return this._balanceInfo;
  }

  @computed public get communityMembers() {
    return this._communityMembers;
  }

  @computed public get justLoggedOut() {
    return this._justLoggedOut;
  }

  @computed public get timezone() {
    return this._timezone;
  }

  @computed public get weekStart() {
    return this._weekStart;
  }

  @computed public get challengePeriodDateRange(): ChallengePeriodDateRange {
    const org = this.userInfo?.organization;
    if (!org) {
      return [];
    }
    return [org.startPeriod, org.endPeriod];
  }

  public signInWith = async (
    type: AuthTypes,
    email?: string,
    password?: string,
    serviceName?: AuthServices,
  ) => {
    try {
      const { success, error } = await authService.logInWith(type, email, password, serviceName);
      if (!success) {
        if (error) {
          notificationStore.notify({
            title: "Authentication error",
            type: "error",
            message: error,
          });
        }
        return false;
      }
      await this.loadUserInfo();
      await this.loadUserAppData();
      return true;
    } catch (e) {
      // if the user doesn't exist in our database
      if ((e as IApiError).status === 401) {
        notificationStore.notify({
          type: "error",
          title: "Authentication error",
          message: ERRORS.USER_NOT_FOUND,
        });
        authService.logOut();
      } else {
      console.error(e);
      }
      return false;
    }
  };

  public checkUserAuthStateAndLoadData = async () => {
    const authUser = await authService.loadAuthState();
    if (authUser) {
      try {
        await this.loadUserInfo();
        await this.loadUserAppData();
      } catch (error) {
        if ((error as IApiError).status === 401) {
          authService.logOut();
        } else {
               console.error(error);

        }
      }
    }
  };

  private _isLoginOut = false;

  public logOut = async () => {
    if (!this.isAuthenticated || this._isLoginOut) {
      // there is no need to do the logout if the user is already logged out
      return;
    }

    this._isLoginOut = true;
    try {
      appStore.setInitState();
      await authService.logOut();
      this.clearUserInfo();
      this.setJustLoggedOut(true);
      appStore.setInitState("ready");
    } catch (error) {
      console.error(error);
    }
    this._isLoginOut = false;
  };

  public loadUserInfo = async (force = false) => {
    if (this.userInfo && !force) {
      return;
    }

    const info = await apiService.getUserInfo();
    this.setUserInfo(info);
  };

  public getUserCardInfo = async () => {
    try {
      const cardInfo = await apiService.getUserCardInfo();

      // if empty object
      if (!Object.keys(cardInfo).length) {
        return { status: GET_CARD_STATUS.NOT_ACTIVATED };
      }

      return { cardInfo, status: GET_CARD_STATUS.SUCCESS };
    } catch (error) {
      if ((error as IApiError).data?.statusCode === 403) {
        return { status: GET_CARD_STATUS.INELIGIBLE };
      }
      return console.error(error);
      // return { status: GET_CARD_STATUS.ERROR };
    }
  };

  public uploadUserAvatar = async (newAvatar: File) => {
    try {
      const { avatarUrl } = await apiService.uploadUserAvatar(newAvatar);
      this.setUserInfo({ ...this.userInfo, avatarUrl } as IUserInfo);
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  public updateUserInfo = async (newUserInfo: IUserInfo) => {
    try {
      await apiService.updateUserInfo(newUserInfo);
      this.setUserInfo(newUserInfo);
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  public changeWellnessGoal = async (scoops: number) => {
    try {
      await apiService.changeWellnessGoal(scoops);
      await this.loadBalanceInfo();
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  private balanceInfoLoading = false;

  public loadBalanceInfo = async (force = false) => {
    if (this.balanceInfoLoading || (this.balanceInfo && !force)) {
      return;
    }

    this.balanceInfoLoading = true;

    try {
      const { units, ...info } = await apiService.getBalanceInfo();

      const hoursInScoop = +(
        await apiService.getConversationRatio(UNIT_TYPES.SCOOPS, UNIT_TYPES.HOURS)
      ).rate;
      let ptoDaysBalance = units.find(
        (item) =>
          item.unitTypeName ===
          (ENV.REACT_APP_USE_PTO_MIN_UNITS ? UNIT_TYPES.PTO_MINUTES : UNIT_TYPES.PTO_DAYS),
      ) as IBalanceUnitItem;

      const scoopsInDay = this.organizationWorkingHours / hoursInScoop;

      if (ENV.REACT_APP_USE_PTO_MIN_UNITS) {
        const convertParams: IConvertPTOUnitsParams = {
          hoursInDay: this.organizationWorkingHours,
          type: "minutesToDays",
        };
        ptoDaysBalance.initialUnitsBalance = convertPTOUnits(
          ptoDaysBalance.initialUnitsBalance,
          convertParams,
        );
        ptoDaysBalance.availableUnits = convertPTOUnits(
          ptoDaysBalance.availableUnits,
          convertParams,
        );
      }
      this.setBalanceInfo({
        ...info,
        scoopsInDay,
        ptoDaysBalance,
        scoopsBalance: units.find(
          (item) => item.unitTypeName === UNIT_TYPES.SCOOPS,
        ) as IBalanceUnitItem,
      });
    } catch (error) {
      console.error(error);
    }
    this.balanceInfoLoading = false;
  };

  public loadCommunityMembers = async (force = false) => {
    if (this.communityMembers && !force) {
      return;
    }

    try {
      const { relatedUsers } = await apiService.getCommunityMembers();
      this.setCommunityMembers(relatedUsers);
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * Load app data for a certain user
   */
  private loadUserAppData = () => {
    // this is a temp workaround until https://letshift.atlassian.net/browse/GG-629
    // it's needed for components/Route to determine onboarding redirect
    return onboardingStore.loadOnboardingProcessData();
  };

  @action private setUserInfo(newUserInfo: IUserInfo) {
    if (!this._userInfo) {
      appStore.setSidebarCollapsed(false);
    }

    // set timezone according to user region
    const timezone = REGION_CODES_TO_TIMEZONE_MAP[newUserInfo.region];

    this.setTimezone(timezone);

    // set week start according to user region
    const weekStart = getWeekStartByTimezone(timezone);
    this.setWeekStart(weekStart);

    this._userInfo = { ...this._userInfo, ...newUserInfo };
  }

  @action private clearUserInfo() {
    this.clearStore();
    experiencesStore.clearStore();
    calendarStore.clearStore();
  }

  @action private setBalanceInfo(balance: IExtendedUserBalance) {
    this._balanceInfo = balance;
  }

  @action private setCommunityMembers(members: ICommunityMember[]) {
    this._communityMembers = members;
  }

  @action public setJustLoggedOut = (value: boolean) => {
    this._justLoggedOut = value;
  };

  @action private setWeekStart = (value: 0 | 1) => {
    dayjs.Ls.en.weekStart = value; // for dayjs().startOf("week")
    this._weekStart = value;
  };

  @action private setTimezone = (value: string) => {
    dayjs.tz.setDefault(value);
    this._timezone = value;
  };

  @action public clearStore = () => {
    this._userInfo = undefined;
    this._communityMembers = undefined;
    this._balanceInfo = undefined;
    this._justLoggedOut = false;
    this._timezone = browserTimezone;
    this._weekStart = getWeekStartByTimezone(browserTimezone);
  };
}

export const userStore: IUserStore = new UserStore();
