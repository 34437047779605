import { ENV } from "config/env";
import TagManager from "react-gtm-module";
import {
  GeneralDataLayer,
  OnboardingDataLayer,
  CalendarEventDataLayer,
  ExperienceDataLayer,
  ExperienceFilters,
  IGTMService,
  GTMSorbetStatus,
  ExperienceDataLayerType,
  GTMSocialType,
} from "./types";

const initialOnboardingDataLayer: OnboardingDataLayer = {
  goals: null,
  peakHours: null,
  activities: null,
  ptoBalanceDays: null,
};

const initialExperienceFilters: ExperienceFilters = {
  scoops: null,
  brand: null,
  priceRange: null,
  favorites: null,
  activities: null,
};

const chosenSorbetInitialDataLayer: Pick<
  ExperienceDataLayer,
  "MFPChosenSorbet" | "TrendingChosenSorbet"
> = {
  MFPChosenSorbet: null,
  TrendingChosenSorbet: null,
};

const initialExperienceDataLayer: ExperienceDataLayer = {
  MFPImpression: null,
  TrendingImpression: null,
  PDPChosenSorbet: null,
  YMALImpression: null,
  YMALChosenSorbet: null,
  FilterName: initialExperienceFilters,
  ...chosenSorbetInitialDataLayer,
};

const initialCalendarEventDataLayer: CalendarEventDataLayer = {
  SorbetName: "",
  SorbetStatus: GTMSorbetStatus.NOT_EXIST,
};

class GTMService implements IGTMService {
  private onboardingDataLayer: OnboardingDataLayer = { ...initialOnboardingDataLayer };

  private calendarEventDataLayer: CalendarEventDataLayer = {
    ...initialCalendarEventDataLayer,
  };

  private experienceDataLayer: ExperienceDataLayer = { ...initialExperienceDataLayer };

  init() {
    TagManager.initialize({
      gtmId: ENV.REACT_APP_GTM_ID,
    });
  }

  onPDPExploreBtnClick(sorbetTitle: string) {
    this.updateExperienceDataLayer({ PDPChosenSorbet: sorbetTitle }, "PDPExploreBtnClick");
  }

  onPDPBookBtnClick(sorbetTitle: string) {
    this.updateExperienceDataLayer({ PDPChosenSorbet: sorbetTitle }, "PDPBookBtnClick");
  }

  onSocialsShareBtnClick(
    layerType: ExperienceDataLayerType,
    socialType: GTMSocialType,
    sorbetTitle: string,
  ) {
    this.updateExperienceDataLayer(
      { ...chosenSorbetInitialDataLayer, [`${layerType}ChosenSorbet`]: sorbetTitle },
      `${layerType}ChosenSorbet${socialType}ShareClick`,
    );
  }

  onExperienceItemClick(layerType: ExperienceDataLayerType, sorbetTitle: string) {
    this.updateExperienceDataLayer(
      { ...chosenSorbetInitialDataLayer, [`${layerType}ChosenSorbet`]: sorbetTitle },
      `${layerType}ChosenSorbetUpdated`,
    );
  }

  onSaveExperienceBtnClick(layerType: ExperienceDataLayerType, sorbetTitle: string) {
    this.updateExperienceDataLayer(
      { ...chosenSorbetInitialDataLayer, [`${layerType}ChosenSorbet`]: sorbetTitle },
      `${layerType}SavedSorbetUpdated`,
    );
  }

  onVisibleImpressionUpdated(layerType: ExperienceDataLayerType, impression: string[]) {
    this.updateExperienceDataLayer(
      { [`${layerType}Impression`]: impression },
      `${layerType}ImpressionUpdated`,
    );
  }

  clearExperienceDataLayer() {
    this.updateExperienceDataLayer(initialExperienceDataLayer, "ExperienceLayerCleared");
  }

  clearOnboardingDataLayer() {
    this.onboardingDataLayer = { ...initialOnboardingDataLayer };
    this.updateDataLayer(this.onboardingDataLayer, "OnboardingLayerCleared");
  }

  clearExperienceFilters() {
    this.updateExperienceFilters(initialExperienceFilters);
  }

  updateGeneralDataLayer(dataLayer: GeneralDataLayer) {
    this.updateDataLayer(dataLayer, "GeneralLayerUpdated");
  }

  updateOnboardingDataLayer(data: OnboardingDataLayer) {
    this.onboardingDataLayer = { ...this.onboardingDataLayer, ...data };
    this.updateDataLayer(this.onboardingDataLayer, "OnboardingLayerUpdated");
  }

  updateExperienceFilters(filters: ExperienceFilters) {
    this.updateExperienceDataLayer(
      {
        FilterName: { ...this.experienceDataLayer.FilterName, ...filters },
      },
      "ExperienceFiltersUpdated",
    );
  }

  updateCalendarEventDataLayer(data: CalendarEventDataLayer) {
    this.calendarEventDataLayer = { ...data };
    this.updateDataLayer(this.calendarEventDataLayer, "CalendarLayerUpdated");
  }

  private updateExperienceDataLayer(data: ExperienceDataLayer, event?: string) {
    this.experienceDataLayer = { ...this.experienceDataLayer, ...data };
    this.updateDataLayer(this.experienceDataLayer, event);
  }

  private updateDataLayer = (dataLayer: object, event = "DataLayerUpdated") =>
    TagManager.dataLayer({ dataLayer: { event, ...dataLayer } });
}

export const gtmService: IGTMService = new GTMService();
