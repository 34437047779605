// Core
import React, { PureComponent } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { Skeleton } from "antd";

// Components
import { FilterButton } from "./FilterButton";
import { FilterTag, ClearFilterTag } from "./FilterTag";

// Styles
import { FiltersWrapper, FiltersButtonsWrapper, FilterTagsWrapper } from "./styles";
import { FilterButtonWrapper, FILTER_BTN_SIZE } from "./FilterButton/styles";

// Types
import { ValueOf } from "utils/types";
import {
  IFilterOption,
  FilterValue,
  IExtendedExperienceFilters,
  ExperienceQuery,
} from "services/api/types";
import { IExperiencesStore } from "stores/experiences/types";
import { IUserStore } from "stores/user/types";
import { IAppStore } from "stores/app/types";

// Other
import { STORES } from "config/constants";
import { uppercaseFirst } from "utils/formatters";
import { parseExperiencesQuery } from "utils/helpers";
import { qsService } from "services/qs";
import { gtmService } from "services/gtm";

// Assets
import brandIcon from "assets/images/brand.svg";
import priceIcon from "assets/images/price.svg";
import activitiesIcon from "assets/images/activities.svg";
import scoopsIcon from "assets/images/scoops.svg";
import favoritesIcon from "assets/images/favorites.svg";

interface IProps extends RouteComponentProps {
  [STORES.EXPERIENCES]?: IExperiencesStore;
  [STORES.USER]?: IUserStore;
  [STORES.APP]?: IAppStore;
}

const initialState: ExperienceQuery = {
  scoops: undefined,
  favorites: undefined,
  activities: undefined,
  priceRange: undefined,
  brand: undefined,
};

interface IFilterButtonConfig {
  key: keyof ExperienceQuery;
  title?: string;
  icon: string;
  iconSize?: number;
  toggle?: boolean;
}

const filterButtons: IFilterButtonConfig[] = [
  { key: "scoops", icon: scoopsIcon },
  { key: "activities", icon: activitiesIcon, title: "Activity" },
  { key: "priceRange", icon: priceIcon, title: "Price Range" },
  { key: "brand", icon: brandIcon },
  { key: "favorites", icon: favoritesIcon, iconSize: 30, toggle: true, title: "Saved" },
];

export const requiresLogin: Partial<Record<keyof IExtendedExperienceFilters, boolean>> = {
  favorites: true,
};

@inject(STORES.EXPERIENCES, STORES.USER, STORES.APP)
@observer
class ExperienceFiltersComponent extends PureComponent<IProps, ExperienceQuery> {
  private get experiencesStore() {
    return this.props[STORES.EXPERIENCES] as IExperiencesStore;
  }

  private get userStore() {
    return this.props[STORES.USER] as IUserStore;
  }

  private get appStore() {
    return this.props[STORES.APP] as IAppStore;
  }

  state: ExperienceQuery = { ...initialState };

  private urlChecked = false;

  componentDidMount() {
    const { experienceFilters, loadExperienceFilters } = this.experiencesStore;
    if (experienceFilters) {
      this.checkUrl();
    } else {
      loadExperienceFilters();
    }
  }

  componentDidUpdate() {
    const { experienceFilters } = this.experiencesStore;
    if (experienceFilters && !this.urlChecked) {
      this.checkUrl();
    }
  }

  private getExperienceFilters() {
    const { experienceFilters } = this.experiencesStore;
    if (!experienceFilters) {
      return undefined;
    }
    return {
      ...experienceFilters,
      favorites: [{ title: "Saved", value: true }],
    } as IExtendedExperienceFilters;
  }

  checkUrl = () => {
    const { location } = this.props;
    const newState = parseExperiencesQuery(location.search);
    this.updateFilters(newState);
    this.urlChecked = true;
  };

  updateFilters = (filters: Partial<ExperienceQuery>) => {
    const { history } = this.props;
    const newState: ExperienceQuery = {
      ...this.state,
      ...filters,
    };
    const search = qsService.stringify(newState);
    this.setState(newState);
    history.replace({ search });

    this.experiencesStore.loadExperiences(newState);
  };

  handleFilterChange = (key: keyof ExperienceQuery) => (values: ValueOf<ExperienceQuery>) => {
    if (!this.userStore.isAuthenticated && key in requiresLogin) {
      this.appStore.showAuthModal();
    } else {
      this.updateFilters({ [key]: values });
      this.updateGTM(key, values);
    }
  };

  updateGTM = (key: keyof ExperienceQuery, values: ValueOf<ExperienceQuery>) => {
    const experienceFilters = this.getExperienceFilters();

    if (experienceFilters) {
      const newValues = values?.map((item) => {
        return (experienceFilters[key] as IFilterOption[]).find((value) => value.value === item)
          ?.title;
      });
      gtmService.updateExperienceFilters({ [key]: newValues });
    }
  };

  handleFilterRemove = (key: keyof ExperienceQuery, value: FilterValue) => () => {
    const values = this.state[key];
    if (values?.length) {
      this.handleFilterChange(key)(values.filter((v) => v !== value));
    }
  };

  clearAllFilters = () => {
    gtmService.clearExperienceFilters();
    this.updateFilters(initialState);
  };

  renderFilterTags = () => {
    const experienceFilters = this.getExperienceFilters();
    if (!experienceFilters) {
      return null;
    }

    return (
      <>
        {(Object.entries(this.state) as [keyof ExperienceQuery, ValueOf<ExperienceQuery>][]).map(
          ([key, values]) =>
            ((values || []) as FilterValue[]).map((value) => {
              const { title } = (experienceFilters[key] as IFilterOption[]).find(
                (item) => item.value === value,
              ) as IFilterOption;

              // hide tags that require login
              if (!this.userStore.isAuthenticated && key in requiresLogin) {
                return null;
              }

              return (
                <FilterTag key={key + value} onRemove={this.handleFilterRemove(key, value)}>
                  {title || value}
                </FilterTag>
              );
            }),
        )}
        <ClearFilterTag onClick={this.clearAllFilters} />
      </>
    );
  };

  render() {
    const experienceFilters = this.getExperienceFilters();

    if (!experienceFilters) {
      return (
        <FiltersWrapper>
          <FiltersButtonsWrapper>
            {filterButtons.map(({ key }) => (
              <FilterButtonWrapper key={key}>
                <Skeleton.Avatar active size={FILTER_BTN_SIZE} />
                <Skeleton.Button
                  active
                  size="small"
                  style={{ marginTop: 10, width: FILTER_BTN_SIZE }}
                />
              </FilterButtonWrapper>
            ))}
          </FiltersButtonsWrapper>
        </FiltersWrapper>
      );
    }

    return (
      <FiltersWrapper>
        <FiltersButtonsWrapper data-testid="filterIconsWrapper">
          {filterButtons.map(({ key, icon, iconSize, toggle, title }) => (
            <FilterButton
              className="exp-filter-button"
              id={key}
              iconSize={iconSize}
              icon={icon}
              key={key}
              toggle={toggle}
              options={experienceFilters[key]}
              activeOptions={this.state[key]}
              title={title || uppercaseFirst(key)}
              onSelectChange={this.handleFilterChange(key)}
            />
          ))}
        </FiltersButtonsWrapper>
        <FilterTagsWrapper data-testid="filterTagsWrapper">
          {this.renderFilterTags()}
        </FilterTagsWrapper>
      </FiltersWrapper>
    );
  }
}

export const ExperienceFilters = withRouter(ExperienceFiltersComponent);
