import { STORES } from "config/constants";
import { userStore } from "./user";
import { experiencesStore } from "./experiences";
import { notificationStore } from "./notification";
import { appStore } from "./app";
import { calendarStore } from "./calendar";
import { calculatorStore } from "./calculator";
import { onboardingStore } from "./onboarding";

// This import is required because of this issue https://stackoverflow.com/a/61655017/10388170
import "mobx-react-lite/batchingForReactDom";

export const stores = {
  [STORES.USER]: userStore,
  [STORES.EXPERIENCES]: experiencesStore,
  [STORES.NOTIFICATION]: notificationStore,
  [STORES.ONBOARDING]: onboardingStore,
  [STORES.APP]: appStore,
  [STORES.CALENDAR]: calendarStore,
  [STORES.CALCULATOR]: calculatorStore,
};
