// Core
import React, { PureComponent } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { inject, observer } from "mobx-react";
import he from "he";

// Components
import { Loader } from "components/Loader";
import { ShareDialog } from "components/ShareDialog";

// Types
import { IExperience } from "services/api/types";
import { ExperienceDataLayerType } from "services/gtm/types";
import { IOnboardingStore } from "stores/onboarding/types";

// Styles
import {
  ExperienceWrapper,
  ExperienceImageContainer,
  ExperienceImage,
  ExperienceContentWrapper,
  ImgHoverContainer,
  ExperienceTitle,
  ExperienceDescription,
  ActionButton,
  ExperienceText,
  LineBreak,
  RatingContainer,
  RatingTitle,
  StyledRate,
  ScoopImgContainer,
  ScoopsCountContainer,
  ButtonsContainer,
  SaveButton,
  DeleteButton,
} from "./styles";
import { CompleteProfileButton, ScoopsImg } from "pages/CompleteOnboarding/styles";
import { DialogDescription, DialogTitle } from "components/Notification/styles";

// Assets
import shareIcon from "assets/images/share-small.svg";
import saveIcon from "assets/images/save-small.svg";
import deleteIcon from "assets/images/trash-small.svg";
import oneScoopIcon from "assets/images/scoops/1-scoops-icecream.svg";
import twoScoopIcon from "assets/images/scoops/2-scoops-icecream.svg";
import threeScoopIcon from "assets/images/scoops/3-scoops-icecream.svg";

// Other
import { ROUTES, STORES } from "config/constants";
import { experiencesStore } from "stores/experiences";
import { gtmService } from "services/gtm";
import { notificationStore } from "stores/notification";

interface IScoopIcon {
  icon: string;
  margin: number;
}

const scoopIcons: IScoopIcon[] = [
  { icon: oneScoopIcon, margin: 0 },
  { icon: twoScoopIcon, margin: 5 },
  { icon: threeScoopIcon, margin: 5 },
];

export type ExperienceItemType = "default" | "saved";

interface IProps extends RouteComponentProps {
  data: IExperience;
  type?: ExperienceItemType;
  gtmDataLayer?: ExperienceDataLayerType;
  [STORES.ONBOARDING]?: IOnboardingStore;
}

interface IState {
  loading: boolean;
}

@inject(STORES.ONBOARDING)
@observer
class ExperienceItemComponent extends PureComponent<IProps, IState> {
  private get onboardingStore() {
    return this.props[STORES.ONBOARDING] as IOnboardingStore;
  }

  state: IState = {
    loading: false,
  };

  private get expId() {
    return this.props.data.id;
  }

  private get wpPost() {
    return this.props.data.wpPost;
  }

  private get expLink() {
    return `${ROUTES.EXPERIENCE}/${this.expId}/${this.wpPost.slug}`;
  }

  private showCompleteOnboardingDialog = () => {
    const { history } = this.props;
    notificationStore.showDialog({
      hideActions: true,
      content: (
        <>
          <ScoopsImg />
          <DialogTitle>Looks good, right?</DialogTitle>
          <DialogDescription>
            Before you can add this Sorbet experience to your favorites list, please complete your
            profile so we can create a suggested a Time Off plan for you based on your PTO balance,
            goals, preferences, and lifestyle
          </DialogDescription>
          <CompleteProfileButton
            onClick={() => {
              notificationStore.closeDialog();
              history.push(ROUTES.ONBOARDING);
            }}>
            COMPLETE PROFILE
          </CompleteProfileButton>
        </>
      ),
    });
  };

  onFavoritesClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    console.log(this.onboardingStore.onboardingCompleted);
    if (!this.onboardingStore.onboardingCompleted) {
      this.showCompleteOnboardingDialog();
      return;
    }

    const { type, gtmDataLayer } = this.props;
    const { favorite, title } = this.wpPost;

    this.setState({ loading: true });
    await experiencesStore[type === "saved" || favorite ? "removeFromFavorites" : "addToFavorites"](
      this.expId,
    );
    this.setState({ loading: false });
    gtmDataLayer && gtmService.onSaveExperienceBtnClick(gtmDataLayer, title);
  };

  handleItemClick = () => {
    const { history, gtmDataLayer } = this.props;
    gtmDataLayer && gtmService.onExperienceItemClick(gtmDataLayer, this.wpPost.title);
    history.push(this.expLink);
  };

  renderActionBtn = () => {
    if (this.props.type === "saved") {
      return (
        <DeleteButton className="exp-item-save-btn" onClick={this.onFavoritesClick}>
          <img src={deleteIcon} alt="delete" />
        </DeleteButton>
      );
    }
    return (
      <SaveButton
        $favorite={this.wpPost.favorite}
        className="exp-item-save-btn"
        onClick={this.onFavoritesClick}>
        <img src={saveIcon} alt="save" />
      </SaveButton>
    );
  };

  render() {
    const { gtmDataLayer, type } = this.props;
    const { loading } = this.state;
    const { imageUrl, title, shortDesc, scoops, ratedCount, rating } = this.wpPost;

    const { icon, margin } = scoopIcons[scoops - 1];

    return (
      <ExperienceWrapper>
        <ExperienceImageContainer>
          <ExperienceImage src={imageUrl} loading="lazy" />
          <ButtonsContainer>
            {type !== "saved" && (
              <ShareDialog
                experienceTitle={title}
                gtmDataLayer={gtmDataLayer}
                shareLink={`${window.origin}/${this.expLink}`}>
                <ActionButton onClick={(e) => e.stopPropagation()}>
                  <img src={shareIcon} alt="share" />
                </ActionButton>
              </ShareDialog>
            )}
            {this.renderActionBtn()}
          </ButtonsContainer>
          <ImgHoverContainer className="exp-item-image-container" onClick={this.handleItemClick}>
            {loading && <Loader />}
          </ImgHoverContainer>
          <ScoopImgContainer>
            <ScoopsCountContainer style={{ marginTop: margin }}>{scoops}</ScoopsCountContainer>
            <img src={icon} loading="lazy" alt="scoop" />
          </ScoopImgContainer>
        </ExperienceImageContainer>
        <ExperienceContentWrapper>
          <ExperienceText>
            <ExperienceTitle>{he.decode(title)}</ExperienceTitle>
            <LineBreak />
            <ExperienceDescription>{he.decode(shortDesc)}</ExperienceDescription>
          </ExperienceText>
          <RatingContainer>
            <RatingTitle>{ratedCount} rated</RatingTitle>
            <StyledRate disabled value={rating} />
          </RatingContainer>
        </ExperienceContentWrapper>
      </ExperienceWrapper>
    );
  }
}

export const ExperienceItem = withRouter(ExperienceItemComponent);
