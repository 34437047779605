export enum API_ERROR_CODES {
  InvalidRegion = 30001,
  UserNotFound = 30002,
  InvalidParam = 60200,
}

export interface IPaginationParams {
  limit?: number;
  offset?: number;
}

export interface IPaginationResponse<T = unknown> {
  count: number;
  limit: number;
  offset: number;
  payload: T[];
}

interface IOrganization {
  adminId: string;
  fid: string;
  id: string;
  name: string;
  startPeriod: string;
  endPeriod: string;
  /**
   * Company working hours. By default it's `9`
   */
  hoursPerDay?: number;
}

export enum AddressType {
  PERSONAL = "personal",
  SHIPPING = "shipping",
}

export interface IAddressData {
  apartment?: string;
  city?: string;
  country?: string;
  state?: string;
  street?: string;
  zipCode?: string;
}

export interface IAddress extends IAddressData {
  id: string;
  userId: string;
  type: AddressType;
}

export enum RegionCodes {
  IL = "IL",
  US_NY = "US-NY",
  US_CA = "US-CA",
  US_CO = "US-CO",
}

export enum PtoPolicy {
  STANDARD = "standard-1",
  STANDARD_2 = "standard-2",
}

export interface IUserInfoResponse {
  id: string;
  fid: string;
  email: string;
  firstName: string;
  lastName: string;
  addresses?: IAddress[];
  avatarUrl: string;
  ptoPolicy?: PtoPolicy | string;
  jobTitle: string;
  organization: IOrganization;
  birthDate?: string;
  createDate: string;
  lastReportedPTOBalance?: number;
  marketingAllowed: boolean;
  orgUnitId: string;
  organizationId: string;
  peakHour: string;
  phone?: string;
  updateDate: string;
  userStatus?: string;
  region: RegionCodes;
}

export interface IUserInfo extends Omit<IUserInfoResponse, "addresses"> {
  personalAddress: IAddressData;
}

export interface ICardInfo {
  token: string;
  state: string;
  cardNumber: string;
  expiration: string;
  balance: number;
  totalFunds: number;
  usedFunds: number;
  currencyCode: string;
  nextDeduction: {
    amount: number;
    date: string;
  };
  activatedDate: string;
}

export interface ICardPanInfo {
  state: string;
  cardNumber: string;
  expiration: string;
  cvc: string;
}

export enum UNIT_TYPES {
  SCOOPS = "SCOOPS",
  PTO_DAYS = "DAYS",
  PTO_MINUTES = "PTOD_IN_MIN",
  HOURS = "HOURS",
}

export interface IBalanceUnitItem {
  unitTypeName: UNIT_TYPES;
  availableUnits: number;
  initialUnitsBalance: number;
}

export interface IUserBalance {
  companyAveragePercent: number;
  userGoalPercent: number;
  units: IBalanceUnitItem[];
}

export interface IExtendedUserBalance extends Omit<IUserBalance, "units"> {
  /**
   * scoops to days conversation ratio
   */
  scoopsInDay: number;

  ptoDaysBalance: IBalanceUnitItem;
  scoopsBalance?: IBalanceUnitItem;
}

export interface IConversationRatioResponse {
  fromUnitTypeId: string;
  rate: string;
  toUnitTypeId: string;
}

export enum CouponType {
  NONE = "none",
  SINGLE = "single",
  INVENTORY = "inventory",
}

interface IExperienceWpPost {
  activities: string[];
  brand: string;
  id: number;
  slug: string;
  title: string;
  shortTitle?: string;
  imageUrl: string;
  favorite: boolean;
  isPromoted: boolean;
  priceRange: string;
  region: string;
  scoops: ScoopsValue;
  shortDesc: string;
  time: string[];
  ratedCount: number;
  rating: number;
  sorbetNumber: string;
}

export interface IExperience {
  creationDate: string;
  id: number;
  wpPost: IExperienceWpPost;
}

export interface INutritionalFact {
  order: number;
  name: string;
  percent: number;
}

export interface IDetailedExperienceWpPost extends IExperienceWpPost {
  cta: string;
  audience: string[];
  imageOneUrl: string;
  imageTwoUrl: string;
  imageThreeUrl: string;
  totalWellness: number;
  nutritionalFacts: INutritionalFact[];
  offer?: string;
  photoCredit: string;
  priceRange: string;
  status: "active" | string;
  tags?: string[];
  timeSensitiveOneLiner: string;
  affiliateLink: string;
  offerDescription: string;
  offerLogo?: string;
  hasCoupon: boolean;
  couponType?: CouponType;
  singleCouponCode?: string;
  couponExpDate?: string;
}

export interface IDetailedExperience extends IExperience {
  wpPost: IDetailedExperienceWpPost;
}

export interface IApiError {
  statusMessage?: string;
  status: number;
  code?: number;
  data?: {
    error: string;
    message?: string;
    statusCode?: number;
  };
}

export interface IBannerData {
  bannerId: string;
  bannerImageUrl: string;
  bannerLink: string;
  bannerText: string;
}

export interface IPromotionData {
  promotionId: string;
  promotionImageUrl: string;
  promotionLink: string;
  promotionText: string;
}

export interface IOnboardingData {
  calendarStep: boolean;
  ptoBalanceStep: boolean;
  goalsStep: boolean;
  peakHoursStep: boolean;
  preferencesStep: boolean;
  isCompleted: boolean;
  congratulations?: boolean;
  onboardedAt?: string;
}

export interface IPeakHoursItem {
  id: number;
  key: string;
  title: string;
}

export interface IGoalItem {
  key: string;
  title: string;
}

export type FilterValue = string | number | boolean;

export interface IFilterOption<T extends FilterValue = string> {
  title?: string;
  value: T;
}

export interface IExperienceFilters {
  scoops: IFilterOption[];
  priceRange: IFilterOption[];
  activities: IFilterOption[];
  brand: IFilterOption[];
}

export interface IExtendedExperienceFilters extends IExperienceFilters {
  favorites: [IFilterOption<boolean>];
}

export type ExperienceQuery = {
  [key in keyof IExtendedExperienceFilters]?: FilterValue[];
};

export interface IAppInitConfig {
  goals: IGoalItem[];
  peakHours: IPeakHoursItem[];
  onboardingCalendarTime: string[];
  contentCategories: string[];
  contentTypes: string[];
  countries: string[];
  states: string[];
}

export type ScoopsValue = 1 | 2 | 3;

export enum CalendarPeriod {
  DAY = "day",
  WEEK = "week",
  MONTH = "month",
  QUARTER = "quarter",
  CUSTOM = "custom",
}

export enum RecurringType {
  DAY = "day",
  WEEK = "week",
  MONTH = "month",
  QUARTER = "quarter",
  NA = "na",
}

export enum EventStatus {
  ACCEPTED = "accepted",
  BOOKED = "booked",
  BLOCKED = "blocked",
  CANCELED = "canceled",
}

export enum EventType {
  STANDARD = "standard",
  RECURRING = "recurring",
}

export interface INewEvent {
  start: string;
  end?: string;
  type: EventType;
  status: EventStatus;
  title?: string;
  recurring?: {
    repeatType: RecurringType;
  };
}

export interface IEvent extends INewEvent {
  desc?: string;
  scoopUnits?: ScoopsValue;
  eventId?: number;
  experienceId?: number;
  slug?: string;
  primaryImageUrl?: string;
  belongsToUser?: boolean;
}

export type HistorySortBy = "name" | "newest" | "oldest";

export type TransactionSortBy = "name" | "newest" | "oldest" | "priceLow" | "priceHigh";

export type CurrencyCodeType = "USD" | string; // ISO 4217

export interface ITransaction {
  name: string;
  date: string;
  category: string;
  type: "refund" | "capture";
  amount: number;
  currencyCode: CurrencyCodeType;
  id: string;
  mcc: string;
}

export interface IEventSchedule {
  start: string;
  end: string;
}

export interface IGetCalendarEventsParams {
  from?: string | Date;
  to?: string | Date;
  status?: EventStatus[];
  period?: CalendarPeriod;
  scoopSize?: ScoopsValue;
}

export interface IAllocationSlot {
  start: string;
  end: string;
  duration: string;
  count: number;
  calcedHoursDuration: number;
}

export interface IAllocationSlots {
  type: ScoopsValue;
  count: number;
  slots: IAllocationSlot[];
}

export interface IAllocationSlotsResponse {
  allocatedSlotsCount: IAllocationSlots[];
  isFullAllocated: boolean;
}

export type ICommunityMember = Pick<IUserInfo, "id" | "firstName" | "lastName" | "avatarUrl">;

export interface ICommunityMembersResponse {
  count: number;
  relatedUsers: ICommunityMember[];
}

export enum CouponStatus {
  NOT_APPLICABLE = "not_applicable",
  NOT_ALLOCATED = "not_allocated",
  ALLOCATED = "allocated",
}

export interface IBookSorbetResponse {
  eventId: number;
  experienceId: number;
  coupon: {
    status: CouponStatus;
    reason?: string;
    couponCode?: string;
    expiresAt?: string;
  };
}
