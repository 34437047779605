import dayjs, { Dayjs } from "dayjs";
import { qsService } from "services/qs";
import { pluralize } from "./formatters";
import { ROUTES } from "config/constants";
import { ExperienceQuery, FilterValue, RegionCodes, ScoopsValue } from "services/api/types";
import { ValueOf } from "./types";

type ValueArray<T> = (T | null)[];
type ReturnValue<T> = ValueArray<T>[];

export function chunkArray<T>(array: ValueArray<T>, size: number): ReturnValue<T> {
  const result: ReturnValue<T> = [];

  // chunk
  for (let i = 0; i < array.length; i += size) {
    const chunk = array.slice(i, i + size);
    result.push(chunk);
  }

  // fill remaining with nulls
  const lastChunk = result[result.length - 1];
  const left = size - lastChunk.length;
  for (let i = 0; i < left; i++) {
    lastChunk.push(null);
  }

  return result;
}

const experienceQueryKeys: (keyof ExperienceQuery)[] = [
  "activities",
  "brand",
  "favorites",
  "priceRange",
  "scoops",
];

/**
 * @param search search string from the url
 */
export function parseExperiencesQuery(search: string) {
  const parsed = qsService.parse(search) as {
    [key in keyof ExperienceQuery]?: FilterValue[] | FilterValue;
  };
  const filters: Partial<ExperienceQuery> = {};

  Object.entries(parsed).forEach(([key, values]) => {
    if (values && experienceQueryKeys.includes(key as keyof ExperienceQuery)) {
      filters[key as keyof ExperienceQuery] = Array.isArray(values) ? values : [values];
    }
  });
  return filters;
}

export function omit<T = object>(obj: T, keys: (keyof T)[]): T {
  const result: { [key in keyof T]?: ValueOf<T> } = {};

  Object.entries(obj).forEach(([key, value]) => {
    if (keys.includes(key as keyof T)) {
      return;
    }
    result[key as keyof T] = value;
  });

  return result as T;
}

export function omitBy<T>(obj: T, predicate: (v: ValueOf<T>) => boolean) {
  return Object.fromEntries(Object.entries(obj).filter(([, v]) => !predicate(v)));
}

export function pickBy<T>(obj: T, predicate: (v: ValueOf<T>) => boolean) {
  return Object.fromEntries(Object.entries(obj).filter(([, v]) => predicate(v)));
}

export function splitBy<T>(obj: T, predicate: (v: ValueOf<T>) => boolean) {
  return [pickBy<T>(obj, predicate), omitBy<T>(obj, predicate)];
}

export function applyTime(date: Dayjs, time?: Dayjs | null, withSeconds = false) {
  const ret = date.clone();
  if (time) {
    return ret
      .hour(time.hour())
      .minute(time.minute())
      .second(withSeconds ? time.second() : 0);
  }
  return ret;
}

// in accordance to CALENDAR_TIME_FORMAT
export function calendarTimeToDate(value: string): Dayjs | undefined {
  const match = value.match(/(\d{2}):(\d{2})\s(\S{2})/);
  if (!match) {
    return;
  }

  let [, h, m, a] = match;
  return dayjs()
    .hour(Number(h))
    .minute(Number(m))
    .second(0)
    .millisecond(0)
    .add(a === "PM" ? 12 : 0, "hour");
}

export function getScoopCountText(count?: ScoopsValue) {
  if (!count) {
    return "";
  }
  return count + " " + pluralize("Scoop", count);
}

export function getExperiencePageUrl(experienceId: number, slug?: string) {
  return `${ROUTES.EXPERIENCE}/${experienceId}/${slug}`;
}

export function convertTimeToScoops(start: string, end: string) {
  const diff = dayjs(end).diff(dayjs(start), "hour");
  if (diff < 3) {
    return 1;
  }
  if (diff > 6) {
    return 3;
  }
  return 2;
}

/**
 * Get popup container for the Antd dropdown
 */
export function getPopupContainer(el: HTMLElement) {
  return el.parentElement as HTMLElement;
}

/**
 * Get the index of the first day of the week according to timezone
 */
export function getWeekStartByTimezone(tz: string): 0 | 1 {
  if (tz === "Asia/Jerusalem") {
    return 0; // sunday
  }

  return 1; // monday
}

/**
 * Create an array range of numbers
 */
export function range(start: number, count: number): number[] {
  return Array(count)
    .fill(undefined)
    .map((_, idx) => start + idx);
}

/**
 * Safely gets the region value from the queryString
 * If the region is broken returns US-NY region
 */
export function parseRegion(query: string) {
  const { region } = qsService.parse(query);
  if (![RegionCodes.IL, RegionCodes.US_NY].includes(region)) {
    return RegionCodes.US_NY;
  }
  return region;
}
