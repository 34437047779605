import { makeAutoObservable, runInAction } from "mobx";
import _ from "underscore";
import { periodArr } from "../../calculator_feature/config/period copy";
import { randomData } from "../../calculator_feature/config/randomData";
import { years } from "../../calculator_feature/config/years";
import { MyRowType } from "./type";

export interface ICalculatorStore {
  baseUrl: string;
  sumOfannualTaxSaving: number;
  sumOfSorbetManages: number;
  sumOfImpactSorbet: number;
  net: number;
  setNet: () => void;
  sumSorbetManaged: number;
  savingPerUsd: number;
  totalSavings: number;
  resultInflation: number;
  resultFundingCost: number;

  backTrigger: number;
  /**
   * User data
   */
  firstName: string;
  companyEmail: string;
  companyName: string;
  lastName: string;
  recipientName: string;
  annualTaxSaving: number;
  setAnnualTaxSaving: (value: number) => void;
  setWageInflationProtection: (value: number) => void;
  email: string;
  /**
   * Step progress
   */
  usedPaidTime: number;
  postFactoring: number;
  taxDeduction: number;
  impactSorbet: number;
  cohortYear1: number;
  cohortYear2: number;
  cohortYear3: number;
  cohortYear4: number;
  cohortYear5: number;
  cohortYear6: number;
  cohortYear7: number;
  cohortYear8: number;
  cohortYear9: number;
  cohortYear10: number;

  sudoMockYear1: number;
  sudoMockYear2: number;
  sudoMockYear3: number;
  sudoMockYear4: number;
  sudoMockYear5: number;
  sudoMockYear6: number;
  sudoMockYear7: number;
  sudoMockYear8: number;
  sudoMockYear9: number;
  sudoMockYear10: number;
  wageInflationProtection: number;
  setImpactSorbet: (value: number) => void;
  setTaxDeduction: (value: number) => void;
  setPostFactoring: (value: number) => void;

  progress: number;
  annualFundingCost: number;
  setAnnualFundingCost: (value: number) => void;
  lastVacationDay: number;
  carryOver: number;
  overLiability: number;
  setOverLiability: (value: number) => void;

  lastRemainingLiability: number;
  setLastRemainingLiability: (value: number) => void;

  /**
   * Step industry
   */
  tenure: number;
  sorbetManaged: number;
  setSorbetManaged: (value: number) => void;
  /**
   * Step progress
   */
  industry: string;

  /**
   * Step employees
   */
  employees: number;

  /**
   * Step taxRate
   */
  taxRate: number;
  /**
   * Step avarageSalary
   */
  avarageSalary: number;
  rolloverName: string;

  liabilitySorber_G14: number;
  /**
   * Sliders
   */
  avarageSalarySlider: number;
  companyLiability: number;
  liabilityDecrease: number;
  remainingLiability: number;
  rolloverSlider: number;
  paidTimeSlider: number;
  avarageSlider: number;
  /**
   * Change progress
   */

  /**
   * calculating data types 1 row
   *
   */
  quarter: number;
  period: number;
  alowedCarryOver: number;
  setAlowedCarryOver: (value: number) => void;
  period2: number;
  howLong: number;
  setHowLong: (value: number) => void;
  whenRepay: number;
  setWhenRepay: (value: number) => void;
  setAccrualDays_G23: (value: number) => void;
  setAccrualDays_AG23: (value: number) => void;
  setCompanyLiability: (value: number) => void;
  // setCompanyLiability2: (value: number) => void;
  setLiabilityDecrease: (value: number) => void;
  setRemainingLiability: (value: number) => void;
  setEndingDayValue: (value: number) => void;
  accrualDays_AG23: number;
  setPeriod: (value: number) => void;
  setYears: (value: number) => void;
  setPeriod2: (value: number) => void;
  setCarryOver: (value: number) => void;
  initialBalance_A13: number;
  endingDayValue: number;
  initialBalance_A14: number;

  initialBalance_AE24: number;
  initialBalance_AE23: number;

  remainingDays_AJ23: number;
  setInitialBalance_AE23: (value: number) => void;
  // setInitialBalance_AE24: (value: number) => void;

  setRemainingDays_AJ23: (value: number) => void;

  setInitialBalance_A13: (value: number) => void;
  setInitialBalance_A14: (value: number) => void;
  vacationDays_AF23: number;
  vacationDays_F23: number;
  periodWithout_AE23: number;
  setPeriodWithout_AE23: (value: number) => void;
  setVacationDays_F23: (value: number) => void;
  setVacationDays_AF23: (value: number) => void;
  accrualDays_G23: number;
  yearsData: number;
  // setAccrualDays_G23: (value: number) => void;
  outstanding_H23: number;
  setOutstanding_H23: (value: number) => void;
  outstanding_AH23: number;
  setOutstanding_AH23: (value: number) => void;
  setLiabilitySlider: (value: number) => void;

  daysBoughtbySorbet_1q: number;

  // setDaysBoughtbySorbet_1q: (value: number) => void;

  remainingDays_J23: number;
  setRemainingDays: (value: number) => void;

  setPeopleLeaving: (value: number) => void;
  setPeopleLeaving_AK23: (value: number) => void;

  peopleLeaving_K23: number;
  peopleLeaving_AK23: number;

  costPerDay_N23: number;
  costPerDay_AN23: number;
  setCostPerDay: (value: number) => void;
  setCostPerDay_AN23: (value: number) => void;
  pow_C23: number;
  pow_AC23: number;
  payment_O23: number;
  payment_AO23: number;
  setPayment: (value: number) => void;
  setPayment_AO23: (value: number) => void;

  interestRate_P23: number;
  setInterestRate_P23: (value: number) => void;

  suda1year: number;
  suda2year: number;
  suda3year: number;
  suda4year: number;
  suda5year: number;
  suda6year: number;
  suda7year: number;
  suda8year: number;
  suda9year: number;
  suda10year: number;
  cell1year: number;
  cell2year: number;
  cell3year: number;
  cell4year: number;
  cell5year: number;
  cell6year: number;
  cell7year: number;
  cell8year: number;
  cell9year: number;
  cell10year: number;
  setSuda1year: (value: number) => void;
  setSuda2year: (value: number) => void;
  setSuda3year: (value: number) => void;
  setSuda4year: (value: number) => void;
  setSuda5year: (value: number) => void;
  setSuda6year: (value: number) => void;
  setSuda7year: (value: number) => void;
  setSuda8year: (value: number) => void;
  setSuda9year: (value: number) => void;
  setSuda10year: (value: number) => void;

  setCell1year: (value: number) => void;
  setCell2year: (value: number) => void;
  setCell3year: (value: number) => void;
  setCell4year: (value: number) => void;
  setCell5year: (value: number) => void;
  setCell6year: (value: number) => void;
  setCell7year: (value: number) => void;
  setCell8year: (value: number) => void;
  setCell9year: (value: number) => void;
  setCell10year: (value: number) => void;
  setCohortYear1: (value: number) => void;
  setCohortYear2: (value: number) => void;
  setCohortYear3: (value: number) => void;
  setCohortYear4: (value: number) => void;
  setCohortYear5: (value: number) => void;
  setCohortYear6: (value: number) => void;
  setCohortYear7: (value: number) => void;
  setCohortYear8: (value: number) => void;
  setCohortYear9: (value: number) => void;
  setCohortYear10: (value: number) => void;

  totalCell: number;
  // setFirstLoop: () => void;
  setTotalCell: (value: number) => void;
  cashFloWithSorbet: number;
  // setFirstLoop: () => void;
  setCashFloWithSorbet: (value: number) => void;

  dataRow: MyRowType[];
  sorbetYearsRow: any[];
  withOutRow: any[];
  lastChartRow: any[];

  // end calculating data types

  daysBoughtbySorbet_2q: number;
  daysBoughtbySorbet_3q: number;
  daysBoughtbySorbet_4q: number;

  increaseProgress: (value: number) => void;

  /**
   * Change tenure
   */
  setTenure: (value: number) => void;
  setTaxRate: (value: number) => void;
  setAvarageSalary: (value: number) => void;

  setRolloverName: (value: string) => void;

  /**
   * sliders hanglers
   */
  setAvarageSalarySlider: (value: number) => void;
  setRolloverSlider: (value: number) => void;
  setPaidTimeSlider: (value: number) => void;
  setAvarageSlider: (value: number) => void;

  /**
   * Change tenure
   */
  setEmployees: (value: number) => void;
  setBackTrigger: (value: number) => void;

  /**
   * set UserData
   */
  setFirstName: (value: string) => void;
  setLastName: (value: string) => void;
  setCompanyEmail: (value: string) => void;
  setCompanyName: (value: string) => void;
  setRecipientName: (value: string) => void;
  setEmail: (value: string) => void;

  /**
   * Change industry
   */
  setIndustry: (value: any) => void;
  setBaseUrl: (value: any) => void;

  /**
   *FORMULAS middleware
   */
  // initialBalance: any;

  /**
   * Clear all fields
   */
  clearStore: () => void;
  firstLoop: () => void;
  secondLoop: () => void;
  thirdLoop: () => void;
  fourthLoop: () => void;
  getSumOfTaxSaving: () => void;
  getSumOfSorbetManages: () => void;
  getSumOfImpactSorbet: () => void;
  getResultInflation: () => void;
  getResultFundingCost: () => void;
  getTotalSavings: () => void;
  getSavingPerUsd: () => void;
  getSumSorbetManaged: () => void;
  getNet: () => void;
  getSorbetYearsRow: () => void;
  getWithOutRow: () => void;
}

class Calculator implements ICalculatorStore {
  // pocStore = [];

  progress = 0;

  backTrigger = 0;

  test = 0;
  wageInflationProtection = 0;
  totalSavings = 0;

  industry = "US average";
  firstName = "";
  lastName = "";
  recipientName = "";
  email = "";
  companyEmail = "";
  companyName = "";

  /**
   * sliders
   */
  avarageSlider = 0.72;

  /**
   * inputs values
   */
  period = 1.1; // mock
  period2 = 1.1; // mock

  periodWithout_AE23 = 1.1; // mock
  usedPaidTime = 0.72;

  employees = 500;
  avarageSalarySlider = 0.033;

  // avarageSalarySlider = 0;
  timerId: any = null;

  rolloverName = "days";
  rolloverSlider = 10;
  tenure = 4.1;
  impactSorbet = 0;
  taxRate = 0.26;
  paidTimeSlider = 15;
  howLong = 10;
  whenRepay = 2;

  liabilitySorber_G14 = 33; // mock
  quarter = 0;
  interestRate_P23 = 0;
  yearsData = 0;
  sorbetManaged = 0;
  resultInflation = 0;

  avarageSalary = 56000;
  /**
   * calculating 1 row
   */
  initialBalance_A13 = 0;
  initialBalance_A14 = 0;
  initialBalance_AE23 = 0;
  initialBalance_AE24 = 0;
  vacationDays_AF23 = 0;
  vacationDays_F23 = 0;
  accrualDays_G23 = 0;
  accrualDays_AG23 = 0;
  outstanding_H23 = 0;
  outstanding_AH23 = 0;
  daysBoughtbySorbet_1q = 0;
  remainingDays_J23 = 0;
  postFactoring = 0;

  cohortYear1 = 0;
  cohortYear2 = 0;
  cohortYear3 = 0;
  cohortYear4 = 0;
  cohortYear5 = 0;
  cohortYear6 = 0;
  cohortYear7 = 0;
  cohortYear8 = 0;
  cohortYear9 = 0;
  cohortYear10 = 0;
  sumOfannualTaxSaving = 0;
  baseUrl = "";
  sumOfSorbetManages = 0;
  sumOfImpactSorbet = 0;
  lastRemainingLiability = 0;
  remainingDays_AJ23 = 0;
  peopleLeaving_K23 = 0;
  peopleLeaving_AK23 = 0;
  costPerDay_N23 = 0;
  endingDayValue = 0;
  costPerDay_AN23 = 0;
  companyLiability = 5;
  overLiability = 0;
  annualFundingCost = 0;
  liabilityDecrease = 0;
  remainingLiability = 0;
  payment_O23 = 0;
  taxDeduction = 0;
  payment_AO23 = 0;
  alowedCarryOver = 0;
  sudoMockYear1 = 1;
  sudoMockYear2 = 2;
  sudoMockYear3 = 3;
  sudoMockYear4 = 4;
  sudoMockYear5 = 5;
  sudoMockYear6 = 6;
  sudoMockYear7 = 7;
  sudoMockYear8 = 8;
  sudoMockYear9 = 9;
  sudoMockYear10 = 10;

  suda1year = 0;
  suda2year = 0;
  suda3year = 0;
  suda4year = 0;
  suda5year = 0;
  suda6year = 0;
  suda7year = 0;
  suda8year = 0;
  suda9year = 0;
  suda10year = 0;
  lastVacationDay = 0;
  savingPerUsd = 0;

  net = 0;
  carryOver = 0;

  cell1year = 0;
  cell2year = 0;
  cell3year = 0;
  cell4year = 0;
  cell5year = 0;
  cell6year = 0;
  cell7year = 0;
  cell8year = 0;
  cell9year = 0;
  cell10year = 0;
  totalCell = 0;
  cashFloWithSorbet = 0;
  sumSorbetManaged = 0;
  annualTaxSaving = 0;
  dataRow = [] as any;
  sorbetYearsRow = [] as any;
  withOutRow = [] as any;
  lastChartRow = [] as any;

  //next lines
  daysBoughtbySorbet_2q = 0;
  daysBoughtbySorbet_3q = 0;
  daysBoughtbySorbet_4q = 0;
  pow_C23 = 0;
  pow_AC23 = 0;
  resultFundingCost = 0;
  constructor() {
    makeAutoObservable(this, {}, { deep: true });
  }
  setAccrualDays_G23: (value: number) => void;

  // loops

  get getNet(): any {
    const netArr = this.lastChartRow.map((item: any) => Math.ceil(item.net));
    return netArr;
  }
  get getSorbetYearsRow(): any {
    const sorbetYearsRowAria = this.sorbetYearsRow.map((item: any) =>
      Math.ceil(item.cashFloWithSorbet),
    );
    return sorbetYearsRowAria;
  }
  get getWithOutRow(): any {
    const getWithOutRowAria = this.withOutRow.map((item: any) => Math.ceil(item.payment_AO23));
    return getWithOutRowAria;
  }
  get getSumOfTaxSaving(): any {
    const newArr = this.lastChartRow.map((item: any) => {
      return item.annualTaxSaving;
    });
    return runInAction(() => {
      return (this.sumOfannualTaxSaving = newArr.reduce((i: number, ac: number) => {
        return i + ac;
      }, 0));
    });
  }
  get getSumOfSorbetManages(): any {
    const newArr = this.lastChartRow.map((item: any) => {
      return item.sorbetManaged;
    });
    return runInAction(() => {
      return (this.sumOfSorbetManages = newArr.reduce((i: number, ac: number) => {
        return i + ac;
      }, 0));
    });
  }

  get getSumOfImpactSorbet(): any {
    const newArr = this.lastChartRow.map((item: any) => {
      return item.impactSorbet;
    });
    return runInAction(() => {
      return (this.sumOfImpactSorbet = newArr.reduce((i: number, ac: number) => {
        return i + ac;
      }, 0));
    });
  }

  get getSumSorbetManaged(): any {
    return runInAction(() => {
      return (this.sumSorbetManaged =
        this.suda1year +
        this.suda2year +
        this.suda3year +
        this.suda4year +
        this.suda5year +
        this.suda6year +
        this.suda7year +
        this.suda8year +
        this.suda9year +
        this.suda10year);
    });
  }
  get getSavingPerUsd(): any {
    return runInAction(() => {
      return (this.savingPerUsd = +(
        Math.pow(1 + this.avarageSalarySlider, this.tenure) - 1
      ).toFixed(2));
    });
    // return runInAction(() => {
    //   return (this.savingPerUsd = this.totalSavings / this.sumSorbetManaged);
    // });
  }
  get getTotalSavings(): any {
    //
    return runInAction(() => {
      return (this.totalSavings = Number(
        (
          Number(this.sumOfannualTaxSaving) +
          Number(this.resultInflation) -
          Number(this.resultFundingCost)
        ).toFixed(0),
      ));
    });
  }
  get getResultInflation(): any {
    //
    const newArr = this.lastChartRow.map((item: any) => {
      return item.wageInflationProtection;
    });
    return runInAction(() => {
      return (this.resultInflation = newArr.reduce((i: number, ac: number) => {
        return i + ac;
      }, 0));
    });
  }
  get getResultFundingCost(): any {
    const newArr = this.lastChartRow.map((item: any) => {
      return item.annualFundingCost;
    });
    return runInAction(() => {
      return (this.resultFundingCost = newArr.reduce((i: number, ac: number) => {
        return i + ac;
      }, 0));
    });
  }

  get firstLoop(): any {
    if (this.dataRow.length > 0) {
      this.dataRow = [];
    }
    for (let i = 0; i < periodArr.length; i++) {
      this.setPeriod(periodArr[i]);
      if (this.period === 1.1) {
        const row1 = {
          period: periodArr[i],
          initialBalance_A13: this.setInitialBalance_A13(),
          vacationDays_F23: this.setVacationDays_F23(),
          accrualDays_G23: this.setAccrualDays(),
          outstanding_H23: this.setOutstanding_H23(),
          daysBoughtbySorbet_1q: this.setDaysBoughtbySorbet(),
          remainingDays_J23: this.setRemainingDays(),
          peopleLeaving_K23: this.setPeopleLeaving(),
          costPerDay_N23: this.setCostPerDay(),
          payment_O23: this.setPayment(),
          interestRate_P23: this.setInterestRate_P23(),
        };

        runInAction(() => {
          return this.dataRow.push(row1);
        });
      } else {
        const row1 = {
          period: periodArr[i],
          initialBalance_A14: this.setInitialBalance_A14(),
          vacationDays_F23: this.setVacationDays_F23(),
          accrualDays_G23: this.setAccrualDays(),
          outstanding_H23: this.setOutstanding_H23(),
          daysBoughtbySorbet_1q: this.setDaysBoughtbySorbet(),
          remainingDays_J23: this.setRemainingDays(),
          peopleLeaving_K23: this.setPeopleLeaving(),
          costPerDay_N23: this.setCostPerDay(),
          payment_O23: this.setPayment(),
          interestRate_P23: this.setInterestRate_P23(),
        };
        runInAction(() => {
          return this.dataRow.push(row1);
        });
      }
    }
    return null;
  }

  get secondLoop(): any {
    //
    if (this.sorbetYearsRow.length > 0) {
      runInAction(() => (this.sorbetYearsRow = []));
    }
    this.setSuda1year();
    this.setSuda2year();
    this.setSuda3year();
    this.setSuda4year();
    this.setSuda5year();
    this.setSuda6year();
    this.setSuda7year();
    this.setSuda8year();
    this.setSuda9year();
    this.setSuda10year();

    for (let i = 0; i < periodArr.length; i++) {
      let period = periodArr[i];
      this.setPeriod2(period);
      if (this.period2 <= 1.4) {
        const row = {
          period: periodArr[i],
          cell1: this.setCell1year(),
          cell2: this.setCell2year(),
          cell3: this.setCell3year(),
          cell4: this.setCell4year(),
          cell5: this.setCell5year(),
          cell6: this.setCell6year(),
          cell7: this.setCell7year(),
          cell8: this.setCell8year(),
          cell9: this.setCell9year(),
          cell10: this.setCell10year(),
          total: this.setTotalCell(),
          cashFloWithSorbet: this.setCashFloWithSorbet(),
        };
        runInAction(() => {
          return this.sorbetYearsRow.push(row);
        });
      } else {
        const row = {
          period: periodArr[i],
          cell1: this.setCell1year(),
          cell2: this.setCell2year(),
          cell3: this.setCell3year(),
          cell4: this.setCell4year(),
          cell5: this.setCell5year(),
          cell6: this.setCell6year(),
          cell7: this.setCell7year(),
          cell8: this.setCell8year(),
          cell9: this.setCell9year(),
          cell10: this.setCell10year(),
          total: this.setTotalCell(),
          cashFloWithSorbet: this.setCashFloWithSorbet(),
        };
        runInAction(() => {
          return this.sorbetYearsRow.push(row);
        });
      }
    }
    return null;
  }
  //LAST CHART

  get fourthLoop(): any {
    if (this.lastChartRow.length > 0) {
      this.lastChartRow = [];
    }
    this.cohortYear1 = 0;
    this.cohortYear2 = 0;
    this.cohortYear3 = 0;
    this.cohortYear4 = 0;
    this.cohortYear5 = 0;
    this.cohortYear6 = 0;
    this.cohortYear7 = 0;
    this.cohortYear8 = 0;
    this.cohortYear9 = 0;
    this.cohortYear10 = 0;
    for (let i = 0; i < years.length; i++) {
      this.setYears(years[i]);
      if (this.yearsData === 1) {
        const row1 = {
          years: years[i],
          carryOver: this.setCarryOver(),
          companyLiability: this.setCompanyLiability(),
          liabilityDecrease: this.setLiabilityDecrease(),
          endingDayValue: this.setEndingDayValue(),
          lastRemainingLiability: this.setLastRemainingLiability(),
          sorbetManaged: this.setSorbetManaged(),
          postFactoring: this.setPostFactoring(),
          alowedCarryOver: this.setAlowedCarryOver(),
          overLiability: this.setOverLiability(),
          taxDeduction: this.setTaxDeduction(),
          impactSorbet: this.setImpactSorbet(),
          annualFundingCost: this.setAnnualFundingCost(),
          annualTaxSaving: this.setAnnualTaxSaving(),
          cohortYear1: this.setCohortYear1(),
          cohortYear2: this.setCohortYear2(),
          cohortYear3: this.setCohortYear3(),
          cohortYear4: this.setCohortYear4(),
          cohortYear5: this.setCohortYear5(),
          cohortYear6: this.setCohortYear6(),
          cohortYear7: this.setCohortYear7(),
          cohortYear8: this.setCohortYear8(),
          cohortYear9: this.setCohortYear9(),
          cohortYear10: this.setCohortYear10(),
          wageInflationProtection: this.setWageInflationProtection(),
          net: this.setNet(),
        };
        runInAction(() => {
          return this.lastChartRow.push(row1);
        });
      } else {
        const row1 = {
          years: years[i],
          carryOver: this.setCarryOver(),
          companyLiability: this.setCompanyLiability(),
          liabilityDecrease: this.setLiabilityDecrease(),
          endingDayValue: this.setEndingDayValue(),
          lastRemainingLiability: this.setLastRemainingLiability(),
          sorbetManaged: this.setSorbetManaged(),
          postFactoring: this.setPostFactoring(),
          alowedCarryOver: this.setAlowedCarryOver(),
          overLiability: this.setOverLiability(),
          taxDeduction: this.setTaxDeduction(),
          impactSorbet: this.setImpactSorbet(),
          annualFundingCost: this.setAnnualFundingCost(),
          annualTaxSaving: this.setAnnualTaxSaving(),
          cohortYear1: this.setCohortYear1(),
          cohortYear2: this.setCohortYear2(),
          cohortYear3: this.setCohortYear3(),
          cohortYear4: this.setCohortYear4(),
          cohortYear5: this.setCohortYear5(),
          cohortYear6: this.setCohortYear6(),
          cohortYear7: this.setCohortYear7(),
          cohortYear8: this.setCohortYear8(),
          cohortYear9: this.setCohortYear9(),
          cohortYear10: this.setCohortYear10(),
          wageInflationProtection: this.setWageInflationProtection(),
          net: this.setNet(),
        };
        runInAction(() => {
          return this.lastChartRow.push(row1);
        });
      }
    }
    return null;
  }
  setNet() {
    return runInAction(() => {
      return (this.net =
        // this.annualTaxSaving + this.wageInflationProtection - this.annualFundingCost);
        this.wageInflationProtection - this.annualFundingCost);
    });
  }

  setWageInflationProtection() {
    return runInAction(() => {
      return (this.wageInflationProtection =
        this.cohortYear1 +
        this.cohortYear2 +
        this.cohortYear3 +
        this.cohortYear4 +
        this.cohortYear5 +
        this.cohortYear6 +
        this.cohortYear7 +
        this.cohortYear8 +
        this.cohortYear9 +
        this.cohortYear10);
    });
  }
  setCohortYear1() {
    if (this.yearsData === 1) {
      const inflationArr = [];
      inflationArr.push(this.tenure);
      inflationArr.push(this.yearsData);
      const sortedInflationArr = inflationArr.sort((a: any, b: any) => a - b);
      const leftSideExpression = 1 + this.avarageSalarySlider;
      return runInAction(() => {
        return (this.cohortYear1 =
          this.suda1year * Math.pow(leftSideExpression, sortedInflationArr[0]) -
          this.suda1year * Math.pow(leftSideExpression, 0));
      });
    } else {
      const inflationArr = [];
      const rightInflationArr = [];

      inflationArr.push(this.tenure);
      rightInflationArr.push(this.tenure);
      inflationArr.push(this.yearsData);

      const myIndex = years[years.indexOf(this.yearsData) - 1];
      const prewData = this.lastChartRow.find((item: any) => item.years === myIndex);
      rightInflationArr.push(prewData.years);

      const leftSideExpression = 1 + this.avarageSalarySlider;
      const sortedInflationArr = inflationArr.sort((a: any, b: any) => a - b);
      const sortedInflationArr2 = rightInflationArr.sort((a: any, b: any) => a - b);
      return runInAction(() => {
        return (this.cohortYear1 =
          this.suda1year * Math.pow(leftSideExpression, sortedInflationArr[0]) -
          this.suda1year * Math.pow(leftSideExpression, sortedInflationArr2[0]));
      });
    }
  }

  setCohortYear2() {
    if (this.yearsData === 2) {
      const rightInflationArr = [];

      rightInflationArr.push(this.tenure);

      const myIndex = years[years.indexOf(this.yearsData) - 1];
      const myIndex2 = years[years.indexOf(this.yearsData) - 2];
      const prewData = this.lastChartRow.find((item: any) => item.years === myIndex);
      const prewData2 = this.lastChartRow.find((item: any) => item.years === myIndex2);

      const inflationArr = [];
      inflationArr.push(this.tenure);
      inflationArr.push(prewData.years);
      // }
      if (prewData2 === undefined) {
        rightInflationArr.push(0);
      } else {
        rightInflationArr.push(prewData2.years);
      }

      const leftSideExpression = 1 + this.avarageSalarySlider;
      const sortedInflationArr = inflationArr.sort((a: any, b: any) => a - b);
      const sortedInflationArr2 = rightInflationArr.sort((a: any, b: any) => a - b);
      return runInAction(() => {
        return (this.cohortYear2 =
          this.suda2year * Math.pow(leftSideExpression, sortedInflationArr[0]) -
          this.suda2year * Math.pow(leftSideExpression, sortedInflationArr2[0]));
      });
    } else {
      const rightInflationArr = [];

      rightInflationArr.push(this.tenure);

      const myIndex = years[years.indexOf(this.yearsData) - 1];
      const myIndex2 = years[years.indexOf(this.yearsData) - 2];
      const prewData = this.lastChartRow.find((item: any) => item.years === myIndex);
      const prewData2 = this.lastChartRow.find((item: any) => item.years === myIndex2);

      const inflationArr = [];
      inflationArr.push(this.tenure);
      if (prewData === undefined) {
        return 0;
      } else {
        inflationArr.push(prewData.years);
      }
      if (prewData2 === undefined) {
        return 0;
      } else {
        rightInflationArr.push(prewData2.years);
      }

      const leftSideExpression = 1 + this.avarageSalarySlider;
      const sortedInflationArr = inflationArr.sort((a: any, b: any) => a - b);
      const sortedInflationArr2 = rightInflationArr.sort((a: any, b: any) => a - b);
      return runInAction(() => {
        return (this.cohortYear2 =
          this.suda2year * Math.pow(leftSideExpression, sortedInflationArr[0]) -
          this.suda2year * Math.pow(leftSideExpression, sortedInflationArr2[0]));
      });
    }
  }
  setCohortYear3() {
    if (this.yearsData === 3) {
      const rightInflationArr = [];

      rightInflationArr.push(this.tenure);

      const myIndex = years[years.indexOf(this.yearsData) - 2];
      const myIndex2 = years[years.indexOf(this.yearsData) - 3];
      const prewData = this.lastChartRow.find((item: any) => item.years === myIndex);
      const prewData2 = this.lastChartRow.find((item: any) => item.years === myIndex2);

      const inflationArr = [];
      inflationArr.push(this.tenure);
      inflationArr.push(prewData.years);
      if (prewData2 === undefined) {
        rightInflationArr.push(0);
      } else {
        rightInflationArr.push(prewData2.years);
      }
      const leftSideExpression = 1 + this.avarageSalarySlider;
      const sortedInflationArr = inflationArr.sort((a: any, b: any) => a - b);
      const sortedInflationArr2 = rightInflationArr.sort((a: any, b: any) => a - b);
      return runInAction(() => {
        return (this.cohortYear3 =
          this.suda3year * Math.pow(leftSideExpression, sortedInflationArr[0]) -
          this.suda3year * Math.pow(leftSideExpression, sortedInflationArr2[0]));
      });
    } else {
      const rightInflationArr = [];

      rightInflationArr.push(this.tenure);

      const myIndex = years[years.indexOf(this.yearsData) - 2];
      const myIndex2 = years[years.indexOf(this.yearsData) - 3];
      const prewData = this.lastChartRow.find((item: any) => item.years === myIndex);
      const prewData2 = this.lastChartRow.find((item: any) => item.years === myIndex2);

      const inflationArr = [];
      inflationArr.push(this.tenure);
      if (prewData === undefined) {
        return 0;
      } else {
        inflationArr.push(prewData.years);
      }
      if (prewData2 === undefined) {
        return 0;
      } else {
        rightInflationArr.push(prewData2.years);
      }

      const leftSideExpression = 1 + this.avarageSalarySlider;
      const sortedInflationArr = inflationArr.sort((a: any, b: any) => a - b);
      const sortedInflationArr2 = rightInflationArr.sort((a: any, b: any) => a - b);
      return runInAction(() => {
        return (this.cohortYear3 =
          this.suda3year * Math.pow(leftSideExpression, sortedInflationArr[0]) -
          this.suda3year * Math.pow(leftSideExpression, sortedInflationArr2[0]));
      });
    }
  }
  setCohortYear4() {
    if (this.yearsData === 4) {
      const rightInflationArr = [];

      rightInflationArr.push(this.tenure);

      const myIndex = years[years.indexOf(this.yearsData) - 3];
      const myIndex2 = years[years.indexOf(this.yearsData) - 4];
      const prewData = this.lastChartRow.find((item: any) => item.years === myIndex);
      const prewData2 = this.lastChartRow.find((item: any) => item.years === myIndex2);

      const inflationArr = [];
      inflationArr.push(this.tenure);
      inflationArr.push(prewData.years);
      if (prewData2 === undefined) {
        rightInflationArr.push(0);
      } else {
        rightInflationArr.push(prewData2.years);
      }
      const leftSideExpression = 1 + this.avarageSalarySlider;
      const sortedInflationArr = inflationArr.sort((a: any, b: any) => a - b);
      const sortedInflationArr2 = rightInflationArr.sort((a: any, b: any) => a - b);
      return runInAction(() => {
        return (this.cohortYear4 =
          this.suda4year * Math.pow(leftSideExpression, sortedInflationArr[0]) -
          this.suda4year * Math.pow(leftSideExpression, sortedInflationArr2[0]));
      });
    } else {
      const rightInflationArr = [];

      rightInflationArr.push(this.tenure);

      const myIndex = years[years.indexOf(this.yearsData) - 3];
      const myIndex2 = years[years.indexOf(this.yearsData) - 4];
      const prewData = this.lastChartRow.find((item: any) => item.years === myIndex);
      const prewData2 = this.lastChartRow.find((item: any) => item.years === myIndex2);

      const inflationArr = [];
      inflationArr.push(this.tenure);
      if (prewData === undefined) {
        return 0;
      } else {
        inflationArr.push(prewData.years);
      }
      if (prewData2 === undefined) {
        return 0;
      } else {
        rightInflationArr.push(prewData2.years);
      }

      const leftSideExpression = 1 + this.avarageSalarySlider;
      const sortedInflationArr = inflationArr.sort((a: any, b: any) => a - b);
      const sortedInflationArr2 = rightInflationArr.sort((a: any, b: any) => a - b);
      return runInAction(() => {
        return (this.cohortYear4 =
          this.suda4year * Math.pow(leftSideExpression, sortedInflationArr[0]) -
          this.suda4year * Math.pow(leftSideExpression, sortedInflationArr2[0]));
      });
    }
  }
  setCohortYear5() {
    if (this.yearsData === 5) {
      const rightInflationArr = [];

      rightInflationArr.push(this.tenure);

      const myIndex = years[years.indexOf(this.yearsData) - 4];
      const myIndex2 = years[years.indexOf(this.yearsData) - 5];
      const prewData = this.lastChartRow.find((item: any) => item.years === myIndex);
      const prewData2 = this.lastChartRow.find((item: any) => item.years === myIndex2);

      const inflationArr = [];
      inflationArr.push(this.tenure);
      inflationArr.push(prewData.years);
      if (prewData2 === undefined) {
        rightInflationArr.push(0);
      } else {
        rightInflationArr.push(prewData2.years);
      }
      const leftSideExpression = 1 + this.avarageSalarySlider;
      const sortedInflationArr = inflationArr.sort((a: any, b: any) => a - b);
      const sortedInflationArr2 = rightInflationArr.sort((a: any, b: any) => a - b);
      return runInAction(() => {
        return (this.cohortYear5 =
          this.suda5year * Math.pow(leftSideExpression, sortedInflationArr[0]) -
          this.suda5year * Math.pow(leftSideExpression, sortedInflationArr2[0]));
      });
    } else {
      const rightInflationArr = [];

      rightInflationArr.push(this.tenure);

      const myIndex = years[years.indexOf(this.yearsData) - 4];
      const myIndex2 = years[years.indexOf(this.yearsData) - 5];
      const prewData = this.lastChartRow.find((item: any) => item.years === myIndex);
      const prewData2 = this.lastChartRow.find((item: any) => item.years === myIndex2);

      const inflationArr = [];
      inflationArr.push(this.tenure);
      if (prewData === undefined) {
        return 0;
      } else {
        inflationArr.push(prewData.years);
      }
      if (prewData2 === undefined) {
        return 0;
      } else {
        rightInflationArr.push(prewData2.years);
      }

      const leftSideExpression = 1 + this.avarageSalarySlider;
      const sortedInflationArr = inflationArr.sort((a: any, b: any) => a - b);
      const sortedInflationArr2 = rightInflationArr.sort((a: any, b: any) => a - b);
      return runInAction(() => {
        return (this.cohortYear5 =
          this.suda5year * Math.pow(leftSideExpression, sortedInflationArr[0]) -
          this.suda5year * Math.pow(leftSideExpression, sortedInflationArr2[0]));
      });
    }
  }
  setCohortYear6() {
    if (this.yearsData === 6) {
      const rightInflationArr = [];

      rightInflationArr.push(this.tenure);

      const myIndex = years[years.indexOf(this.yearsData) - 5];
      const myIndex2 = years[years.indexOf(this.yearsData) - 6];
      const prewData = this.lastChartRow.find((item: any) => item.years === myIndex);
      const prewData2 = this.lastChartRow.find((item: any) => item.years === myIndex2);

      const inflationArr = [];
      inflationArr.push(this.tenure);
      inflationArr.push(prewData.years);
      if (prewData2 === undefined) {
        rightInflationArr.push(0);
      } else {
        rightInflationArr.push(prewData2.years);
      }
      const leftSideExpression = 1 + this.avarageSalarySlider;
      const sortedInflationArr = inflationArr.sort((a: any, b: any) => a - b);
      const sortedInflationArr2 = rightInflationArr.sort((a: any, b: any) => a - b);
      return runInAction(() => {
        return (this.cohortYear6 =
          this.suda6year * Math.pow(leftSideExpression, sortedInflationArr[0]) -
          this.suda6year * Math.pow(leftSideExpression, sortedInflationArr2[0]));
      });
    } else {
      const rightInflationArr = [];

      rightInflationArr.push(this.tenure);

      const myIndex = years[years.indexOf(this.yearsData) - 5];
      const myIndex2 = years[years.indexOf(this.yearsData) - 6];
      const prewData = this.lastChartRow.find((item: any) => item.years === myIndex);
      const prewData2 = this.lastChartRow.find((item: any) => item.years === myIndex2);

      const inflationArr = [];
      inflationArr.push(this.tenure);
      if (prewData === undefined) {
        return 0;
      } else {
        inflationArr.push(prewData.years);
      }
      if (prewData2 === undefined) {
        return 0;
      } else {
        rightInflationArr.push(prewData2.years);
      }

      const leftSideExpression = 1 + this.avarageSalarySlider;
      const sortedInflationArr = inflationArr.sort((a: any, b: any) => a - b);
      const sortedInflationArr2 = rightInflationArr.sort((a: any, b: any) => a - b);
      return runInAction(() => {
        return (this.cohortYear6 =
          this.suda6year * Math.pow(leftSideExpression, sortedInflationArr[0]) -
          this.suda6year * Math.pow(leftSideExpression, sortedInflationArr2[0]));
      });
    }
  }
  setCohortYear7() {
    if (this.yearsData === 7) {
      const rightInflationArr = [];

      rightInflationArr.push(this.tenure);

      const myIndex = years[years.indexOf(this.yearsData) - 6];
      const myIndex2 = years[years.indexOf(this.yearsData) - 7];
      const prewData = this.lastChartRow.find((item: any) => item.years === myIndex);
      const prewData2 = this.lastChartRow.find((item: any) => item.years === myIndex2);

      const inflationArr = [];
      inflationArr.push(this.tenure);
      inflationArr.push(prewData.years);
      if (prewData2 === undefined) {
        rightInflationArr.push(0);
      } else {
        rightInflationArr.push(prewData2.years);
      }
      const leftSideExpression = 1 + this.avarageSalarySlider;
      const sortedInflationArr = inflationArr.sort((a: any, b: any) => a - b);
      const sortedInflationArr2 = rightInflationArr.sort((a: any, b: any) => a - b);
      return runInAction(() => {
        return (this.cohortYear7 =
          this.suda7year * Math.pow(leftSideExpression, sortedInflationArr[0]) -
          this.suda7year * Math.pow(leftSideExpression, sortedInflationArr2[0]));
      });
    } else {
      const rightInflationArr = [];

      rightInflationArr.push(this.tenure);

      const myIndex = years[years.indexOf(this.yearsData) - 6];
      const myIndex2 = years[years.indexOf(this.yearsData) - 7];
      const prewData = this.lastChartRow.find((item: any) => item.years === myIndex);
      const prewData2 = this.lastChartRow.find((item: any) => item.years === myIndex2);

      const inflationArr = [];
      inflationArr.push(this.tenure);
      if (prewData === undefined) {
        return 0;
      } else {
        inflationArr.push(prewData.years);
      }
      if (prewData2 === undefined) {
        return 0;
      } else {
        rightInflationArr.push(prewData2.years);
      }

      const leftSideExpression = 1 + this.avarageSalarySlider;
      const sortedInflationArr = inflationArr.sort((a: any, b: any) => a - b);
      const sortedInflationArr2 = rightInflationArr.sort((a: any, b: any) => a - b);
      return runInAction(() => {
        return (this.cohortYear7 =
          this.suda7year * Math.pow(leftSideExpression, sortedInflationArr[0]) -
          this.suda7year * Math.pow(leftSideExpression, sortedInflationArr2[0]));
      });
    }
  }
  setCohortYear8() {
    if (this.yearsData === 8) {
      const rightInflationArr = [];

      rightInflationArr.push(this.tenure);

      const myIndex = years[years.indexOf(this.yearsData) - 7];
      const myIndex2 = years[years.indexOf(this.yearsData) - 8];
      const prewData = this.lastChartRow.find((item: any) => item.years === myIndex);
      const prewData2 = this.lastChartRow.find((item: any) => item.years === myIndex2);

      const inflationArr = [];
      inflationArr.push(this.tenure);
      inflationArr.push(prewData.years);
      if (prewData2 === undefined) {
        rightInflationArr.push(0);
      } else {
        rightInflationArr.push(prewData2.years);
      }
      const leftSideExpression = 1 + this.avarageSalarySlider;
      const sortedInflationArr = inflationArr.sort((a: any, b: any) => a - b);
      const sortedInflationArr2 = rightInflationArr.sort((a: any, b: any) => a - b);
      return runInAction(() => {
        return (this.cohortYear8 =
          this.suda8year * Math.pow(leftSideExpression, sortedInflationArr[0]) -
          this.suda8year * Math.pow(leftSideExpression, sortedInflationArr2[0]));
      });
    } else {
      const rightInflationArr = [];

      rightInflationArr.push(this.tenure);

      const myIndex = years[years.indexOf(this.yearsData) - 7];
      const myIndex2 = years[years.indexOf(this.yearsData) - 8];
      const prewData = this.lastChartRow.find((item: any) => item.years === myIndex);
      const prewData2 = this.lastChartRow.find((item: any) => item.years === myIndex2);

      const inflationArr = [];
      inflationArr.push(this.tenure);
      if (prewData === undefined) {
        return 0;
      } else {
        inflationArr.push(prewData.years);
      }
      if (prewData2 === undefined) {
        return 0;
      } else {
        rightInflationArr.push(prewData2.years);
      }

      const leftSideExpression = 1 + this.avarageSalarySlider;
      const sortedInflationArr = inflationArr.sort((a: any, b: any) => a - b);
      const sortedInflationArr2 = rightInflationArr.sort((a: any, b: any) => a - b);
      return runInAction(() => {
        return (this.cohortYear8 =
          this.suda8year * Math.pow(leftSideExpression, sortedInflationArr[0]) -
          this.suda8year * Math.pow(leftSideExpression, sortedInflationArr2[0]));
      });
    }
  }
  setCohortYear9() {
    if (this.yearsData === 9) {
      const rightInflationArr = [];

      rightInflationArr.push(this.tenure);

      const myIndex = years[years.indexOf(this.yearsData) - 8];
      const myIndex2 = years[years.indexOf(this.yearsData) - 9];
      const prewData = this.lastChartRow.find((item: any) => item.years === myIndex);
      const prewData2 = this.lastChartRow.find((item: any) => item.years === myIndex2);

      const inflationArr = [];
      inflationArr.push(this.tenure);
      inflationArr.push(prewData.years);
      if (prewData2 === undefined) {
        rightInflationArr.push(0);
      } else {
        rightInflationArr.push(prewData2.years);
      }
      const leftSideExpression = 1 + this.avarageSalarySlider;
      const sortedInflationArr = inflationArr.sort((a: any, b: any) => a - b);
      const sortedInflationArr2 = rightInflationArr.sort((a: any, b: any) => a - b);
      return runInAction(() => {
        return (this.cohortYear9 =
          this.suda9year * Math.pow(leftSideExpression, sortedInflationArr[0]) -
          this.suda9year * Math.pow(leftSideExpression, sortedInflationArr2[0]));
      });
    } else {
      const rightInflationArr = [];

      rightInflationArr.push(this.tenure);

      const myIndex = years[years.indexOf(this.yearsData) - 8];
      const myIndex2 = years[years.indexOf(this.yearsData) - 9];
      const prewData = this.lastChartRow.find((item: any) => item.years === myIndex);
      const prewData2 = this.lastChartRow.find((item: any) => item.years === myIndex2);

      const inflationArr = [];
      inflationArr.push(this.tenure);
      if (prewData === undefined) {
        return 0;
      } else {
        inflationArr.push(prewData.years);
      }
      if (prewData2 === undefined) {
        return 0;
      } else {
        rightInflationArr.push(prewData2.years);
      }

      const leftSideExpression = 1 + this.avarageSalarySlider;
      const sortedInflationArr = inflationArr.sort((a: any, b: any) => a - b);
      const sortedInflationArr2 = rightInflationArr.sort((a: any, b: any) => a - b);
      return runInAction(() => {
        return (this.cohortYear9 =
          this.suda9year * Math.pow(leftSideExpression, sortedInflationArr[0]) -
          this.suda9year * Math.pow(leftSideExpression, sortedInflationArr2[0]));
      });
    }
  }
  setCohortYear10() {
    if (this.yearsData === 10) {
      const rightInflationArr = [];

      rightInflationArr.push(this.tenure);

      const myIndex = years[years.indexOf(this.yearsData) - 9];
      const myIndex2 = years[years.indexOf(this.yearsData) - 10];
      const prewData = this.lastChartRow.find((item: any) => item.years === myIndex);
      const prewData2 = this.lastChartRow.find((item: any) => item.years === myIndex2);

      const inflationArr = [];
      inflationArr.push(this.tenure);
      inflationArr.push(prewData.years);
      if (prewData2 === undefined) {
        rightInflationArr.push(0);
      } else {
        rightInflationArr.push(prewData2.years);
      }
      const leftSideExpression = 1 + this.avarageSalarySlider;
      const sortedInflationArr = inflationArr.sort((a: any, b: any) => a - b);
      const sortedInflationArr2 = rightInflationArr.sort((a: any, b: any) => a - b);
      return runInAction(() => {
        return (this.cohortYear10 =
          this.suda10year * Math.pow(leftSideExpression, sortedInflationArr[0]) -
          this.suda10year * Math.pow(leftSideExpression, sortedInflationArr2[0]));
      });
    } else {
      const rightInflationArr = [];

      rightInflationArr.push(this.tenure);

      const myIndex = years[years.indexOf(this.yearsData) - 9];
      const myIndex2 = years[years.indexOf(this.yearsData) - 10];
      const prewData = this.lastChartRow.find((item: any) => item.years === myIndex);
      const prewData2 = this.lastChartRow.find((item: any) => item.years === myIndex2);

      const inflationArr = [];
      inflationArr.push(this.tenure);
      if (prewData === undefined) {
        return 0;
      } else {
        inflationArr.push(prewData.years);
      }
      if (prewData2 === undefined) {
        return 0;
      } else {
        rightInflationArr.push(prewData2.years);
      }

      const leftSideExpression = 1 + this.avarageSalarySlider;
      const sortedInflationArr = inflationArr.sort((a: any, b: any) => a - b);
      const sortedInflationArr2 = rightInflationArr.sort((a: any, b: any) => a - b);
      return runInAction(() => {
        return (this.cohortYear10 =
          this.suda10year * Math.pow(leftSideExpression, sortedInflationArr[0]) -
          this.suda10year * Math.pow(leftSideExpression, sortedInflationArr2[0]));
      });
    }
  }

  setAnnualTaxSaving() {
    return runInAction(() => {
      return (this.annualTaxSaving = this.impactSorbet * this.taxRate);
    });
  }
  setAnnualFundingCost() {
    if (this.yearsData === 1) {
      return runInAction(
        () =>
          (this.annualFundingCost =
            this.sorbetYearsRow[0].total +
            this.sorbetYearsRow[1].total +
            this.sorbetYearsRow[2].total +
            this.sorbetYearsRow[3].total),
      );
    } else if (this.yearsData === 2) {
      return runInAction(
        () =>
          (this.annualFundingCost =
            this.sorbetYearsRow[4].total +
            this.sorbetYearsRow[5].total +
            this.sorbetYearsRow[6].total +
            this.sorbetYearsRow[7].total),
      );
    } else if (this.yearsData === 3) {
      return runInAction(
        () =>
          (this.annualFundingCost =
            this.sorbetYearsRow[8].total +
            this.sorbetYearsRow[9].total +
            this.sorbetYearsRow[10].total +
            this.sorbetYearsRow[11].total),
      );
    } else if (this.yearsData === 4) {
      return runInAction(
        () =>
          (this.annualFundingCost =
            this.sorbetYearsRow[12].total +
            this.sorbetYearsRow[13].total +
            this.sorbetYearsRow[14].total +
            this.sorbetYearsRow[15].total),
      );
    } else if (this.yearsData === 5) {
      return runInAction(
        () =>
          (this.annualFundingCost =
            this.sorbetYearsRow[16].total +
            this.sorbetYearsRow[17].total +
            this.sorbetYearsRow[18].total +
            this.sorbetYearsRow[19].total),
      );
    } else if (this.yearsData === 6) {
      return runInAction(
        () =>
          (this.annualFundingCost =
            this.sorbetYearsRow[20].total +
            this.sorbetYearsRow[21].total +
            this.sorbetYearsRow[22].total +
            this.sorbetYearsRow[23].total),
      );
    } else if (this.yearsData === 7) {
      return runInAction(
        () =>
          (this.annualFundingCost =
            this.sorbetYearsRow[24].total +
            this.sorbetYearsRow[25].total +
            this.sorbetYearsRow[26].total +
            this.sorbetYearsRow[27].total),
      );
    } else if (this.yearsData === 8) {
      return runInAction(
        () =>
          (this.annualFundingCost =
            this.sorbetYearsRow[28].total +
            this.sorbetYearsRow[29].total +
            this.sorbetYearsRow[30].total +
            this.sorbetYearsRow[31].total),
      );
    } else if (this.yearsData === 9) {
      return runInAction(
        () =>
          (this.annualFundingCost =
            this.sorbetYearsRow[32].total +
            this.sorbetYearsRow[33].total +
            this.sorbetYearsRow[34].total +
            this.sorbetYearsRow[35].total),
      );
    } else if (this.yearsData === 10) {
      return runInAction(
        () =>
          (this.annualFundingCost =
            this.sorbetYearsRow[36].total +
            this.sorbetYearsRow[37].total +
            this.sorbetYearsRow[38].total +
            this.sorbetYearsRow[39].total),
      );
    }
  }
  setImpactSorbet() {
    const arr = [];
    const leftCompare =
      this.sorbetManaged * (1 + this.avarageSalarySlider) + this.overLiability - this.taxDeduction;
    arr.push(leftCompare);
    arr.push(this.sorbetManaged);
    const comparedData = arr.sort((a: any, b: any) => a - b);
    const dataToAdd = () => {
      if (comparedData[0] < 0) {
        return 0;
      } else {
        return comparedData[0];
      }
    };
    return runInAction(() => {
      return (this.impactSorbet = dataToAdd());
    });
  }

  setOverLiability() {
    return runInAction(() => {
      return (this.overLiability = this.alowedCarryOver * this.employees * this.endingDayValue);
    });
  }
  setTaxDeduction() {
    const leftCompare = this.lastRemainingLiability * (1 + this.avarageSalarySlider);
    const rightCompare = (this.paidTimeSlider / 12) * 2.5 * this.endingDayValue * this.employees;
    const arr = [leftCompare, rightCompare];
    const comparedData = arr.sort((a: any, b: any) => a - b);
    return runInAction(() => {
      return (this.taxDeduction = comparedData[0] * this.usedPaidTime);
    });
  }
  setPostFactoring() {
    return runInAction(() => {
      return (this.postFactoring = this.lastRemainingLiability - this.sorbetManaged);
    });
  }
  setSorbetManaged() {
    if (this.yearsData === 1) {
      //
      return runInAction(() => (this.sorbetManaged = this.suda1year));
    } else if (this.yearsData === 2) {
      return runInAction(() => (this.sorbetManaged = this.suda2year));
    } else if (this.yearsData === 3) {
      return runInAction(() => (this.sorbetManaged = this.suda3year));
    } else if (this.yearsData === 4) {
      return runInAction(() => (this.sorbetManaged = this.suda4year));
    } else if (this.yearsData === 5) {
      return runInAction(() => (this.sorbetManaged = this.suda5year));
    } else if (this.yearsData === 6) {
      return runInAction(() => (this.sorbetManaged = this.suda6year));
    } else if (this.yearsData === 7) {
      return runInAction(() => (this.sorbetManaged = this.suda7year));
    } else if (this.yearsData === 8) {
      return runInAction(() => (this.sorbetManaged = this.suda8year));
    } else if (this.yearsData === 9) {
      return runInAction(() => (this.sorbetManaged = this.suda9year));
    } else if (this.yearsData === 10) {
      return runInAction(() => (this.sorbetManaged = this.suda10year));
    }
  }

  setLastRemainingLiability() {
    return runInAction(
      () => (this.lastRemainingLiability = this.companyLiability - this.liabilityDecrease),
    );
  }

  setEndingDayValue() {
    if (this.yearsData === 1) {
      return runInAction(
        () => (this.endingDayValue = this.lastVacationDay * (1 + this.avarageSalarySlider)),
      );
    } else {
      const myIndex = years[years.indexOf(this.yearsData) - 1];
      const prewData = this.lastChartRow.find((item: any) => item.years === myIndex);
      return runInAction(
        () => (this.endingDayValue = prewData.endingDayValue * (1 + this.avarageSalarySlider)),
      );
    }
  }
  setAlowedCarryOver() {
    if (this.yearsData === 1) {
      switch (this.rolloverName) {
        case "days":
          const arrDays = [];
          const resDays = this.postFactoring / this.employees / this.lastVacationDay;
          arrDays.push(resDays);
          arrDays.push(this.rolloverSlider);
          const comparedArrDays = arrDays.sort((a: any, b: any) => a - b);
          return runInAction(() => (this.alowedCarryOver = Number(comparedArrDays[0])));

        case "rollover":
          const arrRoll = [];
          const resRollForMin =
            this.paidTimeSlider * this.endingDayValue * this.employees * this.rolloverSlider;
          arrRoll.push(this.postFactoring);
          arrRoll.push(resRollForMin);
          const comparedArrRoll = arrRoll.sort((a: any, b: any) => a - b);

          return runInAction(
            () =>
              (this.alowedCarryOver =
                Number(comparedArrRoll[0]) / this.lastVacationDay / this.employees),
          );

        default:
          return runInAction(
            () =>
              (this.alowedCarryOver = this.postFactoring / this.lastVacationDay / this.employees),
          );
      }
      //
    } else if (this.yearsData === 2) {
      // ;

      const myIndex = years[years.indexOf(this.yearsData) - 1];
      const prewData = this.lastChartRow.find((item: any) => item.years === myIndex);
      switch (this.rolloverName) {
        case "days":
          const arrDays = [];
          const resDays = this.postFactoring / this.employees / prewData.endingDayValue;
          arrDays.push(resDays);
          arrDays.push(this.rolloverSlider);
          const comparedArrDays = arrDays.sort((a: any, b: any) => a - b);
          return runInAction(() => (this.alowedCarryOver = Number(comparedArrDays[0])));

        case "rollover":
          const arrRoll = [];
          const resRollForMin =
            this.paidTimeSlider * prewData.endingDayValue * this.employees * this.rolloverSlider;
          arrRoll.push(this.postFactoring);
          arrRoll.push(resRollForMin);
          //
          const comparedArrRoll = arrRoll.sort((a: any, b: any) => a - b);

          return runInAction(
            () =>
              (this.alowedCarryOver =
                Number(comparedArrRoll[0]) / prewData.endingDayValue / this.employees),
          );

        default:
          return runInAction(
            () =>
              (this.alowedCarryOver =
                this.postFactoring / this.employees / prewData.endingDayValue),
          );
      }
    } else if (this.yearsData === 3) {
      const myIndex = years[years.indexOf(this.yearsData) - 1];
      const prewData = this.lastChartRow.find((item: any) => item.years === myIndex);
      switch (this.rolloverName) {
        case "days":
          const arrDays = [];
          const resDays = this.postFactoring / this.employees / prewData.endingDayValue;
          arrDays.push(resDays);
          arrDays.push(this.rolloverSlider);
          //
          const comparedArrDays = arrDays.sort((a: any, b: any) => a - b);
          return runInAction(() => (this.alowedCarryOver = Number(comparedArrDays[0])));

        case "rollover":
          const arrRoll = [];
          const resRollForMin =
            this.paidTimeSlider * prewData.endingDayValue * this.employees * this.rolloverSlider;
          arrRoll.push(this.postFactoring);
          arrRoll.push(resRollForMin);
          //
          const comparedArrRoll = arrRoll.sort((a: any, b: any) => a - b);

          return runInAction(
            () =>
              (this.alowedCarryOver =
                Number(comparedArrRoll[0]) / prewData.endingDayValue / this.employees),
          );

        default:
          return runInAction(
            () =>
              (this.alowedCarryOver =
                this.postFactoring / this.employees / prewData.endingDayValue),
          );
      }
    } else if (this.yearsData === 4) {
      const myIndex = years[years.indexOf(this.yearsData) - 1];
      const prewData = this.lastChartRow.find((item: any) => item.years === myIndex);
      switch (this.rolloverName) {
        case "days":
          const arrDays = [];
          const resDays = this.postFactoring / this.employees / prewData.endingDayValue;
          arrDays.push(resDays);
          arrDays.push(this.rolloverSlider);
          //
          const comparedArrDays = arrDays.sort((a: any, b: any) => a - b);
          return runInAction(() => (this.alowedCarryOver = Number(comparedArrDays[0])));

        case "rollover":
          const arrRoll = [];
          const resRollForMin =
            this.paidTimeSlider * prewData.endingDayValue * this.employees * this.rolloverSlider;
          arrRoll.push(this.postFactoring);
          arrRoll.push(resRollForMin);
          //
          const comparedArrRoll = arrRoll.sort((a: any, b: any) => a - b);

          return runInAction(
            () =>
              (this.alowedCarryOver =
                Number(comparedArrRoll[0]) / prewData.endingDayValue / this.employees),
          );

        default:
          return runInAction(
            () =>
              (this.alowedCarryOver =
                this.postFactoring / this.employees / prewData.endingDayValue),
          );
      }
    } else if (this.yearsData === 5) {
      const myIndex = years[years.indexOf(this.yearsData) - 1];
      const prewData = this.lastChartRow.find((item: any) => item.years === myIndex);
      switch (this.rolloverName) {
        case "days":
          const arrDays = [];
          const resDays = this.postFactoring / this.employees / prewData.endingDayValue;
          arrDays.push(resDays);
          arrDays.push(this.rolloverSlider);
          //
          const comparedArrDays = arrDays.sort((a: any, b: any) => a - b);
          return runInAction(() => (this.alowedCarryOver = Number(comparedArrDays[0])));

        case "rollover":
          const arrRoll = [];
          const resRollForMin =
            this.paidTimeSlider * prewData.endingDayValue * this.employees * this.rolloverSlider;
          arrRoll.push(this.postFactoring);
          arrRoll.push(resRollForMin);
          //
          const comparedArrRoll = arrRoll.sort((a: any, b: any) => a - b);

          return runInAction(
            () =>
              (this.alowedCarryOver =
                Number(comparedArrRoll[0]) / prewData.endingDayValue / this.employees),
          );

        default:
          return runInAction(
            () =>
              (this.alowedCarryOver =
                this.postFactoring / this.employees / prewData.endingDayValue),
          );
      }
    } else if (this.yearsData === 6) {
      const myIndex = years[years.indexOf(this.yearsData) - 1];
      const prewData = this.lastChartRow.find((item: any) => item.years === myIndex);
      switch (this.rolloverName) {
        case "days":
          const arrDays = [];
          const resDays = this.postFactoring / this.employees / prewData.endingDayValue;
          arrDays.push(resDays);
          arrDays.push(this.rolloverSlider);
          //
          const comparedArrDays = arrDays.sort((a: any, b: any) => a - b);
          return runInAction(() => (this.alowedCarryOver = Number(comparedArrDays[0])));

        case "rollover":
          const arrRoll = [];
          const resRollForMin =
            this.paidTimeSlider * prewData.endingDayValue * this.employees * this.rolloverSlider;
          arrRoll.push(this.postFactoring);
          arrRoll.push(resRollForMin);
          //
          const comparedArrRoll = arrRoll.sort((a: any, b: any) => a - b);

          return runInAction(
            () =>
              (this.alowedCarryOver =
                Number(comparedArrRoll[0]) / prewData.endingDayValue / this.employees),
          );

        default:
          return runInAction(
            () =>
              (this.alowedCarryOver =
                this.postFactoring / this.employees / prewData.endingDayValue),
          );
      }
    } else if (this.yearsData === 7) {
      const myIndex = years[years.indexOf(this.yearsData) - 1];
      const prewData = this.lastChartRow.find((item: any) => item.years === myIndex);
      switch (this.rolloverName) {
        case "days":
          const arrDays = [];
          const resDays = this.postFactoring / this.employees / prewData.endingDayValue;
          arrDays.push(resDays);
          arrDays.push(this.rolloverSlider);
          //
          const comparedArrDays = arrDays.sort((a: any, b: any) => a - b);
          return runInAction(() => (this.alowedCarryOver = Number(comparedArrDays[0])));

        case "rollover":
          const arrRoll = [];
          const resRollForMin =
            this.paidTimeSlider * prewData.endingDayValue * this.employees * this.rolloverSlider;
          arrRoll.push(this.postFactoring);
          arrRoll.push(resRollForMin);
          //
          const comparedArrRoll = arrRoll.sort((a: any, b: any) => a - b);

          return runInAction(
            () =>
              (this.alowedCarryOver =
                Number(comparedArrRoll[0]) / prewData.endingDayValue / this.employees),
          );

        default:
          return runInAction(
            () =>
              (this.alowedCarryOver =
                this.postFactoring / this.employees / prewData.endingDayValue),
          );
      }
    } else if (this.yearsData === 8) {
      const myIndex = years[years.indexOf(this.yearsData) - 1];
      const prewData = this.lastChartRow.find((item: any) => item.years === myIndex);
      switch (this.rolloverName) {
        case "days":
          const arrDays = [];
          const resDays = this.postFactoring / this.employees / prewData.endingDayValue;
          arrDays.push(resDays);
          arrDays.push(this.rolloverSlider);
          //
          const comparedArrDays = arrDays.sort((a: any, b: any) => a - b);
          return runInAction(() => (this.alowedCarryOver = Number(comparedArrDays[0])));

        case "rollover":
          const arrRoll = [];
          const resRollForMin =
            this.paidTimeSlider * prewData.endingDayValue * this.employees * this.rolloverSlider;
          arrRoll.push(this.postFactoring);
          arrRoll.push(resRollForMin);
          //
          const comparedArrRoll = arrRoll.sort((a: any, b: any) => a - b);

          return runInAction(
            () =>
              (this.alowedCarryOver =
                Number(comparedArrRoll[0]) / prewData.endingDayValue / this.employees),
          );

        default:
          return runInAction(
            () =>
              (this.alowedCarryOver =
                this.postFactoring / this.employees / prewData.endingDayValue),
          );
      }
    } else if (this.yearsData === 9) {
      const myIndex = years[years.indexOf(this.yearsData) - 1];
      const prewData = this.lastChartRow.find((item: any) => item.years === myIndex);
      switch (this.rolloverName) {
        case "days":
          const arrDays = [];
          const resDays = this.postFactoring / this.employees / prewData.endingDayValue;
          arrDays.push(resDays);
          arrDays.push(this.rolloverSlider);
          //
          const comparedArrDays = arrDays.sort((a: any, b: any) => a - b);
          return runInAction(() => (this.alowedCarryOver = Number(comparedArrDays[0])));

        case "rollover":
          const arrRoll = [];
          const resRollForMin =
            this.paidTimeSlider * prewData.endingDayValue * this.employees * this.rolloverSlider;
          arrRoll.push(this.postFactoring);
          arrRoll.push(resRollForMin);
          //
          const comparedArrRoll = arrRoll.sort((a: any, b: any) => a - b);

          return runInAction(
            () =>
              (this.alowedCarryOver =
                Number(comparedArrRoll[0]) / prewData.endingDayValue / this.employees),
          );

        default:
          return runInAction(
            () =>
              (this.alowedCarryOver =
                this.postFactoring / this.employees / prewData.endingDayValue),
          );
      }
    } else if (this.yearsData === 10) {
      const myIndex = years[years.indexOf(this.yearsData) - 1];
      const prewData = this.lastChartRow.find((item: any) => item.years === myIndex);
      switch (this.rolloverName) {
        case "days":
          const arrDays = [];
          const resDays = this.postFactoring / this.employees / prewData.endingDayValue;
          arrDays.push(resDays);
          arrDays.push(this.rolloverSlider);
          //
          const comparedArrDays = arrDays.sort((a: any, b: any) => a - b);
          return runInAction(() => (this.alowedCarryOver = Number(comparedArrDays[0])));

        case "rollover":
          const arrRoll = [];
          const resRollForMin =
            this.paidTimeSlider * prewData.endingDayValue * this.employees * this.rolloverSlider;
          arrRoll.push(this.postFactoring);
          arrRoll.push(resRollForMin);
          //
          const comparedArrRoll = arrRoll.sort((a: any, b: any) => a - b);

          return runInAction(
            () =>
              (this.alowedCarryOver =
                Number(comparedArrRoll[0]) / prewData.endingDayValue / this.employees),
          );

        default:
          return runInAction(
            () =>
              (this.alowedCarryOver =
                this.postFactoring / this.employees / prewData.endingDayValue),
          );
      }
    }
  }

  setCompanyLiability() {
    if (this.yearsData === 1) {
      return runInAction(
        () =>
          (this.companyLiability =
            this.carryOver + this.lastVacationDay * this.paidTimeSlider * this.employees),
      );
    } else {
      const myIndex = years[years.indexOf(this.yearsData) - 1];
      const prewData = this.lastChartRow.find((item: any) => item.years === myIndex);
      return runInAction(
        () =>
          (this.companyLiability =
            (this.paidTimeSlider + prewData.alowedCarryOver) *
            this.employees *
            prewData.endingDayValue),
      );
    }
  }

  setLiabilityDecrease() {
    if (this.yearsData === 1) {
      return runInAction(
        () =>
          (this.liabilityDecrease =
            this.paidTimeSlider * this.employees * this.lastVacationDay * this.usedPaidTime),
      );
    } else {
      const myIndex = years[years.indexOf(this.yearsData) - 1];
      const prewData = this.lastChartRow.find((item: any) => item.years === myIndex);
      return runInAction(
        () =>
          (this.liabilityDecrease =
            this.paidTimeSlider * this.employees * this.usedPaidTime * prewData.endingDayValue),
      );
    }
  }
  setRemainingLiability() {}
  setYears(data: any) {
    return runInAction(() => (this.yearsData = data));
  }
  setCarryOver() {
    runInAction(() => (this.lastVacationDay = this.avarageSalary / 260));
    return runInAction(
      () => (this.carryOver = this.lastVacationDay * this.employees * this.initialBalance_A13),
    );
  }

  //END LAST CHART

  get thirdLoop(): any {
    if (this.withOutRow.length > 0) {
      this.withOutRow = [];
    }
    for (let i = 0; i < periodArr.length; i++) {
      this.setPeriodWithout_AE23(periodArr[i]);
      if (this.periodWithout_AE23 === 1.1) {
        const row1 = {
          period: periodArr[i],
          initialBalance_AE23: this.setInitialBalance_AE23(),
          accrualDays_AG23: this.setAccrualDays_AG23(),
          vacationDays_AF23: this.setVacationDays_AF23(),
          outstanding_AH23: this.setOutstanding_AH23(),
          remainingDays_AJ23: this.setRemainingDays_AJ23(),
          peopleLeaving_AK23: this.setPeopleLeaving_AK23(),
          costPerDay_AN23: this.setCostPerDay_AN23(),
          payment_AO23: this.setPayment_AO23(),
        };

        runInAction(() => {
          return this.withOutRow.push(row1);
        });
      } else {
        const row1 = {
          period: periodArr[i],
          initialBalance_AE23: this.setInitialBalance_AE24(),
          accrualDays_AG23: this.setAccrualDays_AG23(),
          vacationDays_AF23: this.setVacationDays_AF23(),
          outstanding_AH23: this.setOutstanding_AH23(),
          remainingDays_AJ23: this.setRemainingDays_AJ23(),
          peopleLeaving_AK23: this.setPeopleLeaving_AK23(),
          costPerDay_AN23: this.setCostPerDay_AN23(),
          payment_AO23: this.setPayment_AO23(),
        };
        runInAction(() => {
          return this.withOutRow.push(row1);
        });
      }
    }
    return null;
  }

  setPayment_AO23(): any {
    const paymentRes = this.costPerDay_AN23 * this.employees * this.peopleLeaving_AK23;
    runInAction(() => {
      return (this.payment_AO23 = Number(paymentRes));
    });
    return paymentRes;
  }

  setCostPerDay_AN23(): any {
    const pow = String(this.periodWithout_AE23).match(/^([0-9]*)/);
    runInAction(() => {
      return (this.pow_AC23 = Number(pow[0]));
    });
    const res1 = 1 + this.avarageSalarySlider;
    let myString = String(this.avarageSalary);
    myString = myString.replace(/,/g, "");
    const res2 = Number(myString) / 260;
    const powCalc = this.pow_AC23 - 1;
    const res3 = Math.pow(res1, powCalc);
    const res = res2 * res3;
    runInAction(() => {
      return (this.costPerDay_AN23 = Number(res));
      // return (this.costPerDay_AN23 = 10);
    });
    return res;
  }

  setPeopleLeaving_AK23() {
    //👍
    const random = randomData.filter((item: any) => item.key === this.periodWithout_AE23);
    const resPeopleLeaving = (1 / this.tenure) * this.outstanding_AH23 * random[0].attrition;
    runInAction(() => {
      return (this.peopleLeaving_AK23 = resPeopleLeaving);
    });
    return resPeopleLeaving;
  }

  setOutstanding_AH23() {
    //👍
    return runInAction(() => {
      return (this.outstanding_AH23 = Number(
        this.initialBalance_AE23 - this.vacationDays_AF23 + Number(this.accrualDays_AG23),
      ));
    });
  }
  setRemainingDays_AJ23() {
    //👍
    return runInAction(() => {
      return (this.remainingDays_AJ23 = this.outstanding_AH23);
    });
  }

  setInitialBalance_AE23() {
    const prewData = this.dataRow.find((item: any) => item.period === this.periodWithout_AE23);
    return runInAction(() => (this.initialBalance_AE23 = prewData.initialBalance_A13));
  }
  // 26.05
  setInitialBalance_AE24() {
    const myIndex = periodArr[periodArr.indexOf(this.periodWithout_AE23) - 1];
    const prewRow = this.withOutRow.find((item: any) => item.period === myIndex);
    if (Number(String(this.periodWithout_AE23).match(/[^.]*$/)) === 1) {
      let daysBal: any = [];
      daysBal.push(this.rolloverSlider);
      daysBal.push(prewRow.remainingDays_AJ23);
      const minVal = daysBal.sort((a: any, b: any) => {
        return a - b;
      });
      return runInAction(() => {
        return (this.initialBalance_AE23 = minVal[0]);
      });
      // }
    } else {
      return runInAction(() => {
        return (this.initialBalance_AE23 = prewRow.remainingDays_AJ23);
      });
    }
  }

  setVacationDays_AF23() {
    const random = randomData.filter((item: any) => item.key === this.periodWithout_AE23);
    return runInAction(() => {
      return (this.vacationDays_AF23 = Number(
        this.paidTimeSlider * this.usedPaidTime * random[0].vacation,
      ));
    });
  }

  setAccrualDays_AG23() {
    return runInAction(() => {
      return (this.accrualDays_AG23 = Number(this.paidTimeSlider / 4));
    });
  }
  setPeriodWithout_AE23(data: any) {
    return runInAction(() => (this.periodWithout_AE23 = data));
  }

  setTotalCell() {
    return runInAction(() => {
      return (this.totalCell =
        this.cell1year +
        this.cell2year +
        this.cell3year +
        this.cell4year +
        this.cell5year +
        this.cell6year +
        this.cell7year +
        this.cell8year +
        this.cell9year +
        this.cell10year);
    });
  }

  setCashFloWithSorbet() {
    const payment = this.dataRow.find((item: any) => {
      return item.period === this.period2;
    });

    return runInAction(() => {
      return (this.cashFloWithSorbet = this.totalCell + payment.payment_O23);
    });
  }

  setCell1year() {
    const year = String(this.period2).match(/^([0-9]*)/);
    if (Number(year[0]) <= this.whenRepay * 1) {
      const dataFirstLoop = this.dataRow.find((item: any) => item.period === this.period2);
      return runInAction(() => {
        return Number((this.cell1year = (dataFirstLoop.interestRate_P23 * this.suda1year) / 4));
      });
    } else {
      return runInAction(() => {
        return Number((this.cell1year = 0));
      });
    }
  }

  setCell2year() {
    const dataFirstLoop = this.dataRow.find((item: any) => item.period === this.period2);
    const year = String(this.period2).match(/^([0-9]*)/);
    const yearFirstLoop = String(dataFirstLoop.period).match(/^([0-9]*)/);

    if (
      Number(yearFirstLoop[0]) - (Number(year[0]) - 2) <= this.whenRepay &&
      Number(yearFirstLoop[0]) > Number(year[0]) - 1
    ) {
      const myIndex4 = periodArr[periodArr.indexOf(this.period2) - 4];

      const data4 = this.sorbetYearsRow.find((item: any) => {
        return item.period === myIndex4;
      });

      if (data4 === undefined) {
        return runInAction(() => {
          return Number((this.cell2year = 0));
        });
      }
      return runInAction(() => {
        if (this.suda1year > 0) {
          //
          return Number((this.cell2year = (data4.cell1 / this.suda1year) * this.suda2year));
        }
        return Number((this.cell2year = 0));
      });
    } else {
      return runInAction(() => {
        return Number((this.cell2year = 0));
      });
    }
  }
  setCell3year() {
    //
    const dataFirstLoop = this.dataRow.find((item: any) => item.period === this.period2);
    const year = String(this.period2).match(/^([0-9]*)/);
    const yearFirstLoop = String(dataFirstLoop.period).match(/^([0-9]*)/);

    if (
      Number(yearFirstLoop[0]) - (Number(year[0]) - 2) <= this.whenRepay &&
      Number(yearFirstLoop[0]) > Number(year[0]) - 1
    ) {
      const myIndex4 = periodArr[periodArr.indexOf(this.period2) - 4];

      const data4 = this.sorbetYearsRow.find((item: any) => {
        return item.period === myIndex4;
      });

      if (data4 === undefined) {
        return runInAction(() => {
          return Number((this.cell3year = 0));
        });
      }
      return runInAction(() => {
        if (this.suda2year > 0) {
          return Number((this.cell3year = (data4.cell2 / this.suda2year) * this.suda3year));
        }
        return Number((this.cell3year = 0));
      });
    } else {
      return runInAction(() => {
        return Number((this.cell3year = 0));
      });
    }
  }
  setCell4year() {
    const dataFirstLoop = this.dataRow.find((item: any) => item.period === this.period2);
    const year = String(this.period2).match(/^([0-9]*)/);
    const yearFirstLoop = String(dataFirstLoop.period).match(/^([0-9]*)/);

    if (
      Number(yearFirstLoop[0]) - (Number(year[0]) - 2) <= this.whenRepay &&
      Number(yearFirstLoop[0]) > Number(year[0]) - 1
    ) {
      const myIndex4 = periodArr[periodArr.indexOf(this.period2) - 4];

      const data4 = this.sorbetYearsRow.find((item: any) => {
        return item.period === myIndex4;
      });

      if (data4 === undefined) {
        return runInAction(() => {
          return Number((this.cell4year = 0));
        });
      }
      return runInAction(() => {
        if (this.suda3year > 0) {
          return Number((this.cell4year = (data4.cell3 / this.suda3year) * this.suda4year));
        }

        return Number((this.cell4year = 0));
      });
    } else {
      return runInAction(() => {
        return Number((this.cell4year = 0));
      });
    }
  }
  setCell5year() {
    const dataFirstLoop = this.dataRow.find((item: any) => item.period === this.period2);
    const year = String(this.period2).match(/^([0-9]*)/);
    const yearFirstLoop = String(dataFirstLoop.period).match(/^([0-9]*)/);

    if (
      Number(yearFirstLoop[0]) - (Number(year[0]) - 2) <= this.whenRepay &&
      Number(yearFirstLoop[0]) > Number(year[0]) - 1
    ) {
      const myIndex4 = periodArr[periodArr.indexOf(this.period2) - 4];

      const data4 = this.sorbetYearsRow.find((item: any) => {
        return item.period === myIndex4;
      });

      if (data4 === undefined) {
        return runInAction(() => {
          return Number((this.cell5year = 0));
        });
      }
      return runInAction(() => {
        if (this.suda4year > 0) {
          return Number((this.cell5year = (data4.cell4 / this.suda4year) * this.suda5year));
        }
        return Number((this.cell5year = 0));
      });
    } else {
      return runInAction(() => {
        return Number((this.cell5year = 0));
      });
    }
  }
  setCell6year() {
    const dataFirstLoop = this.dataRow.find((item: any) => item.period === this.period2);
    const year = String(this.period2).match(/^([0-9]*)/);
    const yearFirstLoop = String(dataFirstLoop.period).match(/^([0-9]*)/);

    if (
      Number(yearFirstLoop[0]) - (Number(year[0]) - 2) <= this.whenRepay &&
      Number(yearFirstLoop[0]) > Number(year[0]) - 1
    ) {
      const myIndex4 = periodArr[periodArr.indexOf(this.period2) - 4];

      const data4 = this.sorbetYearsRow.find((item: any) => {
        return item.period === myIndex4;
      });

      if (data4 === undefined) {
        return runInAction(() => {
          return Number((this.cell6year = 0));
        });
      }
      return runInAction(() => {
        if (this.suda5year > 0) {
          return Number((this.cell6year = (data4.cell5 / this.suda5year) * this.suda6year));
        }
        return Number((this.cell6year = 0));
      });
    } else {
      return runInAction(() => {
        return Number((this.cell6year = 0));
      });
    }
  }
  setCell7year() {
    const dataFirstLoop = this.dataRow.find((item: any) => item.period === this.period2);
    const year = String(this.period2).match(/^([0-9]*)/);
    const yearFirstLoop = String(dataFirstLoop.period).match(/^([0-9]*)/);

    if (
      Number(yearFirstLoop[0]) - (Number(year[0]) - 2) <= this.whenRepay &&
      Number(yearFirstLoop[0]) > Number(year[0]) - 1
    ) {
      const myIndex4 = periodArr[periodArr.indexOf(this.period2) - 4];

      const data4 = this.sorbetYearsRow.find((item: any) => {
        return item.period === myIndex4;
      });

      if (data4 === undefined) {
        return runInAction(() => {
          return Number((this.cell7year = 0));
        });
      }
      return runInAction(() => {
        // ;
        if (this.suda6year > 0) {
          return Number((this.cell7year = (data4.cell6 / this.suda6year) * this.suda7year));
        } else {
          return Number((this.cell7year = 0));
        }
      });
    } else {
      return runInAction(() => {
        return Number((this.cell7year = 0));
      });
    }
  }
  setCell8year() {
    const dataFirstLoop = this.dataRow.find((item: any) => item.period === this.period2);
    const year = String(this.period2).match(/^([0-9]*)/);
    const yearFirstLoop = String(dataFirstLoop.period).match(/^([0-9]*)/);

    if (
      Number(yearFirstLoop[0]) - (Number(year[0]) - 2) <= this.whenRepay &&
      Number(yearFirstLoop[0]) > Number(year[0]) - 1
    ) {
      const myIndex4 = periodArr[periodArr.indexOf(this.period2) - 4];

      const data4 = this.sorbetYearsRow.find((item: any) => {
        return item.period === myIndex4;
      });

      if (data4 === undefined) {
        return runInAction(() => {
          return Number((this.cell8year = 0));
        });
      }
      return runInAction(() => {
        if (this.suda7year > 0) {
          return Number((this.cell8year = (data4.cell7 / this.suda7year) * this.suda8year));
        }

        return Number((this.cell8year = 0));
      });
    } else {
      return runInAction(() => {
        return Number((this.cell8year = 0));
      });
    }
  }
  setCell9year() {
    const dataFirstLoop = this.dataRow.find((item: any) => item.period === this.period2);
    const year = String(this.period2).match(/^([0-9]*)/);
    const yearFirstLoop = String(dataFirstLoop.period).match(/^([0-9]*)/);

    if (
      Number(yearFirstLoop[0]) - (Number(year[0]) - 2) <= this.whenRepay &&
      Number(yearFirstLoop[0]) > Number(year[0]) - 1
    ) {
      const myIndex4 = periodArr[periodArr.indexOf(this.period2) - 4];

      const data4 = this.sorbetYearsRow.find((item: any) => {
        return item.period === myIndex4;
      });

      if (data4 === undefined) {
        return runInAction(() => {
          return Number((this.cell9year = 0));
        });
      }
      return runInAction(() => {
        if (this.suda8year > 0) {
          return Number((this.cell9year = (data4.cell8 / this.suda8year) * this.suda9year));
        }

        return Number((this.cell9year = 0));
      });
    } else {
      return runInAction(() => {
        return Number((this.cell9year = 0));
      });
    }
  }
  setCell10year() {
    const dataFirstLoop = this.dataRow.find((item: any) => item.period === this.period2);
    const year = String(this.period2).match(/^([0-9]*)/);
    const yearFirstLoop = String(dataFirstLoop.period).match(/^([0-9]*)/);

    if (
      Number(yearFirstLoop[0]) - (Number(year[0]) - 2) <= this.whenRepay &&
      Number(yearFirstLoop[0]) > Number(year[0]) - 1
    ) {
      const myIndex4 = periodArr[periodArr.indexOf(this.period2) - 4];

      const data4 = this.sorbetYearsRow.find((item: any) => {
        return item.period === myIndex4;
      });
      if (data4 === undefined) {
        return runInAction(() => {
          return Number((this.cell10year = 0));
        });
      }
      return runInAction(() => {
        if (this.suda9year > 0) {
          return Number((this.cell10year = (data4.cell9 / this.suda9year) * this.suda10year));
        }
        return Number((this.cell10year = 0));
      });
    } else {
      return runInAction(() => {
        return Number((this.cell10year = 0));
      });
    }
  }

  setSuda1year() {
    const summI23 =
      this.dataRow[0].daysBoughtbySorbet_1q +
      this.dataRow[1].daysBoughtbySorbet_1q +
      this.dataRow[2].daysBoughtbySorbet_1q +
      this.dataRow[3].daysBoughtbySorbet_1q;
    const vpr = this.dataRow[3].costPerDay_N23;
    // const vpr = 10;
    const compare = (): number => {
      if (1 <= this.howLong) {
        return 1;
      } else {
        return 0;
      }
    };
    const res = compare();
    return runInAction(() => {
      return (this.suda1year = summI23 * vpr * this.employees * res);
    });
    // return (this.suda1year = summI23 * vpr * this.employees * compare());
  }
  setSuda2year() {
    const summI23 =
      this.dataRow[4].daysBoughtbySorbet_1q +
      this.dataRow[5].daysBoughtbySorbet_1q +
      this.dataRow[6].daysBoughtbySorbet_1q +
      this.dataRow[7].daysBoughtbySorbet_1q;
    const vpr = this.dataRow[7].costPerDay_N23;
    // const vpr = 20;
    const compare = (): number => {
      if (2 <= this.howLong) {
        return 1;
      } else {
        return 0;
      }
    };
    const res = compare();
    //
    return runInAction(() => {
      return (this.suda2year = summI23 * vpr * this.employees * res);
    });
  }

  setSuda3year() {
    const summI23 =
      this.dataRow[8].daysBoughtbySorbet_1q +
      this.dataRow[9].daysBoughtbySorbet_1q +
      this.dataRow[10].daysBoughtbySorbet_1q +
      this.dataRow[11].daysBoughtbySorbet_1q;
    const vpr = this.dataRow[11].costPerDay_N23;
    // const vpr = 30;
    const compare = (): number => {
      if (3 <= this.howLong) {
        return 1;
      } else {
        return 0;
      }
    };
    // const res = compare();
    return runInAction(() => {
      return (this.suda3year = summI23 * vpr * this.employees * compare());
    });
  }
  setSuda4year() {
    const summI23 =
      this.dataRow[12].daysBoughtbySorbet_1q +
      this.dataRow[13].daysBoughtbySorbet_1q +
      this.dataRow[14].daysBoughtbySorbet_1q +
      this.dataRow[15].daysBoughtbySorbet_1q;
    const vpr = this.dataRow[15].costPerDay_N23;
    // const vpr = 40;
    const compare = (): number => {
      if (4 <= this.howLong) {
        return 1;
      } else {
        return 0;
      }
    };
    // const res = compare();
    return runInAction(() => {
      return (this.suda4year = summI23 * vpr * this.employees * compare());
    });
  }
  setSuda5year() {
    const summI23 =
      this.dataRow[16].daysBoughtbySorbet_1q +
      this.dataRow[17].daysBoughtbySorbet_1q +
      this.dataRow[18].daysBoughtbySorbet_1q +
      this.dataRow[19].daysBoughtbySorbet_1q;
    const vpr = this.dataRow[19].costPerDay_N23;
    // const vpr = 50;
    const compare = (): number => {
      if (5 <= this.howLong) {
        return 1;
      } else {
        return 0;
      }
    };
    // const res = compare();
    return runInAction(() => {
      return (this.suda5year = summI23 * vpr * this.employees * compare());
    });
  }
  setSuda6year() {
    //
    const summI23 =
      this.dataRow[20].daysBoughtbySorbet_1q +
      this.dataRow[21].daysBoughtbySorbet_1q +
      this.dataRow[22].daysBoughtbySorbet_1q +
      this.dataRow[23].daysBoughtbySorbet_1q;
    const vpr = this.dataRow[23].costPerDay_N23;
    const compare = (): number => {
      if (6 <= this.howLong) {
        return 1;
      } else {
        return 0;
      }
    };
    const res = compare();
    return runInAction(() => {
      return (this.suda6year = summI23 * vpr * this.employees * res);
    });
  }
  setSuda7year() {
    const summI23 =
      this.dataRow[24].daysBoughtbySorbet_1q +
      this.dataRow[25].daysBoughtbySorbet_1q +
      this.dataRow[26].daysBoughtbySorbet_1q +
      this.dataRow[27].daysBoughtbySorbet_1q;
    const vpr = this.dataRow[27].costPerDay_N23;
    // const vpr = 70;
    const compare = (): number => {
      if (7 <= this.howLong) {
        return 1;
      } else {
        return 0;
      }
    };
    // const res = compare();
    return runInAction(() => {
      return (this.suda7year = summI23 * vpr * this.employees * compare());
    });
  }
  setSuda8year() {
    const summI23 =
      this.dataRow[28].daysBoughtbySorbet_1q +
      this.dataRow[29].daysBoughtbySorbet_1q +
      this.dataRow[30].daysBoughtbySorbet_1q +
      this.dataRow[31].daysBoughtbySorbet_1q;
    const vpr = this.dataRow[31].costPerDay_N23;
    // const vpr = 80;
    const compare = (): number => {
      if (8 <= this.howLong) {
        return 1;
      } else {
        return 0;
      }
    };
    // const res = compare();
    return runInAction(() => {
      return (this.suda8year = summI23 * vpr * this.employees * compare());
    });
  }
  setSuda9year() {
    const summI23 =
      this.dataRow[32].daysBoughtbySorbet_1q +
      this.dataRow[33].daysBoughtbySorbet_1q +
      this.dataRow[34].daysBoughtbySorbet_1q +
      this.dataRow[35].daysBoughtbySorbet_1q;
    const vpr = this.dataRow[35].costPerDay_N23;
    // const vpr = 90;
    const compare = (): number => {
      if (9 <= this.howLong) {
        return 1;
      } else {
        return 0;
      }
    };
    // const res = compare();
    return runInAction(() => {
      return (this.suda9year = summI23 * vpr * this.employees * compare());
    });
  }
  setSuda10year() {
    const summI23 =
      this.dataRow[36].daysBoughtbySorbet_1q +
      this.dataRow[37].daysBoughtbySorbet_1q +
      this.dataRow[38].daysBoughtbySorbet_1q +
      this.dataRow[39].daysBoughtbySorbet_1q;
    const vpr = this.dataRow[39].costPerDay_N23;
    // const vpr = 10;
    const compare = (): number => {
      if (10 <= this.howLong) {
        return 1;
      } else {
        return 0;
      }
    };
    // const res = compare();
    return runInAction(() => {
      return (this.suda10year = summI23 * vpr * this.employees * compare());
    });
  }

  setInitialBalance_A13() {
    // ;
    if (this.rolloverName === "unlimited") {
      return runInAction(() => {
        return (this.initialBalance_A13 =
          (this.tenure - 1) * (1 - this.usedPaidTime) * this.paidTimeSlider);
      });
    } else {
      switch (this.rolloverName) {
        case "rollover":
          let rolloverDays: any = [];
          rolloverDays.push(this.rolloverSlider * this.paidTimeSlider);
          rolloverDays.push((1 - this.usedPaidTime) * ((this.tenure - 1) * this.paidTimeSlider));

          return runInAction(() => {
            return (this.initialBalance_A13 = Number(_.min(rolloverDays) * 0.7));
          });

        default:
          let otherDays: any = [];
          otherDays.push(this.rolloverSlider);
          otherDays.push((1 - this.usedPaidTime) * ((this.tenure - 1) * this.paidTimeSlider));

          return runInAction(() => {
            return (this.initialBalance_A13 = Number(_.min(otherDays) * 0.7));
          });
      }
    }
  }

  setInitialBalance_A14() {
    const myIndex = periodArr[periodArr.indexOf(this.period) - 1];
    const prewRow = this.dataRow.find((item: any) => item.period === myIndex);
    if (Number(String(this.period).match(/[^.]*$/)) === 1 && this.rolloverName === "days") {
      let daysBal: any = [];
      daysBal.push(this.rolloverSlider);
      daysBal.push(prewRow.remainingDays_J23);
      return runInAction(() => {
        return (this.initialBalance_A14 = Number(_.min(daysBal)));
      });

      // }
    } else if (
      Number(String(this.period).match(/[^.]*$/)) === 1 &&
      this.rolloverName === "rollover"
    ) {
      let daysBal: any = [];
      daysBal.push(prewRow.remainingDays_J23);
      daysBal.push(this.paidTimeSlider * this.rolloverSlider);
      return runInAction(() => {
        return (this.initialBalance_A14 = _.min(daysBal));
      });
    } else {
      return runInAction(() => {
        return (this.initialBalance_A14 = prewRow.remainingDays_J23);
      });
    }
  }

  setVacationDays_F23() {
    const random = randomData.filter((item: any) => item.key === this.period);
    return runInAction(() => {
      return (this.vacationDays_F23 = Number(
        this.paidTimeSlider * this.usedPaidTime * random[0].vacation,
      ));
    });
  }

  setAccrualDays() {
    return runInAction(() => {
      return (this.accrualDays_G23 = Number(this.paidTimeSlider / 4));
    });
  }
  setInterestRate_P23(): any {
    const year = Number(String(this.period).match(/^([0-9]*)/)[0]);
    const quart = Number(String(this.period).match(/[^.]*$/)[0]);
    if (year === 1) {
      return runInAction(() => {
        return (this.interestRate_P23 = 0);
      });
    } else {
      if (Number(year - 1) + Number(quart * 0.25) <= this.tenure) {
        return runInAction(() => {
          return (this.interestRate_P23 =
            Number(this.avarageSalarySlider - 0.005) < 0
              ? 0.0 //Alex 22.06
              : Number(this.avarageSalarySlider - 0.005));
        });
      }
      return runInAction(() => {
        return (this.interestRate_P23 =
          Number(this.avarageSalarySlider - 0.002) < 0
            ? 0.0 //Alex 22.06
            : Number(this.avarageSalarySlider - 0.002));
      });
    }
  }

  setOutstanding_H23() {
    if (this.period === 1.1) {
      return runInAction(() => {
        return (this.outstanding_H23 = Number(
          this.initialBalance_A13 - this.vacationDays_F23 + Number(this.accrualDays_G23),
        ));
      });
    } else {
      return runInAction(() => {
        return (this.outstanding_H23 = Number(
          this.initialBalance_A14 - this.vacationDays_F23 + Number(this.accrualDays_G23),
        ));
      });
    }
  }

  setQuarter(value: number) {
    runInAction(() => {
      return (this.quarter = value);
    });
  }

  // 26.05
  setDaysBoughtbySorbet() {
    //
    if (Number(String(this.period).match(/[^.]*$/)) === 1) {
      const quarter1 = (this.outstanding_H23 * this.liabilitySorber_G14) / 4 / 100;

      this.setQuarter(1);
      return runInAction(() => {
        return (this.daysBoughtbySorbet_1q = Number(quarter1.toFixed(4)));
      });
    } else if (Number(String(this.period).match(/[^.]*$/)) === 2) {
      //
      const quarter2 = (this.outstanding_H23 * this.liabilitySorber_G14) / 4 / 100;
      this.setQuarter(2);
      return runInAction(() => {
        this.daysBoughtbySorbet_1q = Number(quarter2.toFixed(4));
        return (this.daysBoughtbySorbet_2q = Number(quarter2.toFixed(4)));
      });
    } else if (Number(String(this.period).match(/[^.]*$/)) === 3) {
      const quarter3 = (this.outstanding_H23 * this.liabilitySorber_G14) / 4 / 100;
      this.setQuarter(3);
      return runInAction(() => {
        this.daysBoughtbySorbet_1q = Number(quarter3.toFixed(4));
        return (this.daysBoughtbySorbet_3q = Number(quarter3.toFixed(4)));
      });
    } else if (Number(String(this.period).match(/[^.]*$/)) === 4) {
      this.setQuarter(4);
      const myIndex3 = periodArr[periodArr.indexOf(this.period) - 3];
      const myIndex2 = periodArr[periodArr.indexOf(this.period) - 2];
      const myIndex1 = periodArr[periodArr.indexOf(this.period) - 1];

      const firstDays = this.dataRow.find((item: any) => item.period === myIndex3);
      const secDays = this.dataRow.find((item: any) => item.period === myIndex2);
      const thirdDays = this.dataRow.find((item: any) => item.period === myIndex1);

      const rightCompare = () => {
        if (firstDays.initialBalance_A13 || firstDays.initialBalance_A13 === 0) {
          return firstDays.initialBalance_A13 + this.paidTimeSlider * (1 - this.usedPaidTime);
        } else {
          return firstDays.initialBalance_A14 + this.paidTimeSlider * (1 - this.usedPaidTime);
        }
      };

      const res =
        (rightCompare() * this.liabilitySorber_G14) / 100 -
        (firstDays.daysBoughtbySorbet_1q +
          secDays.daysBoughtbySorbet_1q +
          thirdDays.daysBoughtbySorbet_1q);

      return runInAction(() => {
        this.daysBoughtbySorbet_1q = Number(res.toFixed(4));
        return (this.daysBoughtbySorbet_4q = Number(res.toFixed(4)));
      });
    }
  }

  setRemainingDays() {
    if (this.quarter === 1) {
      const remDay = this.outstanding_H23 - this.daysBoughtbySorbet_1q;
      runInAction(() => {
        return (this.remainingDays_J23 = remDay);
      });
      return remDay;
    } else if (this.quarter === 2) {
      const remDay = this.outstanding_H23 - this.daysBoughtbySorbet_2q;
      runInAction(() => {
        return (this.remainingDays_J23 = remDay);
      });
      return remDay;
    } else if (this.quarter === 3) {
      const remDay = this.outstanding_H23 - this.daysBoughtbySorbet_3q;
      runInAction(() => {
        return (this.remainingDays_J23 = remDay);
      });
      return remDay;
    } else if (this.quarter === 4) {
      // debugger
      const remDay = this.outstanding_H23 - this.daysBoughtbySorbet_4q;
      runInAction(() => {
        return (this.remainingDays_J23 = remDay);
      });
      return remDay;
    }
  }

  setPeopleLeaving() {
    const random = randomData.filter((item: any) => item.key === this.period);
    const resPeopleLeaving = (1 / this.tenure) * this.outstanding_H23 * random[0].attrition;
    runInAction(() => {
      return (this.peopleLeaving_K23 = resPeopleLeaving);
    });
    return resPeopleLeaving;
  }

  setCostPerDay(): any {
    const pow = String(this.period).match(/^([0-9]*)/);
    runInAction(() => {
      return (this.pow_C23 = Number(pow[0]));
    });
    const res1 = 1 + this.avarageSalarySlider;
    let myString = String(this.avarageSalary);
    myString = myString.replace(/,/g, "");
    const res2 = Number(myString) / 260;
    const powCalc = this.pow_C23 - 1;
    const res3 = Math.pow(res1, powCalc);
    const res = res2 * res3;
    runInAction(() => {
      return (this.costPerDay_N23 = Number(res));
      // return (this.costPerDay_N23 = 10);
    });
    return res;
  }

  setPayment(): any {
    const paymentRes = this.costPerDay_N23 * this.employees * this.peopleLeaving_K23;
    runInAction(() => {
      return (this.payment_O23 = Number(paymentRes));
    });
    return paymentRes;
  }

  setIndustry(data: string) {
    runInAction(() => {
      return (this.industry = data);
    });
  }

  setBaseUrl(data: string) {
    runInAction(() => {
      return (this.baseUrl = data);
    });
  }

  setRolloverName(data: string) {
    runInAction(() => {
      return (this.rolloverName = data);
    });
  }
  setAvarageSalary(data: number) {
    runInAction(() => {
      return (this.avarageSalary = data);
    });
  }
  setAvarageSalarySlider(data: number) {
    runInAction(() => {
      return (this.avarageSalarySlider = data);
    });
  }
  setLiabilitySlider(data: number) {
    runInAction(() => (this.liabilitySorber_G14 = data));
  }

  setBackTrigger(data: number) {
    runInAction(() => (this.backTrigger = data));
  }

  setRolloverSlider(data: number) {
    runInAction(() => {
      return (this.rolloverSlider = data);
    });
  }

  setPaidTimeSlider(data: number) {
    runInAction(() => {
      return (this.paidTimeSlider = data);
    });
  }

  setAvarageSlider(data: number) {
    runInAction(() => {
      return (this.usedPaidTime = data);
    });
  }

  setEmployees(data: number) {
    runInAction(() => {
      return (this.employees = data);
    });
  }
  setTaxRate(data: number) {
    runInAction(() => {
      return (this.taxRate = data);
    });
  }

  setFirstName(data: string) {
    runInAction(() => {
      return (this.firstName = data);
    });
  }
  setRecipientName(data: string) {
    runInAction(() => {
      return (this.recipientName = data);
    });
  }
  setLastName(data: string) {
    runInAction(() => {
      return (this.lastName = data);
    });
  }
  setCompanyEmail(data: string) {
    runInAction(() => {
      return (this.companyEmail = data);
    });
  }
  setCompanyName(data: string) {
    runInAction(() => {
      return (this.companyName = data);
    });
  }

  setEmail(data: string) {
    runInAction(() => {
      return (this.email = data);
    });
  }

  setHowLong(data: number) {
    runInAction(() => {
      return (this.howLong = data);
    });
  }

  setWhenRepay(data: number) {
    return (this.whenRepay = data);
  }

  clearStore() {
    this.wageInflationProtection = 0;
    this.totalSavings = 0;
    this.industry = "";
    this.firstName = "";
    this.companyName = "";
    this.companyEmail = "";
    this.recipientName = "";
    this.email = "";
    this.avarageSlider = 0.72;
    this.period = 1.1; // mock
    this.period2 = 1.1; // mock
    this.periodWithout_AE23 = 1.1; // mock
    this.usedPaidTime = 0.72;
    this.employees = 0;
    this.avarageSalarySlider = 0.033;
    this.rolloverName = "days";
    this.rolloverSlider = 18;
    this.tenure = 0;
    this.backTrigger = 0;
    this.impactSorbet = 0;
    this.taxRate = 0.26;
    this.paidTimeSlider = 21;
    this.howLong = 10;
    this.whenRepay = 2;
    this.liabilitySorber_G14 = 50; // mock
    this.quarter = 0;
    this.interestRate_P23 = 0;
    this.yearsData = 0;
    this.sorbetManaged = 0;
    this.resultInflation = 0;
    this.avarageSalary = 56000;
    this.initialBalance_A13 = 0;
    this.initialBalance_A14 = 0;
    this.initialBalance_AE23 = 0;
    this.initialBalance_AE24 = 0;
    this.vacationDays_AF23 = 0;
    this.vacationDays_F23 = 0;
    this.accrualDays_G23 = 0;
    this.accrualDays_AG23 = 0;
    this.outstanding_H23 = 0;
    this.outstanding_AH23 = 0;
    this.daysBoughtbySorbet_1q = 0;
    this.remainingDays_J23 = 0;
    this.postFactoring = 0;
    this.cohortYear1 = 0;
    this.cohortYear2 = 0;
    this.cohortYear3 = 0;
    this.cohortYear4 = 0;
    this.cohortYear5 = 0;
    this.cohortYear6 = 0;
    this.cohortYear7 = 0;
    this.cohortYear8 = 0;
    this.cohortYear9 = 0;
    this.cohortYear10 = 0;
    this.sumOfannualTaxSaving = 0;
    this.baseUrl = "";
    this.sumOfSorbetManages = 0;
    this.lastRemainingLiability = 0;
    this.remainingDays_AJ23 = 0;
    this.peopleLeaving_K23 = 0;
    this.peopleLeaving_AK23 = 0;
    this.costPerDay_N23 = 0;
    this.endingDayValue = 0;
    this.costPerDay_AN23 = 0;
    this.companyLiability = 5;
    this.overLiability = 0;
    this.annualFundingCost = 0;
    this.liabilityDecrease = 0;
    this.remainingLiability = 0;
    this.payment_O23 = 0;
    this.taxDeduction = 0;
    this.payment_AO23 = 0;
    this.alowedCarryOver = 0;
    this.sudoMockYear1 = 1;
    this.sudoMockYear2 = 2;
    this.sudoMockYear3 = 3;
    this.sudoMockYear4 = 4;
    this.sudoMockYear5 = 5;
    this.sudoMockYear6 = 6;
    this.sudoMockYear7 = 7;
    this.sudoMockYear8 = 8;
    this.sudoMockYear9 = 9;
    this.sudoMockYear10 = 10;
    this.suda1year = 0;
    this.suda2year = 0;
    this.suda3year = 0;
    this.suda4year = 0;
    this.suda5year = 0;
    this.suda6year = 0;
    this.suda7year = 0;
    this.suda8year = 0;
    this.suda9year = 0;
    this.suda10year = 0;
    this.lastVacationDay = 0;
    this.savingPerUsd = 0;
    this.net = 0;
    this.carryOver = 0;
    this.cell1year = 0;
    this.cell2year = 0;
    this.cell3year = 0;
    this.cell4year = 0;
    this.cell5year = 0;
    this.cell6year = 0;
    this.cell7year = 0;
    this.cell8year = 0;
    this.cell9year = 0;
    this.cell10year = 0;
    this.totalCell = 0;
    this.cashFloWithSorbet = 0;
    this.sumSorbetManaged = 0;
    this.annualTaxSaving = 0;
    this.daysBoughtbySorbet_2q = 0;
    this.daysBoughtbySorbet_3q = 0;
    this.daysBoughtbySorbet_4q = 0;
    this.pow_C23 = 0;
    this.pow_AC23 = 0;
    this.resultFundingCost = 0;
  }

  increaseProgress(data: number): void {
    runInAction(() => {
      return (this.progress = data);
    });
  }

  setTenure(data: number) {
    runInAction(() => {
      return (this.tenure = data);
    });
  }
  setPeriod(periodValue: number) {
    runInAction(() => (this.period = periodValue));
  }

  setPeriod2(periodValue: number) {
    runInAction(() => (this.period2 = periodValue));
  }
}

export const calculatorStore: ICalculatorStore = new Calculator();
