import React from "react";
import BigMainText from "../BigMainText";
import {BigTitle} from "../BigTitle";
import { BigTitleWrapper } from "../PageTextBlock/styles";
import { useWindowSize } from "../../../utils/hooks/useWindowSize";
import size from "../../../config/devices";
import { Size } from "../../../utils/hooks/useWindowSizeTypes";

const MainPageTextBlock = () => {
  const sizes: Size = useWindowSize();
  const tabletWidth = +size.tablet.slice(0, -2);
  const mobileView = () => {
    if (sizes.width) {
      if (sizes.width < tabletWidth) {
        return true;
      }
      return false;
    }
  };
  const isMobile = mobileView();
  return (
    // {
    isMobile ? (
      <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
        <BigTitleWrapper>
          <BigTitle>
            It’s time to make PTO a win-win.
          </BigTitle>
        </BigTitleWrapper>
        <BigMainText>
          PTO is broken. Our one-of-kind employee benefit balances work
          and life, while re-balancing the balance sheet. Pretty sweet!
        </BigMainText>
      </div>
    ) : (
      <div>
        <BigTitleWrapper>
          <BigTitle style={{ fontSize: "24px" }}>
            It’s time to make PTO
            <br /> a win-win.
          </BigTitle>
        </BigTitleWrapper>
        <BigMainText style={{ fontSize: "14px" }}>
          PTO is broken. Our one-of-kind employee benefit balances work
          <br /> and life, while re-balancing the balance sheet. Pretty sweet!
        </BigMainText>
      </div>
    )
  );
};

export default MainPageTextBlock;
