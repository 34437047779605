import { yupResolver } from "@hookform/resolvers";
import BackButton from "calculator_feature/common/components/BackButton";
import NewPageTextBlock from "calculator_feature/common/components/NewPageTextBlock";
import NewSlideButton from "calculator_feature/common/components/NewSlideButton";
import { NumberFormatEmployee } from "calculator_feature/common/utils/customDigit";
import { vacationDays } from "calculator_feature/config/vacationDays";
import { STORES } from "config/constants";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import React, { BaseSyntheticEvent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { ICalculatorStore } from "stores/calculator";
import { FormWrapper, SlideTitle, SmallText, SoundsGood } from "../../../../common/components";
import {
  ButtonsBlock,
  LeftGridSlide,
  LeftWrapperGrid,
  NewSlideWrapper,
  PageWrapper,
  RightWrapperGrid,
} from "../../../../common/slidesStyle";
import { tenureSchema } from "../../../../common/utils/yupUtils";
import { Form } from "../../../../components/Form";
import { Input } from "../../../../components/Input";
import { IStepProps } from "../../types";

interface IFourthStep extends IStepProps {
  [STORES.CALCULATOR]: ICalculatorStore;
}

enum FildName {
  tenure = "tenure",
}

interface FormDataType {
  [FildName.tenure]: number;
}

const TenureStep = ({ onChangeStep, onClose, ...props }: IFourthStep) => {
  const calcStore = props[STORES.CALCULATOR] as ICalculatorStore;
  const history = useHistory();
  const [tenureUpdate, setTenureUpdate] = useState(String(calcStore.tenure));
  const preValue = tenureUpdate === "0" ? "4.1" : tenureUpdate;
  useEffect(() => {
    calcStore.increaseProgress(60);
    // setTenureUpdate(tenureUpdate);
  });
  const handleClick = (e: BaseSyntheticEvent) => {
    e.preventDefault();
    setTenureUpdate("");
    const item = document.getElementById("tenure");
    return item.focus();
  };

  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      tenure: tenureUpdate,
    },
    reValidateMode: "onBlur",
    resolver: yupResolver(tenureSchema),
  });

  const onSubmit = ({ tenure }: FormDataType) => {
    const roundedTenure = Math.round(tenure);
    console.clear();

    const paidTimeData = vacationDays.filter((item) => item.key === roundedTenure);
    let filteredData;
    if (!paidTimeData.length) {
      filteredData = 27;
    } else {
      filteredData = paidTimeData[0].label;
    }
    calcStore.setTenure(Number(tenureUpdate));
    localStorage.setItem("tenure", tenureUpdate);
    if (calcStore.backTrigger === 0) {
      calcStore.setPaidTimeSlider(filteredData);
      localStorage.setItem("paidTimer", String(filteredData));
    }
    history.push("./paidpolicystep");
    console.clear();
  };
  if (!errors.tenure) {
    calcStore.increaseProgress(75);
  } else {
    calcStore.increaseProgress(60);
  }

  const handleInputChange = (e: React.BaseSyntheticEvent) => {
    setTenureUpdate(e.target.value);
    calcStore.setTenure(Number(e.target.value));
  };
  const handleSoundsGood = () => {
    window.open(
      "https://meetings.hubspot.com/veetahl?utm_source=calculator&utm_medium=cta&utm_campaign=chat_with_us",
      "_blank",
    );
  };
  return (
    <PageWrapper className="pageWrapper">
      <SoundsGood onClick={handleSoundsGood}>TELL ME MORE</SoundsGood>
      <NewSlideWrapper className="SlideWrapper">
        <LeftWrapperGrid>
          <LeftGridSlide>
            <NewPageTextBlock />
          </LeftGridSlide>
        </LeftWrapperGrid>
        <RightWrapperGrid style={{ padding: "14% 23% 10% 23%" }}>
          <SlideTitle>Average employee tenure</SlideTitle>
          <SmallText>
            Don’t know or don’t feel comfortable sharing this info?
            <br /> Don’t worry about it! <br /> We’ll use industry averages as default.
          </SmallText>
          <FormWrapper style={{ marginTop: 0 }}>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Input
                ref={register}
                name={FildName.tenure}
                value={preValue}
                onChange={handleInputChange}
                onClick={handleClick}
                id="tenure"
                error={!!errors.tenure}
                helperText={errors?.tenure?.message}
                required
                InputProps={{
                  inputComponent: NumberFormatEmployee,
                }}
              />
              <ButtonsBlock>
                <NewSlideButton>Next</NewSlideButton>
                <BackButton
                  // disabled={disabled}
                  onClick={() => history.push("./salarystep")}>
                  Back
                </BackButton>
              </ButtonsBlock>
            </Form>
          </FormWrapper>
        </RightWrapperGrid>
      </NewSlideWrapper>
    </PageWrapper>
  );
};

export default inject(STORES.CALCULATOR)(observer(TenureStep));
