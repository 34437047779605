import * as msal from "@azure/msal-browser";

import { ENV } from "config/env";

import { AuthServices, IAuthInstance } from "../types";
import { CommonMicrosoftAuthInstance } from "./common";

/**
 * Microsoft auth instance with read only calendar access
 */
class MicrosoftROAuthInstance extends CommonMicrosoftAuthInstance {
  readonly name = AuthServices.MICROSOFT_RO;

  protected readonly scopes = ["profile", "email", "calendars.read"];

  init = () => {
    const config: msal.Configuration = {
      auth: {
        clientId: ENV.REACT_APP_MICROSOFT_RO_CLIENT_ID,
      },
    };

    this.msalInstance = new msal.PublicClientApplication(config);
  };
}

export const microsoftROAuthInstance: IAuthInstance = new MicrosoftROAuthInstance();
