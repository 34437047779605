import { observer } from "mobx-react-lite";
import React from "react";
import LoadingBar from "react-top-loading-bar";
import { calculatorStore as toStore } from "../../../../stores/calculator";
import { useWindowSize } from "../../../utils/hooks/useWindowSize";
import size from "../../../config/devices";
import { Size } from "../../../utils/hooks/useWindowSizeTypes";

const Progress = observer(() => {
  const progress = toStore.progress;
  const sizes: Size = useWindowSize();
  const tabletWidth = +size.tablet.slice(0, -2);
  const progresSize = () => {
    if (sizes.width) {
      if (sizes.width < tabletWidth) {
        return true;
      }
      return false;
    }
  };

  const screen: boolean = progresSize();
  return !!screen ? (
    <LoadingBar
      height="7px"
      color="linear-gradient(90deg, rgba(246,166,90,1) 0%, rgba(244,144,112,1) 36%, rgba(240,68,147,1) 100%)"
      progress={progress}
      background="#ffffff"
    />
  ) : (
    <LoadingBar
      height="10px"
      color="linear-gradient(90deg, rgba(246,166,90,1) 0%, rgba(244,144,112,1) 36%, rgba(240,68,147,1) 100%)"
      progress={progress}
      background="#ffffff"
    />
  );
});

export default Progress;
