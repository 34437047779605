import { Rate } from "antd";
import { Colors, GRADIENT_COLORS_REVERSED } from "assets/styles/constants";
import { ButtonBase } from "components/Buttons";
import styled from "styled-components";

export const StyledRate = styled(Rate)`
  pointer-events: none;

  &.ant-rate {
    font-size: 38px;

    .ant-rate-star:not(:last-child) {
      margin-right: 10px !important;
    }
  }
`;

export const RatingTitle = styled.h4`
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.61;
  color: ${Colors.textColorSecondary};
`;

export const ExploreMoreBtn = styled(ButtonBase).attrs({
  height: 40,
  orientation: "vertical",
  colors: GRADIENT_COLORS_REVERSED,
})`
  width: 200px;
  border-radius: 26px;
  font-size: 16px;
  margin: 29px 0 46px;
`;
