import firebase from "firebase/app";

import { ENV } from "config/env";

import { AuthServices, AuthTypes, IAuthInstance } from "../types";
import { CommonFirebaseAuthInstance } from "./common";

const { MICROSOFT, GOOGLE } = AuthTypes;

class FirebaseRWAuthInstance extends CommonFirebaseAuthInstance {
  readonly name = AuthServices.FIREBASE_RW;

  protected readonly providers = {
    [GOOGLE]: {
      providerId: "google.com",
      scopes: ["profile", "email", "https://www.googleapis.com/auth/calendar.events"],
    },
    [MICROSOFT]: {
      providerId: "microsoft.com",
      scopes: ["profile", "email", "calendars.readWrite"],
    },
  };

  protected fbInstance?: firebase.app.App;

  init = () => {
    const firebaseConfig = {
      apiKey: ENV.REACT_APP_FIREBASE_API_KEY,
      authDomain: `${ENV.REACT_APP_FIREBASE_PROJECT_ID}.firebaseapp.com`,
      databaseURL: `https://${ENV.REACT_APP_FIREBASE_PROJECT_ID}.firebaseio.com`,
      projectId: ENV.REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: `${ENV.REACT_APP_FIREBASE_PROJECT_ID}.appspot.com`,
      messagingSenderId: ENV.REACT_APP_FIREBASE_SENDER_ID,
      appId: ENV.REACT_APP_FIREBASE_APP_ID,
    };

    this.fbInstance = firebase.initializeApp(firebaseConfig, this.name);
  };
}

export const firebaseRWAuthInstance: IAuthInstance = new FirebaseRWAuthInstance();
