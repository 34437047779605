import React, { FC } from "react";
import { Container } from "../../slidesStyle";
import { IFormWrapper } from "../../types";


const FormWrapper: FC<IFormWrapper> = ({ children, ...props }) => {
  return <Container {...props}>{children}</Container>;
};

export default FormWrapper;
