import React, { FC } from "react";
import { IconSlideWrapper } from "../../slidesStyle";
import { CommonFormElement } from "../../types";

const IconWrapper: FC<CommonFormElement> = ({ children, ...props }) => (
  <IconSlideWrapper {...props}>{children}</IconSlideWrapper>
);


export default IconWrapper;
