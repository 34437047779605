import React from "react";
import {BigText} from "../BigText";
import {BigTitle} from "../BigTitle";

const PageTextBlock = () => {
  return (
    <>
      <BigTitle>Time is how you SPEND IT</BigTitle>
      <BigText>
        Introducing a disruptive Fintech solution that offers an optimal way for
        professionals to use unusable, accrued Time-Off while providing
        innovative finance leaders with full financial flexibility to reduce
        vacation accruals, optimize their balance sheet and impact the bottom
        line. Find out how Sorbet can impact your business by answering a few
        short questions:
      </BigText>
    </>
  );
};

export default PageTextBlock;
