import { PluginFunc } from "dayjs";
import { TimeOfDay } from "../types";

const times: [TimeOfDay, number][] = [
  ["morning", 12],
  ["afternoon", 18],
  ["evening", 24],
];

const Plugin: PluginFunc = (_, c) => {
  c.prototype.timeOfDay = function () {
    const found = times.find(([, endHour]) => this.hour() < endHour);
    const [name] = found || ["day"];
    return name;
  };
};

export default Plugin;
