import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { FC } from "react";
import { CommonFormElement } from "calculator_feature/common/types";
import { Theme } from "@material-ui/core";
import { useWindowSize } from "../../../utils/hooks/useWindowSize";
import size from "../../../config/devices";
import { Size } from "../../../utils/hooks/useWindowSizeTypes";

export interface StyleProps {
  screenSize: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: {
    minWidth: ({ screenSize }) => (screenSize ? "150px" : "216px"),
    height: ({ screenSize }) => (screenSize ? "35px" : "48px"),
    borderRadius: "30px",
    backgroundColor: "#ffae00",
    marginTop: ({ screenSize }) => (screenSize ? "15%" : "10.28%"),
    padding: "0 10% 0 10%",

    fontSize: ({ screenSize }) => (screenSize ? "14px" : "24px"),
    fontWeight: 600,
    fontStretch: "normal",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Poppins",
    textTransform: "none",
    color: "#fff",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#ffae00",
      color: "#fff",
      boxShadow:
        "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    },
  },
}));

export const NewPrimaryButton: FC<CommonFormElement> = ({ children, disabled, ...props }) => {
  
  const sizes: Size = useWindowSize();
  const tabletWidth = +size.tablet.slice(0, -2);
  const mobileView = () => {
    if (sizes.width) {
      if (sizes.width < tabletWidth) {
        return { screenSize: true };
      }
      return { screenSize: false };
    }
  };

  const classes = useStyles(mobileView());

  return (
    <Button
      type="submit"
      form="myform"
      variant="contained"
      disabled={Boolean(disabled)}
      // className={classes.text}
      className={classes.root}
      {...props}>
      {children}
    </Button>
  );
};
