import { SelectVacType } from "./types";

export const vacationDays: SelectVacType = [
  { key: 0, label: 13 }, //Default vacation days
  { key: 1, label: 14 },
  { key: 2, label: 14 },
  { key: 3, label: 15 },
  { key: 4, label: 15 },
  { key: 5, label: 16 },
  { key: 6, label: 18 },
  { key: 7, label: 19 },
  { key: 8, label: 19 },
  { key: 9, label: 20 },
  { key: 10, label: 20 },
  { key: 11, label: 22 },
  { key: 12, label: 22 },
  { key: 13, label: 22 },
  { key: 14, label: 22 },
  { key: 15, label: 22 },
  { key: 16, label: 23 },
  { key: 17, label: 23 },
  { key: 18, label: 23 },
  { key: 19, label: 23 },
  { key: 20, label: 25 },
  { key: 21, label: 25 },
  { key: 22, label: 25 },
  { key: 23, label: 25 },
  { key: 24, label: 25 },
  { key: 25, label: 25 },
  { key: 26, label: 25 },
  { key: 27, label: 25 },
  { key: 28, label: 25 },
  { key: 29, label: 25 },
  { key: 30, label: 25 },
];
