import { Props as CarouselProps } from "react-responsive-carousel/lib/ts/components/Carousel";

export enum STORES {
  USER = "USER_STORE",
  EXPERIENCES = "EXPERIENCES_STORE",
  NOTIFICATION = "NOTIFICATION_STORE",
  ONBOARDING = "ONBOARDING_STORE",
  APP = "APP_STORE",
  CALENDAR = "CALENDAR_STORE",
  CALCULATOR = "CALCULATOR_STORE",
}

export const SOCIAL_LINKS = { 
  LINKEDIN: "https://www.linkedin.com/company/67915732/",
  FACEBOOK: "https://www.facebook.com/getsorbet",
  TWITTER: "https://twitter.com/sorbetco",
  INSTAGRAM: "https://www.instagram.com/getsorbet/",
};

export const FAQ_LINK = "https://www.getsorbet.com/faq";

export const BASE_URL = "https://app.getsorbet.com";

export enum ONBOARDING_STEPS {
  WELCOME_STEP = "welcomeStep",
  CALENDAR_STEP = "calendarStep",
  PTO_BALANCE_STEP = "ptoBalanceStep",
  GOALS_STEP = "goalsStep",
  PEAK_HOURS_STEP = "peakHoursStep",
  PREFERENCES_STEP = "preferencesStep",
  CONGRATULATIONS_STEP = "congratulationsStep",
}

export enum ROUTES {
  HOME = "/",
  LOGIN = "/login",
  CALENDAR = "/calendar",
  EXPERIENCE = "/experience",
  EXPERIENCES = "/experiences",
  TRENDING = "/trending",
  ABOUT = "/about",
  TERMS = "/terms",
  CARD = "/card",
  CARD_ACTIVATION = "/activate",
  ACCOUNT = "/myaccount",
  ONBOARDING = "/onboarding",
  COMPLETE_ONBOARDING = "/completeOnboarding",
  RATE = "/rate",
  WAYS_TO_USE_GIFT = "/waysToUseGift",
}

export enum ACCOUNT_ROUTES {
  ACCOUNT = "/myaccount",
  SORBET_HISTORY = "/myaccount/history",
  SAVED_SORBET = "/myaccount/saved",
  CHANGE_WELLNESS = "/myaccount/wellness",
  COMPLETE_PROFILE = "/myaccount/complete",
}

export enum TERMS_ROUTES {
  TERMS_OF_USE = "terms-of-use",
  TERMS_AND_CONDITIONS = "terms-and-conditions",
  PRIVACY = "privacy-notice",
}

export const PAGE_META_DATA = {
  titleTemplate: "Sorbet | %s",
  defaultTitle: "Sorbet",
  description: "Don’t let your Paid Time Off melt away",
};

export const BANNER_CONFIG: Partial<CarouselProps> = {
  interval: 6000,
  transitionTime: 500,
};

export enum ERRORS {
  REQUIRED = "This field is required",
  USER_NOT_FOUND = "Wrong email or password, or user not found",
}

// Calendar Event
// should be "h:mm A" once this is fixed https://letshift.atlassian.net/browse/GG-855
export const CALENDAR_TIME_FORMAT = "HH:mm a";

export const DAY_FORMAT = "D";
export const DAY_OF_WEEK_FORMAT = "dddd";
export const MONTH_FORMAT = "MMMM";
export const YEAR_FORMAT = "YYYY";

/**
 * Company support emails list
 */
export enum COMPANY_EMAILS {
  CANCEL_REQUEST = "support@getsorbet.com",
  SUPPORT = "support@getsorbet.com",
  INFO = "info@getsorbet.com",
}

export enum EMAIL_SUBJECTS {
  SORBET_NOT_TAKEN = "Sorbet not taken",
  ADD_REMOVE_SCOOPS = "Add/Remove scoops",
}

export interface ISidebarSizeInfo {
  width: number;
  breakpoint: number;
  ratio: number;
  collapsedWidth: number;
}

export type SidebarSizeBreakpoint = "MIN" | "MID" | "MAX";

const SIDEBAR_DEFAULT_WIDTH = 480;
const SIDEBAR_DEFAULT_HORIZONTAL_MARGIN = 45;

const SIDEBAR_MIN_BREAKPOINT = 1440;
const SIDEBAR_MID_BREAKPOINT = 1920;
const SIDEBAR_MAX_BREAKPOINT = 2560;

const SIDEBAR_MIN_RATIO = SIDEBAR_MIN_BREAKPOINT / SIDEBAR_MID_BREAKPOINT;
const SIDEBAR_MAX_RATIO = SIDEBAR_MAX_BREAKPOINT / SIDEBAR_MID_BREAKPOINT;

/**
 * Sidebar sizes for different screen sizes
 */
export const SIDEBAR_SIZES: { [key in SidebarSizeBreakpoint]: ISidebarSizeInfo } = {
  MIN: {
    breakpoint: SIDEBAR_MIN_BREAKPOINT,
    width: SIDEBAR_DEFAULT_WIDTH * SIDEBAR_MIN_RATIO,
    ratio: SIDEBAR_MIN_RATIO,
    collapsedWidth: SIDEBAR_DEFAULT_HORIZONTAL_MARGIN * SIDEBAR_MIN_RATIO,
  },
  MID: {
    breakpoint: SIDEBAR_MID_BREAKPOINT,
    width: SIDEBAR_DEFAULT_WIDTH,
    ratio: 1,
    collapsedWidth: SIDEBAR_DEFAULT_HORIZONTAL_MARGIN,
  },
  MAX: {
    breakpoint: SIDEBAR_MAX_BREAKPOINT,
    width: SIDEBAR_DEFAULT_WIDTH * SIDEBAR_MAX_RATIO,
    ratio: SIDEBAR_MAX_RATIO,
    collapsedWidth: SIDEBAR_DEFAULT_HORIZONTAL_MARGIN * SIDEBAR_MAX_RATIO,
  },
};
