import React, { PureComponent } from "react";
import { inject, observer } from "mobx-react";
import { Redirect, Route as ReactRouterRoute, RouteProps } from "react-router-dom";
import Helmet from "react-helmet";

// Components
import { GTMDataLayer } from "components/GTMDataLayer";
import { Loader } from "components/Loader";

// types
import { IUserStore } from "stores/user/types";
import { IOnboardingStore } from "stores/onboarding/types";
import { IAppStore } from "stores/app/types";
import { IRouteConfig } from "config/navigation";

// consts
import { STORES, ROUTES } from "config/constants";

interface IProps extends RouteProps, Pick<IRouteConfig, "metaTitle" | "gtmTitle" | "isPrivate"> {
  [STORES.USER]?: IUserStore;
  [STORES.ONBOARDING]?: IOnboardingStore;
  [STORES.APP]?: IAppStore;
}

@inject(STORES.USER, STORES.ONBOARDING, STORES.APP)
@observer
export class Route extends PureComponent<IProps> {
  private get userStore() {
    return this.props[STORES.USER] as IUserStore;
  }

  private get onboardingStore() {
    return this.props[STORES.ONBOARDING] as IOnboardingStore;
  }

  private get appStore() {
    return this.props[STORES.APP] as IAppStore;
  }

  render() {
    const { isAuthenticated, justLoggedOut, setJustLoggedOut } = this.userStore;
    const { onboardingCompleted } = this.onboardingStore;
    const { initState } = this.appStore;
    const {
      isPrivate,
      render,
      component: Component,
      path,
      children,
      metaTitle,
      gtmTitle,
      ...rest
    } = this.props;

    return (
      <ReactRouterRoute
        path={path}
        {...rest}
        render={(props) => {
          if (!initState) {
            return <Loader />;
          }

          if (initState === "error") {
            return (
              <>Something went wrong</> // TODO: show nice error message
            );
          }

          // redirect to home, if just now logged out,
          if (justLoggedOut) {
            setJustLoggedOut(false);
            if (isPrivate) {
              return <Redirect to={ROUTES.HOME} />;
            }
          }

          // redirect to login
          if (isPrivate && !isAuthenticated) {
            this.appStore.setLoginReferrer(props.location);
            return <Redirect to={ROUTES.LOGIN} />;
          }

          // redirect to onboarding, if not completed
          if (isPrivate && path !== ROUTES.ONBOARDING && !onboardingCompleted) {
            return <Redirect to={ROUTES.COMPLETE_ONBOARDING} />;
          }

          // determine component type - children / render / Component
          let component = children;
          if (render) {
            component = render(props);
          } else if (Component) {
            component = <Component {...props} />;
          }

          // add in page title
          return (
            <GTMDataLayer pageTitle={gtmTitle}>
              <Helmet>
                <title>{metaTitle}</title>
              </Helmet>
              {component}
            </GTMDataLayer>
          );
        }}
      />
    );
  }
}
