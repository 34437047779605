import { ENV } from "config/env";
import dayjs, { ConfigType } from "dayjs";
import { CurrencyCodeType } from "services/api/types";

/**
 * Uppercase first letter in the given text
 */
export const uppercaseFirst = (text: string) => `${text[0].toUpperCase()}${text.substr(1)}`;

/**
 * Add "s" to text if not single
 */
export const pluralize = (text: string, count: number): string => (count === 1 ? text : text + "s");

/**
 * Add currency symbols to digits
 */
export const addCurrency = (
  text: string | number,
  currencyCode: CurrencyCodeType = "USD",
): string => {
  let pattern = `$1 ${currencyCode}`;
  if (currencyCode === "USD") {
    pattern = "$$$1";
  }
  return text.toString().replace(/([\d.]+)/g, pattern);
};

/**
 * Formats dayjs date to the string
 */
export const formatDate = (input: dayjs.ConfigType) => dayjs(input).format("YYYY-MM-DDTHH:mm:ssZ");

/**
 * Return a "DMYY" signature for a given date
 */
export const formatDay = (date: ConfigType) => dayjs(date).tz().format("DMYY");

/**
 * Round PTO days units
 * @param floatNumber amount of floating numbers after the comma
 */
export const roundPTODays = (days: number, floatNumber = 0) => {
  if (ENV.REACT_APP_USE_PTO_MIN_UNITS) {
    floatNumber = 2;
  }
  if (floatNumber <= 0) {
    return Math.floor(days);
  }
  const delimiter = 10 ** floatNumber;
  return Math.floor(days * delimiter) / delimiter;
};

export interface IConvertPTOUnitsParams {
  /**
   * Amount of working hours in company day
   */
  hoursInDay: number;
  /**
   * Default: `minutesToDays`.
   */
  type?: "minutesToDays" | "daysToMinutes";
  /**
   * Default: `true`
   */
  roundDays?: boolean;
  /**
   * Default: `true`
   */
  roundMinutes?: boolean;
}

/**
 * Converts PTO units from days to minutes or vise versa
 */
export const convertPTOUnits = (
  units: number,
  {
    hoursInDay,
    type = "minutesToDays",
    roundDays = true,
    roundMinutes = true,
  }: IConvertPTOUnitsParams,
) => {
  let convertedUnits: number;
  if (type === "minutesToDays") {
    convertedUnits = units / 60 / hoursInDay;
    if (roundDays) {
      convertedUnits = roundPTODays(convertedUnits);
    }
  } else {
    // if type === `daysToMinutes`
    convertedUnits = units * 60 * hoursInDay;
    if (roundMinutes) {
      // the minutes is rounded down to make sure we have enough balance to use
      convertedUnits = Math.round(convertedUnits);
    }
  }
  return convertedUnits;
};
