import { ChartWrapper } from "calculator_feature/common/slidesStyle";
import { STORES } from "config/constants";
import { inject, observer } from "mobx-react";
import React, { useState } from "react";
import { Bar, BarChart, Cell, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { ICalculatorStore } from "stores/calculator";
import { useWindowSize } from "../utils/hooks/useWindowSize";
import size from "../config/devices";
import { Size } from "../utils/hooks/useWindowSizeTypes";

export interface StyleProps {
  screenSize: boolean;
}

const BarChart2 = ({ ...props }: any) => {
  const sizes: Size = useWindowSize();
  const tabletWidth = +size.tablet.slice(0, -2);
  const mobileView = () => {
    if (sizes.width) {
      if (sizes.width < tabletWidth) {
        return { screenSize: true };
      }
      return { screenSize: false };
    }
  };
  const mobile = mobileView();

  const calcStore = props[STORES.CALCULATOR] as ICalculatorStore;
  const netArr: any = calcStore.getNet;

  const [activeIndex, setActiveIndex] = useState(null);
  const data = [
    {
      name: "01",
      value: netArr[0],
    },
    {
      name: "02",
      value: netArr[1],
    },
    {
      name: "03",
      value: netArr[2],
    },
    {
      name: "04",
      value: netArr[3],
    },
    {
      name: "05",
      value: netArr[4],
    },
    {
      name: "06",
      value: netArr[5],
    },
    {
      name: "07",
      value: netArr[6],
    },
    {
      name: "08",
      value: netArr[7],
    },
    {
      name: "09",
      value: netArr[8],
    },
    {
      name: "10",
      value: netArr[9],
    },
  ];

  const handleClick = (data: any, index: any) => {
    setActiveIndex(index);
  };
  const activeItem = data[activeIndex];
  const editTicks = (y: number) => {
    y = y / 1000;
    return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <ChartWrapper>
      <ResponsiveContainer width="100%" height="87%">
        <BarChart
          data={data}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
          onMouseMove={(state: any) => {
            if (state.isTooltipActive) {
              setActiveIndex(state.activeTooltipIndex);
            } else {
              setActiveIndex(null);
            }
          }}>
          <Bar dataKey={"value"} onClick={handleClick} fill="rgb(128, 123, 163)">
            {data.map((entry, index) => (
              <Cell
                values={activeItem && activeItem.value}
                cursor="pointer"
                fill={activeIndex === index ? "#FFAE00" : "rgb(128, 123, 163)"}
                key={`cell-${index}`}
              />
            ))}
          </Bar>
          <Tooltip
            formatter={(value: number) => new Intl.NumberFormat("en").format(value)}
            cursor={{ fill: "none" }}
          />
          <XAxis dataKey="name" style={mobile ? { fontSize: "8px" } : { fontSize: "12px" }} />
          <YAxis
            type="number"
            tickFormatter={editTicks}
            style={mobile ? { fontSize: "8px" } : { fontSize: "12px" }}
          />
        </BarChart>
      </ResponsiveContainer>
    </ChartWrapper>
  );
};

export default inject(STORES.CALCULATOR)(observer(BarChart2));
