import React, { PureComponent } from "react";
import { inject, observer } from "mobx-react";

// Types
import { IAppStore } from "stores/app/types";

// Other
import { STORES } from "config/constants";

interface IProps {
  [STORES.APP]?: IAppStore;
}

@inject(STORES.APP)
@observer
export class Mask extends PureComponent<IProps> {
  private get appStore() {
    return this.props[STORES.APP] as IAppStore;
  }

  public render() {
    const { maskVisible } = this.appStore;

    if (!maskVisible) {
      return null;
    }

    return <div className="ant-modal-mask" />;
  }
}
