import { device } from "calculator_feature/config/devices";
import styled from "styled-components";

export const BigTitleStyle = styled.div`
  /* height: 60px; */
  font-size: 2.5rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: #1c1259;
  text-transform: uppercase;
  display: inline-block;
  margin-bottom: 2.85%;
`;

export const PageTextBlockContainer = styled.div`
  padding: 22.65% 5.11% 30.57% 8.48%;
  @media ${device.mobileS} {
    padding: 5% 1.48% 2% 1.48%;
  }
  @media ${device.mobileM} {
  }
  @media ${device.mobileL} {
  }
  @media ${device.tablet} {
    padding: 22.65% 1.11% 30.57% 8.48%;
  }
  @media ${device.laptop} {
  }
  @media ${device.laptopL} {
    padding: 22.65% 1% 20.57% 10.48%;
  }
  @media ${device.desktop} {
  }
`;

export const BigTextStyle = styled.p`
  line-height: 1.75em;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 5.71%;
  /* width: 1020px; */
  /* height: 112px; */
  font-size: 1em;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  /* line-height: 1.56; */
  letter-spacing: normal;
  color: #1c1259;
  /* @media (max-width: 767px) {
    margin-bottom: 50px;
  }
  @media (max-width: 480px) {
    margin-bottom: 50px;
  } */
`;
export const BoldTextStyle = styled.p`
  /* font-family: Poppins; */
  font-size: 1em;
  font-style: normal;
  font-weight: 600;
  /* line-height: 28px; */
  letter-spacing: 0px;
  text-align: left;
`;
export const HowLongStyle = styled.button<{ active: boolean }>`
  cursor: pointer;
  max-width: 86px;
  min-height: 19px;
  font-size: 12px;
  border-radius: 16px;
  box-shadow: 0 10px 40px 0 rgba(28, 18, 89, 0.15);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  border: 1px solid #1c1259;
  padding: 2px 6px;
  color: ${(props) => (props.active ? "#ffffff" : "#1c1259")};
  background-color: ${(props) => (props.active ? "#1c1259" : "#ffffff")};
  margin-left: 20px;
  &:hover {
    background-color: #1c1259;
    transform: scale(1.05);
    color: #ffffff;
  }
`;
