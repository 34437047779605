import { CommonFormElement } from "calculator_feature/common/types";
import React, { FC } from "react";
import { SlideButtonStyle } from "../../slidesStyle";
import { NewPrimaryButton } from "../NewPrimaryButton/index";

const NewSlideButton: FC<CommonFormElement> = ({ children, disabled, ...props }) => {
  return (
    <SlideButtonStyle style={{ marginRight: "20px", zIndex: 100 }}>
      <NewPrimaryButton disabled={disabled} onClick={props.onClick}>
        {children}
      </NewPrimaryButton>
    </SlideButtonStyle>
  );
};

export default NewSlideButton;
