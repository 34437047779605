import { CommonFormElement, ILight } from "calculator_feature/common/types";
import React, { FC } from "react";
import { LightContainer, LightIcon, LightText } from "../../slidesStyle";

const LightWithText: FC<CommonFormElement> & ILight = ({ children, ...restProps }) => {
  return <LightContainer {...restProps}>{children}</LightContainer>;
};

LightWithText.LightIcon = ({ children, ...restProps }) => (
  <LightIcon {...restProps}>{children}</LightIcon>
);
LightWithText.LightText = ({ children, ...restProps }) => (
  <LightText {...restProps}>{children}</LightText>
);

export default LightWithText;
