import { SidebarSizeBreakpoint, SIDEBAR_SIZES } from "config/constants";
import { action, computed, observable, runInAction } from "mobx";
import { apiService } from "services/api";
import { IAppInitConfig } from "services/api/types";
import {
  AuthModalType,
  IAppStore,
  IErrorModalParams,
  InitStateType,
  IShowAuthModalParams,
  LoginReferrer
} from "./types";

class AppStore implements IAppStore {
  @observable private _sidebarSizeBreakpoint: SidebarSizeBreakpoint = "MIN";

  constructor() {
    this.updateSidebarSize();
    window.addEventListener("resize", this.updateSidebarSize);
  }

  private updateSidebarSize = () => {
    const windowWidth = window.innerWidth;
    let sidebarSizeBreakpoint: SidebarSizeBreakpoint = "MIN";

    if (windowWidth >= SIDEBAR_SIZES.MIN.breakpoint) {
      sidebarSizeBreakpoint = "MID";
    }
    if (windowWidth >= SIDEBAR_SIZES.MID.breakpoint) {
      sidebarSizeBreakpoint = "MAX";
    }
    if (sidebarSizeBreakpoint !== this._sidebarSizeBreakpoint) {
      runInAction(() => {
        this._sidebarSizeBreakpoint = sidebarSizeBreakpoint;
      });
    }
  };

  @computed public get sidebarSizeInfo() {
    return SIDEBAR_SIZES[this._sidebarSizeBreakpoint];
  }

  @computed public get initState() {
    return this._initState;
  }

  @computed public get authModalType() {
    return this._authModalType;
  }

  @computed public get authModalVisible() {
    return !!this._authModalType;
  }

  @computed public get errorModalParams() {
    return this._errorModalParams;
  }

  @computed public get errorModalVisible() {
    return !!this._errorModalParams;
  }

  @computed public get cardActivationModalVisible() {
    return this._cardActivationModalVisible;
  }

  @computed public get maskVisible() {
    return this._maskVisible;
  }

  @computed public get sidebarCollapsed() {
    return this._sidebarCollapsed;
  }

  @computed public get sidebarTransitionEnd() {
    return this._sidebarTransitionEnd;
  }

  @computed public get loginReferrer() {
    return this._loginReferrer;
  }

  @computed public get initConfig() {
    return this._initConfig;
  }

  @computed public get showCalendarPageBanner() {
    return this._showCalendarPageBanner;
  }

  public async loadInitConfig() {
    try {
      const initConfig = await apiService.getInitConfig();
      this.setInitConfig(initConfig);
    } catch (error) {
      console.error(error);
    }
  }

  @action public showAuthModal = ({
    type = AuthModalType.LOGIN,
    loginReferrer,
  }: IShowAuthModalParams = {}) => {
    if (loginReferrer) {
      this._loginReferrer = loginReferrer;
    }
    this._authModalType = type;
  };

  @action public closeAuthModal = () => {
    this._loginReferrer = undefined;
    this._authModalType = undefined;
  };

  @action public showErrorModal = (params?: IErrorModalParams) => {
    this._errorModalParams = params || {};
  };

  @action public closeErrorModal = () => {
    this._errorModalParams = undefined;
  };

  @action public showCardActivationModal = () => {
    this._cardActivationModalVisible = true;
  };

  @action public closeCardActivationModal = () => {
    this._cardActivationModalVisible = false;
  };

  @action public setMaskVisible = (visible: boolean) => {
    this._maskVisible = visible;
  };

  @action public setSidebarCollapsed = (collapsed: boolean) => {
    this._sidebarCollapsed = collapsed;
  };

  @action public setSidebarTransitionEnd = () => {
    this._sidebarTransitionEnd = Date.now();
  };

  @action public setLoginReferrer = (path?: LoginReferrer) => {
    this._loginReferrer = path;
  };

  @action public setInitState = (value?: InitStateType) => {
    this._initState = value;
  };

  @action public dismissCalendarPageBanner = () => {
    window.localStorage.setItem("calendarPageBannerDismissed", "1");
    this._showCalendarPageBanner = false;
  };

  @action private setInitConfig = (config: IAppInitConfig) => {
    this._initConfig = config;
  };

  @observable private _authModalType?: AuthModalType = undefined;

  @observable private _errorModalParams?: IErrorModalParams;

  @observable private _cardActivationModalVisible = false;

  @observable private _maskVisible = false;

  @observable private _sidebarCollapsed = true;

  @observable private _sidebarTransitionEnd = Date.now();

  @observable private _loginReferrer?: LoginReferrer = undefined;

  @observable private _initConfig?: IAppInitConfig = undefined;

  @observable private _initState?: InitStateType = undefined;

  @observable private _showCalendarPageBanner: boolean =
    window.localStorage.getItem("calendarPageBannerDismissed") === null;
}

export const appStore: IAppStore = new AppStore();
