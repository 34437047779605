import { RandomData } from "./types";

export const randomData: RandomData[] = [
  { key: 1.1, vacation: 0.28, attrition: 0.21 },
  { key: 1.2, vacation: 0.24, attrition: 0.21 },
  { key: 1.3, vacation: 0.35, attrition: 0.12 },
  { key: 1.4, vacation: 0.13, attrition: 0.46 },
  { key: 2.1, vacation: 0.11, attrition: 0.45 },
  { key: 2.2, vacation: 0.21, attrition: 0.14 },
  { key: 2.3, vacation: 0.26, attrition: 0.15 },
  { key: 2.4, vacation: 0.42, attrition: 0.26 },
  { key: 3.1, vacation: 0.35, attrition: 0.24 },
  { key: 3.2, vacation: 0.25, attrition: 0.2 },
  { key: 3.3, vacation: 0.33, attrition: 0.24 },
  { key: 3.4, vacation: 0.07, attrition: 0.32 },
  { key: 4.1, vacation: 0.16, attrition: 0.18 },
  { key: 4.2, vacation: 0.16, attrition: 0.11 },
  { key: 4.3, vacation: 0.2, attrition: 0.23 },
  { key: 4.4, vacation: 0.48, attrition: 0.48 },
  { key: 5.1, vacation: 0.32, attrition: 0.29 },
  { key: 5.2, vacation: 0.11, attrition: 0.16 },
  { key: 5.3, vacation: 0.28, attrition: 0.12 },
  { key: 5.4, vacation: 0.29, attrition: 0.43 },
  { key: 6.1, vacation: 0.22, attrition: 0.43 },
  { key: 6.2, vacation: 0.17, attrition: 0.17 },
  { key: 6.3, vacation: 0.3, attrition: 0.22 },
  { key: 6.4, vacation: 0.31, attrition: 0.18 },
  { key: 7.1, vacation: 0.18, attrition: 0.2 },
  { key: 7.2, vacation: 0.1, attrition: 0.2 },
  { key: 7.3, vacation: 0.1, attrition: 0.11 },
  { key: 7.4, vacation: 0.62, attrition: 0.49 },
  { key: 8.1, vacation: 0.17, attrition: 0.19 },
  { key: 8.2, vacation: 0.11, attrition: 0.25 },
  { key: 8.3, vacation: 0.2, attrition: 0.18 },
  { key: 8.4, vacation: 0.52, attrition: 0.38 },
  { key: 9.1, vacation: 0.37, attrition: 0.24 },
  { key: 9.2, vacation: 0.25, attrition: 0.21 },
  { key: 9.3, vacation: 0.25, attrition: 0.24 },
  { key: 9.4, vacation: 0.13, attrition: 0.31 },
  { key: 10.1, vacation: 0.15, attrition: 0.5 },
  { key: 10.2, vacation: 0.25, attrition: 0.2 },
  { key: 10.3, vacation: 0.1, attrition: 0.22 },
  { key: 10.4, vacation: 0.5, attrition: 0.08 },
];
