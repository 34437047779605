// https://ant.design/docs/react/replace-moment

import React from "react";
import { Dayjs } from "dayjs";
import styled, { css } from "styled-components";
import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";
import generatePicker, { PickerDateProps } from "antd/es/date-picker/generatePicker";
import { Colors } from "assets/styles/constants";
import arrowLeftIcon from "assets/images/arrow-left-dark.svg";
import "antd/es/date-picker/style";
import { IInputCommonProps, InputWrapper } from "../Input";
import { commonInputStyles } from "../Input/styles";
import { CalendarIcon } from "./styles";

export const AntdDatePicker = generatePicker<Dayjs>(dayjsGenerateConfig);

const PICKER_PANEL_CLASSNAME = "StyledDatePicker";

const StyledDatePicker = styled(AntdDatePicker)`
  ${commonInputStyles}

  &.ant-picker-focused {
    border-color: ${Colors.textColor};
    ${(p) => p.$isError && `border-color: ${Colors.dangerRed};`}

    &:hover {
      border-color: ${Colors.textColor};
    }
  }

  .ant-picker-input > input {
    font-size: 14px;
    text-align: center;
    word-spacing: -1px;
    line-height: 23px;
    color: ${Colors.textColor};

    &::placeholder {
      color: rgba(${Colors.textColorSecondary_rgb}, 0.6);
    }
  }
  box-shadow: none;

  &:hover {
    border-color: ${Colors.lightBlueGray};
  }
`;

export const DatePickerGlobalStyle = css`
  .${PICKER_PANEL_CLASSNAME} {
    .ant-picker-panel-container {
      border-radius: 6px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
      border: solid 0.5px ${Colors.lightBlueGray};
    }

    .ant-picker-date-panel .ant-picker-body {
      padding-top: 2px;
    }

    .ant-picker-content th {
      font-size: 10px;
      color: ${Colors.orange};
      line-height: 26px;
      height: 26px;
    }

    .ant-picker-content td {
      padding: 4px 0;
    }

    .ant-picker-content th,
    .ant-picker-content td {
      min-width: 20px;
    }

    .ant-picker-cell {
      color: ${Colors.lightBlueGray};

      &:before {
        height: 20px;
      }
    }

    .ant-picker-cell-in-view {
      color: ${Colors.textColor};
    }

    .ant-picker-cell .ant-picker-cell-inner {
      font-size: 12px;
      line-height: 20px;
      min-width: 20px;
      height: 20px;
      border-radius: 6px;
    }

    .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
    .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
    .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
      background-color: ${Colors.orange};
      font-weight: bold;
    }

    .ant-picker-cell-in-view.ant-picker-cell-today {
      .ant-picker-cell-inner {
        color: ${Colors.orange};
        font-weight: bold;

        &:before {
          display: none;
        }
      }
    }

    .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
      color: #ffffff;
    }
  }

  .ant-picker-header {
    border-bottom: none;
    margin-top: 6px;

    .ant-picker-prev-icon,
    .ant-picker-next-icon,
    .ant-picker-super-prev-icon,
    .ant-picker-super-next-icon,
    .ant-picker-header-super-prev-btn,
    .ant-picker-header-super-next-btn {
      display: none;
    }

    .ant-picker-header-next-btn,
    .ant-picker-header-prev-btn,
    .ant-picker-header-super-prev-btn,
    .ant-picker-header-super-next-btn {
      background: url(${arrowLeftIcon}) no-repeat;
      background-size: 24px;
      background-position: 6px center;
    }

    .ant-picker-header-next-btn,
    .ant-picker-header-super-next-btn {
      transform: rotateY(180deg);
    }
  }

  .ant-picker-year-panel {
    .ant-picker-header-super-prev-btn,
    .ant-picker-header-super-next-btn {
      display: inline-block !important;
    }
  }

  .ant-picker-header-view {
    .ant-picker-decade-btn {
      pointer-events: none;
    }

    button {
      color: ${Colors.textColor};
      padding: 0 6px;
      border-radius: 6px;
      font-size: 12px;
      height: 24px;
      line-height: 24px;
      font-weight: normal;

      &:hover {
        color: ${Colors.textColor};
        background: ${Colors.lightBlueGray};
        font-weight: 500;
      }

      &:not(:first-child) {
        margin-left: 0;
      }
    }
  }
`;

export type DatePicker = typeof AntdDatePicker;

export type IDatePickerProps = IInputCommonProps & PickerDateProps<Dayjs>;

export const DatePicker = React.forwardRef<DatePicker, IDatePickerProps>((props, ref) => (
  <InputWrapper
    inputReadOnly
    suffixIcon={<CalendarIcon />}
    allowClear={false}
    showToday={false}
    dropdownClassName={PICKER_PANEL_CLASSNAME}
    format="D . M . YYYY"
    {...props}
    forwardedRef={ref}
    Component={StyledDatePicker}
  />
));
