import Slider from "@material-ui/core/Slider";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import React from "react";
import { Theme } from "@material-ui/core";
import { useWindowSize } from "../utils/hooks/useWindowSize";
import size from "../config/devices";
import { Size } from "../utils/hooks/useWindowSizeTypes";

export interface StyleProps {
  screenSize: boolean;
}
const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: {
    width: 300,
    [theme.breakpoints.down("sm")]: {
      width: 200,
    },
    [theme.breakpoints.up("md")]: {
      width: 250,
    },
    [theme.breakpoints.up("lg")]: {
      width: 380,
    },
  },
  margin: {
    height: theme.spacing(3),
  },
}));

export default function SliderRollover2(props: any) {
  const sizes: Size = useWindowSize();
  const tabletWidth = +size.tablet.slice(0, -2);
  const mobileView = () => {
    if (sizes.width) {
      if (sizes.width < tabletWidth) {
        return { screenSize: true };
      }
      return { screenSize: false };
    }
  };
  const classes = useStyles(mobileView());
  const mobile = mobileView();
  let StyledSlider: any;
  if (mobile?.screenSize) {
    StyledSlider = withStyles({
      root: {
        zIndex: 0,
        '& .MuiSlider-markLabel[data-index="0"]': {
          top: "27%",
          left: "-9% !important",
          color: "#1c1259",
          fontFamily: "Poppins ,sans-serif ",
          fontSize: "10px",
        },
        '& .MuiSlider-markLabel[data-index="1"]': {
          top: "27%",
          left: "114% !important",
          color: "#1c1259",
          fontFamily: "Poppins ,sans-serif ",
          fontSize: "10px",
        },
        "& .MuiSlider-thumb::after": {
          content: "",
          position: "absolute",
          width: "10px",
          height: "10px",
          borderRadius: "50%",
          background: "#1c1259",
          top: "7.5px",
          left: "7.5px",
          bottom: "0",
          right: "0",
        },
        "& .PrivateValueLabel-offset-41 MuiSlider-valueLabel WithStyles(ForwardRef(Slider))-valueLabel-86": {
          width: "max-content",
        },
        "& .PrivateValueLabel-label-43": {
          width: "max-content",
        },
        "& .PrivateValueLabel-circle-42": {
          width: "max-content",
        },
        "& .MuiSlider-marked": {
          zIndex: 0,
        },
      },

      thumb: {
        width: "25px",
        height: "25px",
        marginTop: "-9px",
        backgroundColor: "white",
        position: "relative",
        boxShadow: "0px 0px 2px 4px rgb(74 90 139 / 7%)",
      },
      active: {},
      valueLabel: {
        left: "calc(-50% + 8px)",
        top: -22,
        fontSize: "12px",
        fontWeight: 500,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.5,
        letterSpacing: "normal",
        textAlign: "center",
        color: "#bfbfbf",
        zIndex: 1,

        "& *": {
          background: "transparent",
          color: "#FF5277",
          fontWeight: 600,
        },
      },

      rail: {
        height: 2,
        opacity: 0.5,
        backgroundColor: "#bfbfbf",
      },
      markLabel: {
        fontFamily: '"Poppins"',
        fontSize: "12px",
        fontWeight: 600,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 2,
        letterSpacing: "normal",
        textAlign: "center",
        color: "#d5daeb",
        zIndex: 0,
      },
      markLabelActive: {
        fontSize: "12px",
        fontWeight: 600,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 2,
        letterSpacing: "normal",
        textAlign: "center",
        color: "#d5daeb",
      },
      markActive: {
        opacity: 1,
        backgroundColor: "#bfbfbf",
      },
    })(Slider);
  } else {
    StyledSlider = withStyles({
      root: {
        zIndex: 0,
        '& .MuiSlider-markLabel[data-index="0"]': {
          top: "5%",
          left: "-5% !important",
          color: "#1c1259",
          fontFamily: "Poppins ,sans-serif ",
          fontSize: "14px",
        },
        '& .MuiSlider-markLabel[data-index="1"]': {
          top: "5%",
          left: "108% !important",
          color: "#1c1259",
          fontFamily: "Poppins ,sans-serif ",
          fontSize: "14px",
        },
        "& .MuiSlider-thumb::after": {
          content: "",
          position: "absolute",
          width: "10px",
          height: "10px",
          borderRadius: "50%",
          background: "#1c1259",
          top: "7.5px",
          left: "7.5px",
          bottom: "0",
          right: "0",
        },
        "& .PrivateValueLabel-offset-41 MuiSlider-valueLabel WithStyles(ForwardRef(Slider))-valueLabel-86": {
          width: "max-content",
        },
        "& .PrivateValueLabel-label-43": {
          width: "max-content",
        },
        "& .PrivateValueLabel-circle-42": {
          width: "max-content",
        },
        "& .MuiSlider-marked": {
          zIndex: 0,
        },
      },

      thumb: {
        width: "25px",
        height: "25px",
        marginTop: "-9px",
        backgroundColor: "white",
        position: "relative",
        boxShadow: "0px 0px 2px 4px rgb(74 90 139 / 7%)",
      },
      active: {},
      valueLabel: {
        left: "calc(-50% + 8px)",
        top: -22,
        fontSize: "16px",
        fontWeight: 500,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.5,
        letterSpacing: "normal",
        textAlign: "center",
        color: "#bfbfbf",
        zIndex: 1,
        "& *": {
          background: "transparent",
          color: "#FF5277",
          fontWeight: 600,
        },
      },

      rail: {
        height: 2,
        opacity: 0.5,
        backgroundColor: "#bfbfbf",
      },
      markLabel: {
        fontFamily: '"Poppins"',
        fontSize: "12px",
        fontWeight: 600,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 2,
        letterSpacing: "normal",
        textAlign: "center",
        color: "#d5daeb",
        zIndex: 0,
      },
      markLabelActive: {
        fontSize: "12px",
        fontWeight: 600,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 2,
        letterSpacing: "normal",
        textAlign: "center",
        color: "#d5daeb",
      },
      markActive: {
        opacity: 1,
        backgroundColor: "#bfbfbf",
      },
    })(Slider);
  }
  return (
    <div className={classes.root}>
      <StyledSlider
        ref={props.register}
        step={props.step}
        marks={props.marks}
        min={Number(props.min)}
        max={props.max}
        valueLabelDisplay="on"
        {...props}
      />
    </div>
  );
}
