import styled, { css } from "styled-components";
import { Carousel as ReactCarousel } from "react-responsive-carousel";
import { buttonActionStyle, Colors } from "assets/styles/constants";

export const Carousel = styled(ReactCarousel)`
  li {
    cursor: pointer;
  }
`;

export const CarouselItem = styled.img`
  max-height: 380px;
  width: 100%;
  background-color: #f2f2f2;
  object-fit: cover;
`;

export const Indicator = styled.div<{ $active: boolean }>`
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.8);

  &:not(:last-child) {
    margin-right: 10px;
  }

  ${(p) =>
    p.$active &&
    css`
      border: solid 1px ${Colors.lightGrey};
      background-color: rgba(28, 18, 89, 0.8);
    `}

  ${buttonActionStyle}
`;
