import styled, { css } from "styled-components";

import { Colors } from "assets/styles/constants";

export type ButtonColors = [string, string?];
export type Orientations = "vertical" | "horizontal";

interface IButtonBaseStyleProps {
  $colors: ButtonColors;
  $inverse: boolean;
  $border: boolean;
  $orientation: Orientations;
  $height: number;
  $disabled: boolean;
}

const horizontalGradientStyle = ([color1, color2]: ButtonColors) => css`
  background-image: linear-gradient(to right, ${color1} 0%, ${color2} 50%, ${color1} 100%);
  background-size: 200% auto;

  &:hover {
    background-position: right center;
  }
`;

const verticalGradientStyle = ([color1, color2]: ButtonColors) => css`
  background-image: linear-gradient(to bottom, ${color1} 0%, ${color2} 50%, ${color1} 100%);
  background-size: auto 200%;

  &:hover {
    background-position: left bottom;
  }
`;

const singleColorStyle = (color: string, inverse: boolean) => css`
  background-color: ${inverse ? "transparent" : color};

  &:hover {
    opacity: 0.9;
  }
`;

const disabledStyle = (inverse: boolean) => css`
  ${!inverse && `background-color:  ${Colors.lightBlueGray};`}
  pointer-events: none;
`;

export const StyledButtonBase = styled.button<IButtonBaseStyleProps>`
  color: ${({ $inverse, $colors }) => ($inverse ? $colors[0] : "white")};
  transition: background-position 0.2s linear, opacity 0.2s;
  height: ${({ $height }) => $height}px;
  border-radius: ${({ $height }) => $height / 2}px;
  font-size: 16px;
  font-weight: 600;
  border-width: ${({ $border }) => ($border ? 2 : 0)}px;
  border-style: solid;
  border-color: ${({ $colors }) => $colors[0]};
  position: relative;

  ${({ $disabled, $inverse, $colors, $orientation }) => {
    if ($disabled) {
      return disabledStyle($inverse);
    }

    if ($colors.length === 1) {
      return singleColorStyle($colors[0], $inverse);
    }

    if ($orientation === "horizontal") {
      return horizontalGradientStyle($colors);
    }

    return verticalGradientStyle($colors);
  }}

  &:active {
    opacity: 0.7;
  }
`;

export const ButtonBaseContent = styled.div<{ $visible?: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: ${({ $visible }) => ($visible ? "visible" : "hidden")};
`;

export const LoaderWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: inherit;
  border-radius: inherit;
  z-index: 2;
`;
