export interface OnboardingDataLayer {
  goals?: string[] | null;
  peakHours?: string | null;
  activities?: string[] | null;
  ptoBalanceDays?: number | null;
}

export interface ExperienceFilters {
  scoops?: number[] | null;
  activities?: string[] | null;
  brand?: string | null;
  favorites?: string | null;
  priceRange?: string | null;
}

export interface ExperienceDataLayer {
  MFPImpression?: string[] | null;
  MFPChosenSorbet?: string | null;
  TrendingImpression?: string[] | null;
  TrendingChosenSorbet?: string | null;
  PDPChosenSorbet?: string | null;
  FilterName?: ExperienceFilters;
  YMALImpression?: string[] | null;
  YMALChosenSorbet?: string | null;
}

export enum GTMUserState {
  LOGIN = "login",
  NO_LOGIN = "no-login",
}

export interface GeneralDataLayer {
  userId: number | null;
  userState: GTMUserState;
  pageTitle: string;
}

export enum GTMSorbetStatus {
  EXIST = "Exist",
  NOT_EXIST = "Not exist",
}

export interface CalendarEventDataLayer {
  SorbetName?: string;
  SorbetStatus?: GTMSorbetStatus;
}

export enum ExperienceDataLayerType {
  YMAL = "YMAL",
  TRENDING = "Trending",
  MFP = "MFP",
  PDP = "PDP",
}

export type GTMSocialType = "Facebook" | "LinkedIn" | "Twitter";

export interface IGTMService {
  /**
   * Init the service
   */
  init: () => void;

  updateOnboardingDataLayer: (data: OnboardingDataLayer) => void;

  updateGeneralDataLayer: (data: GeneralDataLayer) => void;

  updateCalendarEventDataLayer: (data: CalendarEventDataLayer) => void;

  updateExperienceFilters: (data: ExperienceFilters) => void;

  clearExperienceFilters: () => void;

  clearExperienceDataLayer: () => void;

  clearOnboardingDataLayer: () => void;

  onPDPExploreBtnClick: (sorbetTitle: string) => void;

  onPDPBookBtnClick: (sorbetTitle: string) => void;

  onSocialsShareBtnClick: (
    layerType: ExperienceDataLayerType,
    socialType: GTMSocialType,
    sorbetTitle: string,
  ) => void;

  onExperienceItemClick: (layerType: ExperienceDataLayerType, sorbetTitle: string) => void;

  onSaveExperienceBtnClick: (layerType: ExperienceDataLayerType, sorbetTitle: string) => void;

  onVisibleImpressionUpdated: (layerType: ExperienceDataLayerType, impression: string[]) => void;
}
