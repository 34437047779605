import React, { FC } from "react";
import Button from "@material-ui/core/Button";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { CommonFormElement } from "calculator_feature/common/types";
import { useWindowSize } from "../utils/hooks/useWindowSize";
import size from "../config/devices";
import { Size } from "../utils/hooks/useWindowSizeTypes";

export interface StyleProps {
  screenSize: boolean;
}
const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  root: {
    padding: "5px 75px 5px 75px",
    minWidth: ({ screenSize }) => (screenSize ? "150px" : "216px"),
    height: ({ screenSize }) => (screenSize ? "35px" : "48px"),
    borderRadius: "30px",
    backgroundColor: "#ffae00",
    fontSize: ({ screenSize }) => (screenSize ? "14px" : "24px"),
    fontWeight: 500,
    fontStretch: "normal",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Poppins",
    textTransform: "none",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#ffae00",
      color: "#fff",
      boxShadow:
        "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    },
  },
  "& .MuiButton-contained.Mui-disabled": {},
}));

export const PrimaryButtonSend: FC<CommonFormElement> = ({
  children,
  disabled,
  type,
  ...props
}) => {
const sizes: Size = useWindowSize();
const tabletWidth = +size.tablet.slice(0, -2);
const mobileView = () => {
  if (sizes.width) {
    if (sizes.width < tabletWidth) {
      return { screenSize: true };
    }
    return { screenSize: false };
  }
};

const classes = useStyles(mobileView());

  return (
    <Button
      type="submit"
      variant="contained"
      color="primary"
      disabled={Boolean(disabled)}
      // className={classes.text}
      className={classes.root}
      {...props}>
      {children}
    </Button>
  );
};
