import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";

import { Wrapper, FlavorImage, ImageWrapper } from "./styles";
import { IPromotionData } from "services/api/types";

import placeholderImg from "assets/images/placeholder-image.jpg";

interface IProps extends RouteComponentProps {
  items?: IPromotionData[];
}

/**
 * Amount of banners to show
 */
const BANNERS_TO_SHOW = 2;

const FeaturedComponent = ({ items, history }: IProps) => {
  if (!items) {
    return (
      <Wrapper>
        <ImageWrapper>
          <FlavorImage src={placeholderImg} />
        </ImageWrapper>
        <ImageWrapper>
          <FlavorImage src={placeholderImg} />
        </ImageWrapper>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      {items.slice(0, BANNERS_TO_SHOW).map(({ promotionId, promotionImageUrl, promotionLink }) => (
        <ImageWrapper key={promotionId} onClick={() => history.push(promotionLink)}>
          <FlavorImage src={promotionImageUrl} loading="lazy" />
        </ImageWrapper>
      ))}
    </Wrapper>
  );
};

export const Featured = withRouter(FeaturedComponent);
