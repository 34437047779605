import React from "react";
import NumberFormat from "react-number-format";
import CancelSharpIcon from "@material-ui/icons/CancelSharp";
export function NumberFormatCustom(props: any) {
  const { inputRef, onChange, ...other } = props;
  return (
    <>
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        prefix={"$"}
        allowNegative={false}
      />
      <CancelSharpIcon
        style={{
          color: "#D5DAEB",
          width: "14px",
          height: "14px",
          cursor: "pointer",
        }}
        onClick={props.onClick}
      />
    </>
  );
}

export function NumberFormatEmployee(props: any) {
  const { inputRef, onChange, ...other } = props;
  return (
    <>
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        allowNegative={false}
        allowLeadingZeros={false}
      />
      <CancelSharpIcon
        style={{
          color: "#D5DAEB",
          width: "14px",
          height: "14px",
          cursor: "pointer",
        }}
        onClick={props.onClick}
      />
    </>
  );
}
